import { app } from './Api'

const post_culture = async (data) => {
    return app.post(`culture`, data)
}

const get_view_culture = async (id) => {
    return app.get(`culture/${id}`)
}

const put_culture = async (id,data) => {
    return app.put(`culture/${id}`,data)
}
const get_cultures = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`culture?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const changeStatus =  async (id,data) => {
    return app.put(`culture/change_status/${id}`,data)
}


export { post_culture, get_view_culture, get_cultures, put_culture, changeStatus }