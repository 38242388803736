import './style.css';
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Wrapper, LogoStyle, Content, ContentRemember, Container, ButtonContainner } from './styles'
import { authentication } from '../../Services/auth'
import Alert from '../../Alerts/Alert'
import RehagroFolhaBranco from '../../Icons/rehagro 2_branco 2.png';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';

const useStyles = makeStyles((theme) => ({
    margin: {
        '& label.Mui-focused': {
            color: '#FFF',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#FFF',
            color: '#FFF'
        },
        
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #FFF'
        },
        '& .MuiInputBase-input':{
            opacity: '0.42',
            borderBottom: '1px solid #FFF',
            color: '#FFF'
        },
        '& .MuiFormLabel-root':{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '19px',
            letterSpacing: '0.15px',
            color: '#FFF',
            
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderColor: "#FFF"
        },
        ' & .MuiInput-underline:after': {
           
            borderBottom: '2px solid #FFF'
          
        },
    width: '100%',
        margin: theme.spacing(1),
    },
}));

function Login(props) {
    const classes = useStyles();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    // const [success, setSuccess] = useState('')
    const [type, setType] = useState('password')


    const handleForm = (e) => {
        e.preventDefault();
        const data = { email: email, password: password }
        authentication(data)
            .then(res => {
                localStorage.setItem("token", res.data.access_token);
                props.setLogin(res.data.user)
            })
            .then(res => props.history.push('/index'))

            .catch(e => setError(e.response.data.error))
    }

    const changeType = (e) => {
        if(type === 'password'){
            setType('text')
        }else{
            setType('password')
        }
    }

    const handleEmail = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setEmail(value)
    }

    const handlePassword = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setPassword(value)
    }

    return (
        <>
            <Container >

                <Wrapper>

                

                    {/* <div className="login-box">             */}
                    {/* /.login-logo */}
                    {/* <div className="card"> */}
                    <Content>
                        <LogoStyle>
                            <img src={RehagroFolhaBranco} width="200px" alt="Logo Rehagro" className="d-block" />
                        </LogoStyle>
                        <div className="card-body login-card-body" style={{ backgroundColor: '#2A4038' }}>
                            <h1 className='title-principal'>BEM VINDO(A) AO APP VISITA E REEMBOLSO</h1>
                            <h2 className='title-principal subtitle'>Digite seu email de cadastro</h2>
                        {error.errors ? (<p className="errors" style={{ color: '#DC143C' }}>{error.errors}</p>) : ''}
                        <i style={{ marginLeft: '125px'}} className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-2 mt-1"></i>
                        {/* <h5 className="login-box-msg">Entre com suas credenciais</h5> */}
                        {/* <p className="login-box-msg">Entre com suas credenciais</p> */}
                        {
                            error ? (
                                <div>
                                    <Alert type="danger" hidden="true">
                                        {error}
                                    </Alert>
                                </div>
                            ) : ('')
                        }
                        <form onSubmit={handleForm}>
                            <div className="input-group">
                                <TextField
                                    className={classes.margin}
                                    label={email ? "Email" : ''}
                                    name="email" 
                                    placeholder="Escreva aqui seu e-mail"
                                    onChange={handleEmail} 
                                    value={email}
                                    type="email" 
                                    InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <PersonOutlineOutlinedIcon   style={{fill: "#FFF"}}/>
                                        </InputAdornment>
                                    ),
                                    }}
                                />

                            {/* <InputMaterial  
                                label={!email ? "Escreva aqui seu e-mail" : ""}
                                name="email" 
                                placeholder="Escreva aqui seu e-mail"
                                onChange={handleEmail} 
                                value={email}
                                type="email" 
                            />
                                {!email && <PermIdentityIcon className='icon-user' style={{fontSize:'25px'}}/>} */}
                            </div>
                            <div className="mb-3 input-group">
                            <TextField
                                    className={classes.margin}
                                    label={password ? "Senha" : ""} 
                                    name="password" 
                                    placeholder="Escreva aqui sua senha"
                                    onChange={handlePassword} 
                                    value={password}
                                    type={type} 
                                    InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <VpnKeyOutlinedIcon   style={{fill: "#FFF"}}/>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                                {/* <InputMaterial  
                                    label={!password ? "Escreva aqui sua senha" : ""} 
                                    name="password" 
                                    placeholder=""
                                    onChange={handlePassword} 
                                    value={password}
                                    type={type} 
                                    // required={true} 
                                /> */}
                                {/* <div  className="input-group-append"> */}
                                {/* <div className="input-group-text"> */}
                                {/* {!password && <span className="icon-password fa fa-lock"/>} */}
                                <button  type="button" onClick={changeType} className="btn" style={{border: '0px solid transparent'}}><span className="icon-view-password fa fa-eye"/></button>
                                {/* </div> */}
                                {/* </div> */}
                            </div>

                            <div className="social-auth-links mb-4 mt-3">
                                <ContentRemember>
                                    <p className="mb-1">
                                        <a href="/send_email_remember">Esqueceu sua senha? <span style={{color: '#2DBF79'}}>Clique Aqui</span></a>
                                    </p>
                                </ContentRemember>

                            </div>

                            <div className="row" style={{justifyContent: 'flex-end'}}>
                                {/* <div className="col-8">
                                            <div className="icheck-primary">
                                                <input type="checkbox" id="remember" />
                                                <label htmlFor="remember">
                                                Lembrar-me
                                                </label>
                                            </div>
                                        </div> */}

                                <div className="col-md-6">
                                    <ButtonContainner className='button'>
                                        <button style={{ borderRadius: '25px' }} type="submit" className="btn btn-login btn-block">Entrar</button>
                                    </ButtonContainner>
                                </div>
                            </div>
                        </form>


                        </div>
                    {/* /.login-card-body */}
                    </Content>
                {/* </div> */}
                {/* </div> */}

                </Wrapper>

            </Container>


            {/* /.login-box */ }
        </ >
    )

}
const mapDispatchToProps = dispatch => {
    return {
        setLogin: (user) => dispatch({ type: "SET_LOGIN", payload: user })
    }
}
export default connect(null, mapDispatchToProps)(Login);