import { getProjectAmendment } from '../../../../Services/projectAmendment'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[
        
        (dispatch,getState)=>{
        const order = getState().project_amendment.order
        const th = getState().project_amendment.th
        const qtd_per_page = getState().project_amendment.qtd_per_page
        const pesquisa = getState().project_amendment.pesquisa || ''
        const id_coordinator = getState().project_amendment.coordinator_report.value || ''
        const id_status = getState().project_amendment.status_report.value || ''
        const id_data = getState().project_amendment.filter_data.value || ''
        const area_report = getState().project_amendment.area_report.value || ''
        const project_report = getState().project_amendment.project_report.value || ''
        const from = getState().project_amendment.from
        const to = getState().project_amendment.to 
        const page = getState().project_amendment.page     
      
        getProjectAmendment(page,qtd_per_page,order,th,pesquisa,id_coordinator,area_report,project_report,id_status,from,to, id_data)
        .then(resp=>dispatch({type:'ProjectAmendment', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().project_amendment.asc
      
        if(asc === true){
            dispatch({type:'GetProjectAmendment_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetProjectAmendment_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_ProjectAmendment',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaProjectAmendment',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[(dispatch, getState) =>{
        const fromFix = getState().project_amendment.fromFix
        const toFix = getState().project_amendment.toFix
        dispatch({
            type:'clearPesquisaProjectAmendment',
            payload:'',
            coordinator_report: '',
            area_report: '' , 
            status_report: '',
            from: fromFix ,
            to: toFix,
            project_report: '',
            filter_data: {value: '1', label: 'Data de lançamento'}

        })
    },get(1)]
}


export const changePage = page => {
    return[{
        type: 'changePageProjectAmendment',
        payload: page
    },get()]
}

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().project_amendment.pesquisa || ''
            const id_coordinator = getState().project_amendment.coordinator_report.value || ''
            const id_status = getState().project_amendment.status_report.value || ''
            const from = getState().project_amendment.from
            const to = getState().project_amendment.to 
            if(pesquisa || id_coordinator || id_status || from || to){
                return dispatch({type:'changePageProjectAmendment', payload:1})
            }
        }, get()
    ]
}