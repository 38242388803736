export function defineYears(){
    let table = []
    let object = {}
    let numYears = 2100
    
    for(let i = 2021; i < numYears ; i++){
        object.label = i; 
        object.value = i;
        table.push(object)
        object = {}   
    }
    return table

}