import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Input from '../../../Forms/Input';
import { useSelector, useDispatch } from 'react-redux';
import { getResource } from '../../../Services/projectEnsino';

export default function ReportVisit(props) {
    const [area, setArea] = useState(
        useSelector((state) => state.projectEnsino.area)
    );
    const [areas, setAreas] = useState([]);
    const [coordinator, setCoordinator] = useState(
        useSelector((state) => state.projectEnsino.coordinator)
    );
    const [coordinators, setCoordinators] = useState([]);
    const [status, setStatus] = useState(
        useSelector((state) => state.projectEnsino.status)
    );
    const [statusOptions, setStatusOptions] = useState([]);
    const [manager, setManager] = useState(
        useSelector((state) => state.projectEnsino.manager)
    );
    const [managers, setManagers] = useState([]);
    const [cod_centro_custo, setCentroCusto] = useState(
        useSelector((state) => state.projectEnsino.centro_custo)
    );
    const [cod_centro_custos, setCentroCustos] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        resource();
    }, []);

    const resource = () => {
        getResource().then((resp) => {
            setAreas(
                resp.data.areas.map((desc) => ({
                    value: desc.value,
                    label: desc.label,
                }))
            );
            setCoordinators(
                resp.data.usersFilter.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setStatusOptions(
                resp.data.status.map((desc) => ({
                    value: desc.value,
                    label: desc.label,
                }))
            );
            setManagers(
                resp.data.usersFilter.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setCentroCustos(
                resp.data.project_ensino.map((desc) => ({
                    value: desc.id,
                    label: desc.cod_centro_custo,
                }))
            );
        });
    };

    const handleArea = (e) => {
        setArea(e);
        dispatch({ type: 'areaProjectEnsino', payload: e });
    };

    const handleManager = (e) => {
        setManager(e);
        dispatch({ type: 'managerProjectEnsino', payload: e });
    };

    const handleCentroCusto = (e) => {
        setCentroCusto(e);
        dispatch({ type: 'centroCustoProjectEnsino', payload: e });
    };

    const handleCoordenador = (e) => {
        setCoordinator(e);
        dispatch({ type: 'coordenadorProjectEnsino', payload: e });
    };

    const handleStatus = (e) => {
        setStatus(e);
        dispatch({ type: 'statusProjectEnsino', payload: e });
    };
    return (
        <>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 mt-4">
                        <label>Área</label>
                        <Select
                            value={area}
                            label="Single select"
                            options={areas}
                            onChange={handleArea}
                        />
                    </div>
                    {/* <div className="col-md-12 mt-4">
                        <label>Gerente</label>
                        <Select
                            value={manager}
                            label="Single select"
                            options={managers}
                            onChange={handleManager}
                        />
                    </div> */}
                    <div className="col-md-12 mt-4">
                        <label>Centro de Custo</label>
                        <Select
                            value={cod_centro_custo}
                            label="Single select"
                            options={cod_centro_custos}
                            onChange={handleCentroCusto}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <label>Coordenador</label>
                        <Select
                            value={coordinator}
                            label="Single select"
                            options={coordinators}
                            onChange={handleCoordenador}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <label>Status</label>
                        <Select
                            value={status}
                            label="Single select"
                            options={statusOptions}
                            onChange={handleStatus}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
