import React from 'react'
import { Link } from 'react-router-dom';

export function goButton(refunds,status,changeStatusReimbursementAll,perfis){
    let get_refunds= refunds || []
    var filtrado = get_refunds.map(desc => (
        desc.id_status
    )).filter(function(elem, pos, arr) {
        return parseInt(arr.indexOf(elem)) === parseInt(pos);
    });

    if(filtrado.length === 1 && status.length > 0){
        switch(filtrado[0]){
            case 1:
                return (
                    <>
                        <Link 
                            to={`#/`} 
                            className='btn btn-outline-success btn-sm'
                            style={{margin: '5px'}}
                            onClick={()=>changeStatusReimbursementAll(2)}
                        >
                            <i className='fas fa-check-square' /> Aprovar Selecionados
                            
                        </Link>
                    </> 

                )
            case 2:
                return(
                    <>
                        <Link 
                            to={`#/`} 
                            className='btn btn-outline-warning btn-sm'
                            style={{margin: '5px'}}
                            onClick={()=>changeStatusReimbursementAll(1)}
                        >
                            <i className='fas fa-spinner' /> Em Análise Selecionados
                        </Link>
                        {perfis.indexOf(1) >=0 || perfis.indexOf(2) >=0 || perfis.indexOf(3) >=0 || perfis.indexOf(6) >=0 ? (
                            <Link 
                                to={`#/`} 
                                className='btn btn-outline-info btn-sm'
                                style={{margin: '5px'}}
                                onClick={()=>changeStatusReimbursementAll(5)} 
                            >
                                <i className='fas fa-money-bill-wave' /> Enviar W3 Selecionados
                            </Link>                 
                        ) : ''}
                    </>
                )
            case 3:
                return (
                    <>
                        <Link 
                            to={`#/`} 
                            className='btn btn-outline-warning btn-sm'
                            style={{margin: '5px'}}
                            onClick={()=>changeStatusReimbursementAll(1)}
                        >
                            <i className='fas fa-spinner' /> Em Análise Selecionados
                        </Link>
                        <Link 
                            to={`#/`} 
                            className='btn btn-outline-success btn-sm'
                            style={{margin: '5px'}}
                            onClick={()=>changeStatusReimbursementAll(2)}
                        >
                            <i className='fas fa-check-square' /> Aprovar Selecionados
                        </Link>                 
                    </>
                )
            default:
                return ''
        }
    }
}