import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import StylePage from '../../../Style Page/StylePage';
import Form from '../../../Forms/Form';
import Input from '../../../Forms/Input';
import Footer from '../../../Footer/Footer';
import TextArea from '../../../Forms/TextArea';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import {
    getTechnical,
    getProjects,
    getRemuneration,
    post_visit,
    put_visit,
    get_view_visit,
    getExtraCharges,
} from '../../../Services/visit';
import { useSelector } from 'react-redux';
import AlertNew from '../../../Alerts/AlertNew';
import Alert from '../../../Alerts/Alert';
import RadioMaterial from '../../../Forms/RadioMaterial';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MyModal from '../../../Modal/MyModal';


export default function Visit(props) {

    let history = useHistory();
    const selectTechnicianRef = useRef(null);
    const selectProjectRef = useRef(null);
    const selectRemunerationRef = useRef(null);
    const selectExtraChargeRef = useRef(null);
    const role = useSelector(state => state.auth.user.perfis);
    const id_user = useSelector(state => state.auth.user.id);
    const [loading, setLoading] = useState(false);
    const [technical, setTechnical] = useState([]);
    const [projects, setProjects] = useState([]);
    const [remuneration, setRemuneration] = useState([]);
    const [id_technician, setIdTechnician] = useState([]);
    const [id_project, setIdProject] = useState([]);
    const [id_remuneration, setIdRemuneration] = useState([]);
    const [description, setDescription] = useState('');
    const [date, setDate] = useState(useSelector(state => state.visit.date));
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState('');
    const [alert, setAlert] = useState('');
    const [created_by, setCreated] = useState('');
    const [id_status, setIdStatus] = useState('');
    const [moduleVisit, setModuleVisit] = useState('Projeto');
    const [idExtraCharge, setIdExtraCharge] = useState('');
    const [extraCharges, setExtraCharges] = useState([]);
    const [modalAproved, setModalAproved] = useState(false);
    const perfis = useSelector(state => state.auth.user.perfis) || [];
    useEffect(() => {
        permissionRole();
        return () => {
            permissionRole();
        };
    }, []);

    useEffect(() => {
        Technical();
    }, []);

    useEffect(() => {
        viewPerId();
    }, []);

    useEffect(() => {
        getAllExtraCharges();
    }, [id_technician, id_project]);

    // useEffect(() => {
    //     remunerationExtraCharge();
    // }, [idExtraCharge])

    // useEffect(() => {
    //     isEdit();
    // }, [props.match.params.action])

    //Função que verifica se o usuário tem permissão para acessar a edição do registro
    function permissionRole() {
        if (perfis.length === 1 && perfis.indexOf(10) >= 0) {
            return props.history.push('/index');
        }
    }

    function isEdit(created, status) {
        if (props.match.params.action === 'edit') {
            if (parseInt(created) !== parseInt(id_user) || parseInt(status) !== 1) {
                props.history.push(`/visit/${props.match.params.id}/view`);
            }
        }
    }


    function Technical() {
        getTechnical()
            .then(resp => {
                setTechnical(
                    resp.data.map(desc => ({
                        value: desc.id,
                        label: desc.name,
                    })),
                );
                if (role.indexOf(7) >= 0) {
                    let myUser = resp.data.filter(resp => resp.id === id_user);
                    setIdTechnician({
                        value: myUser[0].id,
                        label: myUser[0].name,
                    });
                    // setIdTechnician(myUser.map(desc => ({
                    //     value: desc.id,
                    //     label: desc.name
                    // })))
                    Projects(id_user, moduleVisit);
                }
            });
    }

    function getAllExtraCharges(tecnico, projeto) {
        getExtraCharges(tecnico || id_technician.value, projeto || id_project.value)
            .then(resp => {
                setExtraCharges(
                    resp.data.map(desc => ({
                        value: desc.id,
                        label: desc.codigo,
                        valor: desc.valor,
                        data_inicio: desc.data_inicio_cobranca,
                        data_fim: desc.data_fim_cobranca,
                    })),
                );
            });
    }

    function Projects(id, moduleVisit) {
        getProjects(id, moduleVisit)
            .then(resp => {
                setProjects(
                    resp.data.map(desc => ({
                        value: desc.id,
                        label: desc.titulo,
                        data_inicio: desc.data_inicio,
                        data_fim: desc.data_fim,
                    })),
                );
            });
    }

    function Remuneration(id_project) {
        const id = id_technician.value;
        getRemuneration(id, id_project)
            .then(resp => {
                setRemuneration(
                    resp.data.map(desc => ({
                        value: desc.id,
                        label: desc.descricao,
                        valor_remuneracao: desc.valor,
                    })),
                );
            });
    }

    // function remunerationExtraCharge() {
    //     const id = id_technician.value
    //     getRemunerationExtraCharge(id,idExtraCharge.value)
    //     .then(resp => {
    //         setRemuneration(
    //             resp.data.map(desc => ({
    //                 value: desc.id,
    //                 label: desc.descricao,
    //                 valor_remuneracao: desc.valor
    //             }))          
    //         )
    //     })
    // }

    function viewPerId() {
        props.match.params.action && (
            get_view_visit(props.match.params.id)
                .then(resp => {
                    setIdTechnician({
                        value: resp.data[0].id_technical || resp.data[0].id_tecnico_cobranca_extra,
                        label: resp.data[0].name || resp.data[0].tecnico_cobranca_extra,
                    });
                    setIdProject({
                        value: resp.data[0].id_project || resp.data[0].id_projeto_cobranca,
                        label: resp.data[0].project_title || resp.data[0].projeto_cobranca,
                        data_inicio: resp.data[0].data_inicio,
                        data_fim: resp.data[0].data_fim,
                    });
                    setIdRemuneration({
                        value: resp.data[0].id_remuneration || resp.data[0].id_remuneracao_cobranca,
                        label: resp.data[0].remuneration_description || resp.data[0].remuneracao_cobranca,
                        valor_remuneracao: resp.data[0].valor || resp.data[0].valor_cobranca_extra,

                    });
                    setCreated(resp.data[0].created_by);
                    setDescription(resp.data[0].visit_description);
                    setDate(resp.data[0].data_lancamento);
                    setIdStatus(resp.data[0].id_status);
                    Projects(resp.data[0].id_technical || resp.data[0].id_tecnico_cobranca_extra, resp.data[0].id_cobranca_extra ? 'Cobrança Extra' : 'Projeto');
                    Remuneration(resp.data[0].id_technical || resp.data[0].id_tecnico_cobranca_extra, resp.data[0].id_project || resp.data[0].id_projeto_cobranca);
                    isEdit(resp.data[0].created_by, resp.data[0].id_status);
                    setModuleVisit(resp.data[0].id_cobranca_extra ? 'Cobrança Extra' : 'Projeto');
                    if (resp.data[0].id_cobranca_extra) {
                        setIdExtraCharge({
                            value: resp.data[0].id_cobranca_extra,
                            label: resp.data[0].cobranca_extra,
                            data_inicio: resp.data[0].data_inicio_cobranca,
                            data_fim: resp.data[0].data_fim_cobranca,
                        });
                        getAllExtraCharges(resp.data[0].id_tecnico_cobranca_extra, resp.data[0].id_projeto_cobranca);
                    }

                })
        );
    }

    const changeTechnician = e => {
        setIdProject([]);
        setRemuneration([]);
        setIdRemuneration([]);
        setIdExtraCharge([]);
        setIdTechnician(e);
        Projects(e.value, moduleVisit);
    };

    const changeProject = e => {
        setIdRemuneration([]);
        setIdExtraCharge([]);
        setRemuneration([]);
        setIdProject(e);
        Remuneration(e.value);
    };

    const changeModule = e => {
        setIdProject([]);
        setIdExtraCharge([]);
        setIdRemuneration([]);
        setModuleVisit(e.target.value);
        Projects(id_technician.value, e.target.value);
    };

    const changeExtraCharge = e => {
        setIdExtraCharge(e);
    };

    const changeRemuneration = e => {
        setIdRemuneration(e);
    };

    const changeDate = e => {
        setDate(e.target.value);
    };

    const changeDescription = e => {
        setDescription(e.target.value);
    };

    // Modal para confirmar visita com valor 0
    const confirmValueVisit = (e) => {
        if(id_remuneration.valor_remuneracao <= 0 || idExtraCharge.valor <= 0) {
            setModalAproved(true);
        } else {
            handleForm(e);
        }
    }

    const handleForm = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            id_technical: parseInt(id_technician.value),
            id_project: parseInt(id_project.value),
            id_remuneration: moduleVisit === 'Projeto' ? parseInt(id_remuneration.value) : 1,
            idExtraCharge: moduleVisit === 'Cobrança Extra' ? parseInt(idExtraCharge.value) : null,
            description: description,
            date: date,
            confirmVisitZero: true // user confirmou visita com valor zero
        };

        if (props.match.params.action === 'edit') {
            const id = props.match.params.id;
            put_visit(id, data)
                .then(resp => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then(resp => setTimeout(() => {
                    window.location.reload();
                }, 2500))
                .catch(e => {
                    setLoading(false);
                    setSuccess('');
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        setError('');
                        setAlert('');
                    }, 3000);
                });

        } else {
            post_visit(data)
                .then(resp => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then(resp => setTimeout(() => {
                    window.location.reload();
                }, 2500))
                .catch(e => {
                    setLoading(false);
                    setSuccess('');
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        setError('');
                        setAlert('');
                    }, 3000);
                });

        }
    };

    //Função que formata o campo valor
    function formatReal(desc) {
        var tmp = `${desc.toFixed(2)}`.replace('.', ',');

        if (tmp.length > 6)
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, '.$1');
        return tmp;
    }

    function renderValor() {
        if (moduleVisit === 'Projeto') {
            if (id_remuneration.valor_remuneracao > 0) {
                return <Input
                    disabled
                    icon='fas fa-dollar-sign'
                    label='Valor'
                    type='text'
                    value={formatReal(parseFloat(`${id_remuneration.valor_remuneracao}`))}
                    placeholder=''
                />;

            } else {
                return <span></span>;
            }
        }

        if (moduleVisit === 'Cobrança Extra') {
            if (idExtraCharge.valor > 0) {
                return <Input
                    disabled
                    icon='fas fa-dollar-sign'
                    label='Valor'
                    type='text'
                    value={formatReal(parseFloat(`${idExtraCharge.valor}`))}
                    placeholder=''
                />;

            } else {
                return <span></span>;
            }
        }

    }

    function renderVigencia() {
        if (id_project.value || idExtraCharge.value) {

            return (
                <div className='row'>
                    <div className='col-md-6'>
                        <Input
                            icon='fas fa-calendar-alt'
                            label='Data Início'
                            type='date'
                            value={moduleVisit === 'Projeto' ? id_project.data_inicio : moduleVisit === 'Cobrança Extra' ? idExtraCharge.data_inicio : ''}
                            placeholder=''
                            disabled={true}
                        />
                    </div>
                    <div className='col-md-6'>
                        <Input
                            icon='fas fa-calendar-alt'
                            label='Data Fim'
                            type='date'
                            value={moduleVisit === 'Projeto' ? id_project.data_fim : moduleVisit === 'Cobrança Extra' ? idExtraCharge.data_fim : ''}
                            placeholder=''
                            disabled={true}
                        />
                    </div>
                </div>
            );

        } else {
            return <span></span>;
        }


    }

    return (
        <div className='wrapper'>
            <Sidebar />
            <Header />
            <StylePage
                title={props.match.params.action === 'edit' ? 'Edição de Visitas' : props.match.params.action === 'view' ? 'Visualização de Visitas' : 'Cadastro de Visitas'}>
                {loading === true ? (
                    <Content>
                        <ReactLoading type='spinningBubbles' color='blue' height={200} width={100} />
                    </Content>

                ) : ('')}
                {alert ? (
                    <div>
                        <Alert type='danger' onCloseAlert={() => setAlert('')}>
                            {alert}
                        </Alert>
                    </div>
                ) : ('')}
                {success ? (
                    <div>
                        <Alert type='success' onCloseAlert={() => setSuccess('')}>
                            {success}
                        </Alert>
                    </div>
                ) : ('')}
                <div style={{
                    width: '100%',
                    display: loading === true ? ('none') : ('block'),
                    opacity: success || alert ? 0.1 : 1,
                }}>
                    <div className="row">
                        <div className="col-md-12">
                            <MyModal
                                show={modalAproved}
                                onHide={(e) => setModalAproved(false)}
                                title="Aviso!"
                                content={
                                    <Form onSubmit={() => {}}>
                                        <div className='row mb-5'>
                                            <div className='col-12'>
                                                <span style={{fontSize: '16px', fontWeight: 700}}>Valor da visita é igual a zero e não computará no RMA.</span>
                                            </div>
                                            <div className='col-12'>
                                                <span style={{fontSize: '16px', fontWeight: 700}}>Deseja continuar com o registro dessa visita?</span>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={(e) => setModalAproved(false)}>Não</button>
                                            <button type="button" onClick={(e) => {
                                                setModalAproved(false)
                                                handleForm(e);
                                            }} className="btn btn-success">Sim</button>
                                        </div>
                                    </Form>
                                }
                                onSubmit={true}
                                cancel={(e) => setModalAproved(false)}
                                to="#/"
                                type="danger"
                                textButton="Reprovar"                   
                            />
                        </div>
                    </div>
                    {/* <Form onSubmit={handleForm}> */}
                    <Form onSubmit={() =>{}}>
                        <div className='row'>
                            <div className='col-md-3 mt-1'>
                                <RadioMaterial
                                    row
                                    label='Módulo de Lançamento ? *'
                                    aria-label='module'
                                    name='module'
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    value={moduleVisit}
                                    styleLabel={{
                                        color: '#BEBEBE',
                                        fontSize: '10px',
                                        marginBottom: '15px',
                                    }}
                                    onChange={changeModule}
                                >
                                    <FormControlLabel value='Projeto' control={<Radio />} label='Projeto' />
                                    <FormControlLabel value='Cobrança Extra' control={<Radio />}
                                                      label='Serviço Extra' />
                                </RadioMaterial>
                            </div>
                            <div className='col-md-3'>
                                <label>Técnico</label>
                                <Select
                                    value={id_technician}
                                    ref={selectTechnicianRef}
                                    label='Single select'
                                    options={technical}
                                    onChange={changeTechnician}
                                    isDisabled={props.match.params.action === 'view' ? true : false}

                                />
                                {id_technician.length <= 0 &&
                                    <input
                                        tabIndex={-1}
                                        autoComplete='off'
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={id_technician}
                                        onFocus={() => selectTechnicianRef.current.focus()}
                                        required={true}
                                    />
                                }
                                {error ? (<p style={{ color: 'red' }}>{error.id_technical}</p>) : ('')}
                                {/* {validationTechnical && <div className="mt-2"><Alert type="warning">{validationTechnical}</Alert></div>}  */}
                            </div>
                            <div className='col-md-3'>
                                <label>Projeto</label>
                                <Select
                                    value={id_project}
                                    label='Single select'
                                    options={projects}
                                    onChange={changeProject}
                                    ref={selectProjectRef}
                                    isDisabled={props.match.params.action === 'view' ? true : false}
                                />
                                {id_project.length <= 0 &&
                                    <input
                                        tabIndex={-1}
                                        autoComplete='off'
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={id_project}
                                        onFocus={() => selectProjectRef.current.focus()}
                                        required={true}
                                    />
                                }
                                {error ? (<p style={{ color: 'red' }}>{error.id_project}</p>) : ('')}
                                {/* {validationProject && <div className="mt-2"><Alert type="warning">{validationProject}</Alert></div>}  */}
                            </div>
                            {
                                moduleVisit === 'Cobrança Extra' &&
                                <div className='col-md-3'>
                                    <label>Serviço Extra</label>
                                    <Select
                                        value={idExtraCharge}
                                        label='Single select'
                                        options={extraCharges}
                                        onChange={changeExtraCharge}
                                        ref={selectExtraChargeRef}
                                        isDisabled={props.match.params.action === 'view' ? true : false}
                                    />
                                    {idExtraCharge.length <= 0 &&
                                        <input
                                            tabIndex={-1}
                                            autoComplete='off'
                                            style={{
                                                opacity: 0,
                                                width: '100%',
                                                height: 0,
                                                position: 'absolute',
                                            }}
                                            defaultValue={idExtraCharge}
                                            onFocus={() => selectExtraChargeRef.current.focus()}
                                            required={true}
                                        />
                                    }
                                    {error ? (<p style={{ color: 'red' }}>{error.idExtraCharge}</p>) : ('')}
                                    {/* {validationProject && <div className="mt-2"><Alert type="warning">{validationProject}</Alert></div>}  */}
                                </div>
                            }
                            {
                                moduleVisit === 'Projeto' &&
                                <div className='col-md-3'>
                                    <label>Remuneração</label>
                                    <Select
                                        value={id_remuneration}
                                        label='Single select'
                                        options={remuneration}
                                        onChange={changeRemuneration}
                                        ref={selectRemunerationRef}
                                        isDisabled={props.match.params.action === 'view' ? true : false}
                                    />
                                    {id_remuneration.length <= 0 &&
                                        <input
                                            tabIndex={-1}
                                            autoComplete='off'
                                            style={{
                                                opacity: 0,
                                                width: '100%',
                                                height: 0,
                                                position: 'absolute',
                                            }}
                                            defaultValue={id_remuneration}
                                            onFocus={() => selectRemunerationRef.current.focus()}
                                            required={true}
                                        />
                                    }
                                    {error ? (<p style={{ color: 'red' }}>{error.id_remuneration}</p>) : ('')}
                                    {/* {validationRemuneration && <div className="mt-2"><Alert type="warning">{validationRemuneration}</Alert></div>}  */}
                                </div>
                            }
                        </div>
                        {renderVigencia()}
                        <div className='row mt-4'>
                            <div className='col-md-6'>
                                <TextArea
                                    rows='6'
                                    label='Descricao'
                                    value={description}
                                    onChange={changeDescription}
                                    maxLength='255'
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.description}</p>) : ('')}
                            </div>
                            <div className='col-md-6'>
                                <Input
                                    icon='fas fa-calendar-alt'
                                    label='Data da Visita'
                                    onChange={changeDate}
                                    type='date'
                                    value={date}
                                    min={moduleVisit === 'Projeto' ? id_project.data_inicio : moduleVisit === 'Cobrança Extra' ? idExtraCharge.data_inicio : ''}
                                    max={moduleVisit === 'Projeto' ? id_project.data_fim : moduleVisit === 'Cobrança Extra' ? idExtraCharge.data_fim : ''}
                                    // max={`${(parseInt(data_atual.split("-")[0]))+4}-${data_atual.split("-")[1]}-${data_atual.split("-")[2]}`}  
                                    // min={`${(parseInt(data_atual.split("-")[0])-4)}-${data_atual.split("-")[1]}-${data_atual.split("-")[2]}`}  
                                    placeholder=''
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.date}</p>) : ('')}
                                <div className='col-md-6'>
                                    {renderValor()}
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div className='text-right'>
                            <button style={{ marginRight: '10px' }} type='button' className='btn btn-secondary'
                                    onClick={() => history.goBack()}>Voltar
                            </button>
                            {props.match.params.action === 'view' ? (
                                <Link
                                    style={{
                                        cursor: created_by !== id_user || id_status !== 1 ? 'not-allowed' : '',
                                        color: created_by !== id_user || id_status !== 1 ? '#fff' : '',
                                        backgroundColor: created_by !== id_user || id_status !== 1 ? '#007bff' : '',
                                        borderColor: created_by !== id_user || id_status !== 1 ? '#007bff' : '',
                                        opacity: created_by !== id_user || id_status !== 1 ? .65 : '',
                                        boxShadow: created_by !== id_user || id_status !== 1 ? 'none' : '',

                                    }}
                                    onClick={created_by !== id_user || id_status !== 1 ? e => e.preventDefault() : ''}
                                    to={`/visit/${props.match.params.id}/edit`}
                                    className='btn btn-primary'>Editar</Link>
                                // <Link to={`/visit/${props.match.params.id}/edit`} className="btn btn-primary">Editar</Link>
                            ) : (
                                // <button type='submit' className='btn btn-primary'>Salvar</button>
                                <button type='button' onClick={(e) => confirmValueVisit(e)} className='btn btn-primary'>Salvar</button>
                            )}
                        </div>
                    </Form>
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}
