/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import Footer from '../../../Footer/Footer';
import Table from '../../../Table/Table';
import Thead from '../../../Table/Thead';
import Tbody from '../../../Table/Tbody';
import Tfoot from '../../../Table/Tfoot';
import StylePage from '../../../Style Page/StylePage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    search,
    changePage,
    get,
    order,
    change_qtd_per_page,
    changePesquisa,
    clearPesquisa,
} from './Actions/actions';
import { pagination } from '../../../Pagination/paginations';
import { Link } from 'react-router-dom';
import Pagination from '../../../Table/Pagination';
import Cabecalho from '../../../Table/Cabecalho';
import Alert from '../../../Alerts/Alert';
import MyModal from '../../../Modal/MyModal';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import Users from './Users';
import { del_user, changeStatus, get_users } from '../../../Services/user';
import { CSVLink } from 'react-csv';

function GridUser(props) {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [id, setId] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const name = 'users.name';
    const code = 'users.id';
    const email = 'users.email';
    const role = 'descricao';
    //Contantes referentes a exportar excel
    const [users, setUsers] = useState([]);
    const fileUpload = useRef();
    const headers = [
        { label: 'Código W3', key: 'codigo_w3' },
        { label: 'Código Fornecedor W3', key: 'fornecedor_w3' },
        { label: 'Código Fornecedor Senior', key: 'fornecedor_senior' },
        { label: 'Nome', key: 'name' },
        { label: 'Email', key: 'email' },
        { label: 'Perfil', key: 'perfil' },
        { label: 'Filial', key: 'filial' },
        { label: 'Empresa', key: 'empresa' },
    ];

    const getUsers = () => {
        props.get();
    };

    useEffect(() => {
        getUsers();
        return () => {
            getUsers();
        };
    }, []);

    const changeStatusActive = (event) => {
        const id = event.target.id;
        let checked = event.target.checked === true ? 1 : 0;
        const data = {
            ativo: checked,
        };
        changeStatus(id, data)
            .then((resp) => getUsers())
            .catch((resp) => getUsers());
    };

    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
    };

    const handleCloseExcluir = (event) => {
        setModal(false);
        setId('');
    };

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false);

        del_user(id)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError('');
                setLoading(false);
            })
            .then((resp) =>
                setTimeout(() => {
                    setSuccess('');
                }, 4000)
            )
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            })
            .then((resp) => props.get());
        // .then(resp => {setState({success:resp.data.success, error:''})})
    };

    //Função para gerar relatórios csv de usuários
    const download = (event) => {
        get_users(
            props.page || '',
            props.qtd_total || '',
            props.order_var || 'asc',
            props.th || '',
            props.pesquisa || ''
        )
            .then((resp) => {
                setUsers(
                    resp.data.data.map((desc) => ({
                        name: desc.name,
                        codigo_w3: desc.codigo_w3,
                        fornecedor_w3: desc.fornecedor_w3,
                        fornecedor_senior: desc.fornecedor_senior,
                        perfil: desc.descricao,
                        email: desc.email,
                        filial: desc.filial,
                        empresa: desc.empresa,
                    }))
                );
            })
            .then((resp) => {
                fileUpload.current.link.click();
            });
    };

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage>
                {loading === true ? (
                    <Content>
                        <ReactLoading
                            type="spinningBubbles"
                            color="blue"
                            height={200}
                            width={100}
                        />
                    </Content>
                ) : (
                    ''
                )}
                {success ? <Alert type="success">{success}</Alert> : ''}
                {error ? <Alert type="danger">{error}</Alert> : ''}
                <MyModal
                    show={modal}
                    onHide={handleCloseExcluir}
                    title="Excluir Usuário"
                    content="Tem certeza que deseja excluir o usuário ?"
                    cancel={handleCloseExcluir}
                    del={handleExcluir}
                    to="/correcoes"
                    type="danger"
                    textButton="Excluir"
                />
                <div style={{ display: loading === true ? 'none' : 'block' }}>
                    <Table
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div
                                    className="dataTables_info"
                                    id="example1_info"
                                    role="status"
                                    aria-live="polite"
                                >
                                    Exibindo de {props.from} à {props.to} do
                                    total de {props.qtd_total}
                                </div>
                            </div>
                        }
                        cabecalho={
                            <Cabecalho
                                to="#/"
                                action={props.search}
                                clear={props.clearPesquisa}
                                valuePesquisa={props.pesquisa}
                                value={props.qtd_per_page}
                                onChange={props.change_qtd_per_page}
                                changePesquisa={props.changePesquisa}
                                buttonCsv={
                                    props.perfis.indexOf(1) >= 0 ||
                                    props.perfis.indexOf(2) >= 0 ||
                                    props.perfis.indexOf(3) >= 0 ||
                                    props.perfis.indexOf(6) >= 0 ? (
                                        <>
                                            <button
                                                style={{
                                                    background: '#005F31',
                                                    color: '#FFF',
                                                    borderRadius: '20.5px',
                                                    width: '140px',
                                                }}
                                                className="btn btn-sm"
                                                onClick={download}
                                            >
                                                <i className="fas fa-file-csv"></i>{' '}
                                                Exportar CSV
                                            </button>
                                            <CSVLink
                                                filename={`Relatorio-${new Date()}.csv`}
                                                headers={headers}
                                                hidden={true}
                                                separator={';'}
                                                ref={fileUpload}
                                                data={users || []}
                                            >
                                                <span className="fas fa-file-csv">
                                                    {' '}
                                                    Exportar CSV
                                                </span>
                                            </CSVLink>
                                        </>
                                    ) : (
                                        ''
                                    )
                                }
                            />
                        }
                        pagination={
                            <Pagination>
                                {props.pagination(
                                    props.current_page,
                                    props.get,
                                    props.last_page,
                                    props.changePage
                                )}
                            </Pagination>
                        }
                        title="Usuários"
                        button={
                            props.perfis.indexOf(1) >= 0 ||
                            props.perfis.indexOf(4) >= 0 ||
                            /*props.perfis.indexOf(6) >= 0 ||*/
                            props.perfis.indexOf(11) >= 0 ? (
                                <Link
                                    to="/new_user"
                                    className="btn btn-primary"
                                >
                                    Cadastro
                                </Link>
                            ) : (
                                ''
                            )
                        }
                    >
                        <Thead>
                            {/* <th>STATUS</th> */}
                            <th>
                                CÓDIGO
                                <Link
                                    to="/users"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(code)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th === 'users.id'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            <th>
                                NOME
                                <Link
                                    to="/users"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(name)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th === 'users.name'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            <th>
                                EMAIL
                                <Link
                                    to="/users"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(email)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th === 'users.email'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            <th>
                                PERFIL
                                <Link
                                    to="/users"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(role)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th === 'descricao'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            {props.perfis.indexOf(1) >= 0 ||
                            props.perfis.indexOf(4) >= 0 ||
                            /**props.perfis.indexOf(6) >= 0 || */
                            props.perfis.indexOf(11) >= 0 ? (
                                <th>AÇÕES</th>
                            ) : (
                                ''
                            )}
                        </Thead>
                        <Tbody>
                            <Users
                                changeStatus={changeStatusActive}
                                users={props.users}
                                onDelete={onChangeModal}
                            />
                        </Tbody>
                        <Tfoot>
                            {/* <th>STATUS</th> */}
                            <th>CÓDIGO</th>
                            <th>NOME</th>
                            <th>EMAIL</th>
                            <th>PERFIL</th>
                            {props.perfis.indexOf(1) >= 0 ||
                            props.perfis.indexOf(4) >= 0 ||
                            /** props.perfis.indexOf(6) >= 0 || */
                            props.perfis.indexOf(11) >= 0 ? (
                                <th>AÇÕES</th>
                            ) : (
                                ''
                            )}
                        </Tfoot>
                    </Table>
                </div>
            </StylePage>

            <Footer />
        </div>
    );
}

const mapStateToProps = (state) => ({
    users: state.user.users.data,
    perfis: state.auth.user.perfis,
    current_page: state.user.users.current_page,
    last_page: state.user.users.last_page,
    from: state.user.users.from,
    to: state.user.users.to,
    qtd_total: state.user.users.total,
    qtd_per_page: state.user.qtd_per_page,
    pesquisa: state.user.pesquisa,
    id_role: state.auth.user.id_role,
    asc: state.user.asc,
    th: state.user.th,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            pagination,
            get,
            order,
            change_qtd_per_page,
            changePesquisa,
            clearPesquisa,
            changePage,
            search,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GridUser);
