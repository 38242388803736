import React, { useState, useEffect } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import Footer from '../../../Footer/Footer'
import Table from '../../../Table/Table'
import Thead from '../../../Table/Thead'
import Tbody from '../../../Table/Tbody'
import Tfoot from '../../../Table/Tfoot'
import StylePage from '../../../Style Page/StylePage'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { search, changePage, get, order, change_qtd_per_page, changePesquisa, clearPesquisa } from './Actions/actions'
import { pagination } from '../../../Pagination/paginations'
import {Link} from 'react-router-dom'
import Pagination from '../../../Table/Pagination'
import Cabecalho from '../../../Table/Cabecalho'
import Alert from '../../../Alerts/Alert'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import ReimbursementsType from './ReimbursementsType'
import { changeStatus } from '../../../Services/reimbursementType'

function GridReimbursementType(props) {
    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState('');
    const [ error, setError ] = useState('');
    const nome = 'tipos_reembolsos.descricao'; 
    const codigo_conta_financeira = 'tipos_reembolsos.codigo_conta_financeira'

    console.log(props.qtd_total)
   
    const getReimbursementType = () => {
        props.get();
    }

    useEffect(() => {
        getReimbursementType()
        return () => {
            getReimbursementType()
        }
    },[])

    const changeStatusActive = (event) => {
        const id = event.target.id
        let checked = event.target.checked === true ? 1 : 0
        const data = {
            ativo: checked,
        }
        changeStatus(id, data)
            .then(resp => {
                getReimbursementType()
                setError('')
            })
            .catch(resp => {
                setError(resp.response.data.error)
                setTimeout(()=> {
                    setError('')
                },2500)
            })
    }

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage>

                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {success ? (
                    <Alert type="success">{success}</Alert>
                ):("")}
                {error ? (
                    <Alert type="danger">{error}</Alert>
                ):("")}
                <div style={{display: loading === true ? ('none'):('block')}}>

                    <Table 
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                    Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                                </div>
                            </div>
                        }
                            
                        cabecalho={
                            
                            <Cabecalho to="#/" action={props.search} clear={props.clearPesquisa} valuePesquisa={props.pesquisa} value={props.qtd_per_page} onChange={props.change_qtd_per_page} changePesquisa={props.changePesquisa} />
   
                        }
                        pagination={<Pagination>{props.pagination(props.current_page,props.get,props.last_page,props.changePage)}</Pagination>} title="Tipo de Reembolso"
                        button={
                           <Link to="/new_reimbursement_type" className="btn btn-primary">Cadastro</Link>
                            
                        }>
                        <Thead>  
                            <th>STATUS</th>
                            <th>NOME<Link to="/reimbursement_type" style={{float:'right'}} onClick={()=>props.order(nome)}><i className={`right fas fa-sort-amount-${props.th === 'tipos_reembolsos.descricao' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>CÓDIGO SÊNIOR<Link to="/reimbursement_type" style={{float:'right'}} onClick={()=>props.order(codigo_conta_financeira)}><i className={`right fas fa-sort-amount-${props.th === 'tipos_reembolsos.codigo_conta_financeira' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>AÇÕES</th>
                        </Thead>
                        <Tbody>
                            <ReimbursementsType reimbursement_types={props.reimbursement_types} changeStatus={changeStatusActive} />
                        </Tbody>
                        <Tfoot>
                            <th>STATUS</th>
                            <th>NOME</th>
                            <th>CÓDIGO SÊNIOR</th>
                            <th>AÇÕES</th>
                        </Tfoot>
                    </Table>        
                </div>
            </StylePage>
            <Footer />
        </div>
    )
}

const mapStateToProps = state => ({
    reimbursement_types:state.reimbursement_type.reimbursement_type.data,
    current_page:state.reimbursement_type.reimbursement_type.current_page,
    last_page:state.reimbursement_type.reimbursement_type.last_page,
    from:state.reimbursement_type.reimbursement_type.from,
    to:state.reimbursement_type.reimbursement_type.to,
    qtd_total:state.reimbursement_type.reimbursement_type.total,
    qtd_per_page:state.reimbursement_type.qtd_per_page,
    pesquisa:state.reimbursement_type.pesquisa,
    asc:state.reimbursement_type.asc,
    th:state.reimbursement_type.th  
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
        pagination, 
        get, 
        order, 
        change_qtd_per_page, 
        changePesquisa, 
        clearPesquisa,
        changePage,
        search
    }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(GridReimbursementType)
