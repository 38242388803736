import React from 'react'
import {connect} from 'react-redux'

function header(props) {

    const handleRemove = e => {
        e.preventDefault();
        localStorage.removeItem('token')
        localStorage.removeItem('id_role')
        localStorage.removeItem('consultant')
        props.logout()
    }
    
    return (
        <>

            <div style={{backgroundColor: '#2A4038'}}>
                <nav className={`main-header navbar navbar-expand text-sm`}>
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a style={{color: '#FFF'}} className="nav-link" data-widget="pushmenu" href="#/" role="button"><i className="fa fa-bars" /></a>
                        </li>
                    </ul>
                
                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">
                        {/* Messages Dropdown Menu */}
                        <li className="nav-item dropdown">
                            <a style={{color: '#FFF'}} className="nav-link" data-toggle="dropdown" href="#/">
                            <img src={props.image ? `${props.image}` : 'https://tecnocoffeeapi.rehagro.com.br/storage/imagens/hiclipart.com.png'} alt="logo_user" className="rounded-circle mr-2" style={{height:'30px',marginTop:'-8px'}} />
                                {/* <img src="https://tecnocoffeeapi.rehagro.com.br/storage/imagens/hiclipart.com.png" alt="Imagem" className="img-user img-circle elevation-3 mr-2" style={{height:'30px',marginTop:'-8px'}}/> */}
                                <span> {props.name ?(props.name):("Consultor")}</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <a href={`/profile/${props.id}`} className="dropdown-item">
                                    <i className="fas fa-cog" />
                                    <span>  Meus Dados</span>
                                </a>
                            <div className="dropdown-divider" />
                                <a href="#/" className="dropdown-item" onClick={handleRemove}>
                                    <i className="fa fa-power-off" />
                                    <span>  Sair</span>
                                </a>
                            </div>
                        </li>
                    </ul>

                </nav>
            </div>

        </>
    )
}

const mapStateToProps = state => {
    return{
        loggedIn: state.auth.loggedIn,
        name:state.auth.user.name, 
        image:state.auth.user.foto_perfil,
        id: state.auth.user.id     
    }
  };
const mapDispacthToProps = dispatch => {
    return{
        logout:() => dispatch({type:'SET_LOGOUT'})    
    }
  };
  export default connect(mapStateToProps,mapDispacthToProps)(header)