import { get_service_types } from '../../../../Services/serviceType'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[(dispatch,getState)=>{
        const order = getState().service_type.order
        const th = getState().service_type.th
        const qtd_per_page = getState().service_type.qtd_per_page
        const pesquisa = getState().service_type.pesquisa || '' 
        const page = getState().service_type.page      
      
        get_service_types(page,qtd_per_page,order,th,pesquisa)
        .then(resp=>dispatch({type:'ServiceTypes', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().service_type.asc
      
        if(asc === true){
            dispatch({type:'GetServiceTypes_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetServiceTypes_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_service_types',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaServiceTypes',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaServiceTypes',
        payload:''
    },get(1)]
}

export const changePage = page => {
    return[{
        type: 'changePageServiceTypes',
        payload: page
    },get()]
}

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().service_type.pesquisa || ''
            if(pesquisa){
                return dispatch({type:'changePageServiceTypes', payload:1})
            }
        }, get()
    ]
}

