import { get_cultures } from '../../../../Services/culture'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[(dispatch,getState)=>{
        
        const order = getState().culture.order
        const th = getState().culture.th
        const qtd_per_page = getState().culture.qtd_per_page
        const pesquisa = getState().culture.pesquisa || ''       
        const page = getState().culture.page 

        get_cultures(page,qtd_per_page,order,th,pesquisa)
        .then(resp=>dispatch({type:'Cultures', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().culture.asc
      
        if(asc === true){
            dispatch({type:'GetCulture_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetCulture_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

//Função que define a quantidade de registros à serem exibidos por página
export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_culture',
        payload:event.target.value
    },get(1)]
}

//Função que pega a pega a pesquisa digitadda pelo o usuário e salva no estado do redux
export const changePesquisa = event => {
    return[{
        type:'pesquisaCulture',
        payload:event.target.value
    }]
}

//Função que limpa a pesquisa salva na árvore pelo usuário
export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaCulture',
        payload:''
    },get(1)]
}

//Função que altera a página dos registros à serem exibidas
export const changePage = page => {
    return[{
        type: 'changePageCulture',
        payload: page
    },get()]
}

//Função que pega a página que o usuário esta no momento e realiza um get
export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().culture.pesquisa || ''
            if(pesquisa){
                return dispatch({type:'changePageCulture', payload:1})
            }
        }, get()
    ]
}