import { app } from './Api'

const getResource = async() => {
    return app.get('extra_charge/get_resource')
}

const postExtraCharge = async (data) => {
    return app.post(`extra_charge`, data)
}

const putExtraCharge = async (id,data) => {
    return app.post(`extra_charge/${id}`,data)
}

const getExtraCharge = async (page,qtd_per_page,order,th,pesquisa,id_coordinator,id_project,id_status,from,to) => {
    return app.get(`extra_charge?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&id_coordinator=${id_coordinator}&id_project=${id_project}&id_status=${id_status}&from=${from}&to=${to}`)
}

const getViewExtraCharge = async (id) => {
    return app.get(`extra_charge/${id}`)
}

const delExtraCharge = async (id) => {
    return app.delete(`extra_charge/${id}`)
}

const changeStatus =  async (id,data) => {
    return app.put(`change_extra_charge/${id}`,data)
}

const getClient =  async () => {
    return app.get(`extra_charge/get_client`)
}

const delAttachment = async (id) => {
    return app.delete(`extra_charge/del_attachment/${id}`)
}

const exportExcelCSV = async (
    status,
    coordinator,
    project,
    from,
    to
) => {
    return app.get(
        `extra_charge/export_excel?id_status=${status}&id_coordinator=${coordinator}&project=${project}&from=${from}&to=${to}`,
    );
};

export { getResource, delAttachment, postExtraCharge, putExtraCharge, getExtraCharge, getViewExtraCharge, delExtraCharge, changeStatus, getClient, exportExcelCSV }