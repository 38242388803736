import React from 'react'
import Select from 'react-select'
import NumberFormat from 'react-number-format'
import Alert from '../../../Alerts/Alert'
import Input from '../../../Forms/Input'

export default function Shareds(props) {
    return (
       
        <>
            <div className="col-md-4">
                <label>Projeto</label>
                <Select 
                    value={props.id_project}
                    label="Single select"
                    options={props.projects}
                    onChange={props.changeProject}
                    isDisabled={props.isDisabled}    
                />
                {props.error ? (<p style={{color:'red'}}>{props.error.id_project}</p>):('')}
                <div className="mt-3">
                    {props.errorProject && <div className="mt-2"><Alert type="warning">{props.errorProject}</Alert></div>}
                </div>
            </div>
            <div className="col-md-4">
                <NumberFormat   
                    icon="fas fa-percent"
                    label="Percentual"
                    name="percentual" 
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator={"."} 
                    decimalSeparator={","}
                    placeholder=""
                    customInput={Input}
                    onChange={props.changePercent} 
                    value={props.addPercent}
                    disabled={props.isDisabled}
                    isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= 100;}}                          
                />
                <div className="mt-3">
                    {props.errorPercent && <div className="mt-2"><Alert type="warning">{props.errorPercent}</Alert></div>}
                </div>
            </div>
            <div className="col-md-3">
                <NumberFormat   
                    icon="fas fa-dollar-sign"
                    label="Valor"
                    name="valor" 
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator={"."} 
                    decimalSeparator={","}
                    placeholder=""
                    customInput={Input}
                    onChange={props.changeAddValue} 
                    value={props.addValue}
                    disabled={props.isDisabled} 
                    isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= 999999;}}                         
                />
                <div className="mt-3">
                    {props.errorValue && <div className="mt-2"><Alert type="warning">{props.errorValue}</Alert></div>}
                </div>
                {props.errorValueTechnical}
            </div>
            <div className="col-md-1">
                <div className="input-group mb-3" style={{paddingTop:'30px'}}>
                    <button  disabled={props.isDisabled} type="button" className="btn btn-primary" onClick={props.onClick}><span className="fas fa-plus"></span></button>
                </div>
            </div>                 
        </>    
    )
}


