import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-size: cover;
    position:absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    
`

export const ContainerPrecipitation = styled.div`
    width: 100%;
    height: 100%;
    background-size: cover;
    margin-top: 10%;
    position:static;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    
`