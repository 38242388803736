import React from 'react'
import IconSuccess from '../Imagens/success.png'
import IconError from '../Imagens/error.png'
import IconWarning from '../Imagens/warning.png'
import { Container, ContainerPrecipitation } from './style'

export default function AlertNew(props) {
    console.log(props.page)
    return (
        props.page !== 'precipitacao' ?
            <Container>
                <img width="100px" src={props.type === 'success' ? IconSuccess : props.type === 'warning' ? IconWarning : props.type === 'danger' ? IconError : ''} alt="Alerta" />
                <h2>{props.type === 'success' ? 'Sucesso' : props.type === 'warning' ? 'Atenção' : props.type === 'danger' ? 'Erro' : ''}</h2>
                <span>{props.children}</span>            
            </Container>
        :
        <ContainerPrecipitation>
            <img width="100px" src={props.type === 'success' ? IconSuccess : props.type === 'warning' ? IconWarning : props.type === 'danger' ? IconError : ''} alt="Alerta" />
            <h2>{props.type === 'success' ? 'Sucesso' : props.type === 'warning' ? 'Atenção' : props.type === 'danger' ? 'Erro' : ''}</h2>
            <span>{props.children}</span>            
        </ContainerPrecipitation>
    )
}
