import React from 'react'

export default function Input(props) {
    return (
        <>
            <label>{props.label}</label>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text ">
                        <i 
                            className={`fa ${props.icon}`} 
                        />
                    </span>
                </div>
                <input 
                    type={props.type} 
                    name={props.name} 
                    id={props.id}
                    // autocomplete={props.autocomplete}
                    className={`form-control ${props.className}`} 
                    placeholder={props.placeholder} 
                    onChange={props.onChange}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    required={props.required || false}
                    disabled={props.disabled}
                    min={props.min || ""}
                    max={props.max || ""}
                    readOnly={props.readOnly}
                    maxLength={props.maxLength || ""}
                    style={props.style}
                   
                />
            </div>
        </>
    )
}
