import { getExtraCharge } from '../../../../Services/extraCharge'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[
        
        (dispatch,getState)=>{
        const order = getState().extra_charge.order
        const th = getState().extra_charge.th
        const qtd_per_page = getState().extra_charge.qtd_per_page
        const pesquisa = getState().extra_charge.pesquisa || ''
        const id_project = getState().extra_charge.project_report.value || ''
        const id_coordinator = getState().extra_charge.coordinator_report.value || ''
        const id_status = getState().extra_charge.status_report.value || ''
        const from = getState().extra_charge.from
        const to = getState().extra_charge.to 
        const page = getState().extra_charge.page     
      
        getExtraCharge(page,qtd_per_page,order,th,pesquisa,id_coordinator,id_project,id_status,from,to)
        .then(resp=>dispatch({type:'ExtraCharge', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().extra_charge.asc
      
        if(asc === true){
            dispatch({type:'GetExtraCharge_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetExtraCharge_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_ExtraCharge',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaExtraCharge',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[(dispatch, getState) =>{
        const fromFix = getState().extra_charge.fromFix
        const toFix = getState().extra_charge.toFix
        dispatch({
            type:'clearPesquisaExtraCharge',
            payload:'',
            project_report: '',
            coordinator_report: '',
            status_report: '',
            from: fromFix ,
            to: toFix       
        })
    },get(1)]
}


export const changePage = page => {
    return[{
        type: 'changePageExtraCharge',
        payload: page
    },get()]
}

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().extra_charge.pesquisa || ''
            const project_report = getState().extra_charge.project_report.value || ''
            const coordinator_report = getState().extra_charge.coordinator_report.value || ''
            const status_report = getState().extra_charge.status_report.value || ''
            const from = getState().extra_charge.from
            const to = getState().sales_record.to 
            if(pesquisa || project_report  || coordinator_report || status_report || from || to){
                return dispatch({type:'changePageExtraCharge', payload:1})
            }
        }, get()
    ]
}