const CultureReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Cultures':
            return{...state, cultures:actions.payload}

        case 'GetCulture_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetCulture_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_culture':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaCulture':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaCulture':
            return{...state, pesquisa:actions.payload}

        case 'changePageCulture':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default CultureReducer;