import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import Input from '../../../Forms/Input'
import { useSelector, useDispatch } from 'react-redux'
import { getResource } from '../../../Services/registerSgagri'

export default function ReportRecordSgagri(props) {
    const status_report = useSelector(state => state.register_sgagri.status_report)
    const project_report = useSelector(state => state.register_sgagri.project_report)
    const from = useSelector(state => state.register_sgagri.from) 
    const to = useSelector(state => state.register_sgagri.to)
    const dispatch = useDispatch()
    const [ status, setStatus ] = useState([])
    const [ project, setProject ] = useState([])
    
    useEffect(() => {
        getResourceSgagriRecord()
    }, [])

    function getResourceSgagriRecord(){
        getResource()
        .then(resp => {
            setStatus(
                [
                    {value: '', label: '-- SELECIONE --'},
                    ...resp.data.status.map(desc => ({
                        value: desc.id,
                        label: desc.descricao
                    }))
                ]
            )
            setProject(
                [
                    {value: '', label: '-- SELECIONE --'},
                    ...resp.data.project.map(desc => ({
                        value: desc.id,
                        label: desc.title
                    }))
                ]
            )
        })
        
    }
    
    return (
        <>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <label>Título do registro</label>
                        <Select 
                            value={project_report}
                            label="Single select"
                            options={project}
                            onChange={(e) => dispatch({type:'changeProjectReportRegisterSgagri', payload:e})}                      
                        /> 
                    </div>
                   
                </div>
                <div className="row mt-3 mb-4">
                    <div className="col-md-12 mt-3">
                        <label>Status</label>
                        <Select 
                            value={status_report}
                            label="Single select"
                            options={status}
                            onChange={(e) => dispatch({type:'changeStatusReportRegisterSgagri', payload:e})}   
                        /> 
                    </div>
                    <div className="col-md-12 mt-3">     
                        <Input 
                            label="De"
                            type="date"
                            icon="fas fa-calendar"
                            onChange={(e) => dispatch({ type: 'changeFromReportRegisterSgagri', payload:e.target.value })}
                            value={from}
                        />
                    </div>
                    <div className="col-md-12 mt-3"> 
                        <Input 
                            label="Até"
                            type="date"
                            icon="fas fa-calendar"
                            onChange={(e) => dispatch({ type: 'changeToReportRegisterSgagri', payload:e.target.value })}
                            value={to}
                        />
                    </div>   
                </div>
            </div>
        </>
    )
}
