import React, { useState, useEffect, useRef } from "react";
import Header from "../../../Header/Header";
import Sidebar from "../../../Sidebar/Sidebar";
import Footer from "../../../Footer/Footer";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
    changePage,
    get,
    order,
    change_qtd_per_page,
    changePesquisa,
    clearPesquisa,
} from "../RegisterSgagri/Actions/actions";
import { pagination } from "../../../Pagination/paginations";
import {
    delRegisterSgagri,
    changeStatus,
    getClient,
} from "../../../Services/registerSgagri";
import Pagination from "../../../Table/Pagination";
import Alert from "../../../Alerts/Alert";
import MyModal from "../../../Modal/MyModal";
import ReactLoading from "react-loading";
import { Content } from "../../../LoadingStyle/StyleLoading";
import CabecalhoNew from "../../../Table/CabecalhoNew";
import Input from "../../../Forms/Input";
import TextArea from "../../../Forms/TextArea";
import Form from "../../../Forms/Form";
import AprovedIcon from "../../../Icons/emojione-v1_ballot-box-bold-check.png";
import ReprovedIcon from "../../../Icons/fluent_document-footer-remove-24-filled.png";
import IconAnalysys from "../../../Icons/cib_anaconda.png";
import RegistersSgagri from "../RegisterSgagri/RegistersSgagri";
import ReportRecordSgagri from "../RegisterSgagri/ReportRecordSgagri";
import makeAnimated from "react-select/animated";
import NumberFormat from "react-number-format";
import Select from "react-select";
import ModalLarge from "../../../Modal/ModalLarge";

function GridRegisterSgagri(props) {
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState("");
    const [codeCentroCusto, setCodeCentroCusto] = useState("");
    const [modal, setModal] = useState(false);
    const [description, setDescription] = useState("");
    const [modalReproved, setModalReproved] = useState(false);
    const [modalAproved, setModalAproved] = useState(false);
    const [modalFirstAproved, setModalFirstAproved] = useState(false);
    const [dataVencimento, setDataVencimento] = useState("");
    const [valorParcela, setValorParcela] = useState("");
    const [errorPagamento, setErrorPagamento] = useState("");
    const [sgagriRecord, setSgagriRecord] = useState({});
    const [optionsClient, setOptionsClient] = useState([]);
    const [totalPagamento, setTotalPagamento] = useState(0);
    const [id, setId] = useState("");
    const [pagamento, setPagamento] = useState([]);
    const [success, setSuccess] = useState("");
    const [idClient, setIdClient] = useState([]);
    const [error, setError] = useState("");
    const [displayLegend, setDisplayLegend] = useState(true);
    const [modalFilter, setModalFilter] = useState(false);
    const [dataInicio, setDataInicio] = useState("");
    const [dataFim, setDataFim] = useState("");
    const from = useSelector((state) => state.register_sgagri.from);
    const to = useSelector((state) => state.register_sgagri.to);
    const dispatch = useDispatch();
    const [status_all, setStatusAll] = useState([]);
    const selectClientRef = useRef(null);
    const animatedComponents = makeAnimated();
    const [titleProject, setTitleProject] = useState('')
    const getSalesRecord = () => {
        props.get();
    };

    useEffect(() => {
        getSalesRecord();
        return () => {
            getSalesRecord();
        };
    }, []);

    // useEffect(() => {
    //     getAllClient();
    // }, [modalAproved]);

    // function getAllClient() {
    //     if (modalAproved === true) {
    //         getClient().then((resp) => {
    //             setOptionsClient(
    //                 resp.data.map((desc) => ({
    //                     value: desc.id,
    //                     label: desc.nome,
    //                 }))
    //             );
    //         });
    //     }
    // }

    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
    };

    const handleCloseExcluir = (event) => {
        setModal(false);
        setId("");
    };

    const handleCancelOrClose = () => {
        setModalAproved(false)
        setPagamento([])
        setTotalPagamento(0);
        setDataVencimento("");
        setValorParcela("");
        setErrorPagamento("");
        setCodeCentroCusto("");
        setIdClient([]);
    }
    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false);

        delRegisterSgagri(id)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError("");
                setLoading(false);
            })
            .then((resp) =>
                setTimeout(() => {
                    setSuccess("");
                }, 4000)
            )
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.errorSubmit);
                setSuccess("");
                setModal(false);
                setTimeout(() => {
                    setError("");
                }, 4000);
            })
            .then((resp) => props.get());
        // .then(resp => {setState({success:resp.data.success, error:''})})
    };

    //Função que formata o campo valor
    function formatReal(desc) {
        var tmp = `${desc.toFixed(2)}`.replace(".", ",");

        if (tmp.length > 6)
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, ".$1");
        return tmp;
    }

    const changeStatusSgagriRecordAproved = (e) => {
        e.preventDefault();
        if(sgagriRecord.id_status == 1){
            const object = {
                id_status:  4,
                cod_centro_custo: +codeCentroCusto
            };

            changeStatus(sgagriRecord.id, object)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError("");
                setLoading(false);
                setIdClient([]);
                setCodeCentroCusto("");
                setPagamento([])
                setTotalPagamento(0);
                setDataVencimento("");
                setValorParcela("");
                setErrorPagamento("");
                setModalFirstAproved(false);
                setTimeout(() => {
                    setSuccess("");
                }, 4000);
            })
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.errorSubmit);
                setSuccess("");
                setTimeout(() => {
                    setError("");
                }, 4000);
            })
            .then((resp) => props.get());

            return
        }

        setModalAproved(false);
        setLoading(true);

        const object = {
            id_status: 2,
        };

        changeStatus(sgagriRecord.id, object)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError("");
                setLoading(false);
                setIdClient([]);
                setCodeCentroCusto("");
                setPagamento([])
                setTotalPagamento(0);
                setDataVencimento("");
                setValorParcela("");
                setErrorPagamento("");
                setTimeout(() => {
                    setSuccess("");
                }, 4000);
            })
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.errorSubmit);
                setSuccess("");
                setModal(false);
                setTimeout(() => {
                    setError("");
                }, 4000);
            })
            .then((resp) => props.get());
    };
    
    const modalReprovedOn = (sgagriRecordId, status) => {
        setModalReproved(true);
        setSgagriRecord(sgagriRecordId);
    };

    const modalAprovedOn = (sgagriRecord) => {
        if(sgagriRecord.id_status == 1){
            setModalFirstAproved(true)
            setTitleProject(sgagriRecord.title)
            setSgagriRecord(sgagriRecord);
        } else {
            setModalAproved(true);
            setSgagriRecord(sgagriRecord);
            setCodeCentroCusto(sgagriRecord.centro_custo)
            setDataInicio(formataData(sgagriRecord.data_inicio_contrato));
            setDataFim(sgagriRecord.data_fim);

        }
    };

    function formataData(data) {
        let dataSplit = data.split("/");
        let dataFormatada = `${dataSplit[2]}-${dataSplit[1]}-${dataSplit[0]}`;
        return dataFormatada;
    }

    const changeStatusSgagriRecordReproved = (e) => {
        e.preventDefault();
        setModalReproved(false);
        setLoading(true);
        const object = {
            id_status: 3,
            description: description,
        };
        changeStatus(sgagriRecord, object)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError("");
                setLoading(false);
                setDescription("");
                setTimeout(() => {
                    setSuccess("");
                }, 4000);
            })
            .catch((e) => {
                setLoading(false);
                setDescription("");
                setError(e.response.data.errorSubmit);
                setSuccess("");
                setModal(false);
                setTimeout(() => {
                    setError("");
                }, 4000);
            })
            .then((resp) => props.get());
    };

    const check = (event) => {
        let checked = event.target.checked;
        if (checked === true) {
            setStatusAll(status_all.concat(parseInt(event.target.value)));
        } else {
            setStatusAll(
                status_all.filter(
                    (desc) => parseInt(desc) !== parseInt(event.target.value)
                )
            );
        }
    };

    //Função que pega os clientes escolhidos no projeto
    const handleChangeClient = (newValue: any, actionMeta: any) => {
        setIdClient(newValue);
    };


    return (
        <div className="wrapper" style={{ backgroundColor: "#E5E5E5, 100%" }}>
            <Header />
            <Sidebar />
            <div
                className="content-wrapper"
                style={{ backgroundColor: "#E5E5E5, 100%" }}
            >
                <section
                    className="content"
                    style={{ backgroundColor: "#E5E5E5, 100%" }}
                >
                    <MyModal
                        show={modal}
                        onHide={handleCloseExcluir}
                        title="Excluir Registro de Sgagri"
                        content="Tem certeza que deseja excluir o Registro de Sgagri ?"
                        cancel={handleCloseExcluir}
                        del={handleExcluir}
                        to="#/"
                        type="danger"
                        textButton="Excluir"
                    />
                    <div className="row">
                        <div className="col-md-12">
                            <MyModal
                                show={modalFilter}
                                onHide={(e) => setModalFilter(false)}
                                title="Filtrar"
                                content={<ReportRecordSgagri />}
                                cancel={function () {
                                    return [
                                        props.clearPesquisa(),
                                        setTimeout(() => {
                                            setModalFilter(false);
                                        }, 1000),
                                    ];
                                }}
                                del={function () {
                                    dispatch({type:'changeFirstPage', payload:1})
                                    return [
                                        props.get(),
                                        setTimeout(() => {
                                            setModalFilter(false);
                                        }, 1000),
                                    ];
                                }}
                                to="#/"
                                type="success"
                                contentSecondary="Limpar Busca"
                                textButton="Buscar"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <MyModal
                                show={modalReproved}
                                onHide={(e) => setModalReproved(false)}
                                title="Reprovar Lançamento"
                                content={
                                    <Form
                                        onSubmit={
                                            changeStatusSgagriRecordReproved
                                        }
                                    >
                                        <TextArea
                                            label="Motivo"
                                            maxLength="254"
                                            placeholder="Descreva o motivo da reprovação"
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                            value={description}
                                            required={true}
                                        />
                                        <div className="text-right">
                                            <button
                                                style={{ marginRight: "10px" }}
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e) =>
                                                    setModalReproved(false)
                                                }
                                            >
                                                Cancelar
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-danger"
                                            >
                                                Reprovar
                                            </button>
                                        </div>
                                    </Form>
                                }
                                onSubmit={true}
                                cancel={(e) => setModalReproved(false)}
                                // del={changeStatusSgagriRecordReproved}
                                to="#/"
                                type="danger"
                                textButton="Reprovar"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <ModalLarge
                                show={modalAproved}
                                onHide={(e) => handleCancelOrClose()}
                                title="Aprovar Registro Sgagri"
                                cancel={(e) => setModalAproved(false)}
                            >
                                <Form onSubmit={changeStatusSgagriRecordAproved}>             
                                    <div className="row">
                                        <div className="col-md-12">
                                            <NumberFormat
                                                label="Centro de Custo"
                                                name="codCentroCusto"
                                                icon="fas fa-key"
                                                fixedDecimalScale={true}
                                                maxLength={9}
                                                decimalScale={0}
                                                // thousandSeparator={""}
                                                // decimalSeparator={","}
                                                placeholder=""
                                                customInput={Input}
                                                allowNegative={false}
                                                onChange={(e) =>
                                                    setCodeCentroCusto(e.target.value)
                                                }
                                                value={codeCentroCusto}
                                                required={true}
                                                disabled={true}
                                                // required={true}
                                                // isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= 999999999;}}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="text-right mt-4">
                                        <div
                                            style={{
                                                width: "100%",
                                                marginBottom: "20px",
                                                position: "absolute",
                                                bottom: "0%",
                                                left: "-2%",
                                            }}
                                        >
                                            <button
                                                style={{ marginRight: "10px" }}
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e) => handleCancelOrClose()}
                                            >
                                                Cancelar
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-success"
                                            >
                                                {sgagriRecord.id_status === 1 ? 'Pré Aprovar' : 'Aprovar'}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                                {/* // onSubmit={true}
                                // cancel={(e) => setModalAproved(false)}
                                // // del={changeStatusSgagriRecordReproved} 
                                // to="#/"
                                // type="success"
                                // textButton="Aprovar"                    */}
                            </ModalLarge>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <ModalLarge
                                show={modalFirstAproved}
                                onHide={(e) =>  {
                                        setModalFirstAproved(false);
                                        setCodeCentroCusto("");
                                    }
                                }
                                title="Pré Aprovar Registro Sgagri"
                            >
                                {error && <div><Alert type="danger">{error}</Alert></div>}
                                <Form onSubmit={changeStatusSgagriRecordAproved}>
                                    {/* Tilulo Registro Venda */}
                                    <div className="row">
                                        <div className="col-md-12">
                                             <Input 
                                                label="Titulo" 
                                                autocomplete="off"
                                                value={titleProject}  
                                                // icon="fas fa-user" 
                                                name="titulo" 
                                                type="text" 
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    {/* Centro de Custo */}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <NumberFormat
                                                label="Centro de Custo"
                                                name="codCentroCusto"
                                                icon="fas fa-key"
                                                fixedDecimalScale={true}
                                                maxLength={9}
                                                decimalScale={0}
                                                // thousandSeparator={""}
                                                // decimalSeparator={","}
                                                placeholder=""
                                                customInput={Input}
                                                allowNegative={false}
                                                onChange={(e) =>
                                                    setCodeCentroCusto(e.target.value)
                                                }
                                                value={codeCentroCusto}
                                                required={true}
                                                // required={true}
                                                // isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= 999999999;}}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="text-right mt-4">
                                        <div
                                            style={{
                                                width: "100%",
                                                marginBottom: "20px",
                                                position: "absolute",
                                                bottom: "0%",
                                                left: "-2%",
                                            }}
                                        >
                                            <button
                                                style={{ marginRight: "10px" }}
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e) => {
                                                        setModalFirstAproved(false);
                                                        setCodeCentroCusto("");
                                                    }
                                                }
                                            >
                                                Cancelar
                                            </button>
                                            <button onClick={(e) => changeStatusSgagriRecordAproved} type="submit" className="btn btn-success" >
                                                Pré Aprovar
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </ModalLarge>
                        </div>
                    </div>
                    
                    {loading === true ? (
                        <Content>
                            <ReactLoading
                                type="spinningBubbles"
                                color="blue"
                                height={200}
                                width={100}
                            />
                        </Content>
                    ) : (
                        ""
                    )}
                    <div
                        style={{ display: loading === true ? "none" : "block" }}
                    >
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="box">
                                    <div
                                        className="box-body"
                                        style={{
                                            background: "#FFFFFF",
                                            boxShadow:
                                                "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                            padding: "15px",
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <h2>Registros Sgagri</h2>
                                            </div>
                                            <div className="text-right col-sm-6">
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        props.history.push(
                                                            `/new_register_sgagri`
                                                        );
                                                        e.preventDefault();
                                                    }}
                                                    className="btn btn-primary"
                                                >
                                                    Cadastro
                                                </button>
                                            </div>
                                        </div>
                                        {props.salesRecords.length >= 0 ||
                                        props.pesquisa ? (
                                            <CabecalhoNew
                                                filter={
                                                    <>
                                                        <div className="col-md-3 col-sm-12 mt-3">
                                                            <Input
                                                                label="De"
                                                                type="date"
                                                                icon="fas fa-calendar"
                                                                onChange={(e) =>
                                                                    dispatch({
                                                                        type: "changeFromReportRegisterSgagri",
                                                                        payload:
                                                                            e.target.value,
                                                                    })
                                                                }
                                                                value={from}
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-sm-12 mt-3">
                                                            <Input
                                                                label="Até"
                                                                type="date"
                                                                max={to}
                                                                icon="fas fa-calendar"
                                                                onChange={(e) =>
                                                                    dispatch({
                                                                        type: "changeToReportRegisterSgagri",
                                                                        payload:
                                                                            e.target.value,
                                                                    })
                                                                }
                                                                value={to}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                buttonCsv={
                                                    <>
                                                        {/* <button style={{background: '#005F31', color: '#FFF', borderRadius: '20.5px', width: '140px'}} className="btn btn-sm" onClick={download}>
                                                            <i className="fas fa-file-csv"></i> Exportar CSV
                                                        </button> */}
                                                        <button style={{background: '#1A170E', color: '#FFF', borderRadius: '20.5px', width: '140px', marginLeft: '10px'}} className="btn btn-sm" onClick={() => setModalFilter(modalFilter === false ? true : false)}>
                                                            <i className="fas fa-filter"></i> Mais Filtros
                                                        </button>
                                                        {/* <CSVLink
                                                            filename={`Relatorio-${new Date()}.csv`}
                                                            headers={headers}
                                                            hidden={true}
                                                            separator={";"}
                                                            ref={fileUpload}
                                                            data={refunds || []}
                                                        >
                                                            <span className="fas fa-file-csv"> Exportar CSV</span>
                                                        </CSVLink> */}
                                                    </>
                                                }
                                                to="#/"
                                                action={props.get}
                                                clear={props.clearPesquisa}
                                                valuePesquisa={props.pesquisa}
                                                value={props.qtd_per_page}
                                                onChange={
                                                    props.change_qtd_per_page
                                                }
                                                changePesquisa={
                                                    props.changePesquisa
                                                }
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div
                                style={{
                                    display: `${
                                        displayLegend === true
                                            ? "none"
                                            : "block"
                                    }`,
                                }}
                                className="text-right"
                            >
                                <button
                                    onClick={(e) => setDisplayLegend(true)}
                                    className="btn"
                                    style={{
                                        background:
                                            "linear-gradient(99.87deg, #427B67 25.78%, #22AC7A 65.59%)",
                                        borderRadius: "20.5px",
                                    }}
                                >
                                    Legendas
                                </button>
                            </div>
                            <div
                                style={{
                                    backgroundColor: "#FFF",
                                    padding: "10px",
                                    borderRadius: "25px",
                                    display: `${
                                        displayLegend === false
                                            ? "none"
                                            : "block"
                                    }`,
                                }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                        float: "right",
                                    }}
                                >
                                    <button
                                        onClick={(e) => setDisplayLegend(false)}
                                        className="btn"
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-2 mt-2">
                                        <h5 style={{ fontSize: "16px" }}>
                                            Legendas:
                                        </h5>
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span>
                                            <img
                                                src={IconAnalysys}
                                                width="20px"
                                                alt="Analise"
                                            />{" "}
                                            Em Análise
                                        </span>
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span>
                                            <img
                                                src={AprovedIcon}
                                                width="20px"
                                                alt="Aprovar"
                                            />{" "}
                                            Aprovar
                                        </span>
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span>
                                            <img
                                                src={ReprovedIcon}
                                                width="20px"
                                                alt="Reprovar"
                                            />{" "}
                                            Reprovar
                                        </span>
                                    </div>
                                    {/* <div className="col-md-1 mt-2">
                                        <span><img src={IconBilled} width="20px" /> Faturar</span>

                                    </div> */}
                                </div>
                            </div>
                            {success ? (
                                <Alert type="success">{success}</Alert>
                            ) : (
                                ""
                            )}
                            {error ? <Alert type="danger">{error}</Alert> : ""}
                            <div className="row mt-3">
                                {parseInt(props.salesRecords.length) === 0 ? (
                                    <div
                                        style={{
                                            color: "#FFFF",
                                            textAlign: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <span style={{ color: "black" }}>{`${
                                            !props.pesquisa
                                                ? "Não existem Registros de Vendas cadastrados no sistema. Clique no botão Cadastro para inserir um novo Registro de Vendas no Sistema"
                                                : "Nenhum registro encontrado na pesquisa"
                                        }`}</span>
                                    </div>
                                ) : (
                                    ""
                                )}
                                <RegistersSgagri
                                    modalAprovedOn={modalAprovedOn}
                                    modalReprovedOn={modalReprovedOn}
                                    history={props.history}
                                    check={check}
                                    sales_records={props.salesRecords}
                                    formatReal={formatReal}
                                    onDelete={onChangeModal}
                                />
                            </div>
                        </div>
                        {props.salesRecords.length > 0 && (
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="box">
                                        <div
                                            className="box-body"
                                            style={{
                                                background: "#FFFFFF",
                                                boxShadow:
                                                    "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                                padding: "15px",
                                            }}
                                        >
                                            <div className="row mt-4">
                                                <div className="col-md-1">
                                                    <select
                                                        value={
                                                            props.qtd_per_page
                                                        }
                                                        onChange={
                                                            props.change_qtd_per_page
                                                        }
                                                        name="example1_length"
                                                        aria-controls="example1"
                                                        className="custom-select custom-select-sm form-control form-control-sm"
                                                    >
                                                        <option value={8}>
                                                            8
                                                        </option>
                                                        <option value={24}>
                                                            24
                                                        </option>
                                                        <option value={48}>
                                                            48
                                                        </option>
                                                        <option value={96}>
                                                            96
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="col-md-5 mt-1 mb-2">
                                                    Exibindo de {props.from} à{" "}
                                                    {props.to} do total de{" "}
                                                    {props.qtd_total}
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div
                                                        style={{
                                                            float: "right",
                                                        }}
                                                    >
                                                        <Pagination>
                                                            {props.pagination(
                                                                props.current_page,
                                                                props.get,
                                                                props.last_page,
                                                                props.changePage
                                                            )}
                                                        </Pagination>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

const mapStateToProps = (state) => ({
    salesRecords: state.register_sgagri.registers_sgagri.data || [],
    current_page: state.register_sgagri.registers_sgagri.current_page,
    perfis: state.auth.user.perfis,
    last_page: state.register_sgagri.registers_sgagri.last_page,
    from: state.register_sgagri.registers_sgagri.from,
    to: state.register_sgagri.registers_sgagri.to,
    qtd_total: state.register_sgagri.registers_sgagri.total,
    qtd_per_page: state.register_sgagri.qtd_per_page,
    pesquisa: state.register_sgagri.pesquisa || "",
    id_role: state.auth.user.id_role,
    asc: state.register_sgagri.asc,
    th: state.register_sgagri.th,
    id_coordinator: state.register_sgagri.coordinator_report.value || "",
    id_area: state.register_sgagri.area_report.value || "",
    id_status: state.register_sgagri.status_report.value || "",
    from_date: state.register_sgagri.from,
    to_date: state.register_sgagri.to,
    order_var: state.register_sgagri.order,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            pagination,
            get,
            order,
            change_qtd_per_page,
            changePesquisa,
            clearPesquisa,
            changePage,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GridRegisterSgagri);
