import styled from "styled-components";


export const Wrapper = styled.div`
    @media(min-width: 768px){

        width: 100%;
        height: 100%;
        background-color: #2A4038;
        /* background-size: cover !important;  */
        position: absolute;
        float: left;
        background-position: center center;
        background-repeat: no-repeat;
    }
    @media(max-width: 768px){

        width: 100%;
        height: 100%;
        background-color: #2A4038;
        background-size: cover !important; 
        position: absolute;
        background-position: center center;
        background-repeat: no-repeat;
    }

`;



export const LogoStyle = styled.div`
    /* position:relative;
    left:50%;
    margin-left:-65px; */
    @media(min-width: 768px){
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
        align-content: left;
        margin-left: 2%;
        /* margin-top: 30px; */

    }
    @media(max-width: 768px){ /* Aqui centraliza a logo no dispositivo pequeno*/
        width: fit-content; 
        margin: auto;
    }
    
    
    
`;

export const ContentWrapper = styled.div`
    @media(min-width: 768px){ 
        width: 45%;
        background-color: #2A4038;
        margin-top: 13%;
        margin-left: 9%;
    }
    @media(max-width: 768px){

        width: 80%;
        left: 27.5%;
        top: 10%;
        margin-left: -17%;
        position: absolute;
        background-color: #2A4038;
    }
    
`;


export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #2A4038;
`;

export const ContentRemember = styled.div`
    a{
        font-family: SF Pro Text;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #BEC4C9;
    }
    /*a:hover{
        font-weight: bold;
        color: #0070BA;
    }*/
`;

export const ButtonContainner = styled.div`
    button{
        background: #12803E;
        border: 0;
    }
`;

export const ButtonContainnerBack = styled.div`
    button{
        background: #828282;
        border: 0;
        color: #FFF;
    }
`;



