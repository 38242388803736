import React from 'react';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import { useSelector } from 'react-redux';

export default function RenderTechnical({
                                            technical,
                                            handleCheeseChange,
                                            roles,
                                            formatReal,
                                            handleRemove,
                                            isDisabled,
                                            coordinator,
                                            mode,
                                            manager,
                                        }) {
    const technicals = technical || [];
    const perfis = roles || [];
    const user_id = useSelector(state => state.auth.user.id);
    console.log(technicals);
    return technicals.map((desc, index) => (
        
        <tr key={index}>
            <td className='text-center'>
                <Toggle
                    id={`${desc.id}`}
                    checked={desc.ativo === 1}
                    onChange={handleCheeseChange}
                    disabled={mode === 'view' || desc.ativo === 0}
                />

            </td>
            
            <td className='text-center'>{desc.createdAt}</td>

            <td className='text-center'>{desc.dataAlocacao}</td>
            <td className='text-center'>{desc.dataFimAlocacao}</td>
            <td className='text-center'>{desc.atuacao}</td>
            <td className='text-center'>{desc.formato}</td>
            <td className='text-center'>{desc.name_technical}</td>
            {`${perfis}`.indexOf(1) >= 0 || `${perfis}`.indexOf(2) >= 0 || `${perfis}`.indexOf(3) >= 0 || `${perfis}`.indexOf(5) >= 0 || `${perfis}`.indexOf(6) >= 0 || parseInt(coordinator.value) === parseInt(user_id) || parseInt(manager.value) === parseInt(user_id)
                ? (<td className='text-center'>{formatReal(parseFloat(`${desc.dedication} dias/horas`))} dias/horas</td>)
                : parseInt(desc.user_id) === parseInt(user_id)
                    ? <td className='text-center'>{formatReal(parseFloat(`${desc.dedication} dias/horas`))} dias/horas</td>
                    : <td className='text-center'></td>
            }
            {console.log(desc.dedication)}

            <td className='text-center'>{desc.remuneration}</td>

            {`${perfis}`.indexOf(1) >= 0 || `${perfis}`.indexOf(2) >= 0 || `${perfis}`.indexOf(3) >= 0 || `${perfis}`.indexOf(5) >= 0 || `${perfis}`.indexOf(6) >= 0 || parseInt(coordinator.value) === parseInt(user_id) || parseInt(manager.value) === parseInt(user_id)
                ? (<td className='text-center'>R${formatReal(parseFloat(`${desc.value_technical}`))}</td>)
                : parseInt(desc.user_id) === parseInt(user_id)
                    ? <td className='text-center'>R${formatReal(parseFloat(`${desc.value_technical}`))}</td>
                    : <td className='text-center'></td>
            }
            <td className='text-center'>{desc.description}</td>
            <td className='text-center'>
                <button disabled={mode === 'view' || desc.ativo === 0} type='button' className='btn btn-danger'
                        onClick={() => handleRemove(desc.id)}><span className='fas fa-minus'></span></button>
            </td>
        </tr>

    ));
}

