import { get_projects } from '../../../../Services/projectEnsino';

//Função que traz os dados do banco de dados
export const get = () => {
    return [
        (dispatch, getState) => {
            const order = getState().projectEnsino.order;
            const th = getState().projectEnsino.th;
            const qtd_per_page = getState().projectEnsino.qtd_per_page;
            const pesquisa = getState().projectEnsino.pesquisa || '';
            const page = getState().projectEnsino.page;
            const area = getState().projectEnsino.area.value || '';
            const manager = getState().projectEnsino.manager.value || '';
            const cod_centro_custo =
                getState().projectEnsino.cod_centro_custo.value || '';
            const coordinator =
                getState().projectEnsino.coordinator.value || '';
            const status = getState().projectEnsino.status.value || '';
            console.log('states', manager);
            get_projects(
                page,
                qtd_per_page,
                order,
                th,
                pesquisa,
                area,
                manager,
                cod_centro_custo,
                coordinator,
                status
            ).then((resp) =>
                dispatch({ type: 'ProjectsEnsino', payload: resp.data })
            );
        },
    ];
};

//Função que ordena as colunas
export const order = (coluna) => {
    return [
        (dispatch, getState) => {
            const asc = getState().projectEnsino.asc;

            if (asc === true) {
                dispatch({
                    type: 'GetProjectEnsino_Desc',
                    desc: true,
                    asc: false,
                    order: 'desc',
                    th: coluna,
                });
            }
            if (asc === false) {
                dispatch({
                    type: 'GetProjectEnsino_Asc',
                    asc: true,
                    desc: false,
                    order: 'asc',
                    th: coluna,
                });
            }
        },
        get(1),
    ];
};

export const change_qtd_per_page = (event) => {
    return [
        {
            type: 'qtd_per_page_project_ensino',
            payload: event.target.value,
        },
        get(1),
    ];
};

export const changePesquisa = (event) => {
    return [
        {
            type: 'pesquisaProjectEnsino',
            payload: event.target.value,
        },
    ];
};

export const clearPesquisa = (event) => {
    return [
        {
            type: 'clearPesquisaProjectEnsino',
            payload: '',
        },
        get(1),
    ];
};

export const changePage = (page) => {
    return [
        {
            type: 'changePageProjectEnsino',
            payload: page,
        },
        get(),
    ];
};

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().projectEnsino.pesquisa || '';
            if (pesquisa) {
                return dispatch({
                    type: 'changePageProjectEnsino',
                    payload: 1,
                });
            }
        },
        get(),
    ];
};
