import React, { useState, useEffect } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import Footer from '../../../Footer/Footer';
import Table from '../../../Table/Table';
import Thead from '../../../Table/Thead';
import Tbody from '../../../Table/Tbody';
import Tfoot from '../../../Table/Tfoot';
import StylePage from '../../../Style Page/StylePage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pagination } from '../../../Pagination/paginations';
import { Link } from 'react-router-dom';
import Pagination from '../../../Table/Pagination';
import Cabecalho from '../../../Table/Cabecalho';
import Alert from '../../../Alerts/Alert';
import MyModal from '../../../Modal/MyModal';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import Cultures from './Cultures';
import {
    search,
    changePage,
    get,
    order,
    change_qtd_per_page,
    changePesquisa,
    clearPesquisa,
} from './Actions/actions';
import { changeStatus } from '../../../Services/culture'

function GridCulture(props) {

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [id, setId] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const name = 'name';

    useEffect(() => {
        getCultures();
        return () => {
            getCultures();
            console.log(getCultures);
        };

    }, []);

    //Função que tra todos as Culturas cadastrados no banco de dados
    const getCultures = () => {
        props.get();
    };


    //Função que muda o estado do modal para true, e definindo o id de qual registro será excluído permitindo a abertura do mesmo
    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);

    };

    const changeStatusActive = (event) =>{
        const id = event.target.id
        let checked = event.target.checked === true ? 1 : 0
        const data = {
            situacao:checked,     
        }

        changeStatus(id, data)
        .then(resp=> {
            setLoading(false)
            setError('')
            setSuccess(resp.data.success)
            getCultures()
            setTimeout(() => {
                setSuccess('')
            }, 2500);
        })
        .catch(e => {
            setLoading(false)
            setSuccess('')
            setError(e.response.data.error) 
        })
    }


    return (
        <div className='wrapper'>
            <Header />
            <Sidebar />
            <StylePage>

                {
                    loading === true ? (
                        <Content>
                            <ReactLoading type='spinningBubbles' color='blue' height={200} width={100} />
                        </Content>

                    ) : ('')
                }
                {
                    success ? (
                        <Alert type='success'>{success}</Alert>
                    ) : ('')
                }
                {
                    error ? (
                        <Alert type='danger'>{error}</Alert>
                    ) : ('')
                }

                <div style={{ display: loading === true ? ('none') : ('block') }}>

                    <Table
                        view={
                            <div className='col-sm-12 col-md-5'>
                                <div className='dataTables_info' id='example1_info' role='status' aria-live='polite'>
                                    Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                                </div>
                            </div>
                        }

                        cabecalho={

                            <Cabecalho
                                to='#/'
                                action={props.search}
                                clear={props.clearPesquisa}
                                valuePesquisa={props.pesquisa}
                                value={props.qtd_per_page}
                                onChange={props.change_qtd_per_page}
                                changePesquisa={props.changePesquisa}
                            />

                        }
                        pagination={
                            <Pagination>
                                {
                                    props.pagination(props.current_page, props.get, props.last_page, props.changePage)
                                }
                            </Pagination>
                        }
                        title='Culturas'
                        button={
                            props.perfis.indexOf(1) >= 0 || props.perfis.indexOf(2) >= 0 || props.perfis.indexOf(3) >= 0 || props.perfis.indexOf(6) >= 0 || props.perfis.indexOf(10) >= 0 ? (
                                <Link to='/new_cultura' className='btn btn-primary'>Cadastro</Link>
                            ) : ''

                        }>
                        <Thead>
                            <th>STATUS</th>
                            <th>
                                CULTURA
                                <Link
                                    to='/culturas'
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(name)}
                                >
                                    <i className={`right fas fa-sort-amount-${props.th === 'name' ? (props.asc === true ? ('down') : ('up')) : ('down')}-alt`}></i>
                                </Link>
                            </th>
                            <th>AÇÕES</th>
                        </Thead>
                        <Tbody>
                            <Cultures
                                cultures={props.cultures}
                                changeStatus={changeStatusActive}
                            />
                        </Tbody>
                        <Tfoot>
                            <th>STATUS</th>
                            <th>CULTURA</th>
                            <th>AÇÕES</th>
                        </Tfoot>
                    </Table>
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}

const mapStateToProps = state => ({
    cultures: state.culture.cultures.data,
    current_page: state.culture.cultures.current_page,
    last_page: state.culture.cultures.last_page,
    from: state.culture.cultures.from,
    to: state.culture.cultures.to,
    qtd_total: state.culture.cultures.total,
    qtd_per_page: state.culture.qtd_per_page,
    pesquisa: state.culture.pesquisa,
    perfis: state.auth.user.perfis,
    id_role: state.auth.user.id_role,
    asc: state.culture.asc,
    th: state.culture.th,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({
        pagination,
        get,
        order,
        change_qtd_per_page,
        changePesquisa,
        clearPesquisa,
        changePage,
        search,
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GridCulture);
