const ProjectReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Projects':
            return{...state, projects:actions.payload}

        case 'GetProject_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetProject_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_project':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaProject':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaProject':
            return{...state, 
                pesquisa:actions.payload,
                type:actions.payload,
                area:actions.payload,
                manager:actions.payload,
                coordinator:actions.payload,
                status:actions.payload,
            }
            
        case 'pageInitial':
            return{...state, page:actions.payload}
        
        case 'changePageProject':
            return{...state, page:actions.payload}

        case 'type':
            return{...state, type:actions.payload}
        
        case 'area':
            return{...state, area:actions.payload}
        
        case 'manager':
            return{...state, manager:actions.payload}
        
        case 'coordenador':
            return{...state, coordinator:actions.payload}
        
        case 'status':
            return{...state, status:actions.payload}

        

        default:
            return state;
    }
}
export default ProjectReducer;