import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ApprovedIcon from '../Icons/akar-icons_check.png'
import DisapprovedIcon from '../Icons/reprove.png'
import AnalysisIcon from '../Icons/analise.png'
import BilledIcon from '../Icons/Vector.png'
import IconSincronizar from '../Icons/sincronizar.png'

export default function RecipeReviewCardSgagri(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      // maxWidth: 500,
      background: '#FFFFFF',
      // boxShadow: '0px 6px 5px rgba(0, 0, 0, 0.24)', 
      boxShadow: '0px 9px 18px rgba(0, 0, 0, 0.18)',
      overflow: 'visible'
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
  }));
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root} style={props.style}>
      <div style={{float: 'right',marginTop: '8px', marginRight:'8px', width: '30px', height: '30px'}}>

        {props.actions}
      </div>
      <div style={{position: 'absolute', marginTop: '15px', marginLeft: '15px'}}>
        {props.selectStatus}
      </div>
      <div 
        className="text-center"
        style={{
          height: '129px',
          left: '20px',
          top: '-50px',
          
          backgroundColor: `${
            props.colorNew ? props.colorNew :
            parseInt(props.id_status) === 1 ? '#f39c12'
            : parseInt(props.id_status) === 2 ? '#00a65a'
            : parseInt(props.id_status) === 3 ? '#dd4b39'
            : parseInt(props.id_status) === 4 ? '#00c0ef'
            : parseInt(props.id_status) === 6 ? '#000079'
            : parseInt(props.id_status) === 5 ?
              props.perfis.indexOf(1) >=0 || props.perfis.indexOf(2) >=0 || props.perfis.indexOf(3) >=0 || props.perfis.indexOf(6) >=0  ?
                "#00c0ef" : "#00a65a"
            //   props.id_status == 1 ? 'linear-gradient(180deg, #FF9901 0%, #E4F51A 86.98%, #F0E70C 93.75%)'
            // : props.id_status == 2 ? 'linear-gradient(180deg, #20DC33 45.31%, rgba(48, 177, 27, 0.85) 85.42%)'
            // : props.id_status == 3 ? 'linear-gradient(180deg, #F10909 20.31%, rgba(187, 21, 21, 0.85) 85.42%)'
            // : props.id_status == 4 ? 'linear-gradient(180deg, #094AF1 20.31%, rgba(21, 147, 187, 0.85) 85.42%)'
            : '#FFFF'
          }`,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
        }}
      >
        <img width="45px" alt="Icone" src={
          props.iconNew ? props.iconNew :
          parseInt(props.id_status) === 1 ? AnalysisIcon :
          parseInt(props.id_status) === 2 ? ApprovedIcon :
          parseInt(props.id_status) === 3 ? DisapprovedIcon :
          parseInt(props.id_status) === 4 ? AnalysisIcon :
          parseInt(props.id_status) === 5 ?
              props.perfis.indexOf(1) >=0 || props.perfis.indexOf(2) >=0 || props.perfis.indexOf(3) >=0 || props.perfis.indexOf(6) >=0  ?
                IconSincronizar : ApprovedIcon : ''
        } style={{marginTop: '20px', marginLeft: '40px'}} /> 
        <p style={{color: "#FFFF"}}>{
          props.descStatus ? props.descStatus :

          parseInt(props.id_status) === 1 ? 'Em Análise' :
          parseInt(props.id_status) === 2 ? 'Aprovado' :
          parseInt(props.id_status) === 3 ? 'Reprovado' :
          parseInt(props.id_status) === 4 ? 'Pré Aprovado' :
          parseInt(props.id_status) === 5 ?
            props.perfis.indexOf(1) >=0 || props.perfis.indexOf(2) >=0 || props.perfis.indexOf(3) >=0 || props.perfis.indexOf(6) >=0  ?
              "Enviado W3" : "Aprovado"
          :''
        }</p>
      </div>
      
        {props.actionDisabled &&
        <CardHeader
            action={
                props.actionDisabled
            // <IconButton aria-label="settings">
            //     <MoreVertIcon />
            // </IconButton>
            }
        />
      }
      <CardContent>
        <Typography gutterBottom variant="h5" style={{fontSize: '14px', fontWeight: 700, height: '35px'}} component="h2">
            {props.title}
          </Typography>
            {props.ticket && props.ticket.map(des => (
                <>
                    <div style={{display: 'inline-block'}}>
                      {
                        des === 'Historico' && 
                        <span style={{backgroundColor: 'rgb(45, 156, 219)', height: '40px', color: '#FFFF', padding: '10px', textAlign: 'center', width: '30%', fontWeight: 700, borderRadius: '25px'}}>
                            Histórico
                          </span>
                      }
                      {des === 'Incompleto' && 
                        <span style={{backgroundColor: 'rgb(243, 156, 18)', marginLeft: '5px',height: '40px', color: '#FFFF', padding: '10px', textAlign: 'center', width: '30%', fontWeight: 700, borderRadius: '25px'}}>
                          Incompleto
                        </span>
                      }
                    </div>
                      
                </>
            ))}
            {props.visit && 
              <div>
                <span style={{backgroundColor: 'rgb(45, 156, 219)', height: '40px', color: '#FFFF', padding: '5px', textAlign: 'center', width: '30%', fontWeight: 700, borderRadius: '25px'}}>
                  Faltam Lançamentos
                </span>
              </div>
            }
          <hr></hr>
          <Typography variant="body2" color="textSecondary" style={{fontSize: '12px', display:'inline-block'}} component="p">
            {props.children}
          </Typography>
      </CardContent>
      {props.actionRoute !== 'view' && (
          <>
            
            
            <CardActions style={{
               background: 'rgba(193, 192, 192, 0.35)',
               mixBlendMode: 'darken',
               marginTop: '10px'
              //  opacity: 0.6
            }} disableSpacing>
                
               
                {props.actionsStatus}
                
                
                    <IconButton
                      className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded,
                      })}
                      style={{color: '#8A9099'}}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="Mais Informações"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                
                  
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {props.rel}
                </CardContent>
            </Collapse>
          </>
      )}
    </Card>
  );
}
