import React, { useState, useEffect } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import Footer from '../../../Footer/Footer'
import {connect, useSelector, useDispatch} from 'react-redux'
import {bindActionCreators} from 'redux'
import { search, changePage ,get, order, change_qtd_per_page, changePesquisa, clearPesquisa } from './Actions/actions'
import { pagination } from '../../../Pagination/paginations'
import Pagination from '../../../Table/Pagination'
import Alert from '../../../Alerts/Alert'
import MyModal from '../../../Modal/MyModal'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import RefundsShared from './RefundsShared'
import { del_reimbursement, changeStatus, changeStatusAll } from '../../../Services/reimbursement_shared'
import ReportReimbursementShared from './ReportReimbursementShared'
import { goButton } from './js/goButton'
import CabecalhoNew from '../../../Table/CabecalhoNew'
import Input from '../../../Forms/Input'
import TextArea from '../../../Forms/TextArea'
import Form from '../../../Forms/Form'

function GridReimbursement(props) {
    const fromDate = useSelector(state => state.reimbursement_shared.from) 
    const toDate = useSelector(state => state.reimbursement_shared.to)
    const [ loading, setLoading ] = useState(false);
    const [ modal, setModal ] = useState(false);
    const [ description, setDescription ] = useState('');
    const [ modalReproved, setModalReproved ] = useState(false);
    const [ modalFilter, setModalFilter ] = useState(false);
    const [ idReimbursement, setIdReimbursement ] = useState('')
    const [ id, setId ] = useState('');
    // const [ refunds, setRefunds ] = useState([]);
    const [ success, setSuccess ] = useState('');
    const [ error, setError ] = useState('');
    const report = false
    // const headers = [
    //     {label:'Nome do Técnico',key:'name'}, {label:'Título do Projeto',key:'titulo'},
    //     {label:'Tipo de Reembolso',key:'descTipo'}, {label:'Status',key:'descStatus'},
    //     {label:'Descrição',key:'descReembolso'}, {label:'Data de Lançamento',key:'data_lancamento'},
    //     {label:'Data da Despesa',key:'data_despesa'},{label:'Valor',key:'valor'},{label:'KM',key:'km'},
    // ]
    const [ status_allShared, setStatusAllShared ] = useState([])
    // const fileUpload = useRef()
    const dispatch = useDispatch()
   
    const getRefundsShared = () => {
        props.get();
    }

    useEffect(() => {
        getRefundsShared();   
    },[])

    // function todosIguaisOuDiferentes() {
    //     let get_refunds= props.refunds_shared || []
    //     var filtrado = get_refunds.map(desc => (
    //         desc.id_status
    //     )).filter(function(elem, pos, arr) {
    //         return arr.indexOf(elem) == pos;
    //     });
    
    //     if(filtrado.length === 1){
    //         return <th><input onClick={clickAll} name="checks" type="checkbox" id="checks"/></th>
    //     }
    // }

    const modalReprovedOn = (id_reimbursement) => {
        setModalReproved(true)
        setIdReimbursement(id_reimbursement)
    }

    const changeStatusReimbursementReproved = (e) => {
        e.preventDefault();
        setModalReproved(false)
        setLoading(true)
        const object = {
            id_status: 3,
            description: description
        }
        changeStatus(idReimbursement,object)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setDescription('')
            setLoading(false)
            setTimeout(()=> {
                setSuccess('')
            },4000)
        
        })
        .catch(e => {
            setLoading(false)
            setDescription('')
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (props.get()))            

    }
     
    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
        
    }

    const handleCloseExcluir = event => {
        setModal(false);
        setId('');
        
    }

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false)
        
        del_reimbursement(id)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
        
        })
        .then(resp => (
            setTimeout(()=> {
                setSuccess('')
            },4000)

        ))
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (props.get()))
        // .then(resp => {setState({success:resp.data.success, error:''})})

    }

    const changeStatusReimbursementShared = (id_reimbursement_shared,id_status) => {
        setLoading(true)
        const object = {
            id_status: parseInt(id_status)
        }
        console.log(object)
        console.log(id_reimbursement_shared)
        changeStatus(id_reimbursement_shared,object)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
            setTimeout(()=> {
                setSuccess('')
            },4000)
        
        })
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (props.get()))            

    }

    const changeStatusReimbursementSharedAll = (status) => {
        setLoading(true)
        const object = {
            idsReimbursementShared: status_allShared,
            status: status
        }
        changeStatusAll(object,status)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
            setStatusAllShared([])
            setTimeout(()=> {
                setSuccess('')
            },4000)
        
        })
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            // setTimeout(()=> {
            //     setError('')
            // },4000)
        
        })
        .then(resp => (props.get()))            

    }

    const check = event =>{
		let checked = event.target.checked
		if(checked === true){
			setStatusAllShared(status_allShared.concat(parseInt(event.target.value)))
		}else{	
            setStatusAllShared(status_allShared.filter(desc => parseInt(desc) !== parseInt(event.target.value)))
		}		
	}

	// const clickAll = event => {
	// 	let isChecked = event.target.checked
	// 	let checked = document.querySelectorAll('#checked')
	// 	if(isChecked){
	// 		setStatusAllShared([])
	// 		for(let i=0;i<checked.length;i++){
	// 			checked[i].checked = true
    //         }
    //         let refunds_all = props.refunds_shared || []
	// 		setStatusAllShared(refunds_all.map(desc=>(
	// 			desc.id
	// 		)))

	// 	}else if(!isChecked){
	// 		for(let i=0;i<checked.length;i++){
	// 			checked[i].checked = false
	// 		}
	// 		setStatusAllShared([])

	// 	}	
	// }

    // const download = event => {
        
    //     get_refunds(props.page,props.qtd_total,props.order_var,props.th,props.pesquisa,props.id_technical,props.id_project,props.id_type,props.id_status,props.from_date,props.to_date)
    //     .then(resp => {
    //         // console.log(resp.data.data)
    //         // setRefunds(resp.data.data)  
    //     })
    //     .then(resp => {
    //         // console.log(refunds)
    //         fileUpload.current.link.click()
    //     })
        
    // }

    //Função que formata o campo valor
    function formatReal(desc){
        var tmp = `${desc.toFixed(2)}`.replace('.',',');

        if( tmp.length > 6 )
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, ".$1")
        return tmp;
    }

    return (
        <div className="wrapper" style={{backgroundColor: '#E5E5E5, 100%'}}>
            <Header />
            <Sidebar />
            <div className="content-wrapper" style={{backgroundColor: '#E5E5E5, 100%'}}>
                <section className="content">
                    {
                        loading === true ? (
                            <Content>
                                <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                            </Content>
                            
                        ):('')
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <MyModal
                                show={modal}
                                onHide={handleCloseExcluir}
                                title="Excluir Reembolso Compartilhado"
                                content="Tem certeza que deseja excluir o reembolso compartilhado ?"
                                cancel={handleCloseExcluir}
                                del={handleExcluir}     
                                to="#/"
                                type="danger"
                                textButton="Excluir"                   
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <MyModal
                                show={modalFilter}
                                onHide={(e) => setModalFilter(false)}
                                title="Filtrar"
                                content={
                                    <ReportReimbursementShared />
                                }
                                cancel={function(){
                                    return [
                                        props.clearPesquisa(),
                                        setTimeout(()=>{
                                            setModalFilter(false)
                                        },1000)
                                    ]
                                }}
                                del={function(){
                                    return [
                                        props.search(),
                                        setTimeout(()=>{
                                            setModalFilter(false)
                                        },1000)
                                    ]
                                }} 
                                to="#/"
                                type="success"
                                contentSecondary="Limpar Busca"
                                textButton="Buscar"                   
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <MyModal
                                show={modalReproved}
                                onHide={(e) => setModalReproved(false)}
                                title="Reprovar Lançamento"
                                content={
                                    <Form onSubmit={changeStatusReimbursementReproved}>
                                        <TextArea 
                                            label="Motivo" 
                                            maxLength="254"
                                            placeholder="Descreva o motivo da reprovação" 
                                            onChange={(e) => setDescription(e.target.value)} 
                                            value={description} 
                                            required={true} />
                                        <div className="text-right">
                                            <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={(e) => setModalReproved(false)}>Cancelar</button>
                                            <button type="submit" className="btn btn-danger">Reprovar</button>
                                        </div>
                                    </Form>
                                }
                                onSubmit={true}
                                cancel={(e) => setModalReproved(false)}
                                // del={changeStatusReimbursementReproved} 
                                to="#/"
                                type="danger"
                                textButton="Reprovar"                   
                            />
                        </div>
                    </div>
                    <div style={{display: loading === true ? ('none'):('block')}}>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="box">
                                    <div className="box-body" style={{background: '#FFFFFF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '15px'}}>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <h2>Registros de Reembolsos Compartilhados</h2>
                                            </div>
                                            <div className="text-right col-sm-6">
                                                <button 
                                                   
                                                    type="button" 
                                                    onClick={(e) => {props.history.push(`/new_reimbursement_shared`);e.preventDefault()}} 
                                                    className="btn btn-primary"
                                                >Cadastro</button>
                                            </div>
                                        </div>
                                        {
                                            props.refunds_shared.length >= 0 || props.pesquisa || props.fromDate || props.toDate ?
                                                <CabecalhoNew
                                                    filter={
                                                        <>

                                                            <div className="col-md-3 col-sm-12 mt-3">     
                                                                <Input 
                                                                    label="De"
                                                                    type="date"
                                                                    icon="fas fa-calendar"
                                                                    onChange={(e) => dispatch({ type: 'changeFromReportShared', payload:e.target.value })}
                                                                    value={fromDate}
                                                                />
                                                            </div>
                                                            <div className="col-md-3 col-sm-12 mt-3"> 
                                                                <Input 
                                                                    label="Até"
                                                                    type="date"
                                                                    icon="fas fa-calendar"
                                                                    onChange={(e) => dispatch({ type: 'changeToReportShared', payload:e.target.value })}
                                                                    value={toDate}
                                                                />
                                                            </div> 
                                                        </>
                                                    } 
                                                    buttonCsv={
                                        
                                                        <>
                        
                                                            {/* <button style={{background: '#005F31', color: '#FFF', borderRadius: '20.5px', width: '140px'}} className="btn btn-sm" onClick={download}>
                                                                <i className="fas fa-file-csv"></i> Exportar CSV
                                                            </button> */}
                        
                                                            <button style={{background: '#1A170E', color: '#FFF', borderRadius: '20.5px', width: '140px', marginLeft: '10px'}} className="btn btn-sm" onClick={() => setModalFilter(modalFilter === false ? true : false)}>
                                                                <i className="fas fa-filter"></i> Mais Filtros
                                                            </button>
                                                            
                                                            {/* <CSVLink
                                                                filename={`Relatorio-${new Date()}.csv`}
                                                                headers={headers}
                                                                hidden={true}
                                                                separator={";"}
                                                                ref={fileUpload}
                                                                data={refunds || []}
                                                        
                                                            >
                                                                <span className="fas fa-file-csv"> Exportar CSV</span>
                                                            </CSVLink> */}
                                                        </>
                        
                                                        
                                                    }  
                                                    to="#/"
                                                    buttonStatus={
                                                        goButton(props.refunds_shared, status_allShared, changeStatusReimbursementSharedAll)
                                                        
                                                        // <button type="button" onClick={()=>console.log(status_all)}>Teste</button>
                                                    } 
                                                    action={props.search} 
                                                    display="reimbursement" 
                                                    report={report} 
                                                    clear={props.clearPesquisa} 
                                                    valuePesquisa={props.pesquisa} 
                                                    value={props.qtd_per_page} 
                                                    onChange={props.change_qtd_per_page} 
                                                    changePesquisa={props.changePesquisa}  
                                                />
                                            :''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                                    
                            
                        <div className="card-body">
                            {/* <div style={{display: `${displayLegend === true ? 'none' : 'block'}`}} className="text-right">
                                <button onClick={(e) => setDisplayLegend(true)} className="btn btn-secondary" style={{background: 'linear-gradient(99.87deg, #427B67 25.78%, #22AC7A 65.59%)',borderRadius: '20.5px'}}>Legendas</button>
                            </div>
                            <div style={{backgroundColor: '#FFF', padding: '10px', borderRadius:'25px', display: `${displayLegend === false ? 'none' : 'block'}`}}>
                                <div style={{position: 'relative', float: 'right'}}>
                                    <button onClick={(e) => setDisplayLegend(false)} className="btn"><i className="fas fa-times"></i></button>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-1 mt-2">
                                        <h5 style={{fontSize: '16px'}}>Legendas:</h5>
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span><img src={IconAnalysys} width="20px" /> Em Análise</span>

                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span><img src={AprovedIcon} width="20px" /> Aprovar</span>

                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span><img src={ReprovedIcon} width="20px" /> Reprovar</span>

                                    </div>
                                    <div className="col-md-1 mt-2">
                                        <span><img src={IconBilled} width="20px" /> Faturar</span>

                                    </div>
                                    

                                </div>

                            </div> */}
                            {
                                success ? (
                                    <div>
                                        <Alert type="success" onCloseAlert={() => setSuccess('')}>{success}</Alert>
                                    </div>
                                ):("")
                            }
                            {
                                error ? (
                                    <div>
                                        <Alert type="danger" onCloseAlert={() => setError('')}>{error}</Alert>
                                    </div>
                                ):("")
                            }
                            <div className="row mt-3">
                                {
                                    parseInt(props.refunds_shared.length) === 0 ? 
                                        <div 
                                            style={{
                                                color: '#FFFF', 
                                                textAlign: 'center', 
                                                width: '100%'
                                            }}
                                        >
                                            <span style={{color: 'black'}}>{`${!props.pesquisa ? 'Não existem reembolsos compartilhados no sistema. Clique no botão Cadastro para inserir um novo reembolso compartilhado' : 'Nenhum registro encontrado na pesquisa'}`}</span>
                                        </div>
                                    :''

                                }
                                <RefundsShared modalReprovedOn={modalReprovedOn} history={props.history} check={check} changeStatusReimbursementShared={changeStatusReimbursementShared} formatReal={formatReal} refunds_shared={props.refunds_shared} onDelete={onChangeModal} />
                            </div>
                        </div>
                        {props.refunds_shared.length > 0 &&
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="box">
                                        <div className="box-body" style={{background: '#FFFFFF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '15px'}}>
                                                <div className="row mt-4">
                                                    <div className="col-md-1">
                                                    
                                                            
                                                        <select  value={props.qtd_per_page} onChange={props.change_qtd_per_page} name="example1_length" aria-controls="example1" className="custom-select custom-select-sm form-control form-control-sm">
                                                            <option value={8}>8</option>
                                                            <option value={24}>24</option>
                                                            <option value={48}>48</option>
                                                            <option value={96}>96</option>
                                                        </select> 
                                                        
                                                    
                                                    </div>
                                                    <div className="col-md-5 mt-1 mb-2">
                                                        Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div style={{float: 'right'}}>

                                                            <Pagination>{props.pagination(props.current_page,props.get,props.last_page,props.changePage)}</Pagination>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>
            


                    {/* <Table 
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                    Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                                </div>
                            </div>
                        }
                            
                        cabecalho={
                            
                            <Cabecalho buttonCsv={
                               
                                <>
 
                                    <button className="btn btn-outline-success btn-sm" onClick={download}>
                                        <span className="fas fa-file-csv"> Exportar CSV</span>
                                    </button>

                                    <button className="btn btn-outline-secondary btn-sm" style={{marginLeft: '10px'}} onClick={() => setReport(report === false ? true : false)}>
                                        {report === false ? 'Filtros Avançados' : 'Fechar Filtros'} <span className={`fas fa-${report === true ? 'angle-up' : 'angle-down'}`}></span>
                                    </button>
                                    
                                    <CSVLink
                                        filename={`Relatorio-${new Date()}.csv`}
                                        headers={headers}
                                        hidden={true}
                                        separator={";"}
                                        ref={fileUpload}
                                        data={refunds || []}
                                
                                    >
                                        <span className="fas fa-file-csv"> Exportar CSV</span>
                                    </CSVLink>
                                </>

                                
                            }  
                            to="#/"
                            buttonStatus={
                                goButton(props.refunds_shared, status_allShared, changeStatusReimbursementSharedAll)
                                
                                // <button type="button" onClick={()=>console.log(status_all)}>Teste</button>
                            } 
                            action={props.search} display="reimbursement" report={report} clear={props.clearPesquisa} valuePesquisa={props.pesquisa} value={props.qtd_per_page} onChange={props.change_qtd_per_page} changePesquisa={props.changePesquisa}>
                                <div className="col-md-12" style={{display: `${report === false ? 'none' : 'block'}`}}>
                                    <ReportReimbursementShared 
                                    />
                                </div>
                            </Cabecalho>
   
                        }
                        pagination={<Pagination>{props.pagination(props.current_page,props.get,props.last_page,props.changePage)}</Pagination>} title="Reembolsos Compartilhados"
                        button={
                           <Link to="/new_reimbursement_shared" className="btn btn-primary">Cadastro</Link>
                            
                        }
                        >
                        <Thead>  
                        {props.perfis.indexOf(1) >=0 || props.perfis.indexOf(2) >=0 || props.perfis.indexOf(3) >=0 || props.perfis.indexOf(4) >=0 || props.perfis.indexOf(6) >=0 || props.perfis.indexOf(8) >=0 ? todosIguaisOuDiferentes() : ''} 
                            <th>STATUS<Link to="/refunds" style={{float:'right'}} onClick={()=>props.order(status)}><i className={`right fas fa-sort-amount-${props.th === 'status_reembolsos.descricao' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>TÉCNICO<Link to="/refunds" style={{float:'right'}} onClick={()=>props.order(tecnico)}><i className={`right fas fa-sort-amount-${props.th === 'users.name' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>PROJETOS<Link to="/refunds" style={{float:'right'}} onClick={()=>props.order(projeto)}><i className={`right fas fa-sort-amount-${props.th === 'projetos.titulo' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>TIPO<Link to="/refunds" style={{float:'right'}} onClick={()=>props.order(tipo)}><i className={`right fas fa-sort-amount-${props.th === 'tipos_reembolsos.descricao' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>VALOR<Link to="/refunds" style={{float:'right'}} onClick={()=>props.order(valor)}><i className={`right fas fa-sort-amount-${props.th === 'valor' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>LANÇAMENTO<Link to="/refunds" style={{float:'right'}} onClick={()=>props.order(lancamento)}><i className={`right fas fa-sort-amount-${props.th === 'reembolsos.created_at' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>DESPESA<Link to="/refunds" style={{float:'right'}} onClick={()=>props.order(despesa)}><i className={`right fas fa-sort-amount-${props.th === 'reembolsos.data_lancamento' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>AÇÕES</th>
                        </Thead>
                        <Tbody>
                            <RefundsShared check={check} changeStatusReimbursementShared={changeStatusReimbursementShared} formatReal={formatReal} refunds_shared={props.refunds_shared} onDelete={onChangeModal} />
                        </Tbody>
                        <Tfoot>
                            {props.perfis.indexOf(1) >=0 || props.perfis.indexOf(2) >=0 || props.perfis.indexOf(3) >=0 || props.perfis.indexOf(4) >=0 || props.perfis.indexOf(6) >=0 || props.perfis.indexOf(8) >=0 ? todosIguaisOuDiferentes() : ''}
                            <th>STATUS</th>
                            <th>TÉCNICO</th>
                            <th>PROJETOS</th>
                            <th>TIPO</th>
                            <th>VALOR</th>
                            <th>LANÇAMENTO</th>
                            <th>DESPESA</th>
                            <th>AÇÕES</th>
                        </Tfoot>
                    </Table>         */}
                </div>
            
            
            
            <Footer />
        </div>
    )
}

const mapStateToProps = state => ({
    refunds_shared:state.reimbursement_shared.refunds_shared.data || [],
    perfis:state.auth.user.perfis,
    current_page:state.reimbursement_shared.refunds_shared.current_page,
    last_page:state.reimbursement_shared.refunds_shared.last_page,
    from:state.reimbursement_shared.refunds_shared.from,
    to:state.reimbursement_shared.refunds_shared.to,
    qtd_total:state.reimbursement_shared.refunds_shared.total,
    qtd_per_page:state.reimbursement_shared.qtd_per_page,
    id_role:state.auth.user.id_role,
    asc:state.reimbursement_shared.asc,
    th:state.reimbursement_shared.th,
    pesquisa: state.reimbursement_shared.pesquisa || '',
    id_technical: state.reimbursement_shared.technical_report.value || '',
    id_project: state.reimbursement_shared.project_report.value || '' ,  
    id_type: state.reimbursement_shared.type_report.value || '',  
    id_status: state.reimbursement_shared.status_report.value || '',
    from_date: state.reimbursement_shared.from,
    to_date: state.reimbursement_shared.to,
    order_var: state.reimbursement_shared.order


});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
        pagination, 
        get, 
        order, 
        change_qtd_per_page, 
        changePesquisa, 
        clearPesquisa,
        changePage,
        search 
    }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(GridReimbursement)
