import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import StylePage from '../../../Style Page/StylePage'
import Form from '../../../Forms/Form'
import Input from '../../../Forms/Input'
import Alert from '../../../Alerts/Alert'
import Footer from '../../../Footer/Footer'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import Select from 'react-select'
import { Link, useHistory } from "react-router-dom"
import { register } from '../../../Services/auth'
import { create_service_types, get_view_service_types, edit_service_types } from '../../../Services/serviceType'
import NumberFormat from 'react-number-format'


export default function ServiceType(props) {

    let history = useHistory();
    const [ codSenior, setCodSenior ] = useState('')
    const [ description, setDescription ] = useState ('')
    const [ error, setError ] = useState([])
    const [ success, setSuccess ] = useState('')
    const [ alert, setAlert ] = useState('')
    const [ loading, setLoading ] = useState(false)


    useEffect(() => {
        viewPerId();
    }, [])


    function viewPerId() {
        props.match.params.action && (
            get_view_service_types(props.match.params.id)
            .then(resp => {
                setDescription(resp.data.service_type[0].descricao)
                setCodSenior(resp.data.service_type[0].cod_senior)
            })
        )
    }

    const changeCodSenior = event => {
        const cod_senior = event.target.value
        setCodSenior(cod_senior)
    }

    const changeDescription = event => {
        const description = event.target.value
        setDescription(description)
    }

    const handleForm=(e)=>{
        e.preventDefault();
        setLoading(true)
        
        const data = {
            description: description,
            codSenior: codSenior
        }

        if(props.match.params.action === 'edit'){
            const id = props.match.params.id
            edit_service_types(id,data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.message ? "Os dados fornecidos são inválidos" : '')  
            })           

        }else{
            create_service_types(data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.message ? "Os dados fornecidos são inválidos" : '')  
            })              

        }
    }

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage title={props.match.params.action === 'edit' ? "Edição de Tipo de Serviço" : props.match.params.action === 'view' ? "Visualização de Tipo de Serviço" : "Cadastro de Tipo de Serviço"}>
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('')}
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ):('')}
                <div style={{display: loading === true ? ('none'):('block')}}>
                    <Form onSubmit={handleForm} autoComplete="off">
                        <div className="row">
                            <div className={ `${props.match.params.action === 'edit' || props.match.params.action === 'view' ? 'col-md-6 offset-md-3' : 'col-md-6 offset-md-3'}`}>
                                <Input 
                                    maxLength="13"
                                    label="Código Sênior" 
                                    autocomplete="off"
                                    icon="fas fa-user" 
                                    name="description" 
                                    type="text" 
                                    value={codSenior}
                                    onChange={changeCodSenior}
                                    placeholder="Digite o nome do serviço" 
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    required={true}
                                />
                                {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                                {error ? (<p style={{color:'red'}}>{error}</p>):('')}
                            </div>   
                        </div>
                        <div className="row">
                            <div className={ `${props.match.params.action === 'edit' || props.match.params.action === 'view' ? 'col-md-6 offset-md-3' : 'col-md-6 offset-md-3'}`}>
                                <Input 
                                    label="Nome do Serviço" 
                                    autocomplete="off"
                                    icon="fas fa-user" 
                                    name="description" 
                                    type="text" 
                                    value={description}
                                    onChange={changeDescription}
                                    placeholder="Digite o nome do serviço" 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    maxLength="45"
                                />
                                {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div>   
                        </div>                            
                        
                        <br></br>
                        <div className="text-right" style={{marginTop: '9%'}}>
                            <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                <Link to={`/service_type/${props.match.params.id}/edit`} className="btn btn-primary">Editar</Link>
                            ) : (
                                <button type="submit" className="btn btn-primary">Salvar</button>                           
                            )}
                        </div>        
                    </Form>
                </div>   
            </StylePage>
            <Footer />
        </div>
    )
}
