import { app } from './Api';

const get_view_reimbursement = async (id) => {
    return app.get(`reimbursement/${id}`);
};

const get_refunds = async (
    page,
    qtd_per_page,
    order,
    th,
    pesquisa,
    id_technical,
    id_coordinator,
    id_project,
    id_type,
    id_status,
    from,
    to,
    id_reimbursement_shared
) => {
    return app.get(
        `reimbursement?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&id_technical=${id_technical}&id_coordinator=${id_coordinator}&id_project=${id_project}&id_type=${id_type}&id_status=${id_status}&from=${from}&to=${to}&id_reimbursement_shared=${
            id_reimbursement_shared || ''
        }`
    );
};

const post_reimbursement = async (data) => {
    return app.post(`reimbursement`, data);
};

const del_reimbursement = async (id) => {
    return app.delete(`reimbursement/${id}`);
};

const changeStatus = async (id, data) => {
    return app.put(`reimbursement/change_status_reimbursement/${id}`, data);
};

const changeStatusAll = async (data) => {
    return app.put(`change_status_reimbursement_all`, data);
};

const put_reimbursement = async (id, data) => {
    return app.post(`reimbursement/${id}`, data);
};

const getTechnical = async () => {
    return app.get(`reimbursement/technical/get`);
};

const getExtraCharges = async (idTechnician, idProject) => {
    return app.get(
        `reimbursement/extra_charges/get?id_project=${
            idProject || ''
        }&id_technical=${idTechnician}`
    );
};

const getProjects = async (id_technician, moduleVisit) => {
    return app.get(
        `reimbursement/projects/get?id_technical=${id_technician}&typeModule=${moduleVisit}`
    );
};

const getProjectsReport = async (id_technician, id_coordinator) => {
    return app.get(
        `reimbursement/projects_report/get?id_technical=${id_technician}`
    );
};

const getTechnicalReport = async (id_project) => {
    return app.get(
        `reimbursement/technical_report/get?id_project=${id_project}`
    );
};

const getCoordinatorReport = async () => {
    return app.get(`coordinator_report`);
};

const getType = async () => {
    return app.get(`reimbursement/type/get`);
};

const findKmValue = async (data) => {
    return app.get(`findKmValue/${data}`);
};

const getStatusReport = async () => {
    return app.get(`reimbursement/status_reimbursement/get`);
};

const del_attachment = async (id) => {
    return app.delete(`reimbursement/del_attachment/${id}`);
};

export {
    getExtraCharges,
    changeStatusAll,
    getCoordinatorReport,
    getStatusReport,
    getTechnicalReport,
    getProjectsReport,
    del_attachment,
    get_view_reimbursement,
    get_refunds,
    changeStatus,
    post_reimbursement,
    put_reimbursement,
    getTechnical,
    getProjects,
    getType,
    del_reimbursement,
    findKmValue,
};
