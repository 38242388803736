import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import RecipeReviewCardSgagri from '../../../RecipeReviewCard/RecipeReviewCardSgagri'
import { ButtonAction } from '../../../Button/style'
import AprovedIcon from '../../../Icons/emojione-v1_ballot-box-bold-check.png'
import ReprovedIcon from '../../../Icons/fluent_document-footer-remove-24-filled.png'
import './style.css'

export default function RegistersSgagri(props) {
    const id_role = useSelector(state => state.auth.user.id) || ''
    const perfis = useSelector(state => state.auth.user.perfis) || []

    const sales_record = props.sales_records || []
    return sales_record.map(desc=>(
        <div key={desc.id}  className="col-md-3 mt-4 mt-4">
            <RecipeReviewCardSgagri
                id_status={desc.id_status}
                title={desc.coordenador_name}
                ticket={[desc.historico]}
                actionRoute={props.action}
                rel={
                    <>
                        <>
                            <h3 style={{fontSize: '15px', fontWeight: 700}}>Anexo Proposta</h3>
                            <hr></hr>
                            {
                                desc.anexos.map(res => (
                                    <p>
                                        <span style={{fontWeight: 700, fontSize: '11px'}}> 
                                            <a href={res.link} target="_blank" rel="noopener noreferrer">{res.nome}</a>
                                        </span>
                                    </p>
                                ))
                            }
                        </>
                        <hr></hr>
                        {desc.id_status === 3 && (
                            <>
                                <h3 style={{fontSize: '15px', fontWeight: 700}}>Motivo Reprovação</h3>
                                <hr></hr>
                                <p>{desc.motivo_reprovacao}</p>
                            </>
                        )}

                        <>
                            <h3 style={{fontSize: '15px', fontWeight: 700}}>Produtos</h3>
                            <hr></hr>
                            {
                                desc.produtos.map(res => (
                                    <>
                                        <h3 style={{height: '40px', fontSize: '15px', fontWeight: 700}}>{res.produto}</h3>
                                        <p><span style={{height: '40px', fontWeight: 700}}>Valor :</span> <span>{`R$${props.formatReal(parseFloat(res.valor))}`}</span></p>
                                        <hr></hr>
                                    </>
                                ))
                            }
                        </>
                    </>
                }
                actions={
                    <>
                        <ul className="nav">
                            <li className="nav-item dropdown">
                                <a className="nav-link" data-toggle="dropdown" href="#/">
                                    <i style={{color: '#FFF', width: '30px', height: '30px'}} className="fas fa-bars" />             
                                </a>
                                <div className='dropdown-menu dropdown-menu-right'>
                                    <Link 
                                        to={`/register_sgagri/${desc.id}/view`} 
                                        className='dropdown-item'
                                    >
                                        <i className='fas fa-eye' /> Detalhes
                                    </Link>
                                    {
                                        desc.id_status === 1 && parseInt(desc.created_by) === parseInt(id_role) && (
                                            <>
                                                <Link 
                                                    to={`/register_sgagri/${desc.id}/edit`} 
                                                    className='dropdown-item' 
                                                >
                                                    <i className='fas fa-edit' /> Editar
                                                </Link>
                                                <Link 
                                                    to="#/" 
                                                    className='dropdown-item' 
                                                    onClick={()=>props.onDelete(desc.id)} 
                                                >
                                                    <i className='fas fa-trash-alt' /> Excluir
                                                </Link>                                
                                            </>
                                        )
                                    }
                                    {
                                        desc.historico === 'Historico' && (
                                            perfis.indexOf(1) >=0 || perfis.indexOf(2) >=0 || perfis.indexOf(3) >=0 || perfis.indexOf(6) >=0 ?(
                                                <>
                                                    <Link 
                                                        to={`/check_list/${desc.id}/edit`} 
                                                        className='dropdown-item' 
                                                    >
                                                        <i className='fas fa-edit' /> Editar
                                                    </Link>
                                                    <Link 
                                                        to="#/" 
                                                        className='dropdown-item' 
                                                        onClick={()=>props.onDelete(desc.id)} 
                                                    >
                                                        <i className='fas fa-trash-alt' /> Excluir
                                                    </Link>                                
                                                </>  
                                            ): ''
                                        )
                                    }
                                    
                                </div>
                            </li>
                        </ul>	
                    </>
                    
                    
                }
                actionsStatus={
                    props.action !== 'view' ?
                        perfis.indexOf(1) >=0 || perfis.indexOf(2) >=0 || perfis.indexOf(3) >=0 || perfis.indexOf(6) >=0  ? 
                        (
                            desc.id_status === 1 || desc.id_status === 4 ? (
                                <>
                                    <ButtonAction backgroundButton="#1F9950">
                                        <button 
                                            type="button" tooltip="Aprovar"
                                            className='btn'
                                            onClick={()=>props.modalAprovedOn(desc,2)}
                                        >
                                            <img style={{width: '25px', height: '25px'}} src={AprovedIcon} alt="Aprovado"></img>
                                        </button>
                                    </ButtonAction>
                                    <ButtonAction backgroundButton="#F6525C">
                                        <button 
                                            type="button" tooltip="Reprovar"
                                            className='btn ml-3' 
                                            onClick={()=>props.modalReprovedOn(desc.id,3)}
                                            
                                        >
                                            <img style={{width: '25px', height: '25px'}} src={ReprovedIcon} alt="Reprovado"></img>
                                        </button>                  
                                    </ButtonAction>
                                </> 

                            ) : '' 
                        ) : ''
                    : ''
                }
            >   
                <p style={{fontWeight: 700, height: '30px'}}>
                    <span style={{fontWeight: 700, height: '40px'}}> 
                        Título: <a>{desc.title}</a>
                    </span>
                </p>
                <p><span style={{height: '40px', fontWeight: 700}}>Data de Lançamento :</span> <span>{desc.data_lancamento}</span></p>
                <p><span style={{height: '40px', fontWeight: 700}}>Tipo do Registro :</span> <span>{desc.descTipo}</span></p>
                <p><span style={{height: '40px', fontWeight: 700}}>Estado :</span> <span>{desc.state}</span></p>
                <p><span style={{height: '40px', fontWeight: 700}}>Cidade :</span> <span>{desc.city}</span></p>
                {/* <p><span style={{height: '40px', fontWeight: 700}}>Coordenador :</span> <span>{desc.coordenador_name}</span></p> */}
                <p><span style={{height: '40px', fontWeight: 700}}>Área :</span> <span>{desc.descArea}</span></p>
                <p><span style={{height: '40px', fontWeight: 700}}>Hectares da Propriedade :</span> <span>{`${desc.hectares_propriedade ? desc.hectares_propriedade : 0 } Hectares`}</span></p>
                <p><span style={{height: '40px', fontWeight: 700}}>Valor Contrato :</span> <span>{`R$${props.formatReal(parseFloat(desc.valor_contrato))}`}</span></p>
                <p><span style={{height: '40px', fontWeight: 700}}>Tipo de Cobrança :</span> <span>{desc.tipo_cobranca}</span></p>
                <p><span style={{height: '40px', fontWeight: 700}}>Agente da Venda :</span> <span>{desc.agente}</span></p>
                <p><span style={{height: '40px', fontWeight: 700}}>Origem da Venda :</span> <span>{desc.origem_venda}</span></p>
                <p><span style={{height: '40px', fontWeight: 700}}>Data da Venda :</span> <span>{desc.data_venda}</span></p>
                <p><span style={{height: '40px', fontWeight: 700}}>Quantidade de Parcelas :</span> <span>{desc.quantidade_parcela}</span></p>
                <p><span style={{height: '40px', fontWeight: 700}}>Perído de Vigência :</span> <span>{`${desc.periodo_vigencia_meses} meses`}</span></p>
                <p><span style={{height: '40px', fontWeight: 700}}>Data de início do contrato :</span> <span>{desc.data_inicio_contrato}</span></p>
               
            </RecipeReviewCardSgagri>
        </div>
       
    ))
    
}
