import React from 'react';

export default function StylePage(props) {
    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>{props.title}</h1>
                        </div>
                    </div>
                </div>
                {/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            {/* <div className="card-header">
                                <h3 className="card-title">{props.subtitle}</h3>
                                <div className="text-right">
                                    {props.button || ''}
                                </div>
                            </div> */}
                            {props.children}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
