/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Form from '../../../Forms/Form';
import Input from '../../../Forms/Input';
import Select from 'react-select';
import StylePage from '../../../Style Page/StylePage';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import Footer from '../../../Footer/Footer';
import NumberFormat from 'react-number-format';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { ContentUpload } from '../../../Upload/styles';
import Upload from '../../../Upload/upload';
import FileList from '../../../FileList/FileList';
import TextArea from '../../../Forms/TextArea';
import { useSelector } from 'react-redux';
import {
    delAttachment,
    getCity,
    getFilial,
    getResource,
    getViewRegisterSgagri,
    postRegisterSgagri,
    putRegisterSgagri,
    getAreaManager,
} from '../../../Services/registerSgagri';
import { useHistory, Link } from 'react-router-dom';
import Alert from '../../../Alerts/Alert';
import { Content } from '../../../LoadingStyle/StyleLoading';
import ReactLoading from 'react-loading';
import TableProducts from './TableProducts';
import Products from './Products';
import RadioMaterial from '../../../Forms/RadioMaterial';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function RegisterSgagri(props) {
    function formatReal(desc) {
        // console.log(desc);
        var tmp = `${desc.toFixed(2)}`.replace('.', ',');

        if (tmp.length > 6)
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, '.$1');
        return tmp;
    }

    function formatNumeric(desc) {
        // console.log(desc);
        var tmp = `${desc.toFixed(1)}`.replace('.', ',');

        if (tmp.length > 6)
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, '.$1');
        return tmp;
    }

    let history = useHistory();
    const titleRef = useRef(null);
    const produtoRef = useRef(null);
    const culturaRef = useRef(null);
    const anexoRef = useRef(null);
    const selectManagerRef = useRef(null);
    const perfis = useSelector((state) => state.auth.user.perfis) || [];
    const [reembolsavel, setReembolsavel] = useState('Não');
    const selectAreaRef = useRef(null);
    const selectServiceRef = useRef(null);
    const selectTypeChargeRef = useRef(null);
    const selectStateRef = useRef(null);
    const selectCityRef = useRef(null);
    const selectEmpresaRef = useRef(null);
    const selectFilialRef = useRef(null);
    const selectAgentSaleRef = useRef(null);
    const selectOriginOfSaleRef = useRef(null);
    const selectMainActivityRef = useRef(null);
    const selectQtdParcelRef = useRef(null);
    const [created_by, setCreated] = useState('');
    const [id_status, setIdStatus] = useState('');
    const id_user = useSelector((state) => state.auth.user.id);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState('');
    const [alert, setAlert] = useState('');
    const [alertProduto, setAlertProduto] = useState('');
    const [alertAnexo, setAlertAnexo] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [acre, setAcre] = useState('');
    const [descriptionValue, setDescriptionValue] = useState('');
    const [readjustmentPeriod, setReadjustmentPeriod] = useState('');
    const [renewalIndexer, setRenewalIndexer] = useState('');
    const [billingProperty, setBillingProperty] = useState('');
    // const [ imposto, setImposto ]                                 						= useState(16)
    // const [ margemAdm, setMargemAdm ]                             						= useState(10)
    // const [ margemLqd, setMargemLqd ]                             						= useState(20)
    // const [ margemAd, setMargemAd ]                               						= useState(0)
    const [idMainActivity, setIdMainActivity] = useState('');
    const [optionsState, setOptionsState] = useState([]);
    const [optionsEmpresa, setOptionsEmpresa] = useState([]);
    const [idState, setIdState] = useState('');
    const [idCity, setIdCity] = useState('');
    const [idEmpresa, setIdEmpresa] = useState('');
    const [idFilial, setIdFilial] = useState('');
    const [optionsCity, setOptionsCity] = useState([]);
    const [optionsFilial, setOptionsFilial] = useState([]);
    const [agentSale, setAgentSale] = useState('');
    const [optionsAgentSale, setOptionsAgentSale] = useState([]);
    const [originOfSale, setOriginOfSale] = useState('');
    const [optionsProduct, setOptionsProduct] = useState([]);
    const [idProduct, setIdProduct] = useState('');
    const [product, setProduct] = useState([]);
    const [produtivity, setProdutivity] = useState([]);
    const [optionsOriginOfSale, setOptionsOriginOfSale] = useState([]);
    const [idArea, setIdArea] = useState('');
    const [idTypeServiceSgagri, setIdTypeServiceSgagri] = useState('');
    const [idCoordinator, setIdCoordinator] = useState('');
    const [remunerationCoordinator, setRemunerationCoordinator] = useState('');
    const [description, setDescription] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [valueContract, setValueContract] = useState(0);
    const [valueProduct, setValueProduct] = useState('');
    const [typeCharge, setTypeCharge] = useState('');
    const [qtdParcel, setQtdParcel] = useState('');
    const [validity, setValidity] = useState('');
    const [dateSale, setDateSale] = useState('');
    const [dateStartContract, setDateStartContract] = useState('');
    const [optionsArea, setOptionsArea] = useState([]);
    const [optionsMainActivity, setOptionsMainActivity] = useState([]);
    const [optionsTypeServiceSgagri, setOptionsTypeServiceSgagri] = useState(
        []
    );
    const [technical, setTechnical] = useState([]);
    const [removedTechnical, setRemovedTechnical] = useState([]);
    const [managers, setManagers] = useState([]);
    const [idManager, setIdManager] = useState('');
    const optionsTypeCharge = [
        { label: 'Única', value: 'Única' },
        { label: 'Mensal', value: 'Mensal' },
        { label: 'Variável', value: 'Variável' },
    ];
    function optionsQtdParcela(valorContrato) {
        const qtdparcel = [
            { label: `2x de  R$${formatReal(valorContrato / 2)}`, value: 2 },
            { label: `3x de  R$${formatReal(valorContrato / 3)}`, value: 3 },
            { label: `4x de  R$${formatReal(valorContrato / 4)}`, value: 4 },
            { label: `5x de  R$${formatReal(valorContrato / 5)}`, value: 5 },
            { label: `6x de  R$${formatReal(valorContrato / 6)}`, value: 6 },
            { label: `7x de  R$${formatReal(valorContrato / 7)}`, value: 7 },
            { label: `8x de  R$${formatReal(valorContrato / 8)}`, value: 8 },
            { label: `9x de  R$${formatReal(valorContrato / 9)}`, value: 9 },
            { label: `10x de  R$${formatReal(valorContrato / 10)}`, value: 10 },
            { label: `11x de  R$${formatReal(valorContrato / 11)}`, value: 11 },
            { label: `12x de  R$${formatReal(valorContrato / 12)}`, value: 12 },
            { label: `18x de  R$${formatReal(valorContrato / 18)}`, value: 18 },
            { label: `24x de  R$${formatReal(valorContrato / 24)}`, value: 24 },
            { label: `36x de  R$${formatReal(valorContrato / 36)}`, value: 36 },
            { label: `48x de  R$${formatReal(valorContrato / 48)}`, value: 48 },
        ];
        return qtdparcel;
    }

    useEffect(() => {
        getDataAll();
    }, []);

    useEffect(() => {
        viewPerId();
    }, []);

    useEffect(() => {
        city(idState.value);
    }, [idState]);

    useEffect(() => {
        filial(idEmpresa.value);
    }, [idEmpresa]);

    useEffect(() => {
        optionsQtdParcela(valueContract);
        setQtdParcel(
            optionsQtdParcela(valueContract).filter(
                (desc) => parseInt(desc.value) === parseInt(qtdParcel.value)
            )[0] || ''
        );
    }, [qtdParcel.value, valueContract]);

    // useEffect(() => {
    // 	setTechnical([])
    // 	setRemovedTechnical([])
    // }, [idTypeServiceSgagri])

    function isEdit(created, status) {
        if (
            perfis.indexOf(1) >= 0 ||
            perfis.indexOf(2) >= 0 ||
            perfis.indexOf(3) >= 0 ||
            perfis.indexOf(6) >= 0
        ) {
        } else {
            if (props.match.params.action === 'edit') {
                if (
                    parseInt(created) !== parseInt(id_user) ||
                    parseInt(status) !== 1
                ) {
                    props.history.push(
                        `/check_list/${props.match.params.id}/view`
                    );
                }
            }
        }
    }

    //
    const handleChangeArea = (e) => {
        let areaID = e.value;
        setIdArea(e);

        getAreaManager(areaID)
            .then((resp) => {
                setIdManager(resp.data[0]);
                setManagers(
                    resp.data.map((desc) => ({
                        value: desc.value,
                        label: desc.label,
                    }))
                );
            })
            .catch((err) => console.log(err.data));
    };

    function city(idState) {
        getCity(idState).then((resp) => {
            setOptionsCity(
                resp.data.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
        });
    }

    function filial(idEmpresa) {
        getFilial(idEmpresa).then((resp) => {
            setOptionsFilial(
                resp.data.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
        });
    }

    function getDataAll() {
        getResource().then((resp) => {
            setOptionsArea(
                resp.data.area.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setOptionsMainActivity(
                resp.data.areaAtivo.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setIdArea({
                value: resp.data.area[0].id,
                label: resp.data.area[0].descricao,
            });
            // console.log(idArea);
            getAreaManager(resp.data.area[0].id)
                .then((resp) => {
                    setIdManager(resp.data[0]);
                    setManagers(
                        resp.data.map((desc) => ({
                            value: desc.value,
                            label: desc.label,
                        }))
                    );
                })
                .catch((err) => console.log(err.data));
            setOptionsTypeServiceSgagri(
                resp.data.typeServiceSgagri.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setIdTypeServiceSgagri({
                value: resp.data.typeServiceSgagri[0].id,
                label: resp.data.typeServiceSgagri[0].descricao,
            });
            setOptionsState(
                resp.data.state.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setOptionsEmpresa(
                resp.data.empresa.map((desc) => ({
                    value: desc.id,
                    label: desc.nome,
                }))
            );
            setOptionsAgentSale(
                resp.data.agentSale.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setOptionsOriginOfSale(
                resp.data.originOfSale.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setOptionsProduct(
                resp.data.product.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
        });
    }

    const changeState = (e) => {
        setIdState(e);
        setIdCity('');
    };

    const changeEmpresa = (e) => {
        setIdEmpresa(e);
        setIdFilial('');
    };

    const handleProduct = (e) => {
        e.preventDefault();
        var errorCheck = false;
        // console.log(!valueProduct);

        if (
            !idProduct ||
            idProduct.length <= 0 ||
            (!valueProduct && valueProduct !== 0)
        ) {
            return setAlertProduto(
                'É necessário escolher um produto e preencher o valor para adicionar um novo produto !'
            );
        }

        let validate =
            product.filter(
                (desc) => parseInt(desc.idProduct) === parseInt(idProduct.value)
            ) || [];
        if (validate.length > 0) {
            return setAlertProduto('Não é possivel adicionar um mesmo produto');
        }

        setAlertProduto('');

        if (errorCheck) {
            return [setLoading(false)];
        }

        const data = {
            id: uniqueId(),
            idProduct: idProduct.value,
            nameProduct: idProduct.label,
            value: valueProduct,
        };

        setValueContract(valueContract + valueProduct);
        setProduct(product.concat(data));
        setIdProduct([]);
        setValueProduct('');
    };

    function viewPerId() {
        let valorContrato = '';
        let qtd_parcel = '';
        props.match.params.action &&
            getViewRegisterSgagri(props.match.params.id)
                .then((resp) => {
                    valorContrato = resp.data.sgagriRecord[0].valor_contrato
                        ? (valorContrato =
                              resp.data.sgagriRecord[0].valor_contrato)
                        : 0;
                    qtd_parcel = parseInt(
                        resp.data.sgagriRecord[0].quantidade_parcela
                    );
                    setIdTypeServiceSgagri({
                        value: resp.data.sgagriRecord[0].id_tipo_servico_sgagri,
                        label: resp.data.sgagriRecord[0].tipo_servico_sgagri,
                    });
                    setName(resp.data.sgagriRecord[0].contato_nome);
                    setTitle(resp.data.sgagriRecord[0].title);
                    setEmail(resp.data.sgagriRecord[0].contato_email);
                    setPhone(resp.data.sgagriRecord[0].contato_telefone);
                    setIdArea({
                        value: resp.data.sgagriRecord[0]
                            .id_area_registro_sgagri,
                        label: resp.data.sgagriRecord[0].area_registro_sgagri,
                    });
                    setAcre(
                        resp.data.sgagriRecord[0].hectares_propriedade
                            ? resp.data.sgagriRecord[0].hectares_propriedade
                            : ''
                    );
                    setAgentSale({
                        value: resp.data.sgagriRecord[0].id_agente_venda,
                        label: resp.data.sgagriRecord[0].agente_venda,
                    });
                    setOriginOfSale({
                        value: resp.data.sgagriRecord[0].id_origem_venda,
                        label: resp.data.sgagriRecord[0].origem_venda,
                    });
                    setIdState({
                        value: resp.data.sgagriRecord[0].id_state,
                        label: resp.data.sgagriRecord[0].state,
                    });
                    setIdCity({
                        value: resp.data.sgagriRecord[0].id_city,
                        label: resp.data.sgagriRecord[0].city,
                    });
                    setIdEmpresa({
                        value: resp.data.sgagriRecord[0].id_empresa,
                        label: resp.data.sgagriRecord[0].empresa,
                    });
                    setIdFilial({
                        value: resp.data.sgagriRecord[0].id_filial,
                        label: resp.data.sgagriRecord[0].filial,
                    });
                    setDescription(resp.data.sgagriRecord[0].descricao_servico);
                    setDescriptionValue(
                        resp.data.sgagriRecord[0].description_value
                    );
                    setReadjustmentPeriod(
                        resp.data.sgagriRecord[0].readjustment_period
                    );
                    setRenewalIndexer(
                        resp.data.sgagriRecord[0].renewal_indexer
                    );
                    setBillingProperty(
                        resp.data.sgagriRecord[0].billing_property
                            ? resp.data.sgagriRecord[0].billing_property
                            : ''
                    );
                    setIdMainActivity({
                        value: resp.data.sgagriRecord[0].id_main_activity,
                        label: resp.data.sgagriRecord[0].main_activity,
                    });
                    setValueContract(resp.data.sgagriRecord[0].valor_contrato);
                    setTypeCharge({
                        value: optionsTypeCharge.filter(
                            (desc) =>
                                desc.value ===
                                resp.data.sgagriRecord[0].tipo_cobranca
                        )[0].value,
                        label: optionsTypeCharge.filter(
                            (desc) =>
                                desc.value ===
                                resp.data.sgagriRecord[0].tipo_cobranca
                        )[0].label,
                    });
                    setValidity(
                        resp.data.sgagriRecord[0].periodo_vigencia_meses
                    );
                    setDateStartContract(
                        resp.data.sgagriRecord[0].data_inicio_contrato
                    );
                    setDateSale(resp.data.sgagriRecord[0].data_venda);
                    setCreated(resp.data.sgagriRecord[0].created_by);
                    setReembolsavel(
                        parseInt(resp.data.sgagriRecord[0].reembolsavel) === 1
                            ? 'Sim'
                            : 'Não'
                    );
                    setIdStatus(resp.data.sgagriRecord[0].id_status);
                    setProduct(
                        resp.data.product.map((desc) => ({
                            id: uniqueId(),
                            idProduct: desc.id_produto,
                            nameProduct: desc.produto,
                            value: desc.valor,
                        }))
                    );
                    setUploadedFiles(
                        resp.data.attachment.map((file) => ({
                            id: file.id,
                            name: file.nome,
                            readableSize: filesize(file.size),
                            uploaded: true,
                            url: file.link,
                        }))
                    );
                    setIdManager(
                        resp.data.manager.map((desc) => ({
                            value: desc.value,
                            label: desc.label,
                        }))
                    );
                    setManagers(
                        resp.data.manager.map((desc) => ({
                            value: desc.value,
                            label: desc.label,
                        }))
                    );
                    isEdit(
                        resp.data.sgagriRecord[0].created_by,
                        resp.data.sgagriRecord[0].id_status
                    );
                })
                .then((res) => {
                    if (valorContrato > 0) {
                        let optionsParcel = optionsQtdParcela(valorContrato);
                        qtd_parcel &&
                            qtd_parcel !== 1 &&
                            setQtdParcel({
                                value: optionsParcel.filter(
                                    (desc) => desc.value === qtd_parcel
                                )[0].value,
                                label: optionsParcel.filter(
                                    (desc) => desc.value === qtd_parcel
                                )[0].label,
                            });
                    }
                })
                .catch((e) => {
                    setAlert(e.response.data.error);
                    setTimeout(() => {
                        setAlert('');
                        return props.history.push('/registers_sgagri');
                    }, 5000);
                });
    }

    const changePhone = (event) => {
        const number = event.target.value
            .replace('(', '')
            .replace(')', '')
            .replace(' ', '')
            .replace('-', '');
        setPhone(number);
    };

    const handleUpload = (files) => {
        const uploadedFile = files.map((file) => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),

            progress: 0,
            uploaded: false,
            error: false,
            url: null,
        }));
        setUploadedFiles(uploadedFiles.concat(uploadedFile));
    };

    const handleDelete = (id) => {
        const upload = uploadedFiles.filter((file) => file.id === id);
        if (upload[0].url) {
            delAttachment(id).then((resp) => viewPerId(props.match.params.id));
        } else {
            setUploadedFiles(uploadedFiles.filter((file) => file.id !== id));
        }
    };

    const handleDeleteProduct = (id) => {
        let valueProduct = product.filter(
            (desc) => parseInt(desc.id) === parseInt(id)
        )[0].value;
        setValueContract(valueContract - valueProduct);
        setProduct(product.filter((desc) => desc.id !== id));
    };

    const handleForm = (e) => {
        e.preventDefault();
        var errorCheck = false;
        setLoading(true);
        // if(parseInt(idTypeServiceSgagri.value) === 1 || parseInt(idTypeServiceSgagri.value) === 3 ){
        // 	if(uploadedFiles.length <= 0){
        // 		setAlertAnexo("Você precisa adicionar um anexo no registro sgagri !")
        // 		errorCheck = true
        // 		anexoRef.current.scrollIntoView()
        // 	}
        // }else{
        // 	setAlertAnexo("")
        // }

        if (product.length <= 0) {
            setAlertProduto(
                'Você precisa adicionar produtos no registro sgagri !'
            );
            errorCheck = true;
            produtoRef.current.scrollIntoView();
        } else {
            setAlertProduto('');
        }

        if (!title.replace(/\s/g, '').length) {
            setAlertTitle('Preencha o título !');
            errorCheck = true;
            titleRef.current.scrollIntoView();
        } else {
            setAlertTitle('');
        }

        if (errorCheck) {
            return [setLoading(false)];
        }

        const data = new FormData();
        var uploadedFile = uploadedFiles.filter((desc) => !desc.url);

        var resultado = '';
        let attachment = false;
        for (let i = 0; i < uploadedFile.length; i++) {
            if (uploadedFile[i].file) {
                data.append(`file${i}`, uploadedFile[i].file);
                resultado = i;
                attachment = true;
            }
        }
        data.append('name', name);
        data.append('description_value', descriptionValue);
        data.append('readjustment_period', readjustmentPeriod);
        data.append('renewal_indexer', renewalIndexer);
        data.append(
            'billing_property',
            isNaN(billingProperty) ? '' : billingProperty
        );
        data.append('main_activity', parseInt(idMainActivity.value));
        data.append('phone', phone);
        data.append('email', email);
        data.append('reembolsavel', reembolsavel === 'Sim' ? 1 : 0);
        data.append('id_area_registro_sgagri', parseInt(idArea.value));
        // data.append("idManagerArea", parseInt(idManager.value))
        data.append('id_city', parseInt(idCity.value));
        data.append('id_filial', parseInt(idFilial.value));
        data.append('id_empresa', parseInt(idEmpresa.value));
        data.append('id_agente_venda', parseInt(agentSale.value));
        data.append('id_origem', parseInt(originOfSale.value));
        data.append('data_venda', dateSale);
        data.append('hectares_propriedade', isNaN(acre) ? '' : acre);
        data.append('id_tipo', parseInt(idTypeServiceSgagri.value));
        data.append('descricao_servico', description);
        data.append('isAttachment', attachment);
        data.append('valor_contrato', valueContract);
        data.append('tipo_cobranca', typeCharge.value);
        data.append(
            'quantidade_parcela',
            typeCharge.value === 'Única'
                ? 1
                : typeCharge.value === 'Mensal'
                ? qtdParcel.value
                : ''
        );
        data.append('periodo_vigencia_meses', validity);
        data.append('data_inicio_contrato', dateStartContract);
        data.append('product', JSON.stringify(product));
        data.append('qtd', resultado);
        data.append('title', title);

        if (props.match.params.action === 'edit') {
            data.append('_method', 'put');
        }

        if (props.match.params.action === 'edit') {
            const id = props.match.params.id;
            putRegisterSgagri(id, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then((resp) => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then((resp) =>
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500)
                )
                .catch((e) => {
                    setLoading(false);
                    setSuccess('');
                    setError(e.response.data.error);
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        setError('');
                        setAlert('');
                    }, 2500);
                });
        } else {
            postRegisterSgagri(data, {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then((resp) => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then((resp) =>
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500)
                )
                .catch((e) => {
                    setLoading(false);
                    setSuccess('');
                    setError(e.response.data.error);
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        setError('');
                        setAlert('');
                    }, 2500);
                });
        }
    };

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage
                title={
                    props.match.params.action === 'edit'
                        ? 'Edição de Registro Sgagri'
                        : props.match.params.action === 'view'
                        ? 'Visualização de Registro Sgagri'
                        : 'Cadastro de Registro Sgagri'
                }
            >
                {loading === true ? (
                    <Content>
                        <ReactLoading
                            type="spinningBubbles"
                            color="blue"
                            height={200}
                            width={100}
                        />
                    </Content>
                ) : (
                    ''
                )}
                {success ? <Alert type="success">{success}</Alert> : ''}
                {alert ? <Alert type="danger">{alert}</Alert> : ''}
                <div
                    style={{
                        width: '100%',
                        display: loading === true ? 'none' : 'block',
                        opacity: success || alert ? 0.1 : 1,
                    }}
                >
                    <Form onSubmit={handleForm}>
                        <div className="row mb-2">
                            <div className="col-md-4">
                                <label>Tipo do Serviço *</label>
                                <Select
                                    required={true}
                                    value={idTypeServiceSgagri}
                                    ref={selectServiceRef}
                                    options={optionsTypeServiceSgagri}
                                    onChange={(e) => setIdTypeServiceSgagri(e)}
                                    isDisabled={true}
                                />
                                {!idTypeServiceSgagri && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={idTypeServiceSgagri}
                                        onFocus={() =>
                                            selectServiceRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                            </div>
                            <div className="col-md-4">
                                <label>Área *</label> {/*aqui*/}
                                <Select
                                    required={true}
                                    value={idArea}
                                    ref={selectAreaRef}
                                    label="Área de Produção"
                                    options={optionsArea}
                                    // onChange={ (e) => setIdArea(e) }
                                    onChange={(e) => handleChangeArea(e)}
                                    isDisabled={true}
                                />
                                {!idArea && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={idArea}
                                        onFocus={() =>
                                            selectAreaRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                            </div>
                            <div className="col-md-4">
                                <label>Gerente de área</label>
                                <Select
                                    value={idManager}
                                    ref={selectManagerRef}
                                    label="Single select"
                                    options={managers}
                                    onChange={(e) => setIdManager(e)}
                                    isDisabled={
                                        props.match.params.action === 'view' ||
                                        !idArea
                                            ? true
                                            : false
                                    }
                                />
                                {idManager.length <= 0 && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={idManager}
                                        onFocus={() =>
                                            selectManagerRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.manager}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label>Empresa *</label>
                                <Select
                                    required={true}
                                    value={idEmpresa}
                                    ref={selectEmpresaRef}
                                    isDisabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    options={optionsEmpresa}
                                    onChange={changeEmpresa}
                                />
                                {!idEmpresa && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={idEmpresa}
                                        onFocus={() =>
                                            selectEmpresaRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                            </div>
                            <div className="col-md-6">
                                <label>Filial *</label>
                                <Select
                                    required={true}
                                    value={idFilial}
                                    isDisabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    ref={selectFilialRef}
                                    options={optionsFilial}
                                    onChange={(e) => setIdFilial(e)}
                                />
                                {!idFilial && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={idFilial}
                                        onFocus={() =>
                                            selectFilialRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                            </div>
                        </div>
                        <div ref={titleRef} className="row mb-3">
                            <div className="col-md-6">
                                <Input
                                    label="Título *"
                                    type="text"
                                    icon="fas fa-user"
                                    required={true}
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                                {alertTitle ? (
                                    <Alert type="danger">{alertTitle}</Alert>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <NumberFormat
                                    icon="fas fa-tractor"
                                    label="Tamanho da Propriedade (Hectáres)"
                                    name="acre"
                                    allowNegative={false}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    placeholder=""
                                    required={false}
                                    customInput={Input}
                                    onChange={(e) =>
                                        setAcre(
                                            parseFloat(
                                                e.target.value
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace(',', '.')
                                            )
                                        )
                                    }
                                    value={acre}
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    isAllowed={(values) => {
                                        const { floatValue, formattedValue } =
                                            values;
                                        return (
                                            formattedValue === '' ||
                                            floatValue <= 999999999
                                        );
                                    }}
                                />
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.hectaresProperty}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="col-md-4">
                                <NumberFormat
                                    icon="fas fa-money-check-alt"
                                    label="Faturamento Anual da Propriedade"
                                    name="billing property"
                                    allowNegative={false}
                                    required={false}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    placeholder=""
                                    customInput={Input}
                                    onChange={(e) =>
                                        setBillingProperty(
                                            parseFloat(
                                                e.target.value
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace(',', '.')
                                            )
                                        )
                                    }
                                    value={`${billingProperty}`.replace(
                                        '.',
                                        ','
                                    )}
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    isAllowed={(values) => {
                                        const { floatValue, formattedValue } =
                                            values;
                                        return (
                                            formattedValue === '' ||
                                            floatValue <= 999999999
                                        );
                                    }}
                                />
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.billingProperty}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="col-md-4">
                                <label>Atividade Principal do Cliente *</label>
                                <Select
                                    required={true}
                                    value={idMainActivity}
                                    ref={selectMainActivityRef}
                                    label="Atividade Principal do Cliente"
                                    options={optionsMainActivity}
                                    onChange={(e) => setIdMainActivity(e)}
                                    isDisabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                                {!idMainActivity && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={idMainActivity}
                                        onFocus={() =>
                                            selectMainActivityRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-md-4">
                                    <Input
                                        label="Nome do Contato *"
                                        type="text"
                                        icon="fas fa-user"
                                        maxLength="100"
                                        required={true}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        value={name}
                                        disabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        label="Email do Contato *"
                                        type="email"
                                        icon="fas fa-at"
                                        required={true}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        maxLength="100"
                                        value={email}
                                        disabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                                <div className="col-md-4">
                                    <NumberFormat
                                        disabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                        allowEmptyFormatting={false}
                                        label="Telefone do Contato *"
                                        mask=""
                                        customInput={Input}
                                        onChange={changePhone}
                                        format={
                                            phone
                                                ? phone[2] === '3' ||
                                                  phone[2] === '4' ||
                                                  phone[2] === '2'
                                                    ? '(##) ####-####'
                                                    : '(##) #####-####'
                                                : '(##) #####-####'
                                        }
                                        icon="fa-phone"
                                        value={phone}
                                        required={true}
                                        name="telefone"
                                        type="text"
                                        placeholder="Digite o telefone do contato"
                                    />
                                    {error ? (
                                        <p style={{ color: 'red' }}>
                                            {error.phone}
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <label>Estado *</label>
                                    <Select
                                        required={true}
                                        value={idState}
                                        ref={selectStateRef}
                                        isDisabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                        options={optionsState}
                                        onChange={changeState}
                                    />
                                    {!idState && (
                                        <input
                                            tabIndex={-1}
                                            autoComplete="off"
                                            style={{
                                                opacity: 0,
                                                width: '100%',
                                                height: 0,
                                                position: 'absolute',
                                            }}
                                            defaultValue={idState}
                                            onFocus={() =>
                                                selectStateRef.current.focus()
                                            }
                                            required={true}
                                        />
                                    )}
                                </div>
                                <div className="col-md-4">
                                    <label>Cidade *</label>
                                    <Select
                                        required={true}
                                        value={idCity}
                                        isDisabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                        ref={selectCityRef}
                                        options={optionsCity}
                                        onChange={(e) => setIdCity(e)}
                                    />
                                    {!idCity && (
                                        <input
                                            tabIndex={-1}
                                            autoComplete="off"
                                            style={{
                                                opacity: 0,
                                                width: '100%',
                                                height: 0,
                                                position: 'absolute',
                                            }}
                                            defaultValue={idCity}
                                            onFocus={() =>
                                                selectCityRef.current.focus()
                                            }
                                            required={true}
                                        />
                                    )}
                                </div>
                                <div className="col-md-4 mt-1">
                                    <RadioMaterial
                                        row
                                        label="Acerto de despesas com o cliente ? *"
                                        aria-label="reembolsavel"
                                        name="reembolsavel"
                                        disabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                        value={reembolsavel}
                                        styleLabel={{
                                            color: '#BEBEBE',
                                            fontSize: '10px',
                                            marginBottom: '15px',
                                        }}
                                        onChange={(e) =>
                                            setReembolsavel(e.target.value)
                                        }
                                    >
                                        <FormControlLabel
                                            value="Sim"
                                            control={<Radio />}
                                            label="Sim"
                                        />
                                        <FormControlLabel
                                            value="Não"
                                            control={<Radio />}
                                            label="Não"
                                        />
                                    </RadioMaterial>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <TextArea
                                        rows="6"
                                        required={true}
                                        label="Descrição do Serviço / Informações do Cliente *"
                                        value={description}
                                        maxLength="255"
                                        onChange={(e) =>
                                            setDescription(e.target.value)
                                        }
                                        disabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label ref={anexoRef}>
                                        Anexo da Proposta
                                    </label>
                                    <ContentUpload>
                                        <>
                                            <Upload
                                                required={
                                                    parseInt(
                                                        typeCharge.value
                                                    ) === 1 ||
                                                    parseInt(
                                                        typeCharge.value
                                                    ) === 3
                                                        ? true
                                                        : false
                                                }
                                                disabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                                onUpload={handleUpload}
                                            />
                                            {!!uploadedFiles.length && (
                                                <FileList
                                                    action={
                                                        props.match.params
                                                            .action
                                                    }
                                                    toEdit={
                                                        props.match.params
                                                            .action === 'edit'
                                                            ? `/register_sgagri/${props.match.params.id}/edit`
                                                            : ''
                                                    }
                                                    toCadastro="new_register_sgagri"
                                                    files={uploadedFiles}
                                                    onDelete={handleDelete}
                                                />
                                            )}
                                        </>
                                    </ContentUpload>
                                    {alertAnexo ? (
                                        <Alert type="danger">
                                            {alertAnexo}
                                        </Alert>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                            <hr></hr>
                            <br></br>
                            <div className="card-header">
                                <h3 className="card-title">
                                    Informações da Venda
                                </h3>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-4">
                                    <Input
                                        icon="fas fa-calendar"
                                        label="Data da Venda *"
                                        name="dateSale"
                                        type="date"
                                        required={true}
                                        placeholder=""
                                        disabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                        onChange={(e) =>
                                            setDateSale(e.target.value)
                                        }
                                        value={dateSale}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label>Agente da Venda *</label>
                                    <Select
                                        required={true}
                                        value={agentSale}
                                        ref={selectAgentSaleRef}
                                        options={optionsAgentSale}
                                        onChange={(e) => setAgentSale(e)}
                                        isDisabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                    />
                                    {!agentSale && (
                                        <input
                                            tabIndex={-1}
                                            autoComplete="off"
                                            style={{
                                                opacity: 0,
                                                width: '100%',
                                                height: 0,
                                                position: 'absolute',
                                            }}
                                            defaultValue={agentSale}
                                            onFocus={() =>
                                                selectAgentSaleRef.current.focus()
                                            }
                                            required={true}
                                        />
                                    )}
                                </div>
                                <div className="col-md-4">
                                    <label>Origem da Venda *</label>
                                    <Select
                                        required={true}
                                        value={originOfSale}
                                        ref={selectOriginOfSaleRef}
                                        label="typeCharge"
                                        options={optionsOriginOfSale}
                                        onChange={(e) => setOriginOfSale(e)}
                                        isDisabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                    />
                                    {!originOfSale && (
                                        <input
                                            tabIndex={-1}
                                            autoComplete="off"
                                            style={{
                                                opacity: 0,
                                                width: '100%',
                                                height: 0,
                                                position: 'absolute',
                                            }}
                                            defaultValue={originOfSale}
                                            onFocus={() =>
                                                selectOriginOfSaleRef.current.focus()
                                            }
                                            required={true}
                                        />
                                    )}
                                </div>
                            </div>
                            <br></br>
                            <div className="card-header">
                                <h3 ref={produtoRef} className="card-title">
                                    Produtos *
                                </h3>
                            </div>
                            {alertProduto ? (
                                <Alert type="danger">{alertProduto}</Alert>
                            ) : (
                                ''
                            )}
                            <div className="row mt-3">
                                <div className="col-md-5">
                                    <label>Produto *</label>
                                    <Select
                                        value={idProduct}
                                        label="typeCharge"
                                        options={optionsProduct}
                                        onChange={(e) => setIdProduct(e)}
                                        isDisabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                                <div className="col-md-5">
                                    <NumberFormat
                                        icon="fas fa-money-check-alt"
                                        label="Valor *"
                                        name="valueProduct"
                                        allowNegative={false}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        placeholder=""
                                        customInput={Input}
                                        onChange={(e) =>
                                            setValueProduct(
                                                parseFloat(
                                                    e.target.value
                                                        .replace('.', '')
                                                        .replace('.', '')
                                                        .replace(',', '.')
                                                )
                                            )
                                        }
                                        value={valueProduct}
                                        disabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                        isAllowed={(values) => {
                                            const {
                                                floatValue,
                                                formattedValue,
                                            } = values;
                                            return (
                                                formattedValue === '' ||
                                                floatValue <= 999999999
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <div
                                        className="input-group mb-3"
                                        style={{ paddingTop: '30px' }}
                                    >
                                        <button
                                            onClick={handleProduct}
                                            disabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            type="button"
                                            className="btn btn-primary"
                                        >
                                            <span className="fas fa-plus"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <TableProducts>
                                    <Products
                                        isDisabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                        product={product}
                                        formatReal={formatReal}
                                        handleDeleteProduct={
                                            handleDeleteProduct
                                        }
                                    />
                                </TableProducts>
                            </div>
                            <br></br>
                            <div className="card-header">
                                <h3 className="card-title">
                                    Dados do Contrato
                                </h3>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <NumberFormat
                                        icon="fas fa-money-check-alt"
                                        label="Valor Total do Contrato *"
                                        name="valueContract"
                                        allowNegative={false}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        placeholder=""
                                        customInput={Input}
                                        onChange={(e) =>
                                            setValueContract(
                                                parseFloat(
                                                    e.target.value
                                                        .replace('.', '')
                                                        .replace('.', '')
                                                        .replace(',', '.')
                                                )
                                            )
                                        }
                                        value={valueContract}
                                        disabled={true}
                                        isAllowed={(values) => {
                                            const {
                                                floatValue,
                                                formattedValue,
                                            } = values;
                                            return (
                                                formattedValue === '' ||
                                                floatValue <= 999999999
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Tipo de Cobrança Parcelas *</label>
                                    <Select
                                        required={true}
                                        value={typeCharge}
                                        ref={selectTypeChargeRef}
                                        label="typeCharge"
                                        options={optionsTypeCharge}
                                        onChange={(e) => setTypeCharge(e)}
                                        isDisabled={
                                            props.match.params.action ===
                                                'view' ||
                                            valueContract <= 0 ||
                                            valueContract === ''
                                                ? true
                                                : false
                                        }
                                    />
                                    {!typeCharge && (
                                        <input
                                            tabIndex={-1}
                                            autoComplete="off"
                                            style={{
                                                opacity: 0,
                                                width: '100%',
                                                height: 0,
                                                position: 'absolute',
                                            }}
                                            defaultValue={typeCharge}
                                            onFocus={() =>
                                                selectTypeChargeRef.current.focus()
                                            }
                                            required={true}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                {typeCharge.value === 'Única' ? (
                                    <>
                                        <div className="col-md-4">
                                            <Input
                                                icon="fas fa-money-bill-wave"
                                                label="Valor da Parcela"
                                                name="valueParcel"
                                                type="text"
                                                // onChange={(e) => setValueContract(parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')))}
                                                value={`1x de R$${formatReal(
                                                    valueContract
                                                )}`}
                                                disabled={true}
                                            />
                                        </div>
                                    </>
                                ) : typeCharge.value === 'Mensal' ? (
                                    <>
                                        <div className="col-md-4">
                                            <label>Valor das Parcelas *</label>
                                            <Select
                                                required={true}
                                                value={qtdParcel}
                                                ref={selectQtdParcelRef}
                                                label="typeCharge"
                                                options={optionsQtdParcela(
                                                    valueContract
                                                )}
                                                onChange={(e) =>
                                                    setQtdParcel(e)
                                                }
                                                isDisabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {!qtdParcel && (
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0,
                                                        width: '100%',
                                                        height: 0,
                                                        position: 'absolute',
                                                    }}
                                                    defaultValue={qtdParcel}
                                                    onFocus={() =>
                                                        selectQtdParcelRef.current.focus()
                                                    }
                                                    required={true}
                                                />
                                            )}
                                        </div>
                                    </>
                                ) : typeCharge.value === 'Variável' ? (
                                    <div className="col-md-12">
                                        <TextArea
                                            rows="6"
                                            label="Descrição dos Valores de Parcelas"
                                            value={descriptionValue}
                                            maxLength="1000"
                                            onChange={(e) =>
                                                setDescriptionValue(
                                                    e.target.value
                                                )
                                            }
                                            disabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                                {typeCharge.value ? (
                                    <>
                                        <div
                                            className={`col-md-${
                                                typeCharge.value === 'Variável'
                                                    ? '6'
                                                    : '4'
                                            }`}
                                        >
                                            <NumberFormat
                                                icon="fas fa-money-bill-wave"
                                                label="Período de Vigência (Meses) *"
                                                name="validity"
                                                required={true}
                                                allowNegative={false}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                placeholder=""
                                                customInput={Input}
                                                onChange={(e) =>
                                                    setValidity(
                                                        parseFloat(
                                                            e.target.value
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    ',',
                                                                    '.'
                                                                )
                                                        )
                                                    )
                                                }
                                                value={validity}
                                                disabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                                isAllowed={(values) => {
                                                    const {
                                                        floatValue,
                                                        formattedValue,
                                                    } = values;
                                                    return (
                                                        formattedValue === '' ||
                                                        floatValue <= 72
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={`col-md-${
                                                typeCharge.value === 'Variável'
                                                    ? '6'
                                                    : '4'
                                            }`}
                                        >
                                            <Input
                                                icon="fas fa-calendar"
                                                label="Data Início do Contrato *"
                                                name="validity"
                                                type="date"
                                                placeholder=""
                                                required={true}
                                                disabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) =>
                                                    setDateStartContract(
                                                        e.target.value
                                                    )
                                                }
                                                value={dateStartContract}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <TextArea
                                        rows="6"
                                        label="Período de Reajuste"
                                        value={readjustmentPeriod}
                                        required={false}
                                        maxLength="255"
                                        onChange={(e) =>
                                            setReadjustmentPeriod(
                                                e.target.value
                                            )
                                        }
                                        disabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextArea
                                        rows="6"
                                        label="Indexador de Renovação Contratual"
                                        value={renewalIndexer}
                                        required={false}
                                        maxLength="255"
                                        onChange={(e) =>
                                            setRenewalIndexer(e.target.value)
                                        }
                                        disabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                            </div>
                            <hr></hr>
                            <br></br>
                            <br></br>
                            <div className="text-right">
                                <div
                                    style={{
                                        width: '100%',
                                        marginBottom: '20px',
                                        position: 'absolute',
                                        bottom: '0%',
                                        left: '-2%',
                                    }}
                                >
                                    <button
                                        style={{ marginRight: '10px' }}
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => history.goBack()}
                                    >
                                        Voltar
                                    </button>

                                    {props.match.params.action === 'view' ? (
                                        <Link
                                            style={{
                                                cursor:
                                                    created_by !== id_user ||
                                                    id_status !== 1
                                                        ? 'not-allowed'
                                                        : '',
                                                color:
                                                    created_by !== id_user ||
                                                    id_status !== 1
                                                        ? '#fff'
                                                        : '',
                                                backgroundColor:
                                                    created_by !== id_user ||
                                                    id_status !== 1
                                                        ? '#007bff'
                                                        : '',
                                                borderColor:
                                                    created_by !== id_user ||
                                                    id_status !== 1
                                                        ? '#007bff'
                                                        : '',
                                                opacity:
                                                    created_by !== id_user ||
                                                    id_status !== 1
                                                        ? 0.65
                                                        : '',
                                                boxShadow:
                                                    created_by !== id_user ||
                                                    id_status !== 1
                                                        ? 'none'
                                                        : '',
                                            }}
                                            onClick={
                                                created_by !== id_user ||
                                                id_status !== 1
                                                    ? (e) => e.preventDefault()
                                                    : ''
                                            }
                                            to={`/register_sgagri/${props.match.params.id}/edit`}
                                            className="btn btn-primary"
                                        >
                                            Editar
                                        </Link>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Salvar
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Form>
                    <div style={{ marginBottom: '100px' }}></div>
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}

export default RegisterSgagri;
