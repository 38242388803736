/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import Footer from '../../../Footer/Footer';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    changePage,
    get,
    order,
    change_qtd_per_page,
    changePesquisa,
    clearPesquisa,
} from './Actions/actions';
import { pagination } from '../../../Pagination/paginations';
import {
    delProjectAmendment,
    changeStatus,
    exportExcelCSV,
} from '../../../Services/projectAmendment';
import Pagination from '../../../Table/Pagination';
import Alert from '../../../Alerts/Alert';
import MyModal from '../../../Modal/MyModal';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import CabecalhoNew from '../../../Table/CabecalhoNew';
import Input from '../../../Forms/Input';
import TextArea from '../../../Forms/TextArea';
import Form from '../../../Forms/Form';
import AprovedIcon from '../../../Icons/emojione-v1_ballot-box-bold-check.png';
import ReprovedIcon from '../../../Icons/fluent_document-footer-remove-24-filled.png';
import IconAnalysys from '../../../Icons/cib_anaconda.png';
import ContractsAmendments from './ProjectsAmendments';
import ReportProjectAmendment from './ReportProjectAmendment';
import makeAnimated from 'react-select/animated';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import ModalLarge from '../../../Modal/ModalLarge';
import Pagamento from './Pagamento';
import TablePagamento from './TablePagamento';
import { uniqueId } from 'lodash';
import { CSVLink } from 'react-csv';
import { useRef } from 'react';

function GridProjectAmendment(props) {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [description, setDescription] = useState('');
    const [modalReproved, setModalReproved] = useState(false);
    const [modalAproved, setModalAproved] = useState(false);
    const [idProjectAmendment, setIdProjectAmendment] = useState('');
    const [projectAmendment, setProjectAmendment] = useState('');
    const [projectAmendmentCSV, setProjectAmendmentCSV] = useState([]);
    // const [ optionsClient, setOptionsClient ] = useState([])
    const [id, setId] = useState('');
    const [success, setSuccess] = useState('');
    // const [ idClient, setIdClient ] = useState([])
    // const [ titleProject, setTitleProject ] = useState('')
    const [error, setError] = useState('');
    // const [ report, setReport ] = useState(false)
    const [displayLegend, setDisplayLegend] = useState(true);
    const [modalFilter, setModalFilter] = useState(false);
    const [vencimentoParcela, setVencimentoParcela] = useState('');
    const [pagamento, setPagamento] = useState([]);
    const [pagamentoTotal, setPagamentoTotal] = useState(0);
    const [aprovedBlocked, setAprovedBlocked] = useState('');
    const [valorParcela, setValorParcela] = useState('');
    const [cliente, setCliente] = useState([]);
    const from = useSelector((state) => state.project_amendment.from);
    const to = useSelector((state) => state.project_amendment.to);
    const dispatch = useDispatch();
    const [status_all, setStatusAll] = useState([]);
    const animatedComponents = makeAnimated();
    const [headers, setHeaders] = useState('');
    const fileUpload = useRef();

    const getProjectAmendment = () => {
        props.get();
    };

    useEffect(() => {
        getProjectAmendment();
        return () => {
            getProjectAmendment();
        };
    }, []);

    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
    };

    const handleCloseExcluir = (event) => {
        setModal(false);
        setId('');
    };

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false);

        delProjectAmendment(id)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError('');
                setLoading(false);
            })
            .then((resp) =>
                setTimeout(() => {
                    setSuccess('');
                }, 4000)
            )
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            })
            .then((resp) => props.get());
        // .then(resp => {setState({success:resp.data.success, error:''})})
    };
    const [totalPagamento, setTotalPagamento] = useState('');

    const handlePagamento = (e) => {
        e.preventDefault();
        // tipo Alteração de Escopo ou Renovação de Projeto
        if (
            projectAmendment.tipo_cobranca == 'Mensal' &&
            (projectAmendment.id_tipo_alteracao === 5 ||
                projectAmendment.id_tipo_alteracao === 3)
        ) {
            let quantidadeDeParcelas = projectAmendment.quantidade_parcela;
            let valorDasParcelas =
                projectAmendment.valor_contrato / quantidadeDeParcelas;
            let total = projectAmendment.valor_contrato;

            if (pagamento.length != 0) {
                return setAprovedBlocked(
                    'Não é possível adicionar essas parcelas pois o tamanho será diferente da quantidade estabelecida'
                );
            }

            setPagamentoTotal(total);

            let novaDataVencimento;

            // let dataTemporaria = new Date("Thu Jan 01 1970 01:02:01 GMT+0200");
            let dataTemporaria = new Date(vencimentoParcela);
            dataTemporaria.setTime(
                dataTemporaria.getTime() +
                    dataTemporaria.getTimezoneOffset() * 60 * 1000
            );

            let dia;
            let mes;
            let ano;
            let somaParcela = 0;
            let ultimaData;
            let pagamentoTemporario = pagamento;

            for (
                let count = 0;
                count < quantidadeDeParcelas - 1;
                count = count + 1
            ) {
                dia = dataTemporaria.getDate();
                mes = dataTemporaria.getMonth();
                ano = dataTemporaria.getFullYear();

                novaDataVencimento = ano + '-' + (mes + 1) + '-' + dia;

                const data = {
                    id: uniqueId(),
                    dataPagamento: novaDataVencimento,
                    valorParcela: +valorDasParcelas.toFixed(2),
                };

                somaParcela = somaParcela + data.valorParcela;
                pagamentoTemporario = pagamentoTemporario.concat(data);

                dataTemporaria.setMonth(dataTemporaria.getMonth() + 1);
            }

            dia = dataTemporaria.getDate();
            mes = dataTemporaria.getMonth();
            ano = dataTemporaria.getFullYear();
            novaDataVencimento = ano + '-' + (mes + 1) + '-' + dia;

            const data = {
                id: uniqueId(),
                dataPagamento: novaDataVencimento,
                valorParcela: +(total - somaParcela).toFixed(2),
            };

            pagamentoTemporario = pagamentoTemporario.concat(data);
            console.log(pagamentoTemporario);

            setPagamento(pagamento.concat(pagamentoTemporario));
            // setDataVencimento("");
            setVencimentoParcela('');
            setValorParcela('');
            setAprovedBlocked('');
            // setErrorPagamento("");
        } else if (projectAmendment.tipo_cobranca == 'Variável') {
            setAprovedBlocked('');
            const data = {
                id: uniqueId(),
                dataPagamento: vencimentoParcela,
                valorParcela: valorParcela,
            };
            setPagamento(pagamento.concat(data));

            let valor = +(pagamentoTotal + valorParcela).toFixed(2);
            setPagamentoTotal(valor);

            setVencimentoParcela('');
            setValorParcela('');
        } else {
            let total = pagamentoTotal;
            if (total === 0) {
                total = +(pagamentoTotal + valorParcela).toFixed(2);
            } else {
                total = 0;
                return setAprovedBlocked(
                    'Não é possível adicionar outra parcela, pois o tipo de cobrança é unica'
                );
            }
            if (total > projectAmendment.valor_contrato) {
                setAprovedBlocked(
                    'Não é possível adicionar um valor maior que o valor total'
                );
                setTimeout(() => {
                    setAprovedBlocked('');
                }, 3000);
            } else {
                setAprovedBlocked('');
                const data = {
                    id: uniqueId(),
                    dataPagamento: vencimentoParcela,
                    valorParcela: valorParcela,
                };
                setPagamento(pagamento.concat(data));

                let valor = +(pagamentoTotal + valorParcela).toFixed(2);
                setPagamentoTotal(valor);

                setVencimentoParcela('');
                setValorParcela('');
            }
        }
    };

    const handleDeletePagamento = (id) => {
        let valor = pagamento.filter((desc) => desc.id === id);
        setPagamento(pagamento.filter((desc) => desc.id !== id));

        setPagamentoTotal(pagamentoTotal - valor[0].valorParcela);
    };

    //Função que formata o campo valor
    function formatReal(desc) {
        var tmp = `${desc.toFixed(2)}`.replace('.', ',');

        if (tmp.length > 6)
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, '.$1');
        return tmp;
    }

    const changeStatusProjectAmendmentAproved = (e) => {
        e.preventDefault();
        if (
            pagamentoTotal !== projectAmendment.valor_contrato &&
            projectAmendment.tipo_alteracao_projeto !==
                'Atualização de Dados' &&
            projectAmendment.id_tipo_alteracao !== 1 &&
            projectAmendment.id_tipo_alteracao !== 2
        ) {
            setAprovedBlocked(
                'O valor total das parcelas é diferente do valor do contrato.'
            );
            setTimeout(() => {
                setAprovedBlocked('');
            }, 3000);
            return;
        }

        const ordenar = (a, b) => {
            return a.dataPagamento < b.dataPagamento
                ? -1
                : a.dataPagamento > b.dataPagamento
                ? 1
                : 0;
        };
        let pagamentoOrder = pagamento.sort(ordenar);

        setModalAproved(false);
        setPagamento([]);
        setPagamentoTotal(0);
        setCliente([]);

        setLoading(true);
        const object = {
            idStatus: 2,
            pagamento: pagamentoOrder,
        };
        changeStatus(projectAmendment.id, object)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError('');
                setLoading(false);
                setTimeout(() => {
                    setSuccess('');
                }, 4000);
            })
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.errorSubmit);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            })
            .then((resp) => props.get());
    };

    const modalReprovedOn = (idProjectAmendment) => {
        setModalReproved(true);
        setIdProjectAmendment(idProjectAmendment);
    };

    const modalAprovedOn = (projectAmendment) => {
        setModalAproved(true);
        setProjectAmendment(projectAmendment);
        setCliente(
            cliente.concat(
                projectAmendment.clientes.map((desc) => ({
                    label: desc.cliente,
                    value: desc.id_cliente,
                }))
            )
        );
    };

    const handleCancelOrClose = () => {
        setModalAproved(false);
        setPagamento([]);
        setPagamentoTotal(0);
        setCliente([]);
        setAprovedBlocked('');
    };

    const disabledValorParcelaButton = () => {
        if (
            (projectAmendment.id_tipo_alteracao === 5 ||
                projectAmendment.id_tipo_alteracao === 3) &&
            projectAmendment.tipo_cobranca === 'Mensal'
        ) {
            return true;
        } else {
            return false;
        }
    };

    const changeStatusProjectAmendmentReproved = (e) => {
        e.preventDefault();
        setModalReproved(false);
        setPagamento([]);
        setPagamentoTotal(0);
        setCliente([]);
        setLoading(true);
        const object = {
            idStatus: 3,
            description: description,
        };
        changeStatus(idProjectAmendment, object)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError('');
                setLoading(false);
                setDescription('');
                setTimeout(() => {
                    setSuccess('');
                }, 4000);
            })
            .catch((e) => {
                setLoading(false);
                setDescription('');
                setError(e.response.data.errorSubmit);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            })
            .then((resp) => props.get());
    };

    // const changeStatusProjectAmendmentAll = (status) => {
    //     setLoading(true)
    //     const object = {
    //         idsProjectAmendment: status_all,
    //         status: status
    //     }
    //     changeStatusAll(object)
    //     .then(resp => {
    //         setSuccess(resp.data.success)
    //         setError('')
    //         setLoading(false)
    //         setStatusAll([])
    //         setTimeout(()=> {
    //             setSuccess('')
    //         },4000)

    //     })
    //     .catch(e => {
    //         setLoading(false)
    //         setError(e.response.data.error)
    //         setSuccess('')
    //         setModal(false)
    //         setTimeout(()=> {
    //             setError('')
    //         },4000)

    //     })
    //     .then(resp => (props.get()))

    // }

    const check = (event) => {
        let checked = event.target.checked;
        if (checked === true) {
            setStatusAll(status_all.concat(parseInt(event.target.value)));
        } else {
            setStatusAll(
                status_all.filter(
                    (desc) => parseInt(desc) !== parseInt(event.target.value)
                )
            );
        }
    };

    const exportCSV = (event) => {
        setHeaders([
            { label: 'id', key: 'id' },
            { label: 'Centro de Custo', key: 'centroCusto' },
            { label: 'Projeto', key: 'idProjeto' },
            { label: 'Responsável Cadastro', key: 'userCadastro' },
            { label: 'Status Reg. Alteração Projeto', key: 'statusAlteracao' },
            { label: 'Nome Usuário Responsável Aprovação', key: 'aproved' },
            { label: 'Tipo de Alteração Projeto', key: 'tipoAlteracao' },
            { label: 'Id Registro de Venda', key: 'idRegistroVenda' },
            { label: 'Tipo Serviço Projeto', key: 'tipoProjeto' },
            { label: 'Área Projeto', key: 'area' },
            { label: 'Gerente de Área', key: 'gerenteArea' },
            { label: 'Empresa Projeto', key: 'empresa' },
            { label: 'Filial Projeto', key: 'filial' },
            { label: 'Titulo Projeto', key: 'projeto' },
            {
                label: 'Tamanho Propriedade (Hectáres)',
                key: 'hectaresPropriedade',
            },
            {
                label: 'Faturamento Anual da Propriedade',
                key: 'billingProperty',
            },
            {
                label: 'Atividade Principal do Cliente',
                key: 'atividadePrincipal',
            },
            { label: 'Estado', key: 'state' },
            { label: 'Cidade', key: 'city' },
            { label: 'Acerto Despesa Cliente', key: 'acertoDespesaCliente' },
            {
                label: 'Descrição Serviço / Informações Clientes',
                key: 'descricaoServicoCliente',
            },
            { label: 'Cultura', key: 'cultura' },
            { label: 'Quantidade', key: 'quantidade' },
            { label: 'Data da Venda', key: 'dataVenda' },
            { label: 'Agente da Venda', key: 'agenteVenda' },
            { label: 'Origem da Venda', key: 'origemVenda' },
            { label: 'Produto', key: 'produto' },
            { label: 'Valor Produto', key: 'valorProduto' },
            { label: 'Valor Total Contrato', key: 'valorTotalContrato' },
            { label: 'Tipo de Cobranças Parcelas', key: 'tipoCobranca' },
            { label: 'Valor Parcela', key: 'valorParcela' },
            { label: 'Período de Vigência (Meses)', key: 'vigenciaMeses' },
            { label: 'Data Primeira Visita', key: 'dataPrimeiraVisita' },
            { label: 'Período de Reajuste', key: 'periodoReajuste' },
            {
                label: 'Indexador Renovação Contratual',
                key: 'indexadorRenovacao',
            },
            { label: 'Imposto', key: 'imposto' },
            { label: 'Margem Administrativa', key: 'margemAdm' },
            { label: 'Margem Líquida', key: 'margemLqd' },
            { label: 'Margem Adcional', key: 'margemAd' },
            { label: 'Coordenador', key: 'coordenador' },
            { label: 'Técnico', key: 'tecnico' },
            { label: 'Tipo Dedicação', key: 'tipoDedicacao' },
            { label: 'Dedicação', key: 'dedicacao' },
            { label: 'Remuneração RMA', key: 'tipoRemuneracao' },
            { label: 'Valor RMA', key: 'valor' },
            { label: 'Data Base', key: 'data_base' },
            { label: 'Data de Lançamento', key: 'data_lancamento' },
        ]);

        exportExcelCSV(
            props.page,
            props.total_page,
            props.order_var,
            props.th,
            props.pesquisa,
            props.id_coordinator ? props.id_coordinator : '',
            props.id_area ? props.id_area : '',
            props.project ? props.project : '',
            props.id_status ? props.id_status : '',
            props.from_date ? props.from_date : '',
            props.to_date ? props.to_date : ''
        )
            .then((resp) => {
                setProjectAmendmentCSV(resp.data);
            })
            .then((resp) => {
                fileUpload.current.link.click();
            });
    };
    console.log(props.project);
    return (
        <div className="wrapper" style={{ backgroundColor: '#E5E5E5, 100%' }}>
            <Header />
            <Sidebar />
            <div
                className="content-wrapper"
                style={{ backgroundColor: '#E5E5E5, 100%' }}
            >
                <section
                    className="content"
                    style={{ backgroundColor: '#E5E5E5, 100%' }}
                >
                    <MyModal
                        show={modal}
                        onHide={handleCloseExcluir}
                        title="Excluir Alteração de Projeto"
                        content="Tem certeza que deseja excluir a alteração de projeto ?"
                        cancel={handleCloseExcluir}
                        del={handleExcluir}
                        to="#/"
                        type="danger"
                        textButton="Excluir"
                    />
                    <div className="row">
                        <div className="col-md-12">
                            <MyModal
                                show={modalFilter}
                                onHide={(e) => setModalFilter(false)}
                                title="Filtrar"
                                content={<ReportProjectAmendment />}
                                cancel={function () {
                                    return [
                                        props.clearPesquisa(),
                                        setTimeout(() => {
                                            setModalFilter(false);
                                        }, 1000),
                                    ];
                                }}
                                del={function () {
                                    dispatch({
                                        type: 'changeFirstPage',
                                        payload: 1,
                                    });
                                    return [
                                        props.get(),
                                        setTimeout(() => {
                                            setModalFilter(false);
                                        }, 1000),
                                    ];
                                }}
                                to="#/"
                                type="success"
                                contentSecondary="Limpar Busca"
                                textButton="Buscar"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <MyModal
                                show={modalReproved}
                                onHide={(e) => setModalReproved(false)}
                                title="Reprovar Lançamento"
                                content={
                                    <Form
                                        onSubmit={
                                            changeStatusProjectAmendmentReproved
                                        }
                                    >
                                        <TextArea
                                            label="Motivo"
                                            maxLength="254"
                                            placeholder="Descreva o motivo da reprovação"
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                            value={description}
                                            required={true}
                                        />
                                        <div className="text-right">
                                            <button
                                                style={{ marginRight: '10px' }}
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e) =>
                                                    setModalReproved(false)
                                                }
                                            >
                                                Cancelar
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-danger"
                                            >
                                                Reprovar
                                            </button>
                                        </div>
                                    </Form>
                                }
                                onSubmit={true}
                                cancel={(e) => setModalReproved(false)}
                                // del={changeStatusProjectAmendmentReproved}
                                to="#/"
                                type="danger"
                                textButton="Reprovar"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <ModalLarge
                                show={modalAproved}
                                onHide={(e) => handleCancelOrClose()}
                                title="Aprovar Registro de Alteração de Projeto"
                                onSubmit={true}
                                cancel={(e) => setModalAproved(false)}
                                // del={changeStatusProjectAmendmentReproved}
                                to="#/"
                                type="success"
                                textButton="Aprovar"
                            >
                                <Form
                                    onSubmit={
                                        changeStatusProjectAmendmentAproved
                                    }
                                >
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12 mt-3">
                                                <NumberFormat
                                                    label="Código W3"
                                                    name="code"
                                                    icon="fas fa-key"
                                                    fixedDecimalScale={true}
                                                    decimalScale={0}
                                                    thousandSeparator={''}
                                                    decimalSeparator={','}
                                                    placeholder=""
                                                    customInput={Input}
                                                    allowNegative={false}
                                                    value={
                                                        projectAmendment.codigo_w3
                                                    }
                                                    required={true}
                                                    disabled={true}
                                                    // required={true}
                                                    // isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= 999999999;}}
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <Input
                                                    label="Título do Projeto"
                                                    type="text"
                                                    icon="fas fa-heading"
                                                    value={
                                                        projectAmendment.projeto
                                                    }
                                                    required={true}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label>Clientes</label>
                                                <Select
                                                    value={cliente}
                                                    isMulti
                                                    name="Clientes"
                                                    // options={optionsClient}
                                                    components={
                                                        animatedComponents
                                                    }
                                                    closeMenuOnSelect={false}
                                                    className="basic-multi-select"
                                                    // ref={selectClientRef}
                                                    classNamePrefix="select"
                                                    // onChange={handleChangeClient}
                                                    isDisabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-4">
                                                <Input
                                                    icon="fas fa-calendar"
                                                    label="Data Início *"
                                                    // min={}
                                                    // max={}
                                                    name="validity"
                                                    required={true}
                                                    type="date"
                                                    placeholder=""
                                                    disabled={true}
                                                    // onChange={}
                                                    value={
                                                        projectAmendment.data_base
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <Input
                                                    icon="fas fa-calendar"
                                                    label="Data Fim"
                                                    name="validity"
                                                    // min={}
                                                    // max={}
                                                    type="date"
                                                    required={true}
                                                    placeholder=""
                                                    disabled={true}
                                                    // onChange={}
                                                    value={
                                                        projectAmendment.data_fim
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <Input
                                                    icon="fas fa-calendar"
                                                    label="Período de Vigência"
                                                    type="text"
                                                    disabled={true}
                                                    value={`${projectAmendment.periodo_vigencia_meses} meses`}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Input
                                                    icon="fas fa-dollar-sign"
                                                    label="Valor do contrato"
                                                    type="text"
                                                    disabled={true}
                                                    value={`R$${formatReal(
                                                        parseFloat(
                                                            projectAmendment.valor_contrato
                                                        )
                                                    )}`}
                                                />
                                            </div>
                                        </div>
                                        {projectAmendment.tipo_cobranca ===
                                        'Variável' ? (
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <TextArea
                                                        rows="6"
                                                        label="Descrição dos Valores de Parcelas"
                                                        value={
                                                            projectAmendment.descricao_parcela
                                                        }
                                                        maxLength="1000"
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {console.log(projectAmendment)}
                                        {/* <div className="row">
                                            <div className="col-md-12">
                                                <TextArea 
                                                    rows="3" 
                                                    label="Descrição da alteração de projeto"
                                                    value={projectAmendment.descricao_servico}
                                                    maxLength="255"
                                                    disabled={true}
                                                />
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className="text-right mt-4">
                                        <div
                                            style={{
                                                width: '100%',
                                                marginBottom: '20px',
                                                position: 'absolute',
                                                bottom: '0%',
                                                left: '-2%',
                                            }}
                                        >
                                            <button
                                                style={{ marginRight: '10px' }}
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e) =>
                                                    handleCancelOrClose()
                                                }
                                            >
                                                Cancelar
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-success"
                                            >
                                                Aprovar
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                                <Form onSubmit={handlePagamento}>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <Input
                                                icon="fas fa-calendar"
                                                label="Vencimento"
                                                name="validity"
                                                // min={}
                                                max={'2500-12-12'}
                                                type="date"
                                                required={
                                                    projectAmendment.tipo_alteracao_projeto ===
                                                        'Atualização de Dados' ||
                                                    projectAmendment.id_tipo_alteracao ==
                                                        1 ||
                                                    projectAmendment.id_tipo_alteracao ==
                                                        2
                                                        ? false
                                                        : true
                                                }
                                                placeholder=""
                                                disabled={
                                                    projectAmendment.tipo_alteracao_projeto ===
                                                        'Atualização de Dados' ||
                                                    projectAmendment.id_tipo_alteracao ==
                                                        1 ||
                                                    projectAmendment.id_tipo_alteracao ==
                                                        2
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) =>
                                                    setVencimentoParcela(
                                                        e.target.value
                                                    )
                                                }
                                                value={vencimentoParcela}
                                            />
                                        </div>

                                        <div className="col-md-5">
                                            <NumberFormat
                                                icon="fas fa-money-check-alt"
                                                label="Valor da parcela"
                                                name="value"
                                                required={
                                                    projectAmendment.tipo_alteracao_projeto ===
                                                        'Atualização de Dados' ||
                                                    projectAmendment.id_tipo_alteracao ==
                                                        1
                                                        ? false
                                                        : true
                                                }
                                                allowNegative={false}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                placeholder=""
                                                customInput={Input}
                                                onChange={(e) =>
                                                    setValorParcela(
                                                        parseFloat(
                                                            e.target.value
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    ',',
                                                                    '.'
                                                                )
                                                        )
                                                    )
                                                }
                                                value={valorParcela}
                                                disabled={
                                                    projectAmendment.tipo_alteracao_projeto ===
                                                        'Atualização de Dados' ||
                                                    projectAmendment.id_tipo_alteracao ==
                                                        1 ||
                                                    projectAmendment.id_tipo_alteracao ==
                                                        2
                                                        ? true
                                                        : disabledValorParcelaButton()
                                                }
                                                isAllowed={(values) => {
                                                    const {
                                                        floatValue,
                                                        formattedValue,
                                                    } = values;
                                                    return (
                                                        formattedValue === '' ||
                                                        floatValue <= 9999999
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            {projectAmendment.tipo_alteracao_projeto ===
                                                'Atualização de Dados' ||
                                            projectAmendment.id_tipo_alteracao ==
                                                1 ||
                                            projectAmendment.id_tipo_alteracao ==
                                                2 ? (
                                                ''
                                            ) : (
                                                <div
                                                    className="input-group mb-3"
                                                    style={{
                                                        paddingTop: '30px',
                                                    }}
                                                >
                                                    <button
                                                        disabled={
                                                            props.match.params
                                                                .action ===
                                                            'view'
                                                                ? true
                                                                : false
                                                        }
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        <span className="fas fa-plus"></span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        {/* <div className="col-md-3">
                                            <NumberFormat   
                                                        icon="fas fa-money-check-alt"
                                                        label="Valor da parcela"
                                                        name="value" 
                                                        required={true}
                                                        allowNegative={false}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        thousandSeparator={"."} 
                                                        decimalSeparator={","}
                                                        placeholder=""
                                                        customInput={Input}
                                                        onChange={(e) => setValorParcela(parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')))} 
                                                        value={valorParcela}
                                                        disabled={false}
                                                        isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= 9999999;}}                          
                                                    />
                                        </div> */}
                                    </div>
                                    {aprovedBlocked ? (
                                        <div>
                                            <Alert type="danger" hidden="true">
                                                {aprovedBlocked}
                                            </Alert>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <TablePagamento
                                        total={`R$${formatReal(
                                            parseFloat(pagamentoTotal)
                                        )}`}
                                    >
                                        <Pagamento
                                            isDisabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            pagamento={pagamento}
                                            formatReal={formatReal}
                                            handleDeletePagamento={
                                                handleDeletePagamento
                                            }
                                        />
                                    </TablePagamento>
                                </Form>
                            </ModalLarge>
                        </div>
                    </div>
                    {loading === true ? (
                        <Content>
                            <ReactLoading
                                type="spinningBubbles"
                                color="blue"
                                height={200}
                                width={100}
                            />
                        </Content>
                    ) : (
                        ''
                    )}
                    <div
                        style={{ display: loading === true ? 'none' : 'block' }}
                    >
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="box">
                                    <div
                                        className="box-body"
                                        style={{
                                            background: '#FFFFFF',
                                            boxShadow:
                                                '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                            padding: '15px',
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <h2>
                                                    Registros de Alterações de
                                                    Projetos
                                                </h2>
                                            </div>
                                            <div className="text-right col-sm-6">
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        props.history.push(
                                                            `/new_project_amendment`
                                                        );
                                                        e.preventDefault();
                                                    }}
                                                    className="btn btn-primary"
                                                >
                                                    Cadastro
                                                </button>
                                            </div>
                                        </div>
                                        {props.ProjectAmendments.length >= 0 ||
                                        props.pesquisa ? (
                                            <CabecalhoNew
                                                filter={
                                                    <>
                                                        <div className="col-md-3 col-sm-12 mt-3">
                                                            <Input
                                                                label="De"
                                                                type="date"
                                                                icon="fas fa-calendar"
                                                                onChange={(e) =>
                                                                    dispatch({
                                                                        type: 'changeFromReportProjectAmendment',
                                                                        payload:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                                value={from}
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-sm-12 mt-3">
                                                            <Input
                                                                label="Até"
                                                                type="date"
                                                                icon="fas fa-calendar"
                                                                // max={to}
                                                                onChange={(e) =>
                                                                    dispatch({
                                                                        type: 'changeToReportProjectAmendment',
                                                                        payload:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                                value={to}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                buttonCsv={
                                                    <>
                                                        <button
                                                            style={{
                                                                background:
                                                                    '#005F31',
                                                                color: '#FFF',
                                                                borderRadius:
                                                                    '20.5px',
                                                                width: '140px',
                                                            }}
                                                            className="btn btn-sm"
                                                            onClick={exportCSV}
                                                        >
                                                            <i className="fas fa-file-csv"></i>{' '}
                                                            Exportar CSV
                                                        </button>
                                                        <button
                                                            style={{
                                                                background:
                                                                    '#1A170E',
                                                                color: '#FFF',
                                                                borderRadius:
                                                                    '20.5px',
                                                                width: '140px',
                                                                marginLeft:
                                                                    '10px',
                                                            }}
                                                            className="btn btn-sm"
                                                            onClick={() =>
                                                                setModalFilter(
                                                                    modalFilter ===
                                                                        false
                                                                        ? true
                                                                        : false
                                                                )
                                                            }
                                                        >
                                                            <i className="fas fa-filter"></i>{' '}
                                                            Mais Filtros
                                                        </button>
                                                        <CSVLink
                                                            filename={`Relatorio-${new Date()}.csv`}
                                                            headers={headers}
                                                            hidden={true}
                                                            separator={';'}
                                                            ref={fileUpload}
                                                            data={
                                                                projectAmendmentCSV ||
                                                                []
                                                            }
                                                        >
                                                            <span className="fas fa-file-csv">
                                                                {' '}
                                                                Exportar CSV
                                                            </span>
                                                        </CSVLink>
                                                    </>
                                                }
                                                to="#/"
                                                action={props.get}
                                                clear={props.clearPesquisa}
                                                valuePesquisa={props.pesquisa}
                                                value={props.qtd_per_page}
                                                onChange={
                                                    props.change_qtd_per_page
                                                }
                                                changePesquisa={
                                                    props.changePesquisa
                                                }
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div
                                style={{
                                    display: `${
                                        displayLegend === true
                                            ? 'none'
                                            : 'block'
                                    }`,
                                }}
                                className="text-right"
                            >
                                <button
                                    onClick={(e) => setDisplayLegend(true)}
                                    className="btn"
                                    style={{
                                        background:
                                            'linear-gradient(99.87deg, #427B67 25.78%, #22AC7A 65.59%)',
                                        borderRadius: '20.5px',
                                    }}
                                >
                                    Legendas
                                </button>
                            </div>
                            <div
                                style={{
                                    backgroundColor: '#FFF',
                                    padding: '10px',
                                    borderRadius: '25px',
                                    display: `${
                                        displayLegend === false
                                            ? 'none'
                                            : 'block'
                                    }`,
                                }}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        float: 'right',
                                    }}
                                >
                                    <button
                                        onClick={(e) => setDisplayLegend(false)}
                                        className="btn"
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-2 mt-2">
                                        <h5 style={{ fontSize: '16px' }}>
                                            Legendas:
                                        </h5>
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span>
                                            <img
                                                src={IconAnalysys}
                                                width="20px"
                                                alt="Analise"
                                            />{' '}
                                            Em Análise
                                        </span>
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span>
                                            <img
                                                src={AprovedIcon}
                                                width="20px"
                                                alt="Aprovar"
                                            />{' '}
                                            Aprovar
                                        </span>
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span>
                                            <img
                                                src={ReprovedIcon}
                                                width="20px"
                                                alt="Reprovar"
                                            />{' '}
                                            Reprovar
                                        </span>
                                    </div>
                                    {/* <div className="col-md-1 mt-2">
                                        <span><img src={IconBilled} width="20px" /> Faturar</span>

                                    </div> */}
                                </div>
                            </div>
                            {success ? (
                                <Alert type="success">{success}</Alert>
                            ) : (
                                ''
                            )}
                            {error ? (
                                <Alert type="danger">{error}</Alert> 
                            ): (
                                ''
                            )}
                            <div className="row mt-3">
                                {parseInt(props.ProjectAmendments.length) ===
                                0 ? (
                                    <div
                                        style={{
                                            color: '#FFFF',
                                            textAlign: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <span style={{ color: 'black' }}>{`${
                                            !props.pesquisa
                                                ? 'Não existem Registros de Vendas cadastrados no sistema. Clique no botão Cadastro para inserir um novo Registro de Alteração de Projetos no Sistema'
                                                : 'Nenhum registro encontrado na pesquisa'
                                        }`}</span>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {/* <ContractsAmendments changeStatusProjectAmendmentAproved={changeStatusProjectAmendmentAproved} check={check} modalReprovedOn={modalReprovedOn} history={props.history} check={check} ProjectAmendments={props.ProjectAmendments} formatReal={formatReal} onDelete={onChangeModal} /> */}
                                <ContractsAmendments
                                    modalAprovedOn={modalAprovedOn}
                                    modalReprovedOn={modalReprovedOn}
                                    history={props.history}
                                    check={check}
                                    ProjectAmendments={props.ProjectAmendments}
                                    formatReal={formatReal}
                                    onDelete={onChangeModal}
                                />
                            </div>
                        </div>
                        {props.ProjectAmendments.length > 0 && (
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="box">
                                        <div
                                            className="box-body"
                                            style={{
                                                background: '#FFFFFF',
                                                boxShadow:
                                                    '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                                padding: '15px',
                                            }}
                                        >
                                            <div className="row mt-4">
                                                <div className="col-md-1">
                                                    <select
                                                        value={
                                                            props.qtd_per_page
                                                        }
                                                        onChange={
                                                            props.change_qtd_per_page
                                                        }
                                                        name="example1_length"
                                                        aria-controls="example1"
                                                        className="custom-select custom-select-sm form-control form-control-sm"
                                                    >
                                                        <option value={8}>
                                                            8
                                                        </option>
                                                        <option value={24}>
                                                            24
                                                        </option>
                                                        <option value={48}>
                                                            48
                                                        </option>
                                                        <option value={96}>
                                                            96
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="col-md-5 mt-1 mb-2">
                                                    Exibindo de {props.from} à{' '}
                                                    {props.to} do total de{' '}
                                                    {props.qtd_total}
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div
                                                        style={{
                                                            float: 'right',
                                                        }}
                                                    >
                                                        <Pagination>
                                                            {props.pagination(
                                                                props.current_page,
                                                                props.get,
                                                                props.last_page,
                                                                props.changePage
                                                            )}
                                                        </Pagination>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

const mapStateToProps = (state) => ({
    ProjectAmendments: state.project_amendment.contract_changes.data || [],
    current_page: state.project_amendment.contract_changes.current_page,
    perfis: state.auth.user.perfis,
    last_page: state.project_amendment.contract_changes.last_page,
    from: state.project_amendment.contract_changes.from,
    to: state.project_amendment.contract_changes.to,
    qtd_total: state.project_amendment.contract_changes.total,
    qtd_per_page: state.project_amendment.qtd_per_page,
    pesquisa: state.project_amendment.pesquisa || '',
    id_role: state.auth.user.id_role,
    asc: state.project_amendment.asc,
    th: state.project_amendment.th,
    id_coordinator: state.project_amendment.coordinator_report.value || '',
    project: state.project_amendment.project_report.value || '',
    id_area: state.project_amendment.area_report.value || '',
    id_status: state.project_amendment.status_report.value || '',
    from_date: state.project_amendment.from,
    to_date: state.project_amendment.to,
    order_var: state.project_amendment.order,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            pagination,
            get,
            order,
            change_qtd_per_page,
            changePesquisa,
            clearPesquisa,
            changePage,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GridProjectAmendment);
