import { app } from './Api';

const get_view_project = async (id) => {
    return app.get(`project/${id}`);
};

const get_projects = async (
    page,
    qtd_per_page,
    order,
    th,
    pesquisa,
    type,
    area,
    manager,
    coordinator,
    status
) => {
    return app.get(
        `project?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&type=${type}&area=${area}&manager=${manager}&coordinator=${coordinator}&status=${status}`
    );
};

const post_project = async (data) => {
    return app.post(`project`, data);
};

const put_project = async (id, data) => {
    return app.post(`project/${id}`, data);
};

const del_project = async (id) => {
    return app.delete(`project/${id}`);
};

const changeStatusTechnical = async (id, data) => {
    return app.put(`project/change_status_technical/${id}`, data);
};

const getResource = async () => {
    return app.get(`project/create`);
};

const del_attachment = async (id) => {
    return app.delete(`project/del_attachment/${id}`);
};

const getTechnicalProject = async (id_project) => {
    return app.get(`project/technical/${id_project}`);
};

const post_technical_project = async (data) => {
    return app.post(`project/post_technical`, data);
};

const deleteTechnical = async (id) => {
    return app.delete(`project/delete_technical/${id}`);
};

const getPagamentos = async (idProjeto) => {
    return app.get(`project/pagamentos/${idProjeto}`);
};

const alterPagamento = async (data) => {
    return app.put(`change_pagamentos`, data);
};

const getFilial =  async (idEmpresa) => {
    return app.get(`project/get_filial/${idEmpresa}`)
}

const changeStatusPag = async (data) => {
    return app.put(`change_status_pagamentos`, data);
};

const exportExcelDetalhado = async (
    page,
    qtd_per_page,
    order,
    th,
    pesquisa,
    type,
    area,
    manager,
    coordinator,
    status
) => {
    return app.get(
        `project/export_excel?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&type=${type}&area=${area}&manager=${manager}&coordinator=${coordinator}&status=${status}`
    );
};

const exportExcelPorTecnico = async (
    page,
    qtd_per_page,
    order,
    th,
    pesquisa,
    type,
    area,
    manager,
    coordinator,
    status
) => {
    return app.get(
        `project/export_excel_tecnico?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&type=${type}&area=${area}&manager=${manager}&coordinator=${coordinator}&status=${status}`
    );
};

const exportExcelPagamentos = async (
    page,
    qtd_per_page,
    order,
    th,
    pesquisa,
    type,
    area,
    manager,
    coordinator,
    status
) => {
    return app.get(
        `project/export_excel_pagamentos?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&type=${type}&area=${area}&manager=${manager}&coordinator=${coordinator}&status=${status}`
    );
};

const exportExcelAgrupado = async (
    page,
    qtd_per_page,
    order,
    th,
    pesquisa,
    type,
    area,
    manager,
    coordinator,
    status
) => {
    return app.get(
        `project/export_excel_agrupado?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&type=${type}&area=${area}&manager=${manager}&coordinator=${coordinator}&status=${status}`
    );
};

export {
    exportExcelAgrupado,
    exportExcelDetalhado,
    exportExcelPorTecnico,
    exportExcelPagamentos,
    changeStatusPag,
    alterPagamento,
    getPagamentos,
    del_attachment,
    get_view_project,
    get_projects,
    changeStatusTechnical,
    post_project,
    put_project,
    getResource,
    del_project,
    getTechnicalProject,
    post_technical_project,
    deleteTechnical,
    getFilial
};
