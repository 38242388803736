import React from 'react'
import "react-toggle/style.css"

export default function Pagamento({ pagamento, formatReal, handleDeletePagamento, isDisabled }) {
    const pagamentos = pagamento || []
    
    return pagamentos.map((desc) =>(
        <tr key={desc.id}>
            <td className="text-center">{`${desc.dataPagamento.split("-")[2]}/${desc.dataPagamento.split("-")[1]}/${desc.dataPagamento.split("-")[0]}`}</td>
            <td className="text-center">R${formatReal(parseFloat(`${desc.valorParcela}`))}</td>
            <td className="text-center">
                <button disabled={isDisabled} type="button" className="btn btn-danger" onClick={() => handleDeletePagamento(desc.id)}><span className="fas fa-minus"></span></button>
            </td>
        </tr>
        
    ))  
}
