import React from 'react'
import { Link } from 'react-router-dom'
import "react-toggle/style.css"
import Toggle from 'react-toggle'

export default function Areas(props) {
    const area = props.area || []
    return area.map(desc=>(
        <tr key={desc.id}>
            <td>
                <Toggle

                    id={`${desc.id}`}
                    checked={desc.situacao === 1}
                    onChange={props.changeStatus}
                />
            </td>
            <td>{desc.descricao}</td>
            <td>{desc.name}</td>
            <td>
                <ul className="nav">
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#/">
                            <i className="fas fa-ellipsis-h" />             
                        </a>
                        <div className='dropdown-menu dropdown-menu-right'>                  
                            <Link to={`/area/${desc.id}/view`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link>
                            <Link to={`/area/${desc.id}/edit`} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>
                            {/* <Link to="#/" className='dropdown-item' onClick={()=>onDelete(desc.id)} ><i className='fas fa-trash-alt'></i> Excluir</Link>                                 */}
                        </div>
                    </li>
                </ul>		
            </td>                
        </tr>
    ))
    
}
