const UserReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Users':
            return{...state, users:actions.payload}

        case 'GetUser_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetUser_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_user':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaUser':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaUser':
            return{...state, pesquisa:actions.payload}

        case 'changePageUser':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default UserReducer;