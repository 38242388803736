const ProjectAmendmentReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'ProjectAmendment':
            return{...state, contract_changes:actions.payload}

        case 'changeFirstPage':
            return{...state, page:actions.payload}

        case 'GetProjectAmendment_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetProjectAmendment_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_ProjectAmendment':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaProjectAmendment':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaProjectAmendment':
            return{...state, 
                pesquisa:actions.payload,
                coordinator_report: actions.coordinator_report,
                status_report: actions.status_report ,  
                area_report: actions.area_report , 
                from: actions.from ,
                to: actions.to,
                project_report: actions.project_report,
                filter_data:actions.filter_data
            }

        case 'changeCoordinatorReportProjectAmendment':
            return{...state, coordinator_report:actions.payload}

        case 'changeProjectReportProjectAmendment':
            return{...state, project_report:actions.payload}
        
        case 'changeStatusReportProjectAmendment':
            return{...state, status_report:actions.payload}

            case 'changeFilterDataProjectAmendment':
            return{...state, filter_data:actions.payload}

        case 'changeAreaReportProjectAmendment':
            return{...state, area_report:actions.payload}

        case 'changeFromReportProjectAmendment':
            return{...state, from:actions.payload}

        case 'changeToReportProjectAmendment':
            return{...state, to:actions.payload}

        case 'changePageProjectAmendment':
            return{...state, page:actions.payload}
        

        default:
            return state;
    }
}
export default ProjectAmendmentReducer;