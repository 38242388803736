import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import StylePage from '../../../Style Page/StylePage';
import Form from '../../../Forms/Form';
import Input from '../../../Forms/Input';
import Footer from '../../../Footer/Footer';
import TextArea from '../../../Forms/TextArea';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import {
    del_attachment,
    getTechnical,
    getProjects,
    getType,
    post_reimbursement,
    put_reimbursement,
    get_view_reimbursement,
    getExtraCharges,
    findKmValue,
} from '../../../Services/reimbursement';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { ContentUpload } from '../../../Upload/styles';
import Upload from '../../../Upload/upload';
import FileList from '../../../FileList/FileList';
import Alert from '../../../Alerts/Alert';
import RadioMaterial from '../../../Forms/RadioMaterial';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function Reimbursement(props) {
    let history = useHistory();
    const selectTechnicianRef = useRef(null);
    const selectProjectRef = useRef(null);
    const selectExtraChargeRef = useRef(null);
    const selectTypeRef = useRef(null);
    const role = useSelector((state) => state.auth.user.perfis);
    const id_user = useSelector((state) => state.auth.user.id);
    // const data_atual                          = useSelector(state => state.reimbursement.data_atual)
    const [loading, setLoading] = useState(false);
    const [created_by, setCreated] = useState('');
    const [technical, setTechnical] = useState([]);
    const [projects, setProjects] = useState([]);
    const [type, setType] = useState([]);
    const [id_technician, setIdTechnician] = useState([]);
    const [id_project, setIdProject] = useState([]);
    const [id_type, setIdType] = useState([]);
    const [description, setDescription] = useState('');
    const [date, setDate] = useState(
        useSelector((state) => state.reimbursement.date),
    );
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState('');
    const [alert, setAlert] = useState('');
    const [value, setValue] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [km, setKm] = useState('');
    const [valueKm, setValueKm] = useState('');
    const [id_status, setIdStatus] = useState('');
    const [moduleVisit, setModuleVisit] = useState('Projeto');
    const [idExtraCharge, setIdExtraCharge] = useState('');
    const [extraCharges, setExtraCharges] = useState([]);


    useEffect(() => {
        Technical();
    }, []);

    useEffect(() => {
        TypeReimbursement();
    }, []);

    useEffect(() => {
        viewPerId();
    }, []);

    useEffect(() => {
        getAllExtraCharges();
    }, [id_technician, id_project]);

    useEffect(() => {
        if (km !== '') {
            calcValue();
        }
    }, [date]);

    useEffect(() => {
        calcValue();
    }, [id_type]);

    //Função que verifica se o usuário tem permissão para editar o lançamento
    function isEdit(created, status) {
        if (props.match.params.action === 'edit') {
            if (
                parseInt(created) !== parseInt(id_user) ||
                parseInt(status) !== 1
            ) {
                props.history.push(
                    `/reimbursement/${props.match.params.id}/view`,
                );
            }
        }
    }

    function getAllExtraCharges(tecnico, projeto) {
        getExtraCharges(
            tecnico || id_technician.value,
            projeto || id_project.value,
        ).then((resp) => {
            setExtraCharges(
                resp.data.map((desc) => ({
                    value: desc.id,
                    label: desc.codigo,
                    valor: desc.valor,
                    data_inicio: desc.data_inicio,
                    data_fim: desc.data_fim,
                    tipo_projeto: desc.tipo_projeto ?? 1,
                })),
            );
        });
    }

    //Função que carrega os tecnicos
    function Technical() {
        getTechnical().then((resp) => {
            setTechnical(
                resp.data.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                })),
            );
            // if (role.indexOf(7) >= 0) {
            //     let myUser = resp.data.filter((resp) => resp.id === id_user);
            //     setIdTechnician({
            //         value: myUser[0].id,
            //         label: myUser[0].name,
            //     });
            //     Projects(id_user);
            // }
        });
    }

    //Concatenando arquivos para upload
    const handleUpload = (files) => {
        const uploadedFile = files.map((file) => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),

            progress: 0,
            uploaded: false,
            error: false,
            url: null,
        }));
        setUploadedFiles(uploadedFiles.concat(uploadedFile));
    };

    //Deleta arquivo
    const handleDelete = (id) => {
        const upload = uploadedFiles.filter((file) => file.id === id);
        if (upload[0].url) {
            del_attachment(id).then((resp) => viewPerId(props.match.params.id));
        } else {
            setUploadedFiles(uploadedFiles.filter((file) => file.id !== id));
        }
    };

    //Função que carrega os projetos
    function Projects(id, moduleVisit) {
        getProjects(id, moduleVisit).then((resp) => {
            setProjects(
                resp.data.map((desc) => ({
                    value: desc.id,
                    label: desc.titulo,
                    data_inicio: desc.data_inicio,
                    data_fim: desc.data_fim,
                    tipo_projeto: desc.tipo_projeto ?? 1,
                })),
            );
        });
    }

    //Função que carrega os tipos de reeembolso
    function TypeReimbursement(id_project) {
        getType().then((resp) => {
            setType(
                resp.data.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                    valor: desc.valor,
                    comprovante: desc.comprovante,
                })),
            );
        });
    }

    //Função que carrega os dados de acordo o id do lançamento
    function viewPerId() {
        props.match.params.action &&
        get_view_reimbursement(props.match.params.id).then((resp) => {
            setIdTechnician({
                value:
                    resp.data.reimbursement[0].id_technical ||
                    resp.data.reimbursement[0].id_tecnico_cobranca_extra,
                label:
                    resp.data.reimbursement[0].name ||
                    resp.data.reimbursement[0].tecnico_cobranca_extra,
            });
            setIdProject({
                value:
                    resp.data.reimbursement[0].id_project ||
                    resp.data.reimbursement[0].id_projeto_cobranca,
                label:
                    resp.data.reimbursement[0].project_title ||
                    resp.data.reimbursement[0].projeto_cobranca,
                data_inicio: resp.data.reimbursement[0].data_inicio,
                tipo_projeto: resp.data.reimbursement[0].tipo_projeto ?? 1,
                data_fim: resp.data.reimbursement[0].data_fim,
            });
            setIdType({
                value: resp.data.reimbursement[0].id_type,
                label: resp.data.reimbursement[0].type_description,
                valor_remuneracao: resp.data.reimbursement[0].valor,
                comprovante: resp.data.reimbursement[0].comprovante,
            });
            setDescription(
                resp.data.reimbursement[0].reimbursement_description,
            );
            setValue(resp.data.reimbursement[0].valor_reembolso);
            setDate(resp.data.reimbursement[0].data_lancamento);
            setIdStatus(resp.data.reimbursement[0].id_status);
            setCreated(resp.data.reimbursement[0].created_by);
            Projects(
                resp.data.reimbursement[0].id_technical ||
                resp.data.reimbursement[0].id_tecnico_cobranca_extra,
            );
            setModuleVisit(
                resp.data.reimbursement[0].id_cobranca_extra
                    ? 'Cobrança Extra'
                    : 'Projeto',
            );
            setKm(
                parseInt(resp.data.reimbursement[0].id_type) === 3 ||
                parseInt(resp.data.reimbursement[0].id_type) === 11 ||
                parseInt(resp.data.reimbursement[0].id_type) === 12
                    ? resp.data.reimbursement[0].km
                    : '',
            );
            setValueKm(
                parseInt(resp.data.reimbursement[0].id_type) === 3
                    ? resp.data.reimbursement[0].deslocamento_km
                    : parseInt(resp.data.reimbursement[0].id_type) === 11
                        ? resp.data.reimbursement[0].deslocamento_km
                        : parseInt(resp.data.reimbursement[0].id_type) === 12
                            ? 0.9
                            : '',
            );

            if (resp.data.reimbursement[0].id_cobranca_extra) {
                setIdExtraCharge({
                    value: resp.data.reimbursement[0].id_cobranca_extra,
                    label: resp.data.reimbursement[0].cobranca_extra,
                    data_inicio:
                    resp.data.reimbursement[0].data_inicio_cobranca,
                    data_fim: resp.data.reimbursement[0].data_fim_cobranca,
                    tipo_projeto: resp.data.reimbursement[0].tipo_projeto ?? 1,
                });
                getAllExtraCharges(
                    resp.data.reimbursement[0].id_tecnico_cobranca_extra,
                    resp.data.reimbursement[0].id_projeto_cobranca,
                );
            }
            setUploadedFiles(
                resp.data.attachment.map((file) => ({
                    id: file.id,
                    name: file.nome,
                    readableSize: filesize(file.size),
                    uploaded: true,
                    url: file.link,
                })),
            );
            isEdit(
                resp.data.reimbursement[0].created_by,
                resp.data.reimbursement[0].id_status,
            );
        }).catch(e => {
            setAlert(e.response.data.error)
            setTimeout(() => {
                setAlert('');
                return props.history.push('/refunds');
            }, 5000);
        });;
    }

    //Evento de alteração do tecnico
    const changeTechnician = (e) => {
        setIdProject([]);
        setIdTechnician(e);
        Projects(e.value, moduleVisit);
    };

    //Evento de alteração no valor
    const changeValue = (e) => {
        setValue(
            parseFloat(
                e.target.value
                    .replace('.', '')
                    .replace('.', '')
                    .replace(',', '.'),
            ),
        );
    };

    //Evento de alteração no projeto
    const changeProject = (e) => {
        console.log(e);
        setIdProject(e);
    };

    //Evento de alteração no tipo de reembolso
    const changeType = (e) => {
        setIdType(e);
        let carro = type.find(elem => elem.value === 3);
        let moto = type.find(elem => elem.value === 11);

        findKmValue(date).then((resp) => {
            if (parseInt(e.value) === 3) {
                setValueKm(resp.data.km_carro);
                setValue(km * resp.data.km_carro);
            }

            // tipo deslocamento moto
            if (parseInt(e.value) === 11) {
                setValueKm(resp.data.km_moto);
                setValue(km * resp.data.km_moto);
            }
        });

        let valueKmGO =
            parseInt(e.value) === 3
                ? valueKm
                : parseInt(e.value) === 11
                    ? valueKm
                    : parseInt(e.value) === 12
                        ? 0.9
                        : '';
        setValueKm(
            parseInt(e.value) === 3
                ? valueKm
                : parseInt(e.value) === 11
                    ? valueKm
                    : parseInt(e.value) === 12
                        ? 0.9
                        : '',
        );

        if (
            parseInt(e.value) === 3 ||
            parseInt(e.value) === 11 ||
            parseInt(e.value) === 12
        ) {

            // setKm(km);
            // setValue(km * valueKmGO);
            // UpdateKmAfterTypeChange(km, valueKmGO)
            // calcValue();
        } else {
            setKm('');
        }
    };

    // function UpdateKmAfterTypeChange(distance, valKm)
    // {
    //     setKm(distance);
    //     setValue(distance * valKm);
    // }

    function calcValue() {
        setValue(km * valueKm);
    }

    const handleValueKM = (data) => {
        let carro = type.find(elem => elem.value === 3);
        let moto = type.find(elem => elem.value === 11);

        findKmValue(data).then((resp) => {
            if (id_type.value === 3) {
                setValueKm(resp.data.km_carro);
                setValue(km * resp.data.km_carro);
            }

            // tipo deslocamento moto
            if (id_type.value === 11) {
                setValueKm(resp.data.km_moto);
                setValue(km * resp.data.km_moto);
            }
        });

        // tipo deslocamento carro

    };

    //Evento de alteração na data
    const changeDate = (e) => {
        setDate(e.target.value);

        handleValueKM(e.target.value);
    };

    //Evento de Alteração na descrição
    const changeDescription = (e) => {
        setDescription(e.target.value);
    };

    //Evento de alteração do KM
    const changeKm = (e) => {
        const km = parseFloat(
            e.target.value.replace('.', '').replace('.', '').replace(',', '.'),
        );
        setKm(km);
        setValue(km * valueKm);
    };

    const changeModule = (e) => {
        setIdProject([]);
        setIdExtraCharge([]);
        setModuleVisit(e.target.value);
        Projects(id_technician.value, e.target.value);
    };

    const changeExtraCharge = (e) => {
        setIdExtraCharge(e);
    };

    function renderVigencia() {
        if (id_project.value || idExtraCharge.value) {
            return (
                <div className='row'>
                    <div className='col-md-6'>
                        <Input
                            icon='fas fa-calendar-alt'
                            label='Data Início'
                            type='date'
                            value={
                                moduleVisit === 'Projeto'
                                    ? id_project.data_inicio
                                    : moduleVisit === 'Cobrança Extra'
                                        ? idExtraCharge.data_inicio
                                        : ''
                            }
                            placeholder=''
                            disabled={true}
                        />
                    </div>
                    <div className='col-md-6'>
                        <Input
                            icon='fas fa-calendar-alt'
                            label='Data Fim'
                            type='date'
                            value={
                                moduleVisit === 'Projeto'
                                    ? id_project.data_fim
                                    : moduleVisit === 'Cobrança Extra'
                                        ? idExtraCharge.data_fim
                                        : ''
                            }
                            placeholder=''
                            disabled={true}
                        />
                    </div>
                </div>
            );
        } else {
            return <span></span>;
        }
    }

    //Evento que envia os dados para API
    const handleForm = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = new FormData();
        let attachment = false;
        var resultado = -1;

        if (uploadedFiles.length > 0) {
            attachment = true;
        }

        var uploadedFile = uploadedFiles.filter((desc) => !desc.url);

        for (let i = 0; i < uploadedFile.length; i++) {
            if (uploadedFile[i].file) {
                data.append(`file${i}`, uploadedFile[i].file);
                resultado = i;
                attachment = true;
            }
        }
        data.append('id_technical', parseInt(id_technician.value));
        data.append('id_project', parseInt(id_project.value));
        data.append('id_type', parseInt(id_type.value));
        data.append('description', description);
        data.append('date', date);
        data.append('value', value);
        data.append('qtd', resultado);
        data.append('isAttachment', attachment);
        data.append('km', km);
        data.append('comprovante', parseInt(id_type.comprovante));
        data.append('tipo_projeto', parseInt(id_project.tipo_projeto));
        if (moduleVisit === 'Cobrança Extra') {
            data.append('idExtraCharge', parseInt(idExtraCharge.value));
        }

        if (props.match.params.action === 'edit') {
            data.append('_method', 'put');
        }

        if (props.match.params.action === 'edit') {
            const id = props.match.params.id;
            put_reimbursement(id, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then((resp) => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then((resp) =>
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500),
                )
                .catch((e) => {
                    setLoading(false);
                    setSuccess('');
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        setError('');
                        setAlert('');
                    }, 2500);
                });
        } else {
            post_reimbursement(data, {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then((resp) => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then((resp) =>
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500),
                )
                .catch((e) => {
                    setLoading(false);
                    setSuccess('');
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        setError('');
                        setAlert('');
                    }, 2500);
                });
        }
    };

    return (
        <div className='wrapper'>
            <Sidebar />
            <Header />
            <StylePage
                title={
                    props.match.params.action === 'edit'
                        ? 'Edição de Reembolsos'
                        : props.match.params.action === 'view'
                            ? 'Visualização de Reembolsos'
                            : 'Cadastro de Reembolsos'
                }
            >
                {loading === true ? (
                    <Content>
                        <ReactLoading
                            type='spinningBubbles'
                            color='blue'
                            height={200}
                            width={100}
                        />
                    </Content>
                ) : (
                    ''
                )}

                {alert ? (
                    <div>
                        <Alert type='danger' onCloseAlert={() => setAlert('')}>
                            {alert}
                        </Alert> 
                    </div>
                )
                : ''}

                {success ? ( 
                    <div>
                        <Alert type='success' onCloseAlert={() => setSuccess('')}>
                            {success}
                        </Alert> 
                    </div>
                ): ''}

                <div
                    style={{
                        width: '100%',
                        display: loading === true ? 'none' : 'block',
                        opacity: success || alert ? 0.1 : 1,
                    }}
                >
                    <Form onSubmit={handleForm}>
                        <div className='row'>
                            <div className='col-md-3 mt-1'>
                                <RadioMaterial
                                    row
                                    label='Módulo de Lançamento ? *'
                                    aria-label='module'
                                    name='module'
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    value={moduleVisit}
                                    styleLabel={{
                                        color: '#BEBEBE',
                                        fontSize: '10px',
                                        marginBottom: '15px',
                                    }}
                                    onChange={changeModule}
                                >
                                    <FormControlLabel
                                        value='Projeto'
                                        control={<Radio />}
                                        label='Projeto'
                                    />
                                    <FormControlLabel
                                        value='Cobrança Extra'
                                        control={<Radio />}
                                        label='Serviço Extra'
                                    />
                                </RadioMaterial>
                            </div>
                            <div className='col-md-3'>
                                <label>Técnico</label>
                                <Select
                                    value={id_technician}
                                    ref={selectTechnicianRef}
                                    label='Single select'
                                    options={technical}
                                    onChange={changeTechnician}
                                    isDisabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                                {id_technician.length <= 0 && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete='off'
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={id_technician}
                                        onFocus={() =>
                                            selectTechnicianRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.id_technical}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className='col-md-3'>
                                <label>Projeto</label>
                                <Select
                                    value={id_project}
                                    ref={selectProjectRef}
                                    label='Single select'
                                    options={projects}
                                    onChange={changeProject}
                                    isDisabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                                {id_project.length <= 0 && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete='off'
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={id_project}
                                        onFocus={() =>
                                            selectProjectRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.id_project}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                            {moduleVisit === 'Cobrança Extra' && (
                                <div className='col-md-3'>
                                    <label>Serviço Extra</label>
                                    <Select
                                        value={idExtraCharge}
                                        label='Single select'
                                        options={extraCharges}
                                        onChange={changeExtraCharge}
                                        ref={selectExtraChargeRef}
                                        isDisabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                    />
                                    {idExtraCharge.length <= 0 && (
                                        <input
                                            tabIndex={-1}
                                            autoComplete='off'
                                            style={{
                                                opacity: 0,
                                                width: '100%',
                                                height: 0,
                                                position: 'absolute',
                                            }}
                                            defaultValue={idExtraCharge}
                                            onFocus={() =>
                                                selectExtraChargeRef.current.focus()
                                            }
                                            required={true}
                                        />
                                    )}
                                    {error ? (
                                        <p style={{ color: 'red' }}>
                                            {error.idExtraCharge}
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                    {/* {validationProject && <div className="mt-2"><Alert type="warning">{validationProject}</Alert></div>}  */}
                                </div>
                            )}
                            <div
                                className={
                                    moduleVisit === 'Cobrança Extra'
                                        ? 'col-md-12 mt-1'
                                        : 'col-md-3 mt-1'
                                }
                            >
                                <label>Tipo</label>
                                <Select
                                    ref={selectTypeRef}
                                    value={id_type}
                                    label='Single select'
                                    options={type}
                                    onChange={changeType}
                                    isDisabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                                {id_type.length <= 0 && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete='off'
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={id_type}
                                        onFocus={() =>
                                            selectTypeRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.id_type}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        {parseInt(id_type.value) === 3 ||
                        parseInt(id_type.value) === 11 ||
                        parseInt(id_type.value) === 12 ? (
                            <div className='row'>
                                <div className='col-md-6'>
                                    <NumberFormat
                                        label='KM'
                                        icon={`fas fa-${
                                            parseInt(id_type.value) === 3
                                                ? 'car'
                                                : parseInt(id_type.value) === 11
                                                    ? 'motorcycle'
                                                    : 'car'
                                        }`}
                                        name='km'
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        placeholder=''
                                        customInput={Input}
                                        onChange={changeKm}
                                        value={km}
                                        disabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                        required={true}
                                        isAllowed={(values) => {
                                            const {
                                                floatValue,
                                                formattedValue,
                                            } = values;
                                            return (
                                                formattedValue === '' ||
                                                floatValue <= 10000
                                            );
                                        }}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <NumberFormat
                                        label={`${id_type.label}`}
                                        icon={'fas fa-dollar-sign'}
                                        name='km'
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        placeholder=''
                                        customInput={Input}
                                        value={valueKm}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {renderVigencia()}
                        <div className='row'>
                            <div className='col-md-6'>
                                <NumberFormat
                                    label='Valor'
                                    icon='fas fa-dollar-sign'
                                    name='valor'
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    placeholder=''
                                    customInput={Input}
                                    onChange={changeValue}
                                    value={value}
                                    disabled={
                                        props.match.params.action === 'view' ||
                                        parseInt(id_type.value) === 3 ||
                                        parseInt(id_type.value) === 11 ||
                                        parseInt(id_type.value) === 12
                                            ? true
                                            : false
                                    }
                                    required={true}
                                    isAllowed={(values) => {
                                        const { floatValue, formattedValue } =
                                            values;
                                        return (
                                            formattedValue === '' ||
                                            floatValue <= 999999
                                        );
                                    }}
                                />
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.value}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className='col-md-6'>
                                <Input
                                    icon='fas fa-calendar-alt'
                                    label='Data da Despesa'
                                    onChange={changeDate}
                                    type='date'
                                    value={date}
                                    min={
                                        moduleVisit === 'Projeto'
                                            ? id_project.data_inicio
                                            : moduleVisit === 'Cobrança Extra'
                                                ? idExtraCharge.data_inicio
                                                : ''
                                    }
                                    max={
                                        moduleVisit === 'Projeto'
                                            ? id_project.data_fim
                                            : moduleVisit === 'Cobrança Extra'
                                                ? idExtraCharge.data_fim
                                                : ''
                                    }
                                    placeholder=''
                                    required={true}
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                                {error ? (
                                    <p style={{ color: 'red' }}>{error.date}</p>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-6'>
                                <TextArea
                                    rows='6'
                                    label='Descrição'
                                    value={description}
                                    maxLength='255'
                                    onChange={changeDescription}
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.description}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className='col-md-6'>
                                <label>Upload</label>
                                <ContentUpload>
                                    <>
                                        <Upload
                                            disabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            onUpload={handleUpload}
                                            required={id_type.comprovante === 1 ? true : false}
                                        />

                                        {!!uploadedFiles.length && (
                                            <FileList
                                                action={
                                                    props.match.params.action
                                                }
                                                toEdit={
                                                    props.match.params
                                                        .action === 'edit'
                                                        ? `/reimbursement/${props.match.params.id}/edit`
                                                        : ''
                                                }
                                                toCadastro='new_reimbursement'
                                                files={uploadedFiles}
                                                onDelete={handleDelete}
                                                required={id_type.comprovante === 1 ? true : false}
                                            />
                                        )}
                                    </>
                                </ContentUpload>
                            </div>
                        </div>
                        <br></br>
                        <div className='text-right'>
                            <button
                                style={{ marginRight: '10px' }}
                                type='button'
                                className='btn btn-secondary'
                                onClick={() => history.goBack()}
                            >
                                Voltar
                            </button>

                            {props.match.params.action === 'view' ? (
                                <Link
                                    style={{
                                        cursor:
                                            created_by !== id_user ||
                                            id_status !== 1
                                                ? 'not-allowed'
                                                : '',
                                        color:
                                            created_by !== id_user ||
                                            id_status !== 1
                                                ? '#fff'
                                                : '',
                                        backgroundColor:
                                            created_by !== id_user ||
                                            id_status !== 1
                                                ? '#007bff'
                                                : '',
                                        borderColor:
                                            created_by !== id_user ||
                                            id_status !== 1
                                                ? '#007bff'
                                                : '',
                                        opacity:
                                            created_by !== id_user ||
                                            id_status !== 1
                                                ? 0.65
                                                : '',
                                        boxShadow:
                                            created_by !== id_user ||
                                            id_status !== 1
                                                ? 'none'
                                                : '',
                                    }}
                                    onClick={
                                        created_by !== id_user ||
                                        id_status !== 1
                                            ? (e) => e.preventDefault()
                                            : ''
                                    }
                                    to={`/reimbursement/${props.match.params.id}/edit`}
                                    className='btn btn-primary'
                                >
                                    Editar
                                </Link>
                            ) : (
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                >
                                    Salvar
                                </button>
                            )}
                        </div>
                    </Form>
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}
