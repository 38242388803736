import React, { useState, useEffect } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import StylePage from '../../../Style Page/StylePage';
import Form from '../../../Forms/Form';
import Input from '../../../Forms/Input';
import Alert from '../../../Alerts/Alert';
import Footer from '../../../Footer/Footer';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { post_motive, get_view_motive, put_motive } from '../../../Services/motive';
import '../../../../Dependencies/dependencies';
import '../../../../Dependencies/jquery-loader';

export default function Motive(props) {

    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [descricao, setDescricao] = useState('');
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState('');
    const [alert, setAlert] = useState('');
    const perfis = useSelector(state => state.auth.user.perfis);


    useEffect(() => {
        viewPerId();
    }, []);

    useEffect(() => {
        permissionRole();
        return () => {
            permissionRole();
        };
    }, [props.match.params.action]);

    function viewPerId() {
        props.match.params.action && (
            get_view_motive(props.match.params.id)
                .then(resp => {
                    setDescricao(resp.data[0].descricao);
                })
        );
    }

    //Função que verifica se o usuário tem permissão para acessar a edição do registro
    function permissionRole() {
        if (props.match.params.action === 'edit') {
            if (perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) < 0) {
                return props.history.push('/culturas');
            }
        }
    }

    //Função que pega o evento de onChange do campo nome da cultura e salva no state da aplicação
    const changeDescricao = event => {
        const descricao = event.target.value;
        setDescricao(descricao);
    };

    //Função que valida realiza put ou post para a API
    const handleForm = (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert('');
        setSuccess('');

        //Objeto a ser enviado para a API
        const data = {
            descricao: descricao,
        };

        //Verificando qual a action para definir se será Put ou Post
        if (props.match.params.action === 'edit') {
            const id = props.match.params.id;
            put_motive(id, data)
                .then(resp => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then(resp => setTimeout(() => {
                    window.location.reload();
                }, 2500))
                .catch(e => {
                    setLoading(false);
                    setSuccess('');
                    setError(e.response.data.error);
                    setAlert(e.response.data.errorSubmit);
                });


        } else {
            post_motive(data)
                .then(resp => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then(resp => setTimeout(() => {
                    window.location.reload();
                }, 2500))
                .catch(e => {
                    setLoading(false);
                    setSuccess('');
                    setAlert(e.response.data.error);
                    setTimeout(() => {
                        setAlert('');
                    }, 3000);
                });

        }
    };

    return (
        <div className='wrapper'>
            <Sidebar />
            <Header />
            <StylePage
                title={
                    props.match.params.action === 'edit' ? 'Edição de Motivo de Encerramento' :
                        props.match.params.action === 'view' ? 'Visualização de Motivo de Encerramento' :
                            'Cadastro de Motivo de Encerramento'
                }
            >
                {loading === true ? (
                    <Content>
                        <ReactLoading type='spinningBubbles' color='blue' height={200} width={100} />
                    </Content>

                ) : ('')}
                {alert ? (
                    <div>
                        <Alert type='danger'>
                            {alert}
                        </Alert>
                    </div>
                ) : ('')}
                {success ? (
                    <div>
                        <Alert type='success'>
                            {success}
                        </Alert>
                    </div>
                ) : ('')}
                <div style={{ display: loading === true ? ('none') : ('block') }}>
                    <Form onSubmit={handleForm}>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Input
                                    label='Motivo de Encerramento'
                                    value={descricao}
                                    onChange={(e) => setDescricao(e.target.value)}
                                    icon='fa-address-card'
                                    name='descricao'
                                    type='text'
                                    placeholder='Digite o nome do motivo de encerramento'
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.descricao}</p>) : ('')}
                            </div>
                        </div>
                        <br></br>
                        <div className='text-right'>
                            <button style={{ marginRight: '10px' }} type='button' className='btn btn-secondary'
                                    onClick={() => history.goBack()}>Voltar
                            </button>
                            {props.match.params.action === 'view' ? (
                                <Link
                                    // style={{
                                    //     cursor: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 ? 'not-allowed' : '',
                                    //     color: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 ? '#fff' : '',
                                    //     backgroundColor: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 ? '#007bff' : '',
                                    //     borderColor: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 ? '#007bff' : '',
                                    //     opacity: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 ? .65 : '',
                                    //     boxShadow: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 ? 'none' : ''

                                    // }} 
                                    onClick={perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) < 0 ? e => e.preventDefault() : ''}
                                    to={`/motive/${props.match.params.id}/edit`}
                                    className='btn btn-primary'>Editar</Link>
                            ) : (
                                <button disabled={props.match.params.action === 'view' ? true : false} type='submit'
                                        className='btn btn-primary'>Salvar</button>
                            )}
                        </div>
                    </Form>
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}
