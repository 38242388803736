import { createStore, applyMiddleware } from "redux";
import AllReducers from "./reducers/AllReducers";
import promise from "redux-promise";
import multi from "redux-multi";
import thunk from "redux-thunk";

//Função que pega a data atual como default ;

var data = new Date();
var dia = data.getDate();
var ano = data.getFullYear();

function retornaMes(mes) {
    if (mes < 10) {
        return `0${mes}`;
    } else {
        return mes;
    }
}

if (dia < 10) {
    var compDia = 0;
}
if (dia >= 10) {
    compDia = "";
}
var mes = data.getMonth();
var str_date = ano + "-" + retornaMes(mes + 1) + "-" + compDia + dia;
var monthFluxo =
    parseInt(compDia + dia) > 24 && parseInt(compDia + dia) <= 31
        ? retornaMes(mes + 1)
        : retornaMes(mes);
var dateFlux = ano + "-" + monthFluxo + "-" + 25;


let mesComp = data.getUTCMonth();
let anoComp = data.getUTCFullYear();
let diaComp = data.getUTCDate();


let competencia = '';
let fimCompetencia = new Date(`${anoComp}-${retornaMes(mesComp + 1)}-25`);
let hoje = new Date(`${anoComp}-${retornaMes(mesComp + 1)}-${diaComp}`);;

if( hoje > fimCompetencia ){
    competencia  = `${ano}-${retornaMes(mesComp + 3)}`
} else {
    competencia  = `${ano}-${retornaMes(mesComp + 2)}`
}


const initialStates = {
    auth: {
        loggedIn: false,
        user: {},
    },
    user: {
        users: [],
        asc: true,
        desc: false,
        order: "asc",
        th: "users.name",
        qtd_per_page: 10,
        page: 1,
    },
    empresa: {
        empresas: [],
        asc: true,
        desc: false,
        order: "asc",
        th: "empresas.nome",
        qtd_per_page: 10,
        page: 1,
    },
    client: {
        customers: [],
        asc: true,
        desc: false,
        order: "asc",
        th: "nome",
        qtd_per_page: 10,
        page: 1,
    },
    filial: {
        filiais: [],
        asc: true,
        desc: false,
        order: "asc",
        th: "name",
        qtd_per_page: 10,
        page: 1,
    },
    culture: {
        cultures: [],
        asc: true,
        desc: false,
        order: "asc",
        th: "cultura.descricao",
        qtd_per_page: 10,
        page: 1,
    },
    visit: {
        visits: [],
        asc: false,
        desc: true,
        order: "desc",
        th: "atividades.created_at",
        qtd_per_page: 8,
        date: str_date,
        technical_report: "",
        project_report: "",
        remuneration_report: "",
        status_report: "",
        from: dateFlux,
        to: str_date,
        fromFix: dateFlux,
        toFix: str_date,
        page: 1,
        coordinator_report: "",
        data_atual: str_date,
    },
    reimbursement: {
        refunds: [],
        asc: false,
        desc: true,
        order: "desc",
        th: "reembolsos.created_at",
        qtd_per_page: 8,
        date: str_date,
        technical_report: "",
        project_report: "",
        type_report: "",
        status_report: "",
        from: dateFlux,
        to: str_date,
        fromFix: dateFlux,
        toFix: str_date,
        page: 1,
        coordinator_report: "",
        data_atual: str_date,
        id_reimbursement_shared: "",
    },
    project: {
        projects: [],
        asc: true,
        desc: false,
        order: "asc",
        th: "projetos.titulo",
        qtd_per_page: 10,
        date: str_date,
        page: 1,
        type: "",
        area: "",
        manager: "",
        coordinator: "",
        status: "",
    },
    projectEnsino: {
        projectEnsino: [],
        asc: true,
        desc: false,
        order: "asc",
        th: "projetos_ensino.titulo",
        qtd_per_page: 10,
        date: str_date,
        page: 1,
        manager: "",
        cod_centro_custo: "",
        area: '',
        coordinator: "",
        status: "",
    },
    reimbursement_shared: {
        refunds_shared: [],
        asc: false,
        desc: true,
        order: "desc",
        th: "reembolsos_compartilhados.created_at",
        qtd_per_page: 8,
        date: str_date,
        technical_report: "",
        coordinator_report: "",
        project_report: "",
        type_report: "",
        status_report: "",
        from: dateFlux,
        to: str_date,
        fromFix: dateFlux,
        toFix: str_date,
        page: 1,
        data_atual: str_date,
    },
    sales_record: {
        sales_records: [],
        asc: false,
        desc: true,
        order: "desc",
        th: "registro_venda.created_at",
        qtd_per_page: 8,
        date: str_date,
        project_report: "",
        area_report: "",
        status_report: "",
        from: dateFlux,
        to: str_date,
        fromFix: dateFlux,
        toFix: str_date,
        page: 1,
        coordinator_report: "",
        data_atual: str_date,
    },
    register_sgagri: {
        registers_sgagri: [],
        asc: false,
        desc: true,
        order: "desc",
        th: "registro_sgagri.created_at",
        qtd_per_page: 8,
        date: str_date,
        project_report: "",
        area_report: "",
        status_report: "",
        from: dateFlux,
        to: str_date,
        fromFix: dateFlux,
        toFix: str_date,
        page: 1,
        coordinator_report: "",
        data_atual: str_date,
    },
    extra_charge: {
        extra_charges: [],
        asc: false,
        desc: true,
        order: "desc",
        th: "cobranca_extra.created_at",
        qtd_per_page: 8,
        date: str_date,
        project_report: "",
        status_report: "",
        from: dateFlux,
        to: str_date,
        fromFix: dateFlux,
        toFix: str_date,
        page: 1,
        coordinator_report: "",
        data_atual: str_date,
    },

    project_amendment: {
        contract_changes: [],
        asc: false,
        desc: true,
        order: "desc",
        th: "alteracao_contrato.created_at",
        qtd_per_page: 8,
        date: str_date,
        project_report: "",
        area_report: "",
        status_report: "",
        filter_data: {value: '1', label: 'Data de lançamento'},
        from: dateFlux,
        to: str_date,
        fromFix: dateFlux,
        toFix: str_date,
        page: 1,
        coordinator_report: "",
        data_atual: str_date,
    },
    bills_to_pay: {
        bills_to_pay: [],
        asc: false,
        desc: true,
        order: "desc",
        th: "registro_venda.created_at",
        qtd_per_page: 8,
        date: str_date,
        project_report: "",
        area_report: "",
        status_report: "",
        typeBill: "",
        typeProjects: "",
        projectBill: "",
        valorTotal: 0,
        valorTotalContabil: 0,
        valorTotalCorrecao: 0,
        technical: "",
        from: dateFlux,
        to: str_date,
        fromFix: dateFlux,
        toFix: str_date,
        page: 1,
        coordinator_report: "",
        data_atual: str_date,
        competencia: competencia,
    },
    service_type: {
        service_type: [],
        asc: true,
        desc: false,
        order: "asc",
        th: "tipo_registro_venda.descricao",
        qtd_per_page: 10,
        page: 1,
    },
    reimbursement_type: {
        reimbursement_type: [],
        asc: true,
        desc: false,
        order: "asc",
        th: "tipos_reembolsos.descricao",
        qtd_per_page: 10,
        page: 1,
    },
    area: {
        areas: [],
        asc: true,
        desc: false,
        order: "asc",
        th: "area_projetos.descricao",
        qtd_per_page: 10,
        page: 1,
    },
    motive: {
        motives: [],
        asc: true,
        desc: false,
        order: "asc",
        th: "motive.descricao",
        qtd_per_page: 10,
        page: 1,
    },
};
const store = applyMiddleware(thunk, multi, promise)(createStore)(
    AllReducers,
    initialStates,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
