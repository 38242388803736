import { app } from './Api'

const get_view_empresa = async (id) => {
    return app.get(`empresas/${id}`)
}

const get_empresas = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`empresas?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const edit_empresa = async (id,data) => {
    return app.put(`empresas/${id}`,data)
}

const create_empresa = async (data) => {
    return app.post(`empresas`,data)
}

const changeStatus =  async (id,data) => {
    return app.put(`empresas/change_status/${id}`,data)
}

export { get_view_empresa, get_empresas, edit_empresa, create_empresa, changeStatus}