import React from 'react'
import { Link } from 'react-router-dom'

export default function Cabecalho(props) {

    const enterPressed = (event) => {
        var code = event.keyCode || event.which;
        if(code === 13) { //13 is the enter keycode
            props.action()
        } 
    }
    return (
        <>
            {props.children}
            <div className="col-sm-12 col-md-2">
                <div className="dataTables_length" id="example1_length">
                    <label> Visualizar  
                        <select style={{marginLeft: '5px'}} value={parseInt(props.value)} onChange={props.onChange} name="example1_length" aria-controls="example1" className="custom-select custom-select-sm form-control form-control-sm">
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select> Registros
                    </label>
                </div>
            </div>
            <div className="col-sm-12 col-md-10">
                <div id="example1_filter" className="dataTables_filter">
                    <label>
                        
                        {
                            props.display === 'reimbursement' || props.display === 'visit' ? 
                                props.report === false && 
                                    <>

                                        <input 
                                            onKeyPress={enterPressed} 
                                            value={props.valuePesquisa} 
                                            type="" 
                                            onChange={props.changePesquisa} 
                                            className="form-control form-control-sm" 
                                            aria-controls="example1"
                                        /> 
                                        <span style={{marginLeft: '-9%'}} className="fas fa-search"></span> 
                                    </>
                            :
                            <>

                                <input 
                                    onKeyPress={enterPressed} 
                                    value={props.valuePesquisa} 
                                    type="" 
                                    onChange={props.changePesquisa} 
                                    className="form-control form-control-sm" 
                                    aria-controls="example1"
                                /> 
                                <span style={{marginLeft: '-9%'}} className="fas fa-search"></span> 
                            </>
                            
                        }
                        
                    </label>
                    {props.buttonGerarPagamento}
                    {props.buttonChangeStatusSelected}
                    <Link to={props.to} onClick={props.action} style={{marginLeft:'25px'}} className="btn btn-outline-info btn-sm">Pesquisar</Link>
                    <Link to={props.to} onClick={props.clear} style={{marginLeft:'10px', marginRight:'10px'}} className="btn btn-outline-danger btn-sm">Limpar Busca</Link>
                    {props.buttonCsv}
                </div>
            </div>
            <div className="row" style={{display: 'inline-block'}}>
                <div className="col-md-12">        
                    {props.buttonStatus} 
                </div>
            </div>
        </> 
    )
}


// só msotrar botão de atualizar quando o array não estiver vazio
// remover botão de cadastro
// retornar mensagens de sucesso pra gerar/atualizar