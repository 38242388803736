import React from 'react';
import PropTypes from 'prop-types';

Table.propTypes = {
    tableStyle: PropTypes.object,
};
export default function Table(props) {
    const { tableStyle } = props;
    return (
        <section className='content'>
            <div className='row'>
                <div className='col-12'>
                    <div className=''>
                        <div className='card-header'>
                            <h3 className='card-title'>{props.title}</h3>
                            <div className='text-right'>
                                {props.button || ''}
                            </div>
                        </div>
                        {/* /.card-header */}
                        <div className='card-body'>
                            <div className='table-responsive' style={{ padding: '5px', ...tableStyle }}>

                                {props.component}
                                <div id='example1_wrapper' className='dataTables_wrapper dt-bootstrap4 responsive'>
                                    <div className='row'>
                                        {props.cabecalho}
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <table id='example2'
                                                   className={props.className || 'table table-bordered table-striped dataTable dtr-inline'}>
                                                {props.children}
                                            </table>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {props.view}
                                        {props.pagination}
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* /.card-body */}
                    </div>
                    {/* /.card */}
                </div>
                {/* /.col */}
            </div>
            {/* /.row */}
        </section>
    );
}