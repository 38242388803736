import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import Input from '../../../Forms/Input'
import { useSelector, useDispatch } from 'react-redux'
import { getTechnicalReport, getProjectsReport, getType, getStatusReport } from '../../../Services/reimbursement'

export default function ReportReimbursementShared(props) {
    const technical_report = useSelector(state => state.reimbursement_shared.technical_report)
    const project_report = useSelector(state => state.reimbursement_shared.project_report) 
    const type_report = useSelector(state => state.reimbursement_shared.type_report)  
    const status_report = useSelector(state => state.reimbursement_shared.status_report)
    const from = useSelector(state => state.reimbursement_shared.from) 
    const to = useSelector(state => state.reimbursement_shared.to)
    const perfis                          = useSelector(state => state.auth.user.perfis) || []
    const dispatch = useDispatch()
    const [ technical, setTechnical ] = useState([])
    const [ projects, setProjects ] = useState([])
    const [ type, setType ] = useState([])
    const [ status, setStatus ] = useState([])
    
    useEffect(() => {
        Technical(project_report.value);
        return () => {
            Technical()
        }
        
    },[project_report.value])

    useEffect(() => {
        Projects(technical_report.value);
        return () => {
            Projects()
        }
        
    },[technical_report.value])

    useEffect(() => {
        StatusReimbursement();
        return () => {
            StatusReimbursement()
        }
        
    },[])

    useEffect(() => {
        TypeReimbursement();
        return () => {
            TypeReimbursement()
        }
        
    },[])

    function Technical(id_project) {
        getTechnicalReport(parseInt(id_project) > 0 ? parseInt(id_project) : '')
        .then(resp => {
            setTechnical([
                {value:'',label:'-- SELECIONE --'},
                ...resp.data.map(desc => ({
                    value: desc.id,
                    label: desc.name,
                }))
            ]
                // resp.data.map(desc => ({
                //     value: desc.id,
                //     label: desc.name,
                // })).concat({
                //     value:'',
                //     label:'-- VAZIO --'
                // })         
            )
        })
    }

    function Projects(id_technical) {
        getProjectsReport(parseInt(id_technical) > 0 ? parseInt(id_technical) : '')
        .then(resp => {
            setProjects(perfis.indexOf(12) >=0 ? [
                {value:'',label:'-- SELECIONE --'},
                ...resp.data.projetos.map(desc => ({
                    value: desc.id,
                    label: desc.titulo,
                }))
            ] : [
                {value:'',label:'-- SELECIONE --'},
                ...resp.data.projetos.concat(resp.data.projetosEnsino).map(desc => ({
                    value: desc.id,
                    label: desc.titulo,
                }))
            ])
        })
    }

    function TypeReimbursement() {
       
        getType()
        .then(resp => {
            setType(
                resp.data.map(desc => ({
                    value: desc.id,
                    label: desc.descricao,
                }))          
            )
        })
    }

    function StatusReimbursement() {
       
        getStatusReport()
        .then(resp => {
            setStatus([
                {value:'',label:'-- SELECIONE --'},
                ...resp.data.map(desc => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            ]
                // resp.data.map(desc => ({
                //     value: desc.id,
                //     label: desc.descricao,
                // })).concat({
                //     value:'',
                //     label:'-- VAZIO --'
                // })        
            )
        })
    }

    const changeTechnical = e => {
        return[
            dispatch({type:'changeTechnicalReportShared', payload:e}),
            Projects(e.value)
        ]
    }

    const changeProject = e => {
        return[
            dispatch({type:'changeProjectReportShared', payload:e}),
            Technical(e.value)
        ]
    }
    
    return (
        <>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <label>Técnico</label>
                        <Select 
                            value={technical_report}
                            label="Single select"
                            options={technical}
                            onChange={changeTechnical}             
                        /> 
                    </div>
                    <div className="col-md-12 mt-3">
                        <label>Projeto</label>
                        <Select 
                            value={project_report}
                            label="Single select"
                            options={projects}
                            onChange={changeProject}                      
                        /> 
                    </div>
                    <div className="col-md-12 mt-3">
                        <label>Tipo</label>
                        <Select 
                            value={type_report}
                            label="Single select"
                            options={type}
                            onChange={(e) => dispatch({type:'changeTypeReportShared', payload:e})}             
                        /> 
                    </div>   
                </div>
                <div className="row mt-3 mb-4">
                    <div className="col-md-12 mt-3">
                        <label>Status</label>
                        <Select 
                            value={status_report}
                            label="Single select"
                            options={status}
                            onChange={(e) => dispatch({type:'changeStatusReportShared', payload:e})}   
                        /> 
                    </div>
                    <div className="col-md-12 mt-3">     
                        <Input 
                            label="De"
                            type="date"
                            icon="fas fa-calendar"
                            onChange={(e) => dispatch({ type: 'changeFromReportShared', payload:e.target.value })}
                            value={from}
                        />
                    </div>
                    <div className="col-md-12 mt-3"> 
                        <Input 
                            label="Até"
                            type="date"
                            icon="fas fa-calendar"
                            onChange={(e) => dispatch({ type: 'changeToReportShared', payload:e.target.value })}
                            value={to}
                        />
                    </div>   
                </div>
            </div>
        </>
    )
}
