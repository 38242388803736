import { get_refunds } from '../../../../Services/reimbursement'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[
        
        (dispatch,getState)=>{
        const order = getState().reimbursement.order
        const th = getState().reimbursement.th
        const qtd_per_page = getState().reimbursement.qtd_per_page
        const pesquisa = getState().reimbursement.pesquisa || ''
        const id_technical = getState().reimbursement.technical_report.value || ''
        const id_coordinator = getState().reimbursement.coordinator_report.value || ''
        const id_project = getState().reimbursement.project_report.value || ''   
        const id_type = getState().reimbursement.type_report.value || ''  
        const id_status = getState().reimbursement.status_report.value || ''
        const from = getState().reimbursement.from
        const to = getState().reimbursement.to 
        const page = getState().reimbursement.page
        const id_reimbursement_shared = getState().reimbursement.id_reimbursement_shared     
      
        get_refunds(page,qtd_per_page,order,th,pesquisa,id_technical,id_coordinator,id_project,id_type,id_status,from,to,id_reimbursement_shared)
        .then(resp=>dispatch({type:'Refunds', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().reimbursement.asc
      
        if(asc === true){
            dispatch({type:'GetReimbursement_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetReimbursement_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_reimbursement',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaReimbursement',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[(dispatch, getState) =>{
        const fromFix = getState().reimbursement.fromFix
        const toFix = getState().reimbursement.toFix
        dispatch({
            type:'clearPesquisaReimbursement',
            payload:'',
            technical_report: '',
            coordinator_report: '',
            project_report: '' ,  
            type_report: '' , 
            status_report: '',
            from: fromFix ,
            to: toFix,
            id_reimbursement_shared: ''       
        })
    },get(1)]
}

export const changeTechnicalReport = event => {
    return[{
        type:'changeTechnicalReport',
        payload: event
    }]
}

export const changeProjectReport = event => {
    return[{
        type:'changeProjectReport',
        payload: event
    }]
}

export const changeTypeReport = event => {
    return[{
        type:'changeTypeReport',
        payload: event
    }]
}

export const changeStatusReport = event => {
    return[{
        type:'changeStatusReport',
        payload: event
    }]
}

export const changeFromReport = event => {
    return[{
        type:'changeFromReport',
        payload: event.target.value
    }]
}

export const changeToReport = event => {
    return[{
        type:'changeToReport',
        payload: event.target.value
    }]
}

export const changePage = page => {
    return[{
        type: 'changePageReimbursement',
        payload: page
    },get()]
}

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().reimbursement.pesquisa || ''
            const id_technical = getState().reimbursement.technical_report.value || ''
            const id_project = getState().reimbursement.project_report.value || ''   
            const id_type = getState().reimbursement.type_report.value || ''  
            const id_status = getState().reimbursement.status_report.value || ''
            const from = getState().reimbursement.from
            const to = getState().reimbursement.to
            if(pesquisa || id_technical || id_project || id_type || id_status || from || to){
                return dispatch({type:'changePageReimbursement', payload:1})
            }
        }, get()
    ]
}