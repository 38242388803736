import { app } from './Api'

const getResource = async() => {
    return app.get('project_amendment/get_resource')
}

const postProjectAmendment = async (data) => {
    return app.post(`project_amendment`, data)
}

const putProjectAmendment = async (id,data) => {
    return app.post(`project_amendment/${id}`,data)
}

const getProjectAmendment = async (page,qtd_per_page,order,th,pesquisa,id_coordinator,id_area,project,id_status,from,to, id_data) => {
    return app.get(`project_amendment?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&id_coordinator=${id_coordinator}&id_area=${id_area}&id_status=${id_status}&id_project=${project}&from=${from}&to=${to}&id_data=${id_data}`)
}

const exportExcelCSV = async (page,qtd_per_page,order,th,pesquisa,id_coordinator,id_area,project,id_status,from,to) => {
    return app.get(`project_amendment/export_excel?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&id_coordinator=${id_coordinator}&id_area=${id_area}&id_status=${id_status}&id_project=${project}&from=${from}&to=${to}`)
}

const getViewProjectAmendment = async (id) => {
    return app.get(`project_amendment/${id}`)
}

const getViewPerProject = async (id) => {
    return app.get(`project_amendment/get_per_project/${id}`)
}

const delProjectAmendment = async (id) => {
    return app.delete(`project_amendment/${id}`)
}

const changeStatus =  async (id,data) => {
    return app.put(`change_project_amendment/${id}`,data)
}

const getClient =  async () => {
    return app.get(`project_amendment/get_client`)
}

const getCity =  async (idState) => {
    return app.get(`project_amendment/get_city/${idState}`)
}

const delAttachment = async (id) => {
    return app.delete(`project_amendment/del_attachment/${id}`)
}

const getAreaManager = async (idArea) => {
    return app.get(`project_amendment/manager_by_id_area/${idArea}`);
};

export { getResource, 
         delAttachment, 
         postProjectAmendment, 
         putProjectAmendment, 
         getProjectAmendment, 
         getViewProjectAmendment, 
         delProjectAmendment, 
         changeStatus, 
         getClient, 
         getCity, 
         getViewPerProject, 
         exportExcelCSV,
         getAreaManager
}