import styled from 'styled-components';

export const Container = styled.div`
    a{

        color: '#FFF';
        p{
            color: white;
        }
        i{
            color: white;
        }
    }
    a:hover{
        background: linear-gradient(270.37deg, rgba(17, 237, 131, 0.63) 19.87%, rgba(30, 174, 79, 0.08) 92.73%);
      
    }
    .nav-link.active {
        background: linear-gradient(270.37deg, rgba(17, 237, 131, 0.63) 19.87%, rgba(30, 174, 79, 0.08) 92.73%);
        color: #fff;
    }

`