import React, {useState, useEffect} from 'react'
import Table from '../../../Table/Table'
import Thead from '../../../Table/Thead'
import Tbody from '../../../Table/Tbody'
import { alterPagamento, changeStatusPag, getPagamentos } from '../../../Services/project'
import Pagamentos from './Pagamentos'
import Alert from '../../../Alerts/Alert'

export default function TablePagamento(props) {
    const [ pagamentos, setPagamentos ] = useState([])
    const [ editPagamento, setEditPagamento ] = useState([])
    const [ alert, setAlert ] = useState('')
    const [ success, setSuccess ] = useState('')

    useEffect(() => {
        getAllPagamentos()
        
    }, [])

    function getAllPagamentos(){
        getPagamentos(props.match.params.id)
        .then(resp => setPagamentos(resp.data.pagamentos))
    }

    const editLine = (id) => {
        let compoments = document.getElementsByName(`${id}-edit`)
        compoments.forEach(desc => {
            desc.removeAttribute("disabled");
        })
        let compAction = document.getElementsByName(`btn-action`)
        compAction.forEach(desc => {
            desc.style.display = 'none'
        })
        let compClose = document.getElementsByName(`btn-action-close`)
        compClose.forEach(desc => {
            if(desc.getAttribute('id') === id){
                desc.style.display = 'inline-block'
            }
        })
        let compSave = document.getElementsByName(`btn-action-save`)
        compSave.forEach(desc => {
            if(desc.getAttribute('id') === id){
                desc.style.display = 'inline-block'
            }
        })
        setEditPagamento(pagamentos.filter(desc => desc.codigo_parcela === id))
    }

    const actionClose = (id) => {
        let compoments = document.getElementsByName(`${id}-edit`)
        compoments.forEach(desc => {
            desc.setAttribute("disabled", "disabled");
        })
        let compAction = document.getElementsByName(`btn-action`)
        compAction.forEach(desc => {
            desc.style.display = 'block'
        })
        let compClose = document.getElementsByName(`btn-action-close`)
        compClose.forEach(desc => {
            if(desc.getAttribute('id') === id){
                desc.style.display = 'none'
            }
        })
        let compSave = document.getElementsByName(`btn-action-save`)
        compSave.forEach(desc => {
            if(desc.getAttribute('id') === id){
                desc.style.display = 'none'
            }
        })
        setEditPagamento([])
    }

    const handleChange = e => {
        const id = e.target.id
        let pagamentoChange = editPagamento
        pagamentoChange[0][`${id}`] = id === 'valor_parcela' ? parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')) : e.target.value
        setEditPagamento(pagamentoChange)
    }

    const actionSave = e => {
        e.preventDefault();
        alterPagamento(editPagamento[0])
        .then(resp=> {
            setAlert('')
            setSuccess(resp.data.success)
            actionClose(editPagamento[0].codigo_parcela)  
        })
        .then(resp => {
            getAllPagamentos(props.match.params.id)
            setTimeout(() => {
                setSuccess('')
            },4000)
        })
        .catch(e => {
            setSuccess('')
            setAlert(e.response.data.errorSubmit)  
            setTimeout(() => {
                setAlert('')
            },4000)
        })            

    }

    const changeStatusPagamento = (codigoParcela, status) => {
        const data = {
            codigo_parcela: codigoParcela,
            status: status
        }
        changeStatusPag(data)
        .then(resp=> {
            setAlert('')
            setSuccess(resp.data.success)
        })
        .then(resp => {
            getAllPagamentos(props.match.params.id)
            setTimeout(() => {
                setSuccess('')
            },4000)
        })
        .catch(e => {
            setSuccess('')
            setAlert(e.response.data.errorSubmit)  
            setTimeout(() => {
                setAlert('')
            },4000)
        })            
    }

    return (
        <>
            {
                success && <Alert type="success">{success}</Alert>
            }
            {
                alert && <Alert type="danger">{alert}</Alert>
            }
            <Table 
                className="table" 
                // title={props.title}
            >   
                <Thead>
                    <th className="text-center">CÓDIGO PARCELA</th>
                    <th className="text-center">DATA VENCIMENTO</th>
                    <th className="text-center">VALOR</th>
                    <th className="text-center">DATA FATURAMENTO</th>
                    <th className="text-center">DATA PAGAMENTO</th>
                    <th className="text-center">STATUS APURAÇÃO</th>
                    <th className="text-center">AÇÃO</th>
                </Thead>
                <Tbody> 
                    <Pagamentos pagamentos={pagamentos} editLine={editLine} actionClose={actionClose} handleChange={handleChange} actionSave={actionSave} changeStatusPagamento={changeStatusPagamento} />
                </Tbody>
                {/* <tr>
                    <th>Total: {props.total}</th>
                </tr> */}
            
            </Table>
    
        </>
    )
}
