import React from 'react'
import "react-toggle/style.css"

export default function Products({ product, formatReal, handleDeleteProduct, isDisabled }) {
    const products = product || []
    
    return products.map((desc) =>(
        <tr key={desc.id}>
            <td className="text-center">{desc.nameProduct}</td>
            <td className="text-center">R$ {formatReal(parseFloat(`${desc.value}`))}</td>
            <td className="text-center">
                <button disabled={isDisabled} type="button" className="btn btn-danger" onClick={() => handleDeleteProduct(desc.id)}><span className="fas fa-minus"></span></button>
            </td>
        </tr>
        
    ))  
}
