import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard';
import { ButtonAction } from '../../../Button/style';
import { Link } from 'react-router-dom';
import AprovedIcon from '../../../Icons/emojione-v1_ballot-box-bold-check.png';
import ReprovedIcon from '../../../Icons/fluent_document-footer-remove-24-filled.png';
import IconAnalysys from '../../../Icons/cib_anaconda.png';

export default function Visits(props) {
    const registers = useSelector(state => state.visit.visits.data) || [];
    const id_role = useSelector(state => state.auth.user.id) || '';
    const perfis = useSelector(state => state.auth.user.perfis) || [];
    const status = registers.map(desc => (
        desc.id_status
    ));

    //Função que verifica se o usuário tem permissão para acessar a edição do registro
    function permissionRole() {
        if (perfis.length === 1 && perfis.indexOf(10) >= 0) {
            return props.history.push('/index');
        }
    }

    useEffect(() => {
        permissionRole();
        return () => {
            permissionRole();
        };
    }, []);

    function todosIguaisOuDiferentes(states, id, gerente, coordenador) {
        var filtrado = states.filter(function(elem, pos, arr) {
            return parseInt(arr.indexOf(elem)) === parseInt(pos);
        });
        if (filtrado.length === 1) {
            if (perfis.indexOf(7) >= 0) {
                if (parseInt(id_role) === parseInt(gerente) || parseInt(id_role) === parseInt(coordenador)) {
                    return (
                        <label className='chk'>
                            <input className='input-check' style={{ width: '30px', height: '30px' }} name='checked'
                                   type='checkbox' id='checked' onClick={props.check} value={id} />
                            <span>
                                
                            </span>
                        </label>

                    );


                } else {
                    return (
                        <label className='chk'>
                            <input className='input-check' style={{ width: '30px', height: '30px' }} disabled='true'
                                   type='checkbox' />
                            <span></span>
                        </label>
                    );

                }
            } else {
                return (
                    <label className='chk'>
                        <input className='input-check' style={{ width: '30px', height: '30px' }} name='checked'
                               type='checkbox' id='checked' onClick={props.check} value={id} />
                        <span></span>
                    </label>
                );

            }

        }
    }

    const visit = props.visits || [];
    return visit.map(desc => (
        <div key={desc.id} className='col-md-3 mt-4 mt-4'>
            <RecipeReviewCard
                id_status={desc.id_status}
                title={desc.name || desc.tecnico_cobranca_extra}
                visit={desc.limite}
                actionRoute={props.action}
                selectStatus={

                    perfis.indexOf(1) >= 0 || perfis.indexOf(2) >= 0 || perfis.indexOf(3) >= 0 || perfis.indexOf(4) >= 0 || perfis.indexOf(6) >= 0 || perfis.indexOf(8) >= 0 ? (

                        todosIguaisOuDiferentes(status, desc.id, desc.gerente, desc.coordenador)
                    ) : ''

                }
                rel={
                    <>
                        <h3 style={{ fontSize: '15px', fontWeight: 700 }}>Descrição</h3>
                        <hr></hr>
                        {
                            desc.descricao
                        }
                        <hr></hr>
                        {desc.id_status === 3 && (
                            <>
                                <h3 style={{ fontSize: '15px', fontWeight: 700 }}>Motivo Reprovação</h3>
                                <hr></hr>
                                <p>{desc.motivo_reprovacao}</p>
                            </>
                        )}
                    </>
                }
                actions={
                    <>
                        <ul className='nav'>
                            <li className='nav-item dropdown'>
                                <a className='nav-link' data-toggle='dropdown' href='#/'>
                                    <i style={{ color: '#FFF', width: '30px', height: '30px' }}
                                       className='fas fa-bars' />
                                </a>
                                <div className='dropdown-menu dropdown-menu-right'>

                                    <Link
                                        to={`/visit/${desc.id}/view`}
                                        className='dropdown-item'
                                    >
                                        <i className='fas fa-eye' /> Detalhes
                                    </Link>
                                    {
                                        desc.id_status === 1 && parseInt(desc.created_by) === parseInt(id_role) && (
                                            <>
                                                <Link
                                                    to={`/visit/${desc.id}/edit`}
                                                    className='dropdown-item'
                                                >
                                                    <i className='fas fa-edit' /> Editar
                                                </Link>
                                                <Link
                                                    to='#/'
                                                    className='dropdown-item'
                                                    onClick={() => props.onDelete(desc.id)}
                                                >
                                                    <i className='fas fa-trash-alt' /> Excluir
                                                </Link>
                                            </>
                                        )
                                    }
                                </div>
                            </li>
                        </ul>

                        {/* <ButtonAction>
                            <button 
                                    onClick={(e) => {e.preventDefault();props.history.push(`/visit/${desc.id}/view`);e.preventDefault()}} 
                                    className='btn'
                                >
                                    <img style={{width: '20px', height: '20px'}} src={IconView}></img>
                            </button>
                        </ButtonAction>
                        
                        {desc.id_status === 1 && parseInt(desc.created_by) === parseInt(id_role) && (
                            <>
                                <ButtonAction>
                                    <button 
                                        onClick={(e) => {e.preventDefault();props.history.push(`/visit/${desc.id}/edit`);e.preventDefault()}} 
                                        className='btn' 
                                    >
                                        <img style={{width: '20px', height: '20px'}} src={IconEdit}></img>
                                    </button>
                                </ButtonAction>
                                <ButtonAction>
                                <button 
                                    type="button" 
                                    className='btn' 
                                    onClick={()=>props.onDelete(desc.id)} 
                                >
                                    <img style={{width: '20px', height: '20px'}} src={IconDel}></img>
                                </button>                                
    
                                </ButtonAction>
                            </>
                        )} */}
                    </>


                }
                actionsStatus={
                    props.action !== 'view' ?
                        perfis.indexOf(1) >= 0 || perfis.indexOf(2) >= 0 || perfis.indexOf(3) >= 0 || perfis.indexOf(4) >= 0 || perfis.indexOf(6) >= 0 || perfis.indexOf(8) >= 0 || perfis.indexOf(12) >= 0 ?
                            (
                                perfis.indexOf(7) >= 0 ? (
                                        parseInt(id_role) === parseInt(desc.gerente) || parseInt(id_role) === parseInt(desc.coordenador) ? (
                                            desc.id_status === 1 ? (
                                                <>
                                                    <ButtonAction backgroundButton='#1F9950'>
                                                        <button
                                                            type='button' tooltip='Aprovar'
                                                            className='btn'
                                                            onClick={() => props.changeStatusVisit(desc.id, 2)}
                                                        >
                                                            <img style={{ width: '25px', height: '25px' }} src={AprovedIcon}
                                                                 alt='Aprovado'></img>
                                                        </button>
                                                    </ButtonAction>
                                                    <ButtonAction backgroundButton='#F6525C'>
                                                        <button
                                                            type='button' tooltip='Reprovar'
                                                            className='btn'
                                                            onClick={() => props.modalReprovedOn(desc.id, 3)}
                                                        >
                                                            <img style={{ width: '25px', height: '25px' }}
                                                                 src={ReprovedIcon} alt='Reprovado'></img>
                                                        </button>
                                                    </ButtonAction>
                                                </>

                                            ) : desc.id_status === 2 ? (

                                                <>
                                                    <ButtonAction backgroundButton='#F0E70C'>

                                                        <button
                                                            type='button' tooltip='Em análise'
                                                            className='btn'
                                                            onClick={() => props.changeStatusVisit(desc.id, 1)}
                                                        >
                                                            <img style={{ width: '25px', height: '25px' }}
                                                                 src={IconAnalysys} alt='Analise'></img>
                                                        </button>
                                                    </ButtonAction>
                                                    <ButtonAction backgroundButton='#F6525C'>

                                                        <button
                                                            type='button' tooltip='Reprovar'
                                                            className='btn'
                                                            onClick={() => props.modalReprovedOn(desc.id, 3)}
                                                        >
                                                            <img style={{ width: '25px', height: '25px' }}
                                                                 src={ReprovedIcon} alt='Reprovado'></img>
                                                        </button>
                                                    </ButtonAction>
                                                </>

                                            ) : desc.id_status === 3 ? (
                                                <>
                                                    <ButtonAction backgroundButton='#F0E70C'>

                                                        <button
                                                            type='button' tooltip='Em análise'
                                                            className='btn'
                                                            onClick={() => props.changeStatusVisit(desc.id, 1)}
                                                        >
                                                            <img style={{ width: '25px', height: '25px' }}
                                                                 src={IconAnalysys} alt='Analise'></img>
                                                        </button>
                                                    </ButtonAction>
                                                    <ButtonAction backgroundButton='#1F9950'>

                                                        <button
                                                            type='button' tooltip='Aprovar'
                                                            className='btn'
                                                            onClick={() => props.changeStatusVisit(desc.id, 2)}
                                                        >
                                                            <img style={{ width: '25px', height: '25px' }} src={AprovedIcon}
                                                                 alt='Aprovado'></img>
                                                        </button>
                                                    </ButtonAction>
                                                </>

                                            ) : ('')
                                        ) : ('')
                                    ) :
                                    desc.id_status === 1 ? (
                                        <>
                                            <ButtonAction backgroundButton='#1F9950'>

                                                <button
                                                    type='button' tooltip='Aprovar'
                                                    className='btn'
                                                    onClick={() => props.changeStatusVisit(desc.id, 2)}
                                                >
                                                    <img style={{ width: '25px', height: '25px' }} src={AprovedIcon}
                                                         alt='Aprovado'></img>
                                                </button>
                                            </ButtonAction>
                                            <ButtonAction backgroundButton='#F6525C'>

                                                <button
                                                    type='button' tooltip='Reprovar'
                                                    className='btn'
                                                    onClick={() => props.modalReprovedOn(desc.id, 3)}
                                                >
                                                    <img style={{ width: '25px', height: '25px' }} src={ReprovedIcon}
                                                         alt='Reprovado'></img>
                                                </button>
                                            </ButtonAction>
                                        </>

                                    ) : desc.id_status === 2 ? (

                                        <>
                                            <ButtonAction backgroundButton='#F0E70C'>
                                                <button
                                                    type='button' tooltip='Em análise'
                                                    className='btn'
                                                    onClick={() => props.changeStatusVisit(desc.id, 1)}
                                                >
                                                    <img style={{ width: '25px', height: '25px' }} src={IconAnalysys}
                                                         alt='Analise'></img>
                                                </button>
                                            </ButtonAction>
                                            <ButtonAction backgroundButton='#F6525C'>

                                                <button
                                                    type='button' tooltip='Reprovar'
                                                    className='btn'
                                                    onClick={() => props.modalReprovedOn(desc.id, 3)}
                                                >
                                                    <img style={{ width: '25px', height: '25px' }} src={ReprovedIcon}
                                                         alt='Reprovado'></img>
                                                </button>
                                            </ButtonAction>
                                        </>

                                    ) : desc.id_status === 3 ? (

                                        <>
                                            <ButtonAction backgroundButton='#F0E70C'>
                                                <button
                                                    type='button' tooltip='Em análise'
                                                    className='btn'
                                                    onClick={() => props.changeStatusVisit(desc.id, 1)}
                                                >
                                                    <img style={{ width: '25px', height: '25px' }} src={IconAnalysys}
                                                         alt='Analise'></img>
                                                </button>
                                            </ButtonAction>
                                            <ButtonAction backgroundButton='#1F9950'>
                                                <button
                                                    type='button' tooltip='Aprovar'
                                                    className='btn'
                                                    onClick={() => props.changeStatusVisit(desc.id, 2)}
                                                >
                                                    <img style={{ width: '25px', height: '25px' }} src={AprovedIcon}
                                                         alt='Aprovado'></img>
                                                </button>
                                            </ButtonAction>
                                        </>

                                    ) : ('')
                            ) : ''
                        : ''}

            >
                <p style={{ height: '40px' }}>
                    <span style={{ height: '40px', fontWeight: 700 }}>
                        {desc.id_cobranca_extra ?
                            <>
                                <span>Serviço Extra:</span>
                                <a href='#/' onClick={(e) => {
                                    props.history.push(`/extra_charge/${desc.id_cobranca_extra}/view`);
                                    e.preventDefault();
                                }}>{desc.cobranca_extra}</a>
                            </>
                            :
                            <>
                                <span>Projeto:</span>
                                <a href='#/' onClick={(e) => {
                                    props.history.push(`/project/${desc.id_projeto}/view`);
                                    e.preventDefault();
                                }}>{desc.titulo}</a>
                            </>
                        }
                        {/* Projeto: <a href="#/" onClick={(e) => {props.history.push(`/project/${desc.id_cobranca_extra ? desc.id_projeto_cobranca : desc.id_projeto}/view`);e.preventDefault()}}>{desc.titulo || desc.titulo_projeto_cobranca}</a> */}
                    </span>

                </p>

                {/* <p style={{height: '30px'}}>
                    <span style={{height: '40px', fontWeight: 700}}>Projeto :</span> <span>{desc.titulo}</span>
                </p> */}
                <p><span style={{ height: '40px', fontWeight: 700 }}>Coordenador :</span>
                    <span>{desc.coordenador_name || desc.coordenador_name_cobranca}</span></p>
                <p><span style={{ height: '40px', fontWeight: 700 }}>Tipo de Remuneração :</span>
                    <span>{desc.descRemuneracao || desc.remuneracao_cobranca}</span></p>
                {desc.id_tipo === 3 || desc.id_tipo === 11 || desc.id_tipo === 12 ?
                    <p><span style={{ height: '40px', fontWeight: 700 }}>Km :</span> <span>{`${desc.km} km`}</span>
                    </p> : ''}
                <p><span style={{ height: '40px', fontWeight: 700 }}>Valor :</span>
                    {/* <span>{`R$${props.formatReal(parseFloat(desc.id_cobranca_extra ? desc.valor_cobranca_extra : desc.valor))}`}</span> */}
                    <span>{`R$${desc.id_cobranca_extra ? desc.valor_cobranca_extra : desc.valor}`}</span>
                </p>
                <p><span style={{ height: '40px', fontWeight: 700 }}>Data de Lançamento :</span>
                    <span>{desc.data_lancamento}</span></p>
                <p><span style={{ height: '40px', fontWeight: 700 }}>Data da Despesa :</span>
                    <span>{desc.data_despesa}</span></p>
                <p><span style={{ height: '40px', fontWeight: 700 }}>Data da Avaliação :</span>
                    <span>{desc.data_avaliacao}</span></p>
            </RecipeReviewCard>
        </div>

    ));


}
