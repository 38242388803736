import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import Footer from '../../../Footer/Footer'
import Table from '../../../Table/Table'
import Thead from '../../../Table/Thead'
import Tbody from '../../../Table/Tbody'
import Tfoot from '../../../Table/Tfoot'
import StylePage from '../../../Style Page/StylePage'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { pagination } from '../../../Pagination/paginations'
import {Link} from 'react-router-dom'
import Pagination from '../../../Table/Pagination'
import Cabecalho from '../../../Table/Cabecalho'
import Alert from '../../../Alerts/Alert'
import MyModal from '../../../Modal/MyModal'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import Customers from './Customers'
import { CSVLink } from 'react-csv';
import { changeStatus, del_client, exportExcel } from '../../../Services/client'
import { 
    search, 
    changePage, 
    get, 
    order, 
    change_qtd_per_page, 
    changePesquisa, 
    clearPesquisa 
} from './Actions/actions'

function GridClient(props) {

    const [ loading, setLoading ] = useState(false);
    const [ modal, setModal ]     = useState(false);
    const [ id, setId ]           = useState('');
    const [ success, setSuccess ] = useState('');
    const [ error, setError ]     = useState('');
    const [clientesExcel, setClientesExcel] = useState([]);
    const [headers, setHeaders] = useState('');
    const cpf_cnpj                = 'cpf_cnpj'
    const email                   = 'email'
    const nome                    = 'nome'
    const fileUpload = useRef();

    useEffect(() => {
        getClients();
        return () => {
            getClients()
        }
        
    },[])

    //Função que tra todos os clientes cadastrados no banco de dados
    const getClients = () => {
        props.get();
    }
    
    //Função que altera o status de ativo do cliente
    const changeStatusActive = (event) =>{
        const id = event.target.id
        let checked = event.target.checked === true ? 1 : 0
        const data = {
            ativo:checked,     
        }
        changeStatus(id, data)  
        .then(resp => getClients())
        .catch(resp => getClients())
    }
     
    //Função que muda o estado do modal para true, e definindo o id de qual registro será excluído permitindo a abertura do mesmo
    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
        
    }

    //Função que muda o status do modal para false, permitindo o fechamento do mesmo
    const handleCloseExcluir = event => {
        setModal(false);
        setId('');
        
    }

    const downloadExcel = (event) => {
        let page = 1;
        setHeaders([
            { label: 'id_cliente', key: 'value.id' },
            { label: 'pais', key: 'value.pais' },
            { label: 'codigo_w3', key: 'value.codigo_w3' },
            { label: 'razao_social', key: 'value.nome' },
            { label: 'codigo_cliente_senior', key: 'value.codigo_cliente_senior' },
            { label: 'email', key: 'value.email' },
            { label: 'telefone', key: 'value.telefone' },
            { label: 'inscricao_estadual', key: 'value.inscricao_estadual' },
            { label: 'cpf_cnpj', key: 'value.cpf_cnpj' },
            { label: 'cep', key: 'value.cep' },
            { label: 'logradouro', key: 'value.logradouro' },
            { label: 'numero', key: 'value.numero' },
            { label: 'complemento', key: 'value.complemento' },
            { label: 'bairro', key: 'value.bairro' },
            { label: 'cidade', key: 'value.cidade' },
            { label: 'estado', key: 'value.estado' },
            { label: 'principal_atividade', key: 'value.principal_atividade' },
            { label: 'exploracao_atividade', key: 'value.exploracao_atividade' },
            { label: 'imoveis_rurais_registrados_em', key: 'value.imoveis_rurais_registrados_em' },
            { label: 'quantidade_geracoes_atuantes', key: 'value.quantidade_geracoes_atuantes' },
        ]);

        exportExcel(
            page,
            props.qtd_per_page,
            props.order_var,
            props.th,
            props.pesquisa ? props.pesquisa : '',
        )
            .then((resp) => {
                let dados = resp.data.map((value) => {
                    return {
                        value: value.value,
                        // data_base: value.data_base,
                    };
                });
                setClientesExcel(dados);
                // console.log(dados);
            })
            .then((resp) => {
                // console.log(refunds)
                fileUpload.current.link.click();
            }).catch(e => {
                setError(e.response.data.error)
                setSuccess('')
                setTimeout(()=> {
                    setError('')
                },4000)
            
            });
    };

    //Função que deleta o registro
    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false)
        
        del_client(id)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
        
        })
        .then(resp => (
            setTimeout(()=> {
                setSuccess('')
            },4000)

        ))
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (props.get()))
        // .then(resp => {setState({success:resp.data.success, error:''})})

    }

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage>

                {
                    loading === true ? (
                        <Content>
                            <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                        </Content>
                        
                    ):('')
                }
                {
                    success ? (
                        <Alert type="success">{success}</Alert>
                    ):("")
                }
                {
                    error ? (
                        <Alert type="danger">{error}</Alert>
                    ):("")
                }
                <MyModal
                    show={modal}
                    onHide={handleCloseExcluir}
                    title="Excluir Cliente"
                    content="Tem certeza que deseja excluir o cliente ?"
                    cancel={handleCloseExcluir}
                    del={handleExcluir}     
                    to="/clients"
                    type="danger"
                    textButton="Excluir"                   
                />
                <div style={{display: loading === true ? ('none'):('block')}}>

                    <Table 
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                    Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                                </div>
                            </div>
                        }
                            
                        cabecalho={
                            
                            <Cabecalho 
                                to="#/" 
                                action={props.search} 
                                clear={props.clearPesquisa} 
                                valuePesquisa={props.pesquisa} 
                                value={props.qtd_per_page} 
                                onChange={props.change_qtd_per_page} 
                                changePesquisa={props.changePesquisa} 
                                buttonCsv={
                                    <>
                                        <button
                                            style={{
                                                background: '#005F31',
                                                color: '#FFF',
                                                borderRadius: '20.5px',
                                                width: '140px',
                                            }}
                                            className="btn btn-sm"
                                            // onClick={download}
                                            onClick={downloadExcel}
                                        >
                                            <i className="fas fa-file-csv"></i>{' '}
                                            Exportar CSV
                                        </button>
                                        <CSVLink
                                            filename={`Relatorio-${new Date()}.csv`}
                                            headers={headers}
                                            hidden={true}
                                            separator={';'}
                                            ref={fileUpload}
                                            data={clientesExcel || []}
                                        >
                                            <span className="fas fa-file-csv">
                                                {' '}
                                                Exportar CSV
                                            </span>
                                        </CSVLink>
                                    </>
                                }
                            />
   
                        }
                        pagination={
                            <Pagination>
                                {
                                    props.pagination(props.current_page,props.get,props.last_page,props.changePage)
                                }
                            </Pagination>
                        } 
                        title="Clientes"
                        button={
                            props.perfis.indexOf(1) >= 0 || props.perfis.indexOf(2) >= 0 || props.perfis.indexOf(3) >= 0 || props.perfis.indexOf(6) >= 0 || props.perfis.indexOf(10) >= 0 ? (
                                <Link to="/new_client" className="btn btn-primary">Cadastro</Link>
                            ):''
                            
                        }>
                        <Thead>
                            <th>STATUS</th> 
                            <th>
                                CPF/CNPJ
                                <Link 
                                    to="/customers" 
                                    style={{float:'right'}} 
                                    onClick={()=>props.order(cpf_cnpj)}
                                >
                                    <i className={`right fas fa-sort-amount-${props.th === 'cpf_cnpj' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i>
                                </Link>
                            </th>
                            <th>
                                RAZÃO SOCIAL
                                <Link 
                                    to="/customers" 
                                    style={{float:'right'}} 
                                    onClick={()=>props.order(nome)}
                                >
                                    <i className={`right fas fa-sort-amount-${props.th === 'nome' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i>
                                </Link>
                            </th>
                            <th>
                                EMAIL
                                <Link 
                                    to="/customers" 
                                    style={{float:'right'}} 
                                    onClick={()=>props.order(email)}
                                >
                                    <i className={`right fas fa-sort-amount-${props.th === 'email' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i>
                                </Link>
                            </th>
                            <th>AÇÕES</th>
                        </Thead>
                        <Tbody>
                            <Customers 
                                changeStatus={changeStatusActive} 
                                customers={props.customers} 
                                onDelete={onChangeModal} 
                            />
                        </Tbody>
                        <Tfoot> 
                            <th>STATUS</th>
                            <th>CPF/CNPJ</th>
                            <th>RAZÃO SOCIAL</th>
                            <th>EMAIL</th>
                            <th>AÇÕES</th>
                        </Tfoot>
                    </Table>        
                </div>
            </StylePage>
            <Footer />
        </div>
    )
}

const mapStateToProps = state => ({
    customers:state.client.customers.data,
    current_page:state.client.customers.current_page,
    last_page:state.client.customers.last_page,
    from:state.client.customers.from,
    to:state.client.customers.to,
    qtd_total:state.client.customers.total,
    qtd_per_page:state.client.qtd_per_page,
    pesquisa:state.client.pesquisa,
    perfis:state.auth.user.perfis,
    order_var: state.client.order,
    id_role:state.auth.user.id_role,
    asc:state.client.asc,
    th:state.client.th  
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
        pagination, 
        get, 
        order, 
        change_qtd_per_page, 
        changePesquisa, 
        clearPesquisa,
        changePage,
        search 
    }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(GridClient)
