import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import Input from '../../../Forms/Input'
import { useSelector, useDispatch } from 'react-redux'
import { 
    getTechnicalReport, 
    getProjectsReport, 
    getType, 
    getStatusReport, 
    getCoordinatorReport 
} from '../../../Services/reimbursement'

export default function ReportReimbursement(props) {
    const technical_report                = useSelector(state => state.reimbursement.technical_report)
    const coordinator_report              = useSelector(state => state.reimbursement.coordinator_report)
    const project_report                  = useSelector(state => state.reimbursement.project_report) 
    const type_report                     = useSelector(state => state.reimbursement.type_report)  
    const status_report                   = useSelector(state => state.reimbursement.status_report)
    const from                            = useSelector(state => state.reimbursement.from) 
    const to                              = useSelector(state => state.reimbursement.to)
    const perfis                          = useSelector(state => state.auth.user.perfis) || []
    const dispatch                        = useDispatch()
    const [ technical, setTechnical ]     = useState([])
    const [ coordinator, setCoordinator ] = useState([])
    const [ projects, setProjects ]       = useState([])
    const [ type, setType ]               = useState([])
    const [ status, setStatus ]           = useState([])
    
    useEffect(() => {
        Technical(project_report.value);
        return () => {
            Technical()
        }
        
    },[project_report.value])

    useEffect(() => {
        Coordinator();
        return () => {
            Coordinator()
        }
    },[])

    useEffect(() => {
        Projects(technical_report.value, coordinator_report.value);
        return () => {
            Projects()
        }
        
    },[technical_report.value, coordinator_report.value])

    useEffect(() => {
        StatusReimbursement();
        return () => {
            StatusReimbursement()
        }
        
    },[])

    useEffect(() => {
        TypeReimbursement();
        return () => {
            TypeReimbursement()
        }
        
    },[])

    //Função que carrega os tecnicos
    function Technical(id_project) {
        getTechnicalReport(parseInt(id_project) > 0 ? parseInt(id_project) : '')
        .then(resp => {
            setTechnical([
                { value:'', label:'-- SELECIONE --'},
                ...resp.data.map(desc => ({
                    value: desc.id,
                    label: desc.name,
                }))
            ])
        })
    }

    //Função que carrega os coordenadores
    function Coordinator() {
        getCoordinatorReport()
        .then(resp => {
            setCoordinator([
                { value:'', label:'-- SELECIONE --'},
                ...resp.data.map(desc => ({
                    value: desc.id_coordenador,
                    label: desc.coordenador,
                }))
            ])
        })
    }

    //Função que carrega os projetos
    function Projects(id_technical,id_coordinator) {
        getProjectsReport(parseInt(id_technical) > 0 ? parseInt(id_technical) : '',parseInt(id_coordinator) > 0 ? parseInt(id_coordinator) : '')
        .then(resp => {
            setProjects(perfis.indexOf(12) >=0 ? [
                {value:'',label:'-- SELECIONE --'},
                ...resp.data.projetos.map(desc => ({
                    value: desc.id,
                    label: desc.titulo + ' - ' + desc.tipo,
                }))
            ] : [
                {value:'',label:'-- SELECIONE --'},
                ...resp.data.projetos.concat(resp.data.projetosEnsino).map(desc => ({
                    value: desc.id,
                    label: desc.titulo + ' - ' + desc.tipo,
                }))
            ])
        })
    }

    //Função que carrega os tipos de reembolsos
    function TypeReimbursement() {
        getType()
        .then(resp => {
            setType([
                {value: '', label:'-- SELECIONE --'},
                ...resp.data.map(desc => ({
                    value: desc.id,
                    label: desc.descricao,
                }))          
            ])
        })
    }

    //Função que carrega o status do reembolso
    function StatusReimbursement() {
        getStatusReport()
        .then(resp => {
            setStatus(
                perfis.indexOf(1) >=0 || perfis.indexOf(2) >=0 || perfis.indexOf(3) >=0 || perfis.indexOf(6) >=0  ? [

                    {value:'',label:'-- SELECIONE --'},

                    ...resp.data.map(desc => ({
                        value: desc.id,
                        label: desc.descricao,
                    }))
                ] : [
                    {value:'',label:'-- SELECIONE --'},

                    ...resp.data.filter(filt => parseInt(filt.id) !== 5).map(desc => ({
                        value: desc.id,
                        label: desc.descricao,
                    }))
                ]
            )
        })
    }

    //Evento de alteração no tecnico
    const changeTechnical = e => {
        return[
            dispatch({type:'changeTechnicalReport', payload:e}),
            Projects(e.value, coordinator_report.value)
        ]
    }

    //Evento de alteração no coordenador
    const changeCoordinator = e => {
        return[
            dispatch({type:'changeCoordinatorReport', payload:e}),
            Projects(technical_report.value,e.value)
        ]
    }

    //Evento de alteração no projeto
    const changeProject = e => {
        return[
            dispatch({type:'changeProjectReport', payload:e}),
            Technical(e.value)
        ]
    }
    
    return (
        <>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <label>Técnico</label>
                        <Select 
                            value={technical_report}
                            label="Single select"
                            options={technical}
                            onChange={changeTechnical}             
                        /> 
                    </div>
                    <div className="col-md-12 mt-3">
                        <label>Coordenador</label>
                        <Select 
                            value={coordinator_report}
                            label="Single select"
                            options={coordinator}
                            onChange={changeCoordinator}             
                        /> 
                    </div>
                    <div className="col-md-12 mt-3">
                        <label>Projeto</label>
                        <Select 
                            value={project_report}
                            label="Single select"
                            options={projects}
                            onChange={changeProject}                      
                        /> 
                    </div>
                    <div className="col-md-12 mt-3">
                        <label>Tipo</label>
                        <Select 
                            value={type_report}
                            label="Single select"
                            options={type}
                            onChange={(e) => dispatch({type:'changeTypeReport', payload:e})}             
                        /> 
                    </div>   
                </div>
                <div className="row mt-3 mb-4">
                    <div className="col-md-12 mt-3">
                        <label>Status</label>
                        <Select 
                            value={status_report}
                            label="Single select"
                            options={status}
                            onChange={(e) => dispatch({type:'changeStatusReport', payload:e})}   
                        /> 
                    </div>
                    <div className="col-md-12 mt-3">     
                        <Input 
                            label="De"
                            type="date"
                            icon="fas fa-calendar"
                            onChange={(e) => dispatch({ type: 'changeFromReport', payload:e.target.value })}
                            value={from}
                        />
                    </div>
                    <div className="col-md-12 mt-3"> 
                        <Input 
                            label="Até"
                            type="date"
                            icon="fas fa-calendar"
                            onChange={(e) => dispatch({ type: 'changeToReport', payload:e.target.value })}
                            value={to}
                        />
                    </div>   
                </div>
            </div>
        </>
    )
}
