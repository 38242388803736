import React, { useState, useEffect } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import StylePage from '../../../Style Page/StylePage'
import Form from '../../../Forms/Form'
import Input from '../../../Forms/Input'
import Alert from '../../../Alerts/Alert'
import Footer from '../../../Footer/Footer'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import Select from 'react-select'
import { Link, useHistory } from "react-router-dom"
import { create_area, get_view_area, edit_area, get_resource } from '../../../Services/area'
import NumberFormat from 'react-number-format'



export default function Area(props) {

    let history = useHistory();
    const [ description, setDescription ] = useState ('')
    const [ error, setError ] = useState([])
    const [ success, setSuccess ] = useState('')
    const [ alert, setAlert ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ id_gerente, setIdGerente ] = useState('')
    const [ gerentes, setGerentes ] = useState([])
    
    useEffect(() => {
        viewPerId();
    }, [])

    useEffect(() => {
        getResource()
    },[])

    function viewPerId() {
        props.match.params.action && (
            get_view_area(props.match.params.id)
            .then(resp => {
                setDescription(resp.data.area[0].descricao)
                setIdGerente({
                    value: resp.data.area[0].id_gerente,
                    label: resp.data.area[0].name
                })
            })
        )
    }

    const getResource = () => {
        get_resource()
            .then(resp => {
                setGerentes(
                    resp.data.gerentes.map(desc => ({
                        value: desc.id,
                        label: desc.name,
                    }))
                )
            })
    }

    const changeDescription = event => {
        const description = event.target.value
        setDescription(description)
    }

    const handleForm=(e)=>{
        e.preventDefault();
        setLoading(true)
        
        if(!id_gerente){

        }

        const data = {
            description: description,
            codGerente: id_gerente.value,
        }

        if(props.match.params.action === 'edit'){
            const id = props.match.params.id
            edit_area(id,data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.message ? "Os dados fornecidos são inválidos" : '')  
            })           

        }else{
            create_area(data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.message ? "Os dados fornecidos são inválidos" : '')  
            })              

        }
    }

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage title={props.match.params.action === 'edit' ? "Edição de Área" : props.match.params.action === 'view' ? "Visualização de Área" : "Cadastro de Área"}>
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('')}
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ):('')}
                <div style={{display: loading === true ? ('none'):('block')}}>
                    <Form onSubmit={handleForm} autoComplete="off">
                        <div className="row">
                            <div className={ `${props.match.params.action === 'edit' || props.match.params.action === 'view' ? 'col-md-6 offset-md-3' : 'col-md-6 offset-md-3'}`}>
                                <Input 
                                    label="Nome da Área" 
                                    autocomplete="off"
                                    icon="fas fa-user" 
                                    name="description" 
                                    type="text" 
                                    value={description}
                                    onChange={changeDescription}
                                    placeholder="Digite o nome da área" 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    maxLength="45"
                                />
                                {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div>   
                        </div>

                        <div className="row">
                            <div className={ `${props.match.params.action === 'edit' || props.match.params.action === 'view' ? 'col-md-6 offset-md-3' : 'col-md-6 offset-md-3'}`}>
                                <label>Gerente</label>
                                <Select 
                                    value={id_gerente}
                                    label="Single select"
                                    options={gerentes}
                                    onChange={ (e) => setIdGerente(e) }
                                    isDisabled={props.match.params.action === 'view' ? true : false}
                                /> 
                                {id_gerente.length <= 0 && 
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        required={true}
                                    />
                                }
                                {error ? (<p style={{color:'red'}}>{error.area}</p>):('')}  
                            </div>   
                        </div>
                            
                        
                        <br></br>
                        <div className="text-right" style={{marginTop: '9%'}}>
                            <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                <Link to={`/area/${props.match.params.id}/edit`} className="btn btn-primary">Editar</Link>
                            ) : (
                                <button type="submit" className="btn btn-primary">Salvar</button>                           
                            )}
                        </div>        
                    </Form>
                </div>   
            </StylePage>
            <Footer />
        </div>
    )
}
