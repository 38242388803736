import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './Style';

export default function NavItem(props) {
    return (
        <>
            <li className='nav-item'>
                {props.dropdown ?
                    <>
                        <Container class='dropdown show' style={{ height: '42px' }}>
                            <Link to={props.to} className={`nav-link ${props.active === true ? 'active' : ''}`} style={{
                                color: 'white',
                                width: '100%',
                                textAlign: 'inherit',
                                height: 'inherit',
                                display: 'flex',
                                alignItems: 'center',
                            }} role='button' id='dropdownMenuLink' data-toggle='dropdown' aria-haspopup='true'
                                  aria-expanded='false'>
                                <i style={{ marginLeft: '10px', padding: '10px' }}><img width='20px' src={props.icon}
                                                                                        alt='icone'
                                                                                        style={{ color: 'white' }} /></i>
                                {props.item}
                            </Link>
                            <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                {props.subMenus.map(value => (
                                    <a class='dropdown-item' href={value.route}>{value.descricao}</a>
                                ))}

                            </div>
                        </Container>
                    </>
                    : <Container>

                        <Link to={props.to} className={`nav-link ${props.active === true ? 'active' : ''}`}>
                            <i style={{ marginLeft: '10px', padding: '10px' }}><img width='20px' src={props.icon}
                                                                                    alt='icone'
                                                                                    style={{ color: 'white' }} /></i>
                            <p
                                style={
                                    {
                                        fontFamily: 'Roboto',
                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '30px',
                                        letterSpacing: '0.15000000596046448px',
                                        textAlign: 'left',
                                    }
                                }
                            >
                                {props.iconCircle ? (
                                    <i className={`${props.iconCircle}` || ''}></i>
                                ) : ('')}
                                {props.item}

                            </p>
                        </Link>
                    </Container>
                }
            </li>
        </>
    );
}
