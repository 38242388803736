import React from 'react';
import { Link } from 'react-router-dom';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import { useSelector } from 'react-redux';

//Função que mapeia objeto de clientes e renderiza tds
export default function Customers({ customers, onDelete, changeStatus }) {
    const perfis = useSelector(state => state.auth.user.perfis) || [];
    const client = customers || [];
    return client.map(desc => (
        <tr key={desc.id}>
            <td>
                <Toggle

                    id={`${desc.id}`}
                    checked={desc.ativo === 1}
                    onChange={changeStatus}
                    disabled={!(perfis.indexOf(1) >= 0 || perfis.indexOf(2) >= 0 || perfis.indexOf(3) >= 0 || perfis.indexOf(6) >= 0 || perfis.indexOf(10) >= 0)}
                />
            </td>
            <td>{desc.cpf_cnpj}</td>
            <td>{desc.nome}</td>
            <td>{desc.email}</td>
            <td>
                <ul className='nav'>
                    <li className='nav-item dropdown'>
                        <a className='nav-link' data-toggle='dropdown' href='#/'>
                            <i className='fas fa-ellipsis-h' />
                        </a>
                        <div className='dropdown-menu dropdown-menu-right'>
                            <Link
                                to={`/client/${desc.id}/view`}
                                className='dropdown-item'
                            >
                                <i className='fas fa-eye'></i> Detalhes
                            </Link>
                            {
                                perfis.indexOf(1) >= 0 || perfis.indexOf(2) >= 0 || perfis.indexOf(3) >= 0 || perfis.indexOf(6) >= 0 || perfis.indexOf(10) >= 0 ? (
                                    <>
                                        <Link
                                            to={`/client/${desc.id}/edit`}
                                            className='dropdown-item'
                                        >
                                            <i className='fas fa-edit'></i> Editar
                                        </Link>
                                        <Link
                                            to='#/'
                                            className='dropdown-item'
                                            onClick={() => onDelete(desc.id)}
                                        >
                                            <i className='fas fa-trash-alt'></i> Excluir
                                        </Link>
                                    </>
                                ) : ''
                            }
                        </div>
                    </li>
                </ul>
            </td>
        </tr>
    ));

}
