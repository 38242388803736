import React from 'react'
import Menu from '../Menu/Menu'
import {connect} from 'react-redux'
import { PushMenu } from './style'
import Rehagro from '../Icons/rehagro_branco.png'
import RehagroIcon from '../Icons/rehagro_icon.png'


function Sidebar(props) {    
    return (
        <>
            {/* Main Sidebar Container */}
            <aside className={`main-sidebar elevation-4 sidebar-light-black elevation-4`} style={{backgroundColor: '#2A4038'}}>
                <PushMenu>
                    <a className="text-right nav-link push-sidebar" data-widget="pushmenu" href="#/"><i className="icon-circle fas fa-chevron-left" style={{color: '#FFF'}}></i></a>
                </PushMenu>
                {/* Brand Logo */}
                <a href="#/" className='brand-link text-sm navbar-white' style={{backgroundColor: '#2A4038'}}>
                    <img src={RehagroIcon} alt=""  style={{ marginTop: '1%', width: '50px', height: '50px'}}/>
                    <span className="brand-text font-weight-light">
                        <img className="ml-1" alt="Logo Rehagro" src={Rehagro} width="150px" />
                    </span>
                        
                </a>
                {/* Sidebar */}
                <div className="sidebar">
                    {/* Sidebar user panel (optional) */}
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="image">
                            <img src={props.image ? `${props.image}` : 'https://tecnocoffeeapi.rehagro.com.br/storage/imagens/hiclipart.com.png'} className="img-circle elevation-4" alt="" />
                        </div>
                        <div className="info" style={{color: '#FFF'}}>
                            <a style={{color: '#FFF'}} href="#/" className="d-block">{props.name ? (props.name) : ('Consultor')}</a>
                        </div>
                    </div>
                    {/* Sidebar Menu */}
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column text-sm nav-compact nav-child-indent nav-flat nav-legacy" data-widget="treeview" role="menu" data-accordion="false">
                            <Menu /> 
                        </ul>
                    </nav>
                    {/* /.sidebar-menu */}
                </div>
                {/* /.sidebar */}
            </aside>

        </>
    )  
}
const mapStateToProps = state => {
    return{
        
        name:state.auth.user.name, 
        image:state.auth.user.foto_perfil
        
    }
  
};

export default connect(mapStateToProps)(Sidebar)
