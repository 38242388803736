import { combineReducers } from "redux";
import AuthReducer from './AuthReducer';
import UserReducer from "./UserReducer";
import ClientReducer from "./ClientReducer";
import VisitReducer from "./VisitReducer";
import ReimbursementReducer from "./ReimbursementReducer";
import ProjectReducer from "./ProjectReducer";
import ReimbursementSharedReducer from "./ReimbursementSharedReducer";
import SalesRecordReducer from "./SalesRecordReducer";
import RegisterSgagriReducer from "./RegisterSgagriReducer";
import ExtraChargeReducer from "./ExtraChargeReducer";
import ProjectAmendmentReducer from "./ProjectAmendmentReducer";
import BillsToPayReducer from "./BillsToPayReducer";
import FilialReducer from "./FilialReducer";
import EmpresaReducer from "./EmpresaReducer";
import ServiceTypesReducer from "./ServiceTypeReducer";
import ReimbursementTypesReducer from "./ReimbursementTypeReducer";
import AreaReducer from "./AreaReducer";
import CultureReducer from "./CultureReducer";
import MotiveReducer from "./MotiveReducer";
import Project_Ensino from "./ProjectEnsinoReducer";

const AllReducers = combineReducers({
    auth: AuthReducer,
    user: UserReducer,
    client: ClientReducer,
    filial:FilialReducer,
    culture:CultureReducer,
    visit: VisitReducer,
    reimbursement: ReimbursementReducer,
    project: ProjectReducer,
    reimbursement_shared: ReimbursementSharedReducer,
    sales_record: SalesRecordReducer,
    register_sgagri: RegisterSgagriReducer,
    extra_charge: ExtraChargeReducer,
    project_amendment: ProjectAmendmentReducer,
    bills_to_pay: BillsToPayReducer,
    empresa: EmpresaReducer,
    service_type: ServiceTypesReducer,
    reimbursement_type: ReimbursementTypesReducer,
    area: AreaReducer,
    motive: MotiveReducer,
    projectEnsino: Project_Ensino
})

export default AllReducers;