const SalesRecordReducer = (state = {}, actions) => {
    switch (actions.type) {
        case 'SalesRecord':
            return { ...state, sales_records: actions.payload };

        case 'changeFirstPage':
            return { ...state, page: actions.payload };

        case 'GetSalesRecord_Desc':
            return {
                ...state,
                desc: actions.desc,
                asc: actions.asc,
                order: actions.order,
                th: actions.th,
            };

        case 'GetSalesRecord_Asc':
            return {
                ...state,
                desc: actions.desc,
                asc: actions.asc,
                order: actions.order,
                th: actions.th,
            };

        case 'qtd_per_page_SalesRecord':
            return { ...state, qtd_per_page: actions.payload };

        case 'pesquisaSalesRecord':
            return { ...state, pesquisa: actions.payload };

        case 'clearPesquisaSalesRecord':
            return {
                ...state,
                pesquisa: actions.payload,
                coordinator_report: actions.coordinator_report,
                status_report: actions.status_report,
                area_report: actions.area_report,
                project_report: actions.project_report,
                from: actions.from,
                to: actions.to,
            };

        case 'changeCoordinatorReportSalesRecord':
            return { ...state, coordinator_report: actions.payload };

        case 'changeStatusReportSalesRecord':
            return { ...state, status_report: actions.payload };

        case 'changeAreaReportSalesRecord':
            return { ...state, area_report: actions.payload };

        case 'changeProjectReportSalesRecord':
            return { ...state, project_report: actions.payload };

        case 'changeFromReportSalesRecord':
            return { ...state, from: actions.payload };

        case 'changeToReportSalesRecord':
            return { ...state, to: actions.payload };

        case 'changePageSalesRecord':
            return { ...state, page: actions.payload };

        default:
            return state;
    }
};
export default SalesRecordReducer;
