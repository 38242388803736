import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard';
import { ButtonAction } from '../../../Button/style';
import AprovedIcon from '../../../Icons/emojione-v1_ballot-box-bold-check.png';
import ReprovedIcon from '../../../Icons/fluent_document-footer-remove-24-filled.png';
import './style.css';

export default function ContractsAmendments(props) {
    // const registers = useSelector(state => state.project_amendment.contract_changes.data) || []
    const id_role = useSelector((state) => state.auth.user.id) || '';
    const perfis = useSelector((state) => state.auth.user.perfis) || [];
    // const status = registers.map(desc => (
    //     desc.id_status
    // ))

    // function todosIguaisOuDiferentes(states,id,gerente,coordenador) {
    //     var filtrado = states.filter(function(elem, pos, arr) {
    //         return arr.indexOf(elem) == pos;
    //     });

    //     if(filtrado.length === 1){
    //         if(perfis.indexOf(7) >= 0){
    //             if(parseInt(id_role) === parseInt(gerente) || parseInt(id_role) === parseInt(coordenador)){
    //                 return (
    //                     <label className="chk">
    //                         <input className="input-check" style={{width: '30px', height: '30px'}} name="checked" type="checkbox" id="checked" onClick={props.check} value={id}/>
    //                         <span>

    //                         </span>
    //                     </label>

    //                 )

    //             }else{
    //                 return (
    //                     <label className="chk">
    //                         <input className="input-check" style={{width: '30px', height: '30px'}} disabled="true" type="checkbox"/>
    //                         <span></span>
    //                     </label>
    //                 )

    //             }
    //         }
    //         else{
    //             return (
    //                 <label className="chk">
    //                     <input className="input-check" style={{width: '30px', height: '30px'}} name="checked" type="checkbox" id="checked" onClick={props.check} value={id}/>
    //                     <span></span>
    //                 </label>
    //             )

    //         }
    //     }
    // }
    
    const project_amendments = props.ProjectAmendments || []
    let minDate = new Date();
    minDate = minDate.toISOString().split('T')[0];
    
    return project_amendments.map(desc=>(
        <div key={desc.id}  className="col-md-3 mt-4 mt-4">
            <RecipeReviewCard
                id_status={desc.id_status}
                title={desc.coordenador_name}
                actionRoute={props.action}
                rel={
                    <>
                        <h3 style={{ fontSize: '15px', fontWeight: 700 }}>
                            Técnicos
                        </h3>
                        <hr></hr>
                        {desc.tecnicos.map((res) => (
                            <>
                                <h3
                                    style={{
                                        height: '40px',
                                        fontSize: '15px',
                                        fontWeight: 700,
                                    }}
                                >
                                    {res.tecnico}
                                </h3>
                                <p>
                                    <span
                                        style={{
                                            height: '40px',
                                            fontWeight: 700,
                                        }}
                                    >
                                        Dedicação :
                                    </span>{' '}
                                    <span>{`${res.dedicacao} dias`}</span>
                                </p>
                                <p>
                                    <span
                                        style={{
                                            height: '40px',
                                            fontWeight: 700,
                                        }}
                                    >
                                        Remuneração :
                                    </span>{' '}
                                    <span>{res.tipoRemuneracao}</span>
                                </p>
                                <p>
                                    <span
                                        style={{
                                            height: '40px',
                                            fontWeight: 700,
                                        }}
                                    >
                                        Valor :
                                    </span>{' '}
                                    <span>{`R$${props.formatReal(
                                        parseFloat(res.valor)
                                    )}`}</span>
                                </p>
                                <hr></hr>
                            </>
                        ))}
                        <hr></hr>
                        {desc.id_status === 3 && (
                            <>
                                <h3
                                    style={{
                                        fontSize: '15px',
                                        fontWeight: 700,
                                    }}
                                >
                                    Motivo Reprovação
                                </h3>
                                <hr></hr>
                                <p>{desc.motivo_reprovacao}</p>
                            </>
                        )}
                        <>
                            <h3 style={{ fontSize: '15px', fontWeight: 700 }}>
                                Produtos
                            </h3>
                            <hr></hr>
                            {desc.produtos.map((res) => (
                                <>
                                    <h3
                                        style={{
                                            height: '40px',
                                            fontSize: '15px',
                                            fontWeight: 700,
                                        }}
                                    >
                                        {res.produto}
                                    </h3>
                                    <p>
                                        <span
                                            style={{
                                                height: '40px',
                                                fontWeight: 700,
                                            }}
                                        >
                                            Valor :
                                        </span>{' '}
                                        <span>{`R$${props.formatReal(
                                            parseFloat(res.valor)
                                        )}`}</span>
                                    </p>
                                    <hr></hr>
                                </>
                            ))}
                        </>
                        <>
                            <h3 style={{ fontSize: '15px', fontWeight: 700 }}>
                                Produtividade
                            </h3>
                            <hr></hr>
                            {desc.produtividade.map((res) => (
                                <>
                                    <h3
                                        style={{
                                            height: '40px',
                                            fontSize: '15px',
                                            fontWeight: 700,
                                        }}
                                    >
                                        {res.cultura}
                                    </h3>
                                    <p>
                                        <span
                                            style={{
                                                height: '40px',
                                                fontWeight: 700,
                                            }}
                                        >
                                            Valor :
                                        </span>{' '}
                                        <span>{res.valor}</span>
                                    </p>
                                    <hr></hr>
                                </>
                            ))}
                        </>
                    </>
                }
                actions={
                    <>
                        <ul className="nav">
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link"
                                    data-toggle="dropdown"
                                    href="#/"
                                >
                                    <i
                                        style={{
                                            color: '#FFF',
                                            width: '30px',
                                            height: '30px',
                                        }}
                                        className="fas fa-bars"
                                    />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <Link
                                        to={`/project_amendment/${desc.id}/view`}
                                        className="dropdown-item"
                                    >
                                        <i className="fas fa-eye" /> Detalhes
                                    </Link>
                                    {(desc.id_status === 1 &&
                                        parseInt(desc.created_by) ===
                                            parseInt(id_role) && (
                                            <>
                                                <Link
                                                    to={`/project_amendment/${desc.id}/edit`}
                                                    className="dropdown-item"
                                                >
                                                    <i className="fas fa-edit" />{' '}
                                                    Editar
                                                </Link>
                                                <Link
                                                    to="#/"
                                                    className="dropdown-item"
                                                    onClick={() =>
                                                        props.onDelete(desc.id)
                                                    }
                                                >
                                                    <i className="fas fa-trash-alt" />{' '}
                                                    Excluir
                                                </Link>
                                            </>
                                        )) ||
                                        (desc.id_status === 1 &&
                                            (perfis[0] === 1 ||
                                                perfis[0] === 6) && (
                                                <>
                                                    <Link
                                                        to={`/project_amendment/${desc.id}/edit`}
                                                        className="dropdown-item"
                                                    >
                                                        <i className="fas fa-edit" />{' '}
                                                        Editar
                                                    </Link>
                                                </>
                                            ))}
                                </div>
                            </li>
                        </ul>
                    </>
                }
                actionsStatus={
                    props.action !== 'view' ? (
                        perfis.indexOf(1) >= 0 ||
                        perfis.indexOf(2) >= 0 ||
                        perfis.indexOf(3) >= 0 ||
                        perfis.indexOf(6) >= 0 ? (
                            desc.id_status === 1 ? (
                                <>
                                    <ButtonAction backgroundButton="#1F9950">
                                        <button
                                            type="button"
                                            tooltip="Aprovar"
                                            className="btn"
                                            // onClick={()=>props.changeStatusProjectAmendmentAproved(desc.id,2)}
                                            onClick={() =>
                                                props.modalAprovedOn(desc, 2)
                                            }
                                        >
                                            <img
                                                style={{
                                                    width: '25px',
                                                    height: '25px',
                                                }}
                                                src={AprovedIcon}
                                                alt="Aprovado"
                                            ></img>
                                        </button>
                                    </ButtonAction>
                                    <ButtonAction backgroundButton="#F6525C">
                                        <button
                                            type="button"
                                            tooltip="Reprovar"
                                            className="btn ml-3"
                                            onClick={() =>
                                                props.modalReprovedOn(
                                                    desc.id,
                                                    3
                                                )
                                            }
                                        >
                                            <img
                                                style={{
                                                    width: '25px',
                                                    height: '25px',
                                                }}
                                                src={ReprovedIcon}
                                                alt="Reprovado"
                                            ></img>
                                        </button>
                                    </ButtonAction>
                                </> 
                            ) : (desc.id_status === 2 && desc.data_base >= minDate) ? 
                            <>
                                    <ButtonAction backgroundButton="#F6525C">
                                        <button 
                                            type="button" tooltip="Reprovar"
                                            className='btn ml-3' 
                                            onClick={()=>props.modalReprovedOn(desc.id,3)}
                                        >
                                            <img style={{width: '25px', height: '25px'}} src={ReprovedIcon} alt="Reprovado"></img>
                                        </button>                  
                                    </ButtonAction>
                                </> 
                                :'' 
                        ) : ''
                    ): ''
                }
            >
                <p style={{ fontWeight: 700, height: '30px' }}>
                    <span style={{ fontWeight: 700, height: '40px' }}>
                        Projeto:{' '}
                        <a
                            href="#/"
                            onClick={(e) => {
                                props.history.push(
                                    `/project/${desc.id_projeto}/view`
                                );
                                e.preventDefault();
                            }}
                        >
                            {desc.projeto}
                        </a>
                    </span>
                </p>
                <p><span style={{height: '40px', fontWeight: 700}}>Tipo de Alteração :</span> <span>{desc.tipo_alteracao_projeto}</span></p>
                <p><span style={{height: '40px', fontWeight: 700}}>Data de Lançamento :</span> <span>{desc.data_lancamento}</span></p>
                <p><span style={{height: '40px', fontWeight: 700}}>Data base :</span> <span>{desc.data_base_formatada}</span></p>
            </RecipeReviewCard>
        </div>
    ));
}
