import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import StylePage from '../../../Style Page/StylePage'
import Form from '../../../Forms/Form'
import Input from '../../../Forms/Input'
import Alert from '../../../Alerts/Alert'
import Footer from '../../../Footer/Footer'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import RadioMaterial from '../../../Forms/RadioMaterial'
import Select from 'react-select'
import { Link, useHistory } from "react-router-dom"
import { create_reimbursement_type, get_view_reimbursement_type, edit_reimbursement_type } from '../../../Services/reimbursementType'
import NumberFormat from 'react-number-format'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'


export default function ReimbursementType(props) {

    let history = useHistory();
    const [description, setDescription] = useState('')
    const [codigoContaFinanceira, setCodigoContaFinanceira] = useState('')
    const [comprovante, setComprovante] = useState(0)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [alert, setAlert] = useState('')
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        viewPerId();
    }, [])


    function viewPerId() {
        props.match.params.action && (
            get_view_reimbursement_type(props.match.params.id)
                .then(resp => {
                    setDescription(resp.data[0].descricao)
                    setCodigoContaFinanceira(resp.data[0].codigo_conta_financeira)
                    setComprovante(resp.data[0].comprovante)
                })
        )
    }

    const changeCodContaFinanceira = event => {
        const cod_conta_financeira = event.target.value
        setCodigoContaFinanceira(cod_conta_financeira)
    }

    const changeDescription = event => {
        const description = event.target.value
        setDescription(description)
    }

    const changeComprovante = event => {
        const comprovante = event.target.value
        setComprovante(parseInt(comprovante))
    }

    const handleForm = (e) => {
        e.preventDefault();
        setLoading(true)

        const data = {
            descricao: description,
            codigo_conta_financeira: codigoContaFinanceira,
            comprovante: comprovante,
        }

        if (props.match.params.action === 'edit') {
            const id = props.match.params.id
            edit_reimbursement_type(id, data)
                .then(resp => {
                    setLoading(false)
                    setAlert('')
                    setError('')
                    setSuccess(resp.data.success)
                })
                .then(resp => setTimeout(() => {
                    history.goBack()
                }, 1000))
                .catch(e => {
                    setLoading(false)
                    setSuccess('')
                    setError(e.response.data.error)
                })

        } else {
            create_reimbursement_type(data)
                .then(resp => {
                    setLoading(false)
                    setAlert('')
                    setError('')
                    setSuccess(resp.data.success)
                })
                .then(resp => setTimeout(() => {
                    history.goBack()
                }, 1000))
                .catch(e => {
                    setLoading(false)
                    setSuccess('')
                    setError(e.response.data.error)
                })

        }
    }

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage title={props.match.params.action === 'edit' ? "Edição de Tipo de Reembolso" : props.match.params.action === 'view' ? "Visualização de Tipo de Reembolso" : "Cadastro de Tipo de Reembolso"}>
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>

                ) : ('')}
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ) : ('')}
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ) : ('')}
                {error ? (
                    <Alert type="danger">
                        {error}
                    </Alert>
                ) : ('')}
                <div style={{ display: loading === true ? ('none') : ('block') }}>
                    <Form onSubmit={handleForm} autoComplete="off">
                        <div className="row">
                            <div className={`col-md-6 ${props.match.params.action === 'edit' || props.match.params.action === 'view' ? 'offset-md-3' : 'offset-md-3'}`}>
                                <NumberFormat
                                    label="Código Sênior"
                                    name="codigo_conta_financeira"
                                    icon="fas fa-key"
                                    value={codigoContaFinanceira}
                                    onChange={changeCodContaFinanceira}
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    thousandSeparator={""}
                                    decimalSeparator={","}
                                    placeholder=""
                                    customInput={Input}
                                    allowNegative={false}
                                    disabled={props.match.params.action === 'view' ? (true) : (false)}
                                    required={true}
                                    maxLength="11"
                                    isAllowed={(values) => { const { floatValue, formattedValue } = values; return formattedValue === "" || floatValue <= 99999999999; }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className={`${props.match.params.action === 'edit' || props.match.params.action === 'view' ? 'col-md-6 offset-md-3' : 'col-md-6 offset-md-3'}`}>
                                <Input
                                    label="Nome do Tipo de Reembolso"
                                    autocomplete="off"
                                    icon="fas fa-user"
                                    name="descricao"
                                    type="text"
                                    value={description}
                                    onChange={changeDescription}
                                    placeholder="Digite o nome do reembolso"
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    maxLength="45"
                                />
                                {/* {error ? (<p style={{ color: 'red' }}>{error}</p>) : ('')} */}
                            </div>
                        </div>
                        <div className="row">
                            <div className={`col-md-6 ${props.match.params.action === 'edit' || props.match.params.action === 'view' ? 'offset-md-3' : 'offset-md-3'}`}>
                                <RadioMaterial
                                    row 
                                    label="Comprovante ?"
                                    aria-label="comprovante" 
                                    name="comprovante" 
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    value={comprovante} 
                                    styleLabel={{
                                        color: '#BEBEBE',
                                        fontSize: '10px',
                                        marginBottom: '15px'
                                    }} 
                                    onChange={changeComprovante}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label="Sim" />
                                    <FormControlLabel value={0} control={<Radio />} label="Não" />
                                </RadioMaterial>
                            </div>
                        </div>
                        <br></br>
                        <div className="text-right" style={{ marginTop: '9%' }}>
                            <button style={{ marginRight: '10px' }} type="button" className="btn btn-secondary" onClick={() => history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                <Link to={`/reimbursement_type/${props.match.params.id}/edit`} className="btn btn-primary">Editar</Link>
                            ) : (
                                <button type="submit" className="btn btn-primary">Salvar</button>
                            )}
                        </div>
                    </Form>
                </div>
            </StylePage>
            <Footer />
        </div>
    )
}
