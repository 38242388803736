/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'react-toggle/style.css';
import { useSelector } from 'react-redux';

export default function Projects({ projects, onDelete }) {
    const project = projects || [];
    const perfis = useSelector((state) => state.auth.user.perfis);
    const history = useHistory();
    //Função que verifica se o usuário tem permissão para acessar a edição do registro
    function permissionRole() {
        if (perfis.length === 1 && perfis.indexOf(10) >= 0) {
            return history.push('/index');
        }
    }

    useEffect(() => {
        permissionRole();
        return () => {
            permissionRole();
        };
    }, [permissionRole]);

    return project.map((desc) => (
        <tr key={desc.id}>
            <td style={{ width: '16%' }}>{desc.titulo}</td>
            <td style={{ width: '18%' }}>{desc.centro_custo}</td>
            <td style={{ width: '8%' }}>{desc.descTipo}</td>
            <td style={{ width: '8%' }}>{desc.descArea}</td>
            <td style={{ width: '15%' }}>{desc.gerente}</td>
            <td style={{ width: '16%' }}>{desc.coordenador}</td>
            {/* <td>{desc.cliente}</td> */}
            <td style={{ width: '12%' }}>{desc.descStatus}</td>
            <td style={{ width: '7%' }}>
                <ul className="nav">
                    <li className="nav-item dropdown">
                        <a
                            className="nav-link"
                            data-toggle="dropdown"
                            href="#/"
                        >
                            <i className="fas fa-ellipsis-h" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <Link
                                to={`/project/${desc.id}/view`}
                                className="dropdown-item"
                            >
                                <i className="fas fa-eye"></i> Detalhes
                            </Link>
                            {perfis.indexOf(1) >= 0 ||
                            perfis.indexOf(8) >= 0 ||
                            perfis.indexOf(9) >= 0 ||
                            perfis.indexOf(3) >= 0 ||
                            perfis.indexOf(6) >= 0 ? (
                                <>
                                    {desc.descTipo !== 'Consultoria' ? (
                                        <Link
                                            to={`/project/${desc.id}/edit`}
                                            className="dropdown-item"
                                        >
                                            <i className="fas fa-edit"></i>{' '}
                                            Editar
                                        </Link>
                                    ) : null}
                                    {/* <Link to='#/' className='dropdown-item' onClick={() => onDelete(desc.id)}><i
                                            className='fas fa-trash-alt'></i> Excluir</Link> */}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    </li>
                </ul>
            </td>
        </tr>
    ));
}
