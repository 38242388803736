import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default function RadioMaterial(props) {
  return (
    <FormControl 
        component="fieldset"
        disabled={props.disabled}
    >
        <FormLabel 
            component="legend"
            style={{color: '#212529', fontWeight: 700, fontSize: '.875rem'}}
            
        >
            {props.label}
        </FormLabel>
        <RadioGroup 
            row
            aria-label={props.ariaLabel} 
            name={props.name}
            value={props.value} 
            onChange={props.onChange}
        >
            {props.children}
        </RadioGroup>
    </FormControl>
  );
}
