import { app } from './Api'

const get_view_reimbursement_type = async (id) => {
    return app.get(`reimbursement_type/${id}`)
}

const get_reimbursement_type = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`reimbursement_type?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const edit_reimbursement_type = async (id,data) => {
    return app.put(`reimbursement_type/${id}`,data)
}

const create_reimbursement_type = async (data) => {
    return app.post(`reimbursement_type`,data)
}

const changeStatus =  async (id,data) => {
    return app.put(`reimbursement_type/change_status/${id}`,data)
}


export { get_view_reimbursement_type, get_reimbursement_type, edit_reimbursement_type, create_reimbursement_type, changeStatus }