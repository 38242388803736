const ReimbursementTypesReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'ReimbursementsType':
            return{...state, reimbursement_type:actions.payload}

        case 'GetReimbursementTypes_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetReimbursementTypes_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_reimbursement_types':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaReimbursementTypes':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaReimbursementTypes':
            return{...state, pesquisa:actions.payload}

        case 'changePageReimbursementTypes':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default ReimbursementTypesReducer;