import styled from "styled-components";

export const ButtonContainner = styled.div`
    button{
        background: linear-gradient(180deg, #00E6FF 0%, #0070BA 109.18%);
        display: 'inline-block';
    }
`;

export const ButtonSaveContainner = styled.div`
    button{
        background: '#F2994A';

        /* background: linear-gradient(180deg, #00E6FF 0%, #0070BA 109.18%); */
        display: 'inline-block';
    }
`;

export const ButtonAction = styled.div`
    display: 'inline-block';
    button{
        display: 'inline-block';
        /* margin: 5px;
        background-color: ${props => props.backgroundButton};
        color: white;
        padding: 5px;
        height: 30px;
        width: 30px;
        border-radius: 15px; */
        @media(min-with: 768px){
            display: 'inline-block';
        }
        @media(max-with: 768px){
            display: 'inline-block';
        }
    }
    button :hover{
        background: '#262D40';
    }
`;