const RegisterSgagriReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'RegisterSgagri':
            return{...state, registers_sgagri:actions.payload}

        case 'changeFirstPage':
            return{...state, page:actions.payload}
    

        case 'GetRegisterSgagri_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetRegisterSgagri_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_RegisterSgagri':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaRegisterSgagri':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaRegisterSgagri':
            return{...state, 
                pesquisa:actions.payload,
                status_report: actions.status_report , 
                project_report: actions.project_report,  
                from: actions.from ,
                to: actions.to
            }

        case 'changeStatusReportRegisterSgagri':
            return{...state, status_report:actions.payload}

        case 'changeProjectReportRegisterSgagri':
            return{...state, project_report:actions.payload}

        case 'changeFromReportRegisterSgagri':
            return{...state, from:actions.payload}

        case 'changeToReportRegisterSgagri':
            return{...state, to:actions.payload}

        case 'changePageRegisterSgagri':
            return{...state, page:actions.payload}
        

        default:
            return state;
    }
}
export default RegisterSgagriReducer;