import { app } from './Api'

const post_filial = async (data) => {
    return app.post(`filial`, data)
}

const get_view_filial = async (id) => {
    return app.get(`filial/${id}`)
}

const put_filial = async (id,data) => {
    return app.put(`filial/${id}`,data)
}
const get_filiais = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`filial?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const getResource = async () => {
    return app.get(`filial_get_resource`)
}

const changeStatus =  async (id,data) => {
    return app.put(`filial/change_status/${id}`,data)
}

export { post_filial, get_view_filial, get_filiais, put_filial, getResource, changeStatus }