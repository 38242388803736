import {Route, Redirect} from 'react-router-dom'
import React from 'react'
import {connect} from 'react-redux'

const LoginRouter = ({component:Component, ...rest }) =>{   
    return (  
        <Route
            {...rest}        
            render={props => {
                const token = localStorage.getItem('token')         
                if(!token){   
                    return <Component {...props} />                
                }else{
                    return <Redirect
                        to={{
                            pathname: "/index",
                            state: { from: props.location }
                        }}
                    />  
                }     
            }}
        />
    );
}
const mapStateToProps = state => {
    return{
          
    }
}
export default connect(mapStateToProps)(LoginRouter);