import React from 'react';
import { Link } from 'react-router-dom';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import { useSelector } from 'react-redux';

//Função que mapeia objeto de filiais e renderiza tds
export default function Filiais({ filiais, changeStatus }) {
    const perfis = useSelector(state => state.auth.user.perfis) || [];
    const filial = filiais || [];
    return filial.map(desc => (
        <tr key={desc.id}>
            <td>
                <Toggle

                    id={`${desc.id}`}
                    checked={desc.situacao === 1}
                    onChange={changeStatus}
                />
            </td>
            <td>{desc.cnpj}</td>
            <td>{desc.name}</td>
            <td>{desc.nome}</td>
            <td>
                <ul className='nav'>
                    <li className='nav-item dropdown'>
                        <a className='nav-link' data-toggle='dropdown' href='#/'>
                            <i className='fas fa-ellipsis-h' />
                        </a>
                        <div className='dropdown-menu dropdown-menu-right'>
                            <Link
                                to={`/filial/${desc.id}/view`}
                                className='dropdown-item'
                            >
                                <i className='fas fa-eye'></i> Detalhes
                            </Link>
                            {
                                perfis.indexOf(1) >= 0 || perfis.indexOf(2) >= 0 || perfis.indexOf(3) >= 0 || perfis.indexOf(6) >= 0 || perfis.indexOf(10) >= 0 ? (
                                    <>
                                        <Link
                                            to={`/filial/${desc.id}/edit`}
                                            className='dropdown-item'

                                        >
                                            <i className='fas fa-edit'></i> Editar
                                        </Link>
                                    </>
                                ) : ''
                            }
                        </div>
                    </li>
                </ul>
            </td>
        </tr>
    ));

}
