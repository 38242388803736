import React from 'react'
import Table from '../../../Table/Table'
import Thead from '../../../Table/Thead'
import Tbody from '../../../Table/Tbody'

export default function TableTechnicalCheckList(props) {
    return (
        <Table 
            className="table" 
            title={props.title}
        >
            <Thead>
                <th className="text-center">TÉCNICO</th>
                <th className="text-center">TIPO DE DEDICAÇÃO</th>
                <th className="text-center">DEDICAÇÃO</th>
                <th className="text-center">REMUNERAÇÃO RMA</th>
                <th className="text-center">VALOR RMA</th>
                <th className="text-center">AÇÃO</th>
            </Thead>
            <Tbody> 
                {props.children}
            </Tbody>
            
        </Table>
    )
}