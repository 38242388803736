import { app } from './Api';

const getResource = async () => {
    return app.get('sales_record/get_resource');
};

const postSalesRecord = async (data) => {
    return app.post(`sales_record`, data);
};

const putSalesRecord = async (id, data) => {
    return app.post(`sales_record/${id}`, data);
};

const getSalesRecord = async (
    page,
    qtd_per_page,
    order,
    th,
    pesquisa,
    id_coordinator,
    id_area,
    project_report,
    id_status,
    from,
    to
) => {
    return app.get(
        `sales_record?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&id_coordinator=${id_coordinator}&id_area=${id_area}&id_registro_venda=${project_report}&id_status=${id_status}&from=${from}&to=${to}`
    );
};

const getViewSalesRecord = async (id) => {
    return app.get(`sales_record/${id}`);
};

const delSalesRecord = async (id) => {
    return app.delete(`sales_record/${id}`);
};

const changeStatus = async (id, data) => {
    return app.put(`change_sales_record/${id}`, data);
};

const getClient = async () => {
    return app.get(`sales_record/get_client`);
};

const getCity = async (idState) => {
    return app.get(`sales_record/get_city/${idState}`);
};

const getFilial = async (idEmpresa) => {
    return app.get(`sales_record/get_filial/${idEmpresa}`);
};

const getAreaManager = async (idArea) => {
    return app.get(`sales_record/manager_by_id_area/${idArea}`);
};

const delAttachment = async (id) => {
    return app.delete(`sales_record/del_attachment/${id}`);
};

const exportExcel = async (
    page,
    qtd_per_page,
    order,
    th,
    pesquisa,
    id_coordinator,
    id_area,
    project_report,
    id_status,
    from,
    to
) => {
    return app.get(
        `sales_record/export_excel?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&id_coordinator=${id_coordinator}&id_area=${id_area}&id_registro_venda=${project_report}&id_status=${id_status}&from=${from}&to=${to}`
    );
};

export {
    getAreaManager,
    getResource,
    getFilial,
    delAttachment,
    postSalesRecord,
    putSalesRecord,
    getSalesRecord,
    getViewSalesRecord,
    delSalesRecord,
    changeStatus,
    getClient,
    getCity,
    exportExcel,
};
