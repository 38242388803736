import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import StylePage from '../../../Style Page/StylePage';
import Form from '../../../Forms/Form';
import Input from '../../../Forms/Input';
import Alert from '../../../Alerts/Alert';
import Footer from '../../../Footer/Footer';
import TextArea from '../../../Forms/TextArea';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import {
    del_attachment,
    getTechnical,
    getProjects,
    getType,
    post_reimbursement,
    put_reimbursement,
    get_view_reimbursement,
} from '../../../Services/reimbursement_shared';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { ContentUpload } from '../../../Upload/styles';
import Upload from '../../../Upload/upload';
import FileList from '../../../FileList/FileList';
import Shareds from './Shareds';

export default function ReimbursementShared(props) {

    let history = useHistory();
    const role = useSelector(state => state.auth.user.perfis);
    const id_user = useSelector(state => state.auth.user.id);
    const selectTechnicianRef = useRef(null);
    const selectTypeRef = useRef(null);
    const data_atual = useSelector(state => state.reimbursement_shared.data_atual);
    const [loading, setLoading] = useState(false);
    const [technical, setTechnical] = useState([]);
    const [projects, setProjects] = useState([]);
    const [type, setType] = useState([]);
    const [id_technician, setIdTechnician] = useState([]);
    const [id_project, setIdProject] = useState([]);
    const [id_type, setIdType] = useState([]);
    const [validationProject, setValidationProject] = useState('');
    const [validationAddPercent, setValidationAddPercent] = useState('');
    const [validationAddValue, setValidationAddValue] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState(useSelector(state => state.reimbursement.date));
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState('');
    const [alert, setAlert] = useState('');
    const [value, setValue] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [km, setKm] = useState('');
    const [valueKm, setValueKm] = useState('');
    const [addPercent, setAddPercent] = useState('');
    const [addValue, setAddValue] = useState('');
    const [shareds, setShareds] = useState([]);
    const [contador, setContador] = useState(0);
    const [created_by, setCreated] = useState('');
    const [id_status, setIdStatus] = useState('');


    useEffect(() => {
        Technical();
    }, []);

    useEffect(() => {
        TypeReimbursement();
    }, []);

    useEffect(() => {
        viewPerId();
    }, []);

    // useEffect(() => {
    //     isEdit();
    // }, [props.match.params.action])

    function isEdit(created, status) {
        if (props.match.params.action === 'edit') {
            if (parseInt(created) !== parseInt(id_user) || parseInt(status) === 3) {
                props.history.push(`/reimbursement_shared/${props.match.params.id}/view`);
            }
        }
    }


    function viewPerId() {
        props.match.params.action && (
            get_view_reimbursement(props.match.params.id)
                .then(resp => {
                    setIdTechnician({
                        value: resp.data.reimbursement_shared[0].id_technical,
                        label: resp.data.reimbursement_shared[0].name,
                    });
                    setIdType({
                        value: resp.data.reimbursement_shared[0].id_type,
                        label: resp.data.reimbursement_shared[0].descType,
                        comprovante: resp.data.reimbursement_shared[0].comprovante,
                    });
                    setDescription(resp.data.reimbursement_shared[0].descricao);
                    setValue(resp.data.reimbursement_shared[0].valor_total);
                    setIdStatus(resp.data.reimbursement_shared[0].id_status);
                    setDate(resp.data.reimbursement_shared[0].data_lancamento);
                    Projects(resp.data.reimbursement_shared[0].id_technical);
                    setCreated(resp.data.reimbursement_shared[0].created_by);
                    setValueKm(parseInt(resp.data.reimbursement_shared[0].id_type) === 3 ? 1.70 : parseInt(resp.data.reimbursement_shared[0].id_type) === 11 ? 0.85 : parseInt(resp.data.reimbursement_shared[0].id_type) === 12 ? 0.90 : '');
                    setKm(parseInt(resp.data.reimbursement_shared[0].id_type) === 3 || parseInt(resp.data.reimbursement_shared[0].id_type) === 11 || parseInt(resp.data.reimbursement_shared[0].id_type) === 12 ? resp.data.reimbursement_shared[0].km : '');
                    setShareds(resp.data.reimbursement_shared_project.map(desc => ({
                        id: parseInt(desc.id),
                        id_project: { label: desc.titulo, value: parseInt(desc.id_project) },
                        percent: parseFloat(desc.percentual),
                        value_shared: (parseFloat(resp.data.reimbursement_shared[0].valor_total) * parseFloat(desc.percentual)) / 100,
                        created_at: desc.created_at,
                        id_status: desc.id_status,
                        status: desc.status,

                    })));
                    setUploadedFiles(
                        resp.data.attachment.map(file => ({
                            id: file.id,
                            name: file.nome,
                            readableSize: filesize(file.size),
                            uploaded: true,
                            url: file.link,
                        })),
                    );
                    isEdit(resp.data.reimbursement_shared[0].created_by, resp.data.reimbursement_shared[0].id_status);
                })
        ).catch(e => {
            setAlert(e.response.data.error)
            setTimeout(() => {
                setAlert('');
                return props.history.push('/refunds_shared');
            }, 5000);
        });;
    }

    function Projects(id) {
        getProjects(id)
            .then(resp => {
                setProjects(
                    resp.data.map(desc => ({
                        value: desc.id,
                        label: desc.titulo,
                    })),
                );
            });
    }

    function TypeReimbursement(id_project) {
        getType()
            .then(resp => {
                setType(
                    resp.data.map(desc => ({
                        value: desc.id,
                        label: desc.descricao,
                        comprovante: desc.comprovante,
                    })),
                );
            });
    }


    function Technical() {
        getTechnical()
            .then(resp => {
                setTechnical(
                    resp.data.map(desc => ({
                        value: desc.id,
                        label: desc.name,
                    })),
                );
                if (role.indexOf(7) >= 0) {
                    let myUser = resp.data.filter(resp => resp.id === id_user);
                    setIdTechnician({
                        value: myUser[0].id,
                        label: myUser[0].name,
                    });
                    // setIdTechnician(myUser.map(desc => ({
                    //     value: desc.id,
                    //     label: desc.name
                    // })))
                    Projects(id_user);
                }
            });
    }

    function renderTR() {
        const shared = shareds || [];
        return shared.map(desc => (
            <>
                <div key={desc.id} id={parseInt(desc.id)} className='row'>
                    <div className='col-md-5'>
                        <label>Projeto</label>
                        <Select
                            value={desc.id_project}
                            label='Single select'
                            options={projects}
                            onChange={changeProjectShared}
                            name={`${desc.id}`}
                            isDisabled={props.match.params.action === 'view' || parseInt(desc.id_status) === 2 || parseInt(desc.id_status) === 3 ? true : false}
                        />
                    </div>
                    <div className='col-md-3'>
                        <NumberFormat
                            icon='fas fa-percent'
                            label='Percentual'
                            name='percentual'
                            allowNegative={false}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            placeholder=''
                            customInput={Input}
                            onChange={changePercentShared}
                            value={desc.percent}
                            disabled={props.match.params.action === 'view' || parseInt(desc.id_status) === 2 || parseInt(desc.id_status) === 3 ? true : false}
                            isAllowed={(values) => {
                                const { floatValue, formattedValue } = values;
                                return formattedValue === '' || floatValue <= 100;
                            }}
                        />
                    </div>
                    <div className='col-md-3'>
                        <NumberFormat
                            icon='fas fa-dollar-sign'
                            label='Valor'
                            name='valor'
                            allowNegative={false}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            placeholder=''
                            customInput={Input}
                            onChange={changeValueShared}
                            value={desc.value_shared}
                            disabled={props.match.params.action === 'view' || parseInt(desc.id_status) === 2 || parseInt(desc.id_status) === 3 ? true : false}
                            isAllowed={(values) => {
                                const { floatValue, formattedValue } = values;
                                return formattedValue === '' || floatValue <= 999999;
                            }}
                        />
                    </div>
                    <div className='col-md-1'>
                        <div className='input-group mb-3' style={{ paddingTop: '30px' }}>
                            <button
                                disabled={props.match.params.action === 'view' || parseInt(desc.id_status) === 2 || parseInt(desc.id_status) === 3 ? true : false}
                                type='button' onClick={(e) => handleRemove(desc.id)} className='btn btn-danger'><span
                                className='fas fa-minus'></span></button>
                        </div>
                    </div>
                    <div className='col-md-2' style={{ paddingTop: '35px' }}>
                        <p style={{ color: desc.status === 'Aprovado' ? 'green' : desc.status === 'Em Análise' ? 'orange' : desc.status === 'Reprovado' ? 'red' : 'green' }}>{`Status : ${desc.status || 'Aguardando Envio'}`}</p>
                        {/* <img style={{width: '25px', height: '25px'}} src={parseInt(desc.id_status) === 1 ? AnalysisIcon : parseInt(desc.id_status) === 2 ? ApprovedIcon : parseInt(desc.id_status) === 3 ? DisapprovedIcon : ''} /> */}
                    </div>
                </div>
                <hr></hr>
            </>
        ));

    }

    const changePercentShared = e => {
        const id = e.target.parentNode.parentNode.parentNode.id;
        const percent = parseFloat(e.target.value.replace('.', '').replace('.', '').replace(',', '.'));

        setShareds(
            shareds.map(desc => ({
                id: desc.id,
                id_project: desc.id_project,
                percent: parseInt(desc.id) === parseInt(id) ? percent : desc.percent,
                value_shared: parseInt(desc.id) === parseInt(id) ? (value * percent) / 100 : desc.value_shared,
                id_status: desc.id_status,
                status: desc.status,
                created_at: desc.created_at,
            })),
        );

    };

    const changeValueShared = e => {
        const id = e.target.parentNode.parentNode.parentNode.id;
        const value_shared = parseFloat(e.target.value.replace('.', '').replace('.', '').replace(',', '.'));

        setShareds(
            shareds.map(desc => ({
                id: desc.id,
                id_project: desc.id_project,
                percent: parseInt(desc.id) === parseInt(id) ? (value_shared * 100) / value : desc.percent,
                value_shared: parseInt(desc.id) === parseInt(id) ? value_shared : desc.value_shared,
                created_at: desc.created_at,
                id_status: desc.id_status,
                status: desc.status,
            })),
        );

    };

    const changeProjectShared = (newValue: any, actionMeta: any) => {
        const id = actionMeta.name;
        const project_shared = newValue;

        setShareds(
            shareds.map(desc => ({
                id: desc.id,
                id_project: parseInt(desc.id) === parseInt(id) ? project_shared : desc.id_project,
                percent: desc.percent,
                value_shared: desc.value_shared,
                created_at: desc.created_at,
                id_status: desc.id_status,
                status: desc.status,
            })),
        );
    };

    const handleRemove = id => {
        // const id = parseInt(e.target.parentNode.parentNode.parentNode.parentNode.id)
        setShareds(shareds.filter(desc => parseInt(desc.id) !== parseInt(id)));
    };

    const handleUpload = files => {
        const uploadedFile = files.map(file => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),

            progress: 0,
            uploaded: false,
            error: false,
            url: null,

        }));
        setUploadedFiles(uploadedFiles.concat(uploadedFile));
    };

    const handleDelete = id => {

        const upload = uploadedFiles.filter(file => file.id === id);
        if (upload[0].url) {
            del_attachment(id)
                .then(resp => (
                    viewPerId(props.match.params.id)
                ));
        } else {
            setUploadedFiles(uploadedFiles.filter(file => file.id !== id));
        }
    };

    const changePercent = e => {
        const percent = parseFloat(e.target.value.replace('.', '').replace('.', '').replace(',', '.'));
        setAddPercent(percent);
        setAddValue((value * percent) / 100);
    };

    const changeAddValue = e => {
        const addValue = parseFloat(e.target.value.replace('.', '').replace('.', '').replace(',', '.'));
        setAddValue(addValue);
        setAddPercent((addValue * 100) / value);
    };

    const changeTechnician = e => {
        setIdProject([]);
        setIdTechnician(e);
        Projects(e.value);
        setShareds([]);
    };

    const changeValue = e => {
        const value_reimbursement = parseFloat(e.target.value.replace('.', '').replace('.', '').replace(',', '.'));
        setValue(parseFloat(e.target.value.replace('.', '').replace('.', '').replace(',', '.')));
        if (shareds.length > 0) {
            setShareds(shareds.map(desc => ({
                id: desc.id,
                id_project: desc.id_project,
                percent: desc.percent,
                value_shared: (desc.percent * value_reimbursement) / 100,
                created_at: desc.created_at,
            })));
        }
    };

    const changeProject = e => {
        setIdProject(e);
    };

    const changeType = e => {
        setIdType(e);
        let valueKmGO = parseInt(e.value) === 3 ? 1.70 : parseInt(e.value) === 11 ? 0.85 : parseInt(e.value) === 12 ? 0.90 : '';
        setValueKm(parseInt(e.value) === 3 ? 1.70 : parseInt(e.value) === 11 ? 0.85 : parseInt(e.value) === 12 ? 0.90 : '');
        if (parseInt(e.value) === 3 || parseInt(e.value) === 11 || parseInt(e.value) === 12) {
            setKm(km);
            setValue(km * valueKmGO);
            if (shareds.length > 0) {
                setShareds(shareds.map(desc => ({
                    id_project: desc.id_project,
                    percent: desc.percent,
                    value_shared: (desc.percent * (km * valueKmGO)) / 100,

                })));
            }
        } else {
            setKm('');
        }
    };

    const changeDate = e => {
        setDate(e.target.value);
    };

    const changeDescription = e => {
        setDescription(e.target.value);
    };

    const changeKm = e => {
        const km = parseFloat(e.target.value.replace('.', '').replace('.', '').replace(',', '.'));
        setKm(km);
        setValue(km * valueKm);
        if (shareds.length > 0) {
            setShareds(shareds.map(desc => ({
                id_project: desc.id_project,
                percent: desc.percent,
                value_shared: (desc.percent * (km * valueKm)) / 100,
            })));
        }
    };

    const handleShareds = (e) => {

        let contador_tr = contador;

        if (id_project.length <= 0) {
            return [
                setValidationProject('Projeto Obrigatório !'),
                setTimeout(() => {
                    setValidationProject('');
                }, 2000),
            ];
        }
        if (addPercent <= 0 || isNaN(addPercent) === true || addPercent === 'undefined') {
            return [
                setValidationAddPercent('Percentual Obrigatório !'),
                setTimeout(() => {
                    setValidationAddPercent('');
                }, 2000),
            ];
        }
        if (addValue <= 0 || isNaN(addValue) === true || addValue === 'undefined') {
            return [
                setValidationAddValue('Valor Obrigatório !'),
                setTimeout(() => {
                    setValidationAddValue('');
                }, 2000),
            ];
        }


        // if(trs.length > 0){
        let filter = shareds.filter(resp => parseInt(resp.id_project.value) === parseInt(id_project.value));
        if (filter.length > 0) {
            return [
                setAlert('Não é permitido projetos iguais'),
                setTimeout(() => {
                    setAlert('');
                }, 3000),
            ];


        }
        let percent = 0;
        for (let i = 0; i < shareds.length; i++) {
            percent = percent + shareds[i].percent;
            // contador_tr++
        }
        if (percent + addPercent > 100) {
            return [
                setAlert('A soma das porcertagens não devem ultrapassarem o limite de 100%.'),
                setTimeout(() => {
                    setAlert('');
                }, 3000),
            ];
        }
        // }
        // let object = {}
        // object.id_project = id_project
        // object.percent = addPercent
        // object.value_shared = addValue
        // object.id = contador_tr
        // contador_tr++
        const object = {
            id: uniqueId(),
            id_project: id_project,
            percent: addPercent,
            value_shared: addValue,
            id_status: '',

        };

        setShareds(shareds.concat(object));
        setContador(contador_tr);
        setIdProject('');
        setAddPercent('');
        setAddValue('');
    };

    const handleForm = (e) => {
        e.preventDefault();
        // console.log(shareds)
        // debugger
        setLoading(true);
        if (shareds.length <= 0) {
            return [
                setLoading(false),
                setAlert('Não é possivel cadastrar o reembolso sem as despesas compartilhadas !'),
                setTimeout(() => {
                    setAlert('');
                }, 3000),

            ];
        }

        let percents = shareds.map(desc => parseFloat(desc.percent));

        let soma = 0;
        for (let i = 0; i < percents.length; i++) {
            soma = soma + percents[i];
        }
        soma = Math.round(soma * 100) / 100;
        if (soma !== 100) {
            return [
                setLoading(false),
                setAlert('A soma das porcentagens são diferentes de 100 !'),
                setTimeout(() => {
                    setAlert('');
                }, 3000),

            ];
        }

        for (let i = 0; i < shareds.length; i++) {
            for (let j = i + 1; j < shareds.length; j++) {
                if (parseInt(shareds[i].id_project.value) === parseInt(shareds[j].id_project.value)) {
                    return [
                        setLoading(false),
                        setAlert('Não é permitido projetos iguais'),
                        setTimeout(() => {
                            setAlert('');
                        }, 3000),

                    ];
                }
            }
        }

        const data = new FormData();
        var resultado = -1;
        let attachment = false;

        if (uploadedFiles.length > 0) {
            attachment = true;
        }
        var uploadedFile = uploadedFiles.filter(desc => !desc.url);

        
        for (let i = 0; i < uploadedFile.length; i++) {
            if (uploadedFile[i].file) {
                data.append(`file${i}`, uploadedFile[i].file);
                resultado = i;
                attachment = true;
            }
        }

        data.append('id_technical', parseInt(id_technician.value));
        // data.append("id_project", shareds.map(desc => parseInt(desc.id_project.value)))
        // data.append("percent", shareds.map(desc => parseFloat(desc.percent)))
        data.append('shareds', JSON.stringify(shareds));
        data.append('id_type', parseInt(id_type.value));
        data.append('description', description);
        data.append('date', date);
        data.append('value', value);
        data.append('qtd', resultado);
        data.append('comprovante', parseInt(id_type.comprovante));
        data.append('isAttachment', attachment);
        data.append('km', km);

        if (props.match.params.action === 'edit') {
            data.append('_method', 'put');

        }

        if (props.match.params.action === 'edit') {
            const id = props.match.params.id;
            put_reimbursement(id, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            })
                .then(resp => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then(resp => setTimeout(() => {
                    window.location.reload();
                }, 2500))
                .catch(e => {
                    setLoading(false);
                    setSuccess('');
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        setError('');
                        setAlert('');
                    }, 2500);
                });

        } else {
            post_reimbursement(data, {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },


            })
                .then(resp => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then(resp => setTimeout(() => {
                    window.location.reload();
                }, 2500))
                .catch(e => {
                    setLoading(false);
                    setSuccess('');
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        setError('');
                        setAlert('');
                    }, 2500);
                });

        }
    };

    return (
        <div className='wrapper'>
            <Sidebar />
            <Header />
            <StylePage
                title={props.match.params.action === 'edit' ? 'Edição de Reembolsos Compartilhados' : props.match.params.action === 'view' ? 'Visualização de Reembolsos Compartilhados' : 'Cadastro de Reembolsos Compartilhados'}>
                {loading === true ? (
                    <Content>
                        <ReactLoading type='spinningBubbles' color='blue' height={200} width={100} />
                    </Content>

                ) : ('')}
                {alert ?
                    <div>
                        <Alert type='danger' onCloseAlert={() => setAlert('')}>{alert}</Alert> 
                    </div> 
                : ''} 
                { success ?
                    <div>
                        <Alert type='success' onCloseAlert={() => setSuccess('')}>{success}</Alert> 
                    </div>
                : ''}
                <div style={{
                    width: '100%',
                    opacity: success || alert ? 0.1 : 1,
                    display: loading === true ? ('none') : ('block'),
                }}>
                    <Form onSubmit={handleForm}>
                        <div className='row'>
                            <div className='col-md-4'>
                                <label>Técnico</label>
                                <Select
                                    value={id_technician}
                                    ref={selectTechnicianRef}
                                    label='Single select'
                                    options={technical}
                                    onChange={changeTechnician}
                                    isDisabled={props.match.params.action === 'view' || parseInt(id_status) === 2 || parseInt(id_status) === 3 ? true : false}

                                />
                                {id_technician.length <= 0 &&
                                    <input
                                        tabIndex={-1}
                                        autoComplete='off'
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={id_technician}
                                        onFocus={() => selectTechnicianRef.current.focus()}
                                        required={true}
                                    />
                                }
                                {error ? (<p style={{ color: 'red' }}>{error.id_technical}</p>) : ('')}
                                {/* {validationTechnical && <div className="mt-2"><Alert type="warning">{validationTechnical}</Alert></div>}  */}
                            </div>
                            <div className='col-md-4'>
                                <label>Tipo</label>
                                <Select
                                    value={id_type}
                                    ref={selectTypeRef}
                                    label='Single select'
                                    options={type}
                                    onChange={changeType}
                                    isDisabled={props.match.params.action === 'view' || parseInt(id_status) === 2 || parseInt(id_status) === 3 ? true : false}
                                />
                                {id_type.length <= 0 &&
                                    <input
                                        tabIndex={-1}
                                        autoComplete='off'
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={id_type}
                                        onFocus={() => selectTypeRef.current.focus()}
                                        required={true}
                                    />
                                }
                                {error ? (<p style={{ color: 'red' }}>{error.id_type}</p>) : ('')}
                                {/* {validationType && <div className="mt-2"><Alert type="warning">{validationType}</Alert></div>}  */}
                            </div>
                            <div className='col-md-4'>
                                <NumberFormat
                                    label='Valor'
                                    icon='fas fa-dollar-sign'
                                    name='valor'
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    placeholder=''
                                    customInput={Input}
                                    onChange={changeValue}
                                    value={value}
                                    disabled={props.match.params.action === 'view' || parseInt(id_status) === 2 || parseInt(id_status) === 3 || parseInt(id_type.value) === 3 || parseInt(id_type.value) === 11 || parseInt(id_type.value) === 12 ? (true) : (false)}
                                    required={true}
                                    isAllowed={(values) => {
                                        const { floatValue, formattedValue } = values;
                                        return formattedValue === '' || floatValue <= 999999;
                                    }}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.value}</p>) : ('')}
                            </div>
                        </div>
                        {parseInt(id_type.value) === 3 || parseInt(id_type.value) === 11 || parseInt(id_type.value) === 12 ? (
                            <div className='row'>
                                <div className='col-md-6'>
                                    <NumberFormat
                                        label='KM'
                                        icon={`fas fa-${parseInt(id_type.value) === 3 ? 'car' : parseInt(id_type.value) === 11 ? 'motorcycle' : 'car'}`}
                                        name='km'
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        placeholder=''
                                        customInput={Input}
                                        onChange={changeKm}
                                        value={km}
                                        disabled={props.match.params.action === 'view' || parseInt(id_status) === 2 || parseInt(id_status) === 3 ? (true) : (false)}
                                        required={true}
                                        isAllowed={(values) => {
                                            const { floatValue, formattedValue } = values;
                                            return formattedValue === '' || floatValue <= 10000;
                                        }}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <NumberFormat
                                        label={`${id_type.label}`}
                                        icon={'fas fa-dollar-sign'}
                                        name='km'
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        placeholder=''
                                        customInput={Input}
                                        value={valueKm}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        ) : ('')}
                        <br></br>

                        <div className='card-header'>
                            <h3 className='card-title'>Adição de Rateios</h3>
                        </div>
                        <div className='row mt-3'>
                            <Shareds
                                id_project={id_project}
                                changeProject={changeProject}
                                projects={projects}
                                isDisabled={props.match.params.action === 'view' ? true : false}
                                error={error}
                                errorProject={validationProject}
                                addValue={addValue}
                                addPercent={addPercent}
                                changePercent={changePercent}
                                changeAddValue={changeAddValue}
                                errorPercent={validationAddPercent}
                                errorValue={validationAddValue}
                                onClick={handleShareds}
                            />
                        </div>
                        <hr />
                        {/* <br></br> */}
                        {/* {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('')} */}
                        {/* <div className="row">
                            <div className="col-md-12"> */}
                        {/* <TableShareds> */}
                        <br />
                        <div className='card-header'>
                            <h3 className='card-title'>Rateios Adicionados</h3>
                        </div>
                        <hr />
                        <div>
                            {renderTR()}
                        </div>
                        {/* </TableShareds> */}
                        {/* </div>
                        </div>     */}
                        <div className='row mt-4'>
                            <div className='col-md-6'>
                                <TextArea
                                    rows='8'
                                    label='Descrição'
                                    value={description}
                                    onChange={changeDescription}
                                    disabled={props.match.params.action === 'view' || parseInt(id_status) === 2 || parseInt(id_status) === 3 ? true : false}
                                    maxLength='254'
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.description}</p>) : ('')}
                            </div>
                            <div className='col-md-6'>
                                <label>Upload</label>
                                <ContentUpload>
                                    <>
                                        <Upload
                                            disabled={props.match.params.action === 'view' || parseInt(id_status) === 2 || parseInt(id_status) === 3 ? (true) : (false)}
                                            onUpload={handleUpload} 
                                            required={id_type.comprovante === 1 ? true : false} />
                                        {!!uploadedFiles.length && (
                                            <FileList
                                                disabled={props.match.params.action === 'view' || parseInt(id_status) === 2 || parseInt(id_status) === 3 ? (true) : (false)}
                                                action={props.match.params.action} status={id_status}
                                                toEdit={props.match.params.action === 'edit' ? (`/reimbursement_shared/${props.match.params.id}/edit`) : ('')}
                                                toCadastro='new_reimbursement_shared' files={uploadedFiles}
                                                onDelete={handleDelete} 
                                                required={id_type.comprovante === 1 ? true : false} />)}
                                    </>
                                </ContentUpload>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Input
                                    icon='fas fa-calendar-alt'
                                    label='Data da Despesa'
                                    onChange={changeDate}
                                    type='date'
                                    value={date}
                                    max={`${(parseInt(data_atual.split('-')[0])) + 4}-${data_atual.split('-')[1]}-${data_atual.split('-')[2]}`}
                                    min={`${(parseInt(data_atual.split('-')[0]) - 4)}-${data_atual.split('-')[1]}-${data_atual.split('-')[2]}`}
                                    placeholder=''
                                    required={true}
                                    disabled={props.match.params.action === 'view' || parseInt(id_status) === 2 || parseInt(id_status) === 3 ? true : false}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.date}</p>) : ('')}
                            </div>
                        </div>
                        <br></br>
                        <div className='text-right'>
                            <button style={{ marginRight: '10px' }} type='button' className='btn btn-secondary'
                                    onClick={() => history.goBack()}>Voltar
                            </button>
                            {props.match.params.action === 'view' ? (
                                <Link
                                    style={{
                                        cursor: created_by !== id_user || id_status === 3 ? 'not-allowed' : '',
                                        color: created_by !== id_user || id_status === 3 ? '#fff' : '',
                                        backgroundColor: created_by !== id_user || id_status === 3 ? '#007bff' : '',
                                        borderColor: created_by !== id_user || id_status === 3 ? '#007bff' : '',
                                        opacity: created_by !== id_user || id_status === 3 ? .65 : '',
                                        boxShadow: created_by !== id_user || id_status === 3 ? 'none' : '',

                                    }}
                                    onClick={created_by !== id_user || id_status === 3 ? e => e.preventDefault() : ''}
                                    to={`/reimbursement_shared/${props.match.params.id}/edit`}
                                    className='btn btn-primary'>Editar</Link>
                                // <Link to={`/reimbursement_shared/${props.match.params.id}/edit`} className="btn btn-primary">Editar</Link>
                            ) : (
                                <button type='submit' className='btn btn-primary'>Salvar</button>
                            )}
                        </div>
                    </Form>
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}
