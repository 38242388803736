import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import Input from '../../../Forms/Input'
import { useSelector, useDispatch } from 'react-redux'
import { getResource } from '../../../Services/projectAmendment'

export default function ReportProjectAmendment(props) {
    const coordinator_report = useSelector(state => state.project_amendment.coordinator_report)
    const area_report = useSelector(state => state.project_amendment.area_report) 
    const status_report = useSelector(state => state.project_amendment.status_report)
    const filter_data = useSelector(state => state.project_amendment.filter_data)
    const project_report = useSelector(state => state.project_amendment.project_report)
    const from = useSelector(state => state.project_amendment.from) 
    const to = useSelector(state => state.project_amendment.to)
    const dispatch = useDispatch()
    const [ area, setArea ] = useState([])
    const [ coordinator, setCoordinator ] = useState([])
    const [ status, setStatus ] = useState([])
    const [ filtros_data, setFiltrosData ] = useState([])
    const [ project, setProject ] = useState([])
    
    useEffect(() => {
        getResourceReportProjectAmendment()
    }, [])
    function getResourceReportProjectAmendment(){
        getResource()
        .then(resp => {
            setCoordinator(
                [
                    {value: '', label: '-- SELECIONE --'},
                    ...resp.data.coordinator.map(desc => ({
                        value: desc.id,
                        label: desc.name
                    }))
                ]
            )
            setArea(
                [
                    {value: '', label: '-- SELECIONE --'},
                    ...resp.data.area.map(desc => ({
                        value: desc.id,
                        label: desc.descricao
                    }))
                ]
            )

            setStatus(
                [
                    {value: '', label: '-- SELECIONE --'},
                    ...resp.data.status.map(desc => ({
                        value: desc.id,
                        label: desc.descricao
                    }))
                ]
            )
            setProject(
                [
                    {value: '', label: '-- SELECIONE --'},
                    ...resp.data.project.map(desc => ({
                        value: desc.id,
                        label: desc.projeto
                    }))
                ]
            )
            setFiltrosData(
                [
                    {value: '1', label: 'Data de lançamento'},
                    {value: '2', label: 'Data base'},
                ]
            )
        })
    }
    
    return (
        <>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <label>Coordenador</label>
                        <Select 
                            value={coordinator_report}
                            label="Single select"
                            options={coordinator}
                            onChange={(e) => dispatch({type:'changeCoordinatorReportProjectAmendment', payload:e})}             
                        /> 
                    </div>
                    <div className="col-md-12 mt-3">
                        <label>Área</label>
                        <Select 
                            value={area_report}
                            label="Single select"
                            options={area}
                            onChange={(e) => dispatch({type:'changeAreaReportProjectAmendment', payload:e})}                      
                        /> 
                    </div>

                    <div className="col-md-12 mt-3">
                        <label>Projeto</label>
                        <Select 
                            value={project_report}
                            label="Single select"
                            options={project}
                            onChange={(e) => dispatch({type:'changeProjectReportProjectAmendment', payload:e})}                      
                        /> 
                    </div>
                   
                </div>
                <div className="row mt-3 mb-4">
                    <div className="col-md-12 mt-3">
                        <label>Status</label>
                        <Select 
                            value={status_report}
                            label="Single select"
                            options={status}
                            onChange={(e) => dispatch({type:'changeStatusReportProjectAmendment', payload:e})}   
                        /> 
                    </div>
                    <div className="col-md-12 mt-3">
                        <label>Filtrar data por</label>
                        <Select 
                            value={filter_data}
                            label="Single select"
                            options={filtros_data}
                            onChange={(e) => dispatch({type:'changeFilterDataProjectAmendment', payload:e})}   
                        /> 
                    </div>
                    <div className="col-md-12 mt-3">     
                        <Input 
                            label="De"
                            type="date"
                            icon="fas fa-calendar"
                            onChange={(e) => dispatch({ type: 'changeFromReportProjectAmendment', payload:e.target.value })}
                            value={from}
                        />
                    </div>
                    <div className="col-md-12 mt-3"> 
                        <Input 
                            label="Até"
                            type="date"
                            icon="fas fa-calendar"
                            onChange={(e) => dispatch({ type: 'changeToReportProjectAmendment', payload:e.target.value })}
                            value={to}
                        />
                    </div>   
                </div>
            </div>
        </>
    )
}
