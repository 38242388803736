const VisitReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Visits':
            return{...state, visits:actions.payload}

        case 'GetVisit_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetVisit_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_visit':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaVisit':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaVisit':
            return{...state, 
                pesquisa:actions.payload,
                technical_report: actions.technical_report,
                coordinator_report: actions.coordinator_report,
                project_report: actions.project_report ,  
                remuneration_report: actions.remuneration_report , 
                status_report: actions.status_report,
                from: actions.from ,
                to: actions.to
            }

        case 'changeTechnicalReportVisit':
            return{...state, technical_report:actions.payload}

        case 'changeCoordinatorReportVisit':
            return{...state, coordinator_report:actions.payload}

        case 'changeProjectReportVisit':
            return{...state, project_report:actions.payload}

        case 'changeRemunerationReport':
            return{...state, remuneration_report:actions.payload}
        
        case 'changeStatusReportVisit':
            return{...state, status_report:actions.payload}

        case 'changeFromReportVisit':
            return{...state, from:actions.payload}

        case 'changeToReportVisit':
            return{...state, to:actions.payload}

        case 'changePageVisit':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default VisitReducer;