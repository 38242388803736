import React, { useState, useEffect } from "react";
import Select from "react-select";
import Input from "../../../Forms/Input";
import { useSelector, useDispatch } from "react-redux";
import { getResource } from "../../../Services/billsToPay";

export default function ReportBills(props) {
    const status = useSelector((state) => state.bills_to_pay.status_report);
    const [statusOptions, setStatusOptions] = useState([]);

    const technical = useSelector((state) => state.bills_to_pay.technical);
    const [technicalOptions, setTechnicalOptions] = useState([]);

    const typeBill = useSelector((state) => state.bills_to_pay.typeBill);
    const [typeBillOptions, setTypeBillOptions] = useState([]);

    const typeProjects = useSelector((state) => state.bills_to_pay.typeProjects);
    const [typeProjectsOptions, setTypeProjectsOptions] = useState([]);

    const projectBill = useSelector((state) => state.bills_to_pay.projectBill);
    const [projectBillOptions, setProjectBillOptions] = useState([]);

    const perfis = useSelector((state) => state.auth.user.perfis);

    const [projectsEnsino, setProjectsEnsino] = useState([]);
    const [projectsConsultoria, setProjectsConsultoria] = useState([]);

    const dispatch = useDispatch();

    // const typeBillManualOptions = [
    //     { label: "Diária", value: "Diária" },
    //     { label: "Mensal", value: "Mensal" },
    //     { label: "Apuracao", value: "Apuracao" },
    //     {
    //         label: "Apuracao - Serviços Extras",
    //         value: "Apuracao - Cobrança Extra",
    //     },
    // ];

    useEffect(() => {
        resource();
    }, []);

    const resource = () => {
        getResource().then((resp) => {
            // console.log('data',resp);
            setStatusOptions(
                resp.data.status.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setTechnicalOptions(
                resp.data.technical.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );

            setTypeBillOptions(
                resp.data.typeBillsToPay.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            
            setProjectsEnsino(
                resp.data.projetoEnsino.map((desc) => ({
                    value: desc.id,
                    label: desc.titulo,
                    typeProject: 2
                }))
            );
            setProjectsConsultoria(
                resp.data.projectBill.map((desc) => ({
                    value: desc.id,
                    label: desc.titulo,
                    typeProject: desc.id_tipo
                }))
            );
            setTypeProjectsOptions(
                resp.data.typeProjects.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
        });
    };

    useEffect(() => {
        setProjectBillOptions(
            projectsConsultoria.concat(projectsEnsino)
        )
    }, [projectsEnsino, projectsConsultoria]);

    return (
        <>
            <div className="col-md-12">
                <div className="row">
                    {perfis.indexOf(1) >= 0 ||
                    perfis.indexOf(2) >= 0 ||
                    perfis.indexOf(3) >= 0 ||
                    perfis.indexOf(6) >= 0 ? (
                        <div className="col-md-12 mt-4">
                            <label>Nome profissional</label>
                            <Select
                                value={technical}
                                label="Single select"
                                options={technicalOptions}
                                onChange={(e) =>
                                    dispatch({
                                        type: "BillsTechnicalChange",
                                        payload: e,
                                    })
                                }
                            />
                        </div>
                    ) : (
                        ""
                    )}

                    {perfis.indexOf(1) >= 0 ||
                    perfis.indexOf(2) >= 0 ||
                    perfis.indexOf(3) >= 0 ||
                    perfis.indexOf(6) >= 0 ? (
                        <div className="col-md-12 mt-4">
                            <label>Projeto</label>
                            <Select
                                value={projectBill}
                                label="Single select"
                                options={projectBillOptions}
                                onChange={(e) =>
                                    dispatch({
                                        type: "BillsProjectChange",
                                        payload: e,
                                    })
                                }
                            />
                        </div>
                    ) : (
                        ""
                    )}

                    <div className="col-md-12 mt-4">
                        <label>Remuneração</label>
                        <Select
                            value={typeBill}
                            label="Single select"
                            options={typeBillOptions}
                            onChange={(e) =>
                                dispatch({
                                    type: "BillsTypeChange",
                                    payload: e,
                                })
                            }
                        />
                    </div>

                    <div className="col-md-12 mt-4">
                        <label>Status</label>
                        <Select
                            value={status}
                            label="Single select"
                            options={statusOptions}
                            onChange={(e) =>
                                dispatch({
                                    type: "BillsStatusChange",
                                    payload: e,
                                })
                            }
                        />
                    </div>

                    <div className="col-md-12 mt-4">
                        <label>Tipo</label>
                        <Select
                            value={typeProjects}
                            label="Single select"
                            options={typeProjectsOptions}
                            onChange={(e) =>
                                dispatch({
                                    type: "BillsTypesProjectsChange",
                                    payload: e,
                                })
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
