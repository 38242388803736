import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard';
import Aguardando from '../../../Icons/aguardando.png';
import Iniciado from '../../../Icons/iniciado.png';
import Finalizado from '../../../Icons/finalizado.png';

export default function RefundsShared(props) {
    const dispatch = useDispatch();
    // const registers = useSelector(state => state.reimbursement_shared.refunds_shared.data) || []
    const id_role = useSelector(state => state.auth.user.id) || '';
    // const perfis = useSelector(state => state.auth.user.perfis) || []
    // const status = registers.map(desc => (
    //     desc.id_status
    // ))

    // function todosIguaisOuDiferentes(states,id,gerente,coordenador) {
    //     var filtrado = states.filter(function(elem, pos, arr) {
    //         return arr.indexOf(elem) == pos;
    //     });

    //     if(filtrado.length === 1){
    //         if(perfis.indexOf(7) >= 0){
    //             if(parseInt(id_role) === parseInt(gerente) || parseInt(id_role) === parseInt(coordenador)){
    //                 return (
    //                     <label className="chk">
    //                         <input className="input-check" style={{width: '30px', height: '30px'}} name="checked" type="checkbox" id="checked" onClick={props.check} value={id}/>
    //                         <span>

    //                         </span>
    //                     </label>

    //                 ) 


    //             }else{
    //                 return (
    //                     <label className="chk">
    //                         <input className="input-check" style={{width: '30px', height: '30px'}} disabled="true" type="checkbox"/>
    //                         <span></span>
    //                     </label>
    //                 ) 

    //             }
    //         }
    //         else{
    //             return (
    //                 <label className="chk">
    //                     <input className="input-check" style={{width: '30px', height: '30px'}} name="checked" type="checkbox" id="checked" onClick={props.check} value={id}/>
    //                     <span></span>
    //                 </label>
    //             ) 

    //         }
    //     }
    // }

    const reimbursement_shared = props.refunds_shared || [];
    return reimbursement_shared.map(desc => (
        <div key={desc.id} className='col-md-3 mt-4 mt-4'>
            <RecipeReviewCard
                id_status={desc.id_status}
                title={desc.name}
                actionRoute={props.action}
                descStatus={desc.descStatus}
                iconNew={
                    parseInt(desc.id_status) === 1 ? Aguardando :
                        parseInt(desc.id_status) === 2 ? Iniciado :
                            parseInt(desc.id_status) === 3 ? Finalizado :
                                Finalizado
                }
                colorNew={
                    parseInt(desc.id_status) === 1 ? '#f39c12' :
                        parseInt(desc.id_status) === 2 ? '#00c0ef' :
                            parseInt(desc.id_status) === 3 ? '#00a65a' :
                                Finalizado
                }
                // selectStatus={

                //     perfis.indexOf(1) >=0 || perfis.indexOf(2) >=0 || perfis.indexOf(3) >=0 || perfis.indexOf(4) >=0 || perfis.indexOf(6) >=0 || perfis.indexOf(8) >=0 ? (

                //         todosIguaisOuDiferentes(status,desc.id,desc.gerente,desc.coordenador)
                //     ):''

                // }
                rel={
                    <>
                        <h3 style={{ fontSize: '15px', fontWeight: 700 }}>Projetos</h3>
                        <hr></hr>
                        {
                            desc.projetos.map(res => (
                                <>
                                    <p style={{ height: '30px' }}>
                                        <span style={{ height: '40px', fontWeight: 700 }}>
                                            Projeto:
                                            {res.tipo_projeto === 1 &&
                                                <Link to={`/project/${res.id_projeto}/view`}>{res.projeto}</Link>}
                                            {res.tipo_projeto === 2 && <Link
                                                to={`project_ensino/${res.id_projeto}/view/`}>{res.projeto}</Link>}
                                        </span>

                                    </p>
                                    <p style={{ height: '30px' }}>
                                        <span style={{ height: '40px', fontWeight: 700 }}>
                                            Coordenador: 
                                        </span>
                                        {` ${res.coordenador}`}
                                    </p>
                                    <p style={{ height: '30px' }}>
                                        <span style={{ height: '40px', fontWeight: 700 }}>
                                            Percentual: 
                                        </span>
                                        {`${res.percentual} %`}
                                    </p>
                                    <p style={{
                                        height: '30px',
                                        color: res.status === 'Aprovado' ? 'green' : res.status === 'Em Análise' ? 'orange' : res.status === 'Reprovado' ? 'red' : 'green',
                                    }}>
                                        <span style={{ height: '40px', fontWeight: 700 }}>
                                            Status: 
                                        </span>
                                        {` ${res.status}`}
                                    </p>
                                    <hr></hr>
                                </>

                            ))
                        }

                        <>
                            <h3 style={{ fontSize: '15px', fontWeight: 700 }}>Comprovantes</h3>
                            <hr></hr>
                            {
                                desc.anexos.map(re => (
                                    <p>
                                        <span style={{ fontWeight: 700, fontSize: '11px' }}>
                                            <a href={re.link} target='_blank' rel='noopener noreferrer'>{re.nome}</a>
                                        </span>
                                    </p>
                                ))
                            }
                            <hr></hr>
                            {desc.id_status === 3 && (
                                <>
                                    <h3 style={{ fontSize: '15px', fontWeight: 700 }}>Motivo Reprovação</h3>
                                    <hr></hr>
                                    <p>{desc.motivo_reprovacao}</p>
                                </>
                            )}
                        </>

                    </>

                }
                actions={


                    <>
                        <ul className='nav'>
                            <li className='nav-item dropdown'>
                                <a className='nav-link' data-toggle='dropdown' href='#/'>
                                    <i style={{ color: '#FFF', width: '30px', height: '30px' }}
                                       className='fas fa-bars' />
                                </a>
                                <div className='dropdown-menu dropdown-menu-right'>

                                    <Link
                                        to={`/reimbursement_shared/${desc.id}/view`}
                                        className='dropdown-item'
                                    >
                                        <i className='fas fa-eye' /> Detalhes
                                    </Link>
                                    {
                                        parseInt(desc.id_status) !== 3 && parseInt(desc.created_by) === parseInt(id_role) && (
                                            <>
                                                <Link
                                                    to={`/reimbursement_shared/${desc.id}/edit`}
                                                    className='dropdown-item'
                                                >
                                                    <i className='fas fa-edit' /> Editar
                                                </Link>
                                                {
                                                    parseInt(desc.id_status) === 1 &&
                                                    <Link
                                                        to='#/'
                                                        className='dropdown-item'
                                                        onClick={() => props.onDelete(desc.id)}
                                                    >
                                                        <i className='fas fa-trash-alt' /> Excluir
                                                    </Link>
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            </li>
                        </ul>

                        {/* <ButtonAction>
                            <button 
                                    onClick={(e) => {e.preventDefault();props.history.push(`/reimbursement/${desc.id}/view`);e.preventDefault()}} 
                                    className='btn'
                                >
                                    <img src={IconView}></img>
                            </button>
                        </ButtonAction>
                        
                        {desc.id_status === 1 && parseInt(desc.created_by) === parseInt(id_role) && (
                            <>
                                <ButtonAction>
                                    <button 
                                        onClick={(e) => {e.preventDefault();props.history.push(`/reimbursement/${desc.id}/edit`);e.preventDefault()}} 
                                        className='btn  ml-4' 
                                    >
                                        <img src={IconEdit}></img>
                                    </button>
                                </ButtonAction>
                                <ButtonAction>
                                <button 
                                    type="button" 
                                    className='btn  ml-4' 
                                    onClick={()=>props.onDelete(desc.id)} 
                                >
                                    <img src={IconDel}></img>
                                </button>                                
    
                                </ButtonAction>
                            </>
                        )} */}
                    </>


                }
                // actionsStatus={
                //     props.action !== 'view' &&


                //             perfis.indexOf(1) >=0 || perfis.indexOf(2) >=0 || perfis.indexOf(3) >=0 || perfis.indexOf(4) >=0 || perfis.indexOf(6) >=0 || perfis.indexOf(8) >=0 ? 
                //                     (
                //                         perfis.indexOf(7) >= 0 ? (
                //                             parseInt(id_role) === parseInt(desc.gerente) || parseInt(id_role) === parseInt(desc.coordenador) ? (
                //                                 desc.id_status === 1 ? (
                //                                     <>
                //                                         <ButtonAction backgroundButton="#1F9950">
                //                                             <button 
                //                                                 type="button" 
                //                                                 className='btn'
                //                                                 onClick={()=>props.changeStatusReimbursement(desc.id,2)}
                //                                             >
                //                                                 <img style={{width: '25px', height: '25px'}} src={AprovedIcon}></img>
                //                                             </button>
                //                                         </ButtonAction>
                //                                         <ButtonAction backgroundButton="#F6525C">
                //                                             <button 
                //                                                 type="button" 
                //                                                 className='btn ml-3' 
                //                                                 onClick={()=>props.modalReprovedOn(desc.id,3)}
                //                                             >
                //                                                 <img style={{width: '25px', height: '25px'}} src={ReprovedIcon}></img>
                //                                             </button>                  
                //                                         </ButtonAction>
                //                                     </> 

                //                                 ) : desc.id_status === 2 ? (

                //                                     <>
                //                                         <ButtonAction backgroundButton="#F0E70C">

                //                                             <button 
                //                                                 type="button" 
                //                                                 className='btn'
                //                                                 onClick={()=>props.changeStatusReimbursement(desc.id,1)}
                //                                             >
                //                                                 <img style={{width: '25px', height: '25px'}} src={IconAnalysys}></img>
                //                                             </button>
                //                                         </ButtonAction>
                //                                         <ButtonAction backgroundButton="#F6525C">

                //                                             <button 
                //                                                 type="button" 
                //                                                 className='btn ml-3'
                //                                                 onClick={()=>props.modalReprovedOn(desc.id,3)}
                //                                             >
                //                                                 <img style={{width: '25px', height: '25px'}} src={ReprovedIcon}></img>
                //                                             </button>
                //                                         </ButtonAction>
                //                                         {/* <ButtonAction>

                //                                             <button 
                //                                                 type="button" 
                //                                                 className='btn ml-3'
                //                                                 onClick={()=>props.changeStatusReimbursement(desc.id,4)} 
                //                                             >
                //                                                 <img style={{width: '25px', height: '25px'}} src={IconBilled}></img>
                //                                             </button>                  
                //                                         </ButtonAction> */}
                //                                     </>

                //                                 ) :desc.id_status === 3 ? (

                //                                     <>
                //                                         <ButtonAction backgroundButton="#F0E70C">

                //                                             <button 
                //                                                 type="button" 
                //                                                 className='btn'
                //                                                 onClick={()=>props.changeStatusReimbursement(desc.id,1)}
                //                                             >
                //                                                 <img style={{width: '25px', height: '25px'}} src={IconAnalysys}></img>
                //                                             </button>
                //                                         </ButtonAction>
                //                                         <ButtonAction backgroundButton="#1F9950">

                //                                             <button 
                //                                                 type="button" 
                //                                                 className='btn ml-3'
                //                                                 onClick={()=>props.changeStatusReimbursement(desc.id,2)}
                //                                             >
                //                                                 <img style={{width: '25px', height: '25px'}} src={AprovedIcon}></img>
                //                                             </button>                 
                //                                         </ButtonAction>
                //                                     </>

                //                                 ):('')
                //                             ):('')
                //                         ):
                //                         desc.id_status === 1 ? (

                //                             <>
                //                                 <ButtonAction backgroundButton="#1F9950">

                //                                     <button 
                //                                         type="button" 
                //                                         className='btn'
                //                                         onClick={()=>props.changeStatusReimbursement(desc.id,2)}
                //                                     >
                //                                         <img style={{width: '25px', height: '25px'}} src={AprovedIcon}></img>
                //                                     </button>
                //                                 </ButtonAction>
                //                                 <ButtonAction backgroundButton="#F6525C">

                //                                     <button 
                //                                         type="button" 
                //                                         className='btn ml-3' 
                //                                         onClick={()=>props.modalReprovedOn(desc.id,3)}
                //                                     >
                //                                         <img style={{width: '25px', height: '25px'}} src={ReprovedIcon}></img>
                //                                     </button>                  
                //                                 </ButtonAction>
                //                             </> 

                //                         ) : desc.id_status === 2 ? (

                //                             <>
                //                                 <ButtonAction backgroundButton="#F0E70C">

                //                                     <button 
                //                                         type="button" 
                //                                         className='btn'
                //                                         onClick={()=>props.changeStatusReimbursement(desc.id,1)}
                //                                     >
                //                                         <img style={{width: '25px', height: '25px'}} src={IconAnalysys}></img>
                //                                     </button>
                //                                 </ButtonAction>
                //                                 <ButtonAction backgroundButton="#F6525C">

                //                                     <button 
                //                                         type="button" 
                //                                         className='btn ml-3'
                //                                         onClick={()=>props.modalReprovedOn(desc.id,3)}
                //                                     >
                //                                         <img style={{width: '25px', height: '25px'}} src={ReprovedIcon}></img>
                //                                     </button>
                //                                 </ButtonAction>
                //                                 {/* <ButtonAction>

                //                                     <button 
                //                                         type="button" 
                //                                         className='btn ml-3'
                //                                         onClick={()=>props.changeStatusReimbursement(desc.id,4)} 
                //                                     >
                //                                         <img style={{width: '25px', height: '25px'}} src={IconBilled}></img>
                //                                     </button>                  
                //                                 </ButtonAction> */}
                //                             </>

                //                         ) :desc.id_status === 3 ? (

                //                             <>
                //                                 <ButtonAction backgroundButton="#F0E70C">
                //                                     <button 
                //                                         type="button" 
                //                                         className='btn'
                //                                         onClick={()=>props.changeStatusReimbursement(desc.id,1)}
                //                                     >
                //                                         <img style={{width: '25px', height: '25px'}} src={IconAnalysys}></img>
                //                                     </button>
                //                                 </ButtonAction>
                //                                 <ButtonAction backgroundButton="#1F9950">
                //                                     <button 
                //                                         type="button" 
                //                                         className='btn ml-3'
                //                                         onClick={()=>props.changeStatusReimbursement(desc.id,2)}
                //                                     >
                //                                         <img style={{width: '25px', height: '25px'}} src={AprovedIcon}></img>
                //                                     </button>                 
                //                                 </ButtonAction>
                //                             </>

                //                         ):('')


                //                     ):''}

            >

                {/* <p style={{height: '30px'}}>
                    <span style={{height: '40px', fontWeight: 700}}>Projeto :</span> <span>{desc.titulo}</span>
                </p> */}
                <p><span style={{ height: '40px', fontWeight: 700 }}>Tipo :</span> <span>{desc.descTipo}</span></p>
                {desc.id_tipo === 3 || desc.id_tipo === 11 || desc.id_tipo === 12 ?
                    <p><span style={{ height: '40px', fontWeight: 700 }}>Km :</span> <span>{`${desc.km} km`}</span>
                    </p> : ''}
                <p><span style={{ height: '40px', fontWeight: 700 }}>Valor :</span>
                    <span>{`R$${props.formatReal(parseFloat(desc.valor))}`}</span></p>
                <p><span style={{ height: '40px', fontWeight: 700 }}>Data de Lançamento :</span>
                    <span>{desc.data_lancamento}</span></p>
                <p><span style={{ height: '40px', fontWeight: 700 }}>Data da Despesa :</span>
                    <span>{desc.data_despesa}</span></p>
                <a href='#/' onClick={(e) => {
                    dispatch({ type: 'changeIdReimbursementShared', payload: desc.id });
                    e.preventDefault();
                    props.history.push(`/refunds`);
                    e.preventDefault();
                }}>Ir Para Reembolso</a>
            </RecipeReviewCard>
        </div>

    ));


    // return reimbursement_shared.map(desc=>(
    //     <tr key={desc.id}>
    //         {
    //             perfis.indexOf(1) >=0 || perfis.indexOf(2) >=0 || perfis.indexOf(3) >=0 || perfis.indexOf(4) >=0 || perfis.indexOf(6) >=0 || perfis.indexOf(8) >=0 ? (

    //                 todosIguaisOuDiferentes(status,desc.id)
    //             ):''
    //         }
    //         <td  
    //             className="text-center" 
    //             style={{
    //                 width: '10%',
    //                 color: 
    //                     `${
    //                         desc.descStatus === 'Aprovado' 
    //                         ? ('green')
    //                         : desc.descStatus === 'Em Análise' 
    //                         ? ('orange')
    //                         : desc.descStatus === 'Reprovado'
    //                         ? ('red') 
    //                         : ('blue')
    //                     }`
    //             }}
    //         >
    //             {
    //                 desc.descStatus === 'Reprovado' 
    //                 ? (<i className="fas fa-times"></i>)
    //                 : desc.descStatus === 'Em Análise' 
    //                 ? (<i className="fas fa-exclamation-circle"></i>)
    //                 : desc.descStatus === 'Aprovado'
    //                 ? (<i className="fas fa-chevron-down"></i>)
    //                 : (<i className="fas fa-check-double"></i>)
    //             }
    //         </td>

    //         <td style={{width: '10%'}}>{desc.name}</td>
    //         <td>{desc.titulo}</td>
    //         <td>{desc.descTipo}</td>
    //         <td style={{width: '10%'}}>R${formatReal(parseFloat(`${desc.valor}`))}</td>
    //         <td style={{width: '14%'}}>{desc.data_lancamento}</td>
    //         <td style={{width: '11%'}}>{desc.data_despesa}</td>
    //         <td>
    //             <ul className="nav">
    //                 <li className="nav-item dropdown">
    //                     <a className="nav-link" data-toggle="dropdown" href="#/">
    //                         <i className="fas fa-ellipsis-h" />             
    //                     </a>
    //                     <div className='dropdown-menu dropdown-menu-right'>
    //                         {
    //                             perfis.indexOf(1) >=0 || perfis.indexOf(2) >=0 || perfis.indexOf(3) >=0 || perfis.indexOf(4) >=0 || perfis.indexOf(6) >=0 || perfis.indexOf(8) >=0 ? (

    //                                 desc.id_status === 1 ? (

    //                                     <>
    //                                         <Link 
    //                                             to={`#/`} 
    //                                             className='dropdown-item'
    //                                             onClick={()=>changeStatusReimbursementShared(desc.id,2)}
    //                                         >
    //                                             <i className='fas fa-check-circle' /> Aprovar
    //                                         </Link>
    //                                         <Link 
    //                                             to={`#/`} 
    //                                             className='dropdown-item'
    //                                             onClick={()=>changeStatusReimbursementShared(desc.id,3)} 
    //                                         >
    //                                             <i className='fas fa-times-circle' /> Reprovar
    //                                         </Link>                  
    //                                     </> 

    //                                 ) : desc.id_status === 2 ? (

    //                                     <>
    //                                         <Link 
    //                                             to={`#/`} 
    //                                             className='dropdown-item'
    //                                             onClick={()=>changeStatusReimbursementShared(desc.id,1)}
    //                                         >
    //                                             <i className='fas fa-exclamation-circle' /> Em Análise
    //                                         </Link>
    //                                         <Link 
    //                                             to={`#/`} 
    //                                             className='dropdown-item'
    //                                             onClick={()=>changeStatusReimbursementShared(desc.id,3)}
    //                                         >
    //                                             <i className='fas fa-times-circle' /> Reprovar
    //                                         </Link>
    //                                         <Link 
    //                                             to={`#/`} 
    //                                             className='dropdown-item' 
    //                                             onClick={()=>changeStatusReimbursementShared(desc.id,4)}
    //                                         >
    //                                             <i className='fas fa-check-double' /> Faturar
    //                                         </Link>                  
    //                                     </>

    //                                 ) :desc.id_status === 3 ? (

    //                                     <>
    //                                         <Link 
    //                                             to={`#/`} 
    //                                             className='dropdown-item'
    //                                             onClick={()=>changeStatusReimbursementShared(desc.id,1)}
    //                                         >
    //                                             <i className='fas fa-exclamation-circle' /> Em Análise
    //                                         </Link>
    //                                         <Link 
    //                                             to={`#/`} 
    //                                             className='dropdown-item'
    //                                             onClick={()=>changeStatusReimbursementShared(desc.id,2)}
    //                                         >
    //                                             <i className='fas fa-check-circle' /> Aprovar
    //                                         </Link>                 
    //                                     </>

    //                                 ):('')
    //                             ):''
    //                         }
    //                         <Link 
    //                             to={`/reimbursement_shared/${desc.id}/view`} 
    //                             className='dropdown-item'
    //                         >
    //                             <i className='fas fa-eye' /> Detalhes
    //                         </Link>
    //                         {
    //                             desc.id_status === 1 && parseInt(desc.created_by) === parseInt(id_role) && (
    //                                 <>
    //                                     <Link 
    //                                         to={`/reimbursement_shared/${desc.id}/edit`} 
    //                                         className='dropdown-item' 
    //                                     >
    //                                         <i className='fas fa-edit' /> Editar
    //                                     </Link>
    //                                     <Link 
    //                                         to="#/" 
    //                                         className='dropdown-item' 
    //                                         onClick={()=>onDelete(desc.id)} 
    //                                     >
    //                                         <i className='fas fa-trash-alt' /> Excluir
    //                                     </Link>                                
    //                                 </>
    //                             )
    //                         }
    //                     </div>
    //                 </li>
    //             </ul>		
    //         </td>                
    //     </tr>
    // ))

}
