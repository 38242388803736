/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import Footer from '../../../Footer/Footer';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    changePage,
    get,
    order,
    change_qtd_per_page,
    changePesquisa,
    clearPesquisa,
} from './Actions/actions';
import {
    del_visit,
    get_visits,
    changeStatus,
    changeStatusAll,
    exportExcel,
} from '../../../Services/visit';
import { pagination } from '../../../Pagination/paginations';
import Pagination from '../../../Table/Pagination';
import Alert from '../../../Alerts/Alert';
import MyModal from '../../../Modal/MyModal';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import Visits from './Visits';
import { CSVLink } from 'react-csv';
import ReportVisit from './ReportVisit';
import CabecalhoNew from '../../../Table/CabecalhoNew';
import Input from '../../../Forms/Input';
import TextArea from '../../../Forms/TextArea';
import Form from '../../../Forms/Form';
import { goButton } from './js/goButton';
import AprovedIcon from '../../../Icons/emojione-v1_ballot-box-bold-check.png';
import ReprovedIcon from '../../../Icons/fluent_document-footer-remove-24-filled.png';
import IconAnalysys from '../../../Icons/cib_anaconda.png';
import IconBilled from '../../../Icons/ic_round-payments.png';

function GridVisit(props) {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [description, setDescription] = useState('');
    const [modalReproved, setModalReproved] = useState(false);
    const [idVisit, setIdVisit] = useState('');
    const [id, setId] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [visits, setVisits] = useState([]);
    const [displayLegend, setDisplayLegend] = useState(true);
    const [modalFilter, setModalFilter] = useState(false);
    const headers = [
        { label: 'Nome do Técnico', key: 'name' },
        { label: 'Id Projeto', key: 'id_projeto' },
        { label: 'Título do Projeto', key: 'titulo' },
        { label: 'Tipo de Remuneração', key: 'descRemuneracao' },
        { label: 'Status', key: 'descStatus' },
        { label: 'Descrição', key: 'descAtividade' },
        { label: 'Data de Lançamento', key: 'data_lancamento' },
        { label: 'Data da Despesa', key: 'data_despesa' },
        { label: 'Valor', key: 'valor' },
        { label: 'Centro de Custo', key: 'centroCusto' },
    ];
    const from = useSelector((state) => state.visit.from);
    const to = useSelector((state) => state.visit.to);
    const dispatch = useDispatch();
    const [status_all, setStatusAll] = useState([]);
    const fileUpload = useRef();

    const getVisits = () => {
        props.get();
    };

    useEffect(() => {
        getVisits();
        return () => {
            getVisits();
        };
    }, []);

    // function todosIguaisOuDiferentes() {
    //     let get_visits_all= props.visits || []
    //     var filtrado = get_visits_all.map(desc => (
    //         desc.id_status
    //     )).filter(function(elem, pos, arr) {
    //         return arr.indexOf(elem) == pos;
    //     });

    //     if(filtrado.length === 1){
    //         return <th><input onClick={clickAll} name="checks" type="checkbox" id="checks"/></th>
    //     }
    // }

    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
    };

    const handleCloseExcluir = (event) => {
        setModal(false);
        setId('');
    };

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false);

        del_visit(id)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError('');
                setLoading(false);
            })
            .then((resp) =>
                setTimeout(() => {
                    setSuccess('');
                }, 4000)
            )
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            })
            .then((resp) => props.get());
    };

    //Função que formata o campo valor
    function formatReal(desc) {
        var tmp = `${desc.toFixed(2)}`.replace('.', ',');

        if (tmp.length > 6)
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, '.$1');
        return tmp;
    }

    const changeStatusVisit = (id_visit, id_status) => {
        setLoading(true);
        const object = {
            id_status: parseInt(id_status),
        };
        changeStatus(id_visit, object)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError('');
                setLoading(false);
                setTimeout(() => {
                    setSuccess('');
                }, 4000);
            })
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            })
            .then((resp) => props.get());
    };

    const modalReprovedOn = (id_visit) => {
        setModalReproved(true);
        setIdVisit(id_visit);
    };

    const changeStatusReimbursementReproved = (e) => {
        e.preventDefault();
        setModalReproved(false);
        setLoading(true);
        const object = {
            id_status: 3,
            description: description,
        };
        changeStatus(idVisit, object)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError('');
                setLoading(false);
                setDescription('');
                setTimeout(() => {
                    setSuccess('');
                }, 4000);
            })
            .catch((e) => {
                setLoading(false);
                setDescription('');
                setError(e.response.data.error);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            })
            .then((resp) => props.get());
    };

    const changeStatusVisitAll = (status) => {
        setLoading(true);
        const object = {
            idsVisits: status_all,
            status: status,
        };
        changeStatusAll(object)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError('');
                setLoading(false);
                setStatusAll([]);
                setTimeout(() => {
                    setSuccess('');
                }, 4000);
            })
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            })
            .then((resp) => props.get());
    };

    const check = (event) => {
        let checked = event.target.checked;
        if (checked === true) {
            setStatusAll(status_all.concat(parseInt(event.target.value)));
        } else {
            setStatusAll(
                status_all.filter(
                    (desc) => parseInt(desc) !== parseInt(event.target.value)
                )
            );
        }
    };

    // const clickAll = event => {
    // 	let isChecked = event.target.checked
    // 	let checked = document.querySelectorAll('#checked')
    // 	if(isChecked){
    // 		setStatusAll([])
    // 		for(let i=0;i<checked.length;i++){
    // 			checked[i].checked = true
    //         }
    //         let visits_all = props.visits || []
    // 		setStatusAll(visits_all.map(desc=>(
    // 			desc.id_atividades
    // 		)))

    // 	}else if(!isChecked){
    // 		for(let i=0;i<checked.length;i++){
    // 			checked[i].checked = false
    // 		}
    // 		setStatusAll([])

    // 	}
    // }

    const download = (event) => {
        exportExcel(
            props.page,
            props.qtd_total,
            props.order_var,
            props.th,
            props.pesquisa,
            props.id_technical,
            props.id_coordinator,
            props.id_project,
            props.id_remuneration,
            props.id_status,
            props.from_date,
            props.to_date
        )
            .then((resp) => {
                setVisits(resp.data.data);
            })
            .then((resp) => {
                fileUpload.current.link.click();
            });
    };

    return (
        <div className="wrapper" style={{ backgroundColor: '#E5E5E5, 100%' }}>
            <Header />
            <Sidebar />
            <div
                className="content-wrapper"
                style={{ backgroundColor: '#E5E5E5, 100%' }}
            >
                <section
                    className="content"
                    style={{ backgroundColor: '#E5E5E5, 100%' }}
                >
                    <MyModal
                        show={modal}
                        onHide={handleCloseExcluir}
                        title="Excluir Visita"
                        content="Tem certeza que deseja excluir a visita ?"
                        cancel={handleCloseExcluir}
                        del={handleExcluir}
                        to="/visits"
                        type="danger"
                        textButton="Excluir"
                    />
                    <div className="row">
                        <div className="col-md-12">
                            <MyModal
                                show={modalFilter}
                                onHide={(e) => setModalFilter(false)}
                                title="Filtrar"
                                content={<ReportVisit />}
                                cancel={function () {
                                    return [
                                        props.clearPesquisa(),
                                        setTimeout(() => {
                                            setModalFilter(false);
                                        }, 1000),
                                    ];
                                }}
                                del={function () {
                                    return [
                                        props.get(),
                                        setTimeout(() => {
                                            setModalFilter(false);
                                        }, 1000),
                                    ];
                                }}
                                to="#/"
                                type="success"
                                contentSecondary="Limpar Busca"
                                textButton="Buscar"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <MyModal
                                show={modalReproved}
                                onHide={(e) => setModalReproved(false)}
                                title="Reprovar Lançamento"
                                content={
                                    <Form
                                        onSubmit={
                                            changeStatusReimbursementReproved
                                        }
                                    >
                                        <TextArea
                                            label="Motivo"
                                            maxLength="254"
                                            placeholder="Descreva o motivo da reprovação"
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                            value={description}
                                            required={true}
                                        />
                                        <div className="text-right">
                                            <button
                                                style={{ marginRight: '10px' }}
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e) =>
                                                    setModalReproved(false)
                                                }
                                            >
                                                Cancelar
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-danger"
                                            >
                                                Reprovar
                                            </button>
                                        </div>
                                    </Form>
                                }
                                onSubmit={true}
                                cancel={(e) => setModalReproved(false)}
                                // del={changeStatusReimbursementReproved}
                                to="#/"
                                type="danger"
                                textButton="Reprovar"
                            />
                        </div>
                    </div>
                    {loading === true ? (
                        <Content>
                            <ReactLoading
                                type="spinningBubbles"
                                color="blue"
                                height={200}
                                width={100}
                            />
                        </Content>
                    ) : (
                        ''
                    )}
                    <div
                        style={{ display: loading === true ? 'none' : 'block' }}
                    >
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="box">
                                    <div
                                        className="box-body"
                                        style={{
                                            background: '#FFFFFF',
                                            boxShadow:
                                                '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                            padding: '15px',
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <h2>Registros de Visitas</h2>
                                            </div>
                                            <div className="text-right col-sm-6">
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        props.history.push(
                                                            `/new_visit`
                                                        );
                                                        e.preventDefault();
                                                    }}
                                                    className="btn btn-primary"
                                                >
                                                    Cadastro
                                                </button>
                                            </div>
                                        </div>
                                        {props.visits.length >= 0 ||
                                        props.pesquisa ? (
                                            <CabecalhoNew
                                                filter={
                                                    <>
                                                        <div className="col-md-3 col-sm-12 mt-3">
                                                            <Input
                                                                label="De"
                                                                type="date"
                                                                icon="fas fa-calendar"
                                                                onChange={(e) =>
                                                                    dispatch({
                                                                        type: 'changeFromReportVisit',
                                                                        payload:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                                value={from}
                                                            />
                                                        </div>
                                                        <div className="col-md-3 col-sm-12 mt-3">
                                                            <Input
                                                                label="Até"
                                                                type="date"
                                                                icon="fas fa-calendar"
                                                                onChange={(e) =>
                                                                    dispatch({
                                                                        type: 'changeToReportVisit',
                                                                        payload:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                                value={to}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                buttonStatus={goButton(
                                                    props.visits,
                                                    status_all,
                                                    changeStatusVisitAll
                                                )}
                                                buttonCsv={
                                                    <>
                                                        <button
                                                            style={{
                                                                background:
                                                                    '#005F31',
                                                                color: '#FFF',
                                                                borderRadius:
                                                                    '20.5px',
                                                                width: '140px',
                                                            }}
                                                            className="btn btn-sm"
                                                            onClick={download}
                                                        >
                                                            <i className="fas fa-file-csv"></i>{' '}
                                                            Exportar CSV
                                                        </button>
                                                        <button
                                                            style={{
                                                                background:
                                                                    '#1A170E',
                                                                color: '#FFF',
                                                                borderRadius:
                                                                    '20.5px',
                                                                width: '140px',
                                                                marginLeft:
                                                                    '10px',
                                                            }}
                                                            className="btn btn-sm"
                                                            onClick={() =>
                                                                setModalFilter(
                                                                    modalFilter ===
                                                                        false
                                                                        ? true
                                                                        : false
                                                                )
                                                            }
                                                        >
                                                            <i className="fas fa-filter"></i>{' '}
                                                            Mais Filtros
                                                        </button>

                                                        <CSVLink
                                                            filename={`Relatorio-${new Date()}.csv`}
                                                            headers={headers}
                                                            hidden={true}
                                                            separator={';'}
                                                            ref={fileUpload}
                                                            data={visits || []}
                                                        >
                                                            <span className="fas fa-file-csv">
                                                                {' '}
                                                                Exportar CSV
                                                            </span>
                                                        </CSVLink>
                                                    </>
                                                }
                                                to="#/"
                                                action={props.get}
                                                clear={props.clearPesquisa}
                                                valuePesquisa={props.pesquisa}
                                                value={props.qtd_per_page}
                                                onChange={
                                                    props.change_qtd_per_page
                                                }
                                                changePesquisa={
                                                    props.changePesquisa
                                                }
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div
                                style={{
                                    display: `${
                                        displayLegend === true
                                            ? 'none'
                                            : 'block'
                                    }`,
                                }}
                                className="text-right"
                            >
                                <button
                                    onClick={(e) => setDisplayLegend(true)}
                                    className="btn btn-secondary"
                                    style={{
                                        background:
                                            'linear-gradient(99.87deg, #427B67 25.78%, #22AC7A 65.59%)',
                                        borderRadius: '20.5px',
                                    }}
                                >
                                    Legendas
                                </button>
                            </div>
                            <div
                                style={{
                                    backgroundColor: '#FFF',
                                    padding: '10px',
                                    borderRadius: '25px',
                                    display: `${
                                        displayLegend === false
                                            ? 'none'
                                            : 'block'
                                    }`,
                                }}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        float: 'right',
                                    }}
                                >
                                    <button
                                        onClick={(e) => setDisplayLegend(false)}
                                        className="btn"
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-1 mt-2">
                                        <h5 style={{ fontSize: '16px' }}>
                                            Legendas:
                                        </h5>
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span>
                                            <img
                                                src={IconAnalysys}
                                                width="20px"
                                                alt="Analise"
                                            />{' '}
                                            Em Análise
                                        </span>
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span>
                                            <img
                                                src={AprovedIcon}
                                                width="20px"
                                                alt="Aprovar"
                                            />{' '}
                                            Aprovar
                                        </span>
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span>
                                            <img
                                                src={ReprovedIcon}
                                                width="20px"
                                                alt="Reprovar"
                                            />{' '}
                                            Reprovar
                                        </span>
                                    </div>
                                    <div className="col-md-1 mt-2">
                                        <span>
                                            <img
                                                src={IconBilled}
                                                width="20px"
                                                alt="Faturar"
                                            />{' '}
                                            Faturar
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {success ? (
                                <Alert type="success">{success}</Alert>
                            ) : (
                                ''
                            )}
                            {error ? <Alert type="danger">{error}</Alert> : ''}
                            <div className="row mt-3">
                                {parseInt(props.visits.length) === 0 ? (
                                    <div
                                        style={{
                                            color: '#FFFF',
                                            textAlign: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <span style={{ color: 'black' }}>{`${
                                            !props.pesquisa
                                                ? 'Não existem Visitas cadastradas no sistema. Clique no botão Cadastro para inserir uma nova visita no Sistema'
                                                : 'Nenhum registro encontrado na pesquisa'
                                        }`}</span>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <Visits
                                    check={check}
                                    modalReprovedOn={modalReprovedOn}
                                    history={props.history}
                                    changeStatusVisit={changeStatusVisit}
                                    visits={props.visits}
                                    formatReal={formatReal}
                                    onDelete={onChangeModal}
                                />
                            </div>
                        </div>
                        {props.visits.length > 0 && (
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="box">
                                        <div
                                            className="box-body"
                                            style={{
                                                background: '#FFFFFF',
                                                boxShadow:
                                                    '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                                padding: '15px',
                                            }}
                                        >
                                            <div className="row mt-4">
                                                <div className="col-md-1">
                                                    <select
                                                        value={
                                                            props.qtd_per_page
                                                        }
                                                        onChange={
                                                            props.change_qtd_per_page
                                                        }
                                                        name="example1_length"
                                                        aria-controls="example1"
                                                        className="custom-select custom-select-sm form-control form-control-sm"
                                                    >
                                                        <option value={8}>
                                                            8
                                                        </option>
                                                        <option value={24}>
                                                            24
                                                        </option>
                                                        <option value={48}>
                                                            48
                                                        </option>
                                                        <option value={96}>
                                                            96
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="col-md-5 mt-1 mb-2">
                                                    Exibindo de {props.from} à{' '}
                                                    {props.to} do total de{' '}
                                                    {props.qtd_total}
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div
                                                        style={{
                                                            float: 'right',
                                                        }}
                                                    >
                                                        <Pagination>
                                                            {props.pagination(
                                                                props.current_page,
                                                                props.get,
                                                                props.last_page,
                                                                props.changePage
                                                            )}
                                                        </Pagination>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>

                {/* <Table 
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                    Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                                </div>
                            </div>
                        }
                            
                        cabecalho={
                            
                            <Cabecalho 
                                to="#/" 
                                action={props.get} 
                                clear={props.clearPesquisa} 
                                valuePesquisa={props.pesquisa} 
                                value={props.qtd_per_page} 
                                onChange={props.change_qtd_per_page} 
                                changePesquisa={props.changePesquisa}
                                display="visit"
                                report={report} 
                                buttonStatus={
                                    goButton(props.visits, status_all, changeStatusVisitAll)
                                }
                                buttonCsv={
                                    <>
 
                                        <button className="btn btn-outline-success btn-sm" onClick={download}>
                                            <span className="fas fa-file-csv"> Exportar CSV</span>
                                        </button>

                                        <button className="btn btn-outline-secondary btn-sm" style={{marginLeft: '10px'}} onClick={() => setReport(report === false ? true : false)}>
                                            {report === false ? 'Filtros Avançados' : 'Fechar Filtros'} <span className={`fas fa-${report === true ? 'angle-up' : 'angle-down'}`}></span>
                                        </button>
                                        
                                        <CSVLink
                                            filename={`Relatorio-${new Date()}.csv`}
                                            headers={headers}
                                            hidden={true}
                                            separator={";"}
                                            ref={fileUpload}
                                            data={visits || []}
                                    
                                        >
                                            <span className="fas fa-file-csv"> Exportar CSV</span>
                                        </CSVLink>
                                    </>
                                }
                            >
                                <div className="col-md-12" style={{display: `${report === false ? 'none' : 'block'}`}}>
                                    <ReportVisit /> 
                                    
                                </div>
                            </Cabecalho>
   
                        }
                        pagination={<Pagination>{props.pagination(props.current_page,props.get,props.last_page,props.changePage)}</Pagination>} title="Visitas"
                        button={
                           <Link to="/new_visit" className="btn btn-primary">Cadastro</Link>
                            
                        }>
                        <Thead>    
                            {props.perfis.indexOf(1) >=0 || props.perfis.indexOf(2) >=0 || props.perfis.indexOf(3) >=0 || props.perfis.indexOf(4) >=0 || props.perfis.indexOf(6) >=0 || props.perfis.indexOf(8) >=0 ? todosIguaisOuDiferentes() : ''} 
                            <th>STATUS<Link to="/visits" style={{float:'right'}} onClick={()=>props.order(status)}><i className={`right fas fa-sort-amount-${props.th === 'status_atividades.descricao' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>TÉCNICO<Link to="/visits" style={{float:'right'}} onClick={()=>props.order(tecnico)}><i className={`right fas fa-sort-amount-${props.th === 'users.name' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>PROJETO<Link to="/visits" style={{float:'right'}} onClick={()=>props.order(projeto)}><i className={`right fas fa-sort-amount-${props.th === 'projetos.titulo' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>REMUNERAÇÃO<Link to="/visits" style={{float:'right'}} onClick={()=>props.order(remuneracao)}><i className={`right fas fa-sort-amount-${props.th === 'tipos_remuneracoes.descricao' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>VALOR<Link to="/visits" style={{float:'right'}} onClick={()=>props.order(valor)}><i className={`right fas fa-sort-amount-${props.th === 'valor' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>LANÇAMENTO<Link to="/visits" style={{float:'right'}} onClick={()=>props.order(lancamento)}><i className={`right fas fa-sort-amount-${props.th === 'atividades.created_at' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>DESPESA<Link to="/visits" style={{float:'right'}} onClick={()=>props.order(despesa)}><i className={`right fas fa-sort-amount-${props.th === 'atividades.data_lancamento' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>AÇÕES</th>
                        </Thead>
                        <Tbody>
                            <Visits check={check} changeStatusVisit={changeStatusVisit} visits={props.visits} formatReal={formatReal} onDelete={onChangeModal} />
                        </Tbody>
                        <Tfoot>
                            {props.perfis.indexOf(1) >=0 || props.perfis.indexOf(2) >=0 || props.perfis.indexOf(3) >=0 || props.perfis.indexOf(4) >=0 || props.perfis.indexOf(6) >=0 || props.perfis.indexOf(8) >=0 ? todosIguaisOuDiferentes() : ''} 
                            <th>STATUS</th>
                            <th>TÉCNICO</th>
                            <th>PROJETO</th>
                            <th>REMUNERAÇÃO</th>
                            <th>VALOR</th>
                            <th>LANÇAMENTO</th>
                            <th>DESPESA</th>
                            <th>AÇÕES</th>
                        </Tfoot>
                    </Table>         */}
            </div>

            <Footer />
        </div>
    );
}

const mapStateToProps = (state) => ({
    visits: state.visit.visits.data || [],
    current_page: state.visit.visits.current_page,
    perfis: state.auth.user.perfis,
    last_page: state.visit.visits.last_page,
    from: state.visit.visits.from,
    to: state.visit.visits.to,
    qtd_total: state.visit.visits.total,
    qtd_per_page: state.visit.qtd_per_page,
    pesquisa: state.visit.pesquisa || '',
    id_role: state.auth.user.id_role,
    asc: state.visit.asc,
    th: state.visit.th,
    id_technical: state.visit.technical_report.value || '',
    id_coordinator: state.visit.coordinator_report.value || '',
    id_project: state.visit.project_report.value || '',
    id_remuneration: state.visit.remuneration_report.value || '',
    id_status: state.visit.status_report.value || '',
    from_date: state.visit.from,
    to_date: state.visit.to,
    order_var: state.visit.order,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            pagination,
            get,
            order,
            change_qtd_per_page,
            changePesquisa,
            clearPesquisa,
            changePage,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GridVisit);
