/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import Footer from '../../../Footer/Footer';
import Table from '../../../Table/Table';
import Thead from '../../../Table/Thead';
import Tbody from '../../../Table/Tbody';
import Tfoot from '../../../Table/Tfoot';
import StylePage from '../../../Style Page/StylePage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    search,
    changePage,
    get,
    order,
    change_qtd_per_page,
    changePesquisa,
    clearPesquisa,
} from './Actions/actions';
import { pagination } from '../../../Pagination/paginations';
import { Link } from 'react-router-dom';
import Pagination from '../../../Table/Pagination';
import Cabecalho from '../../../Table/Cabecalho';
import Alert from '../../../Alerts/Alert';
import MyModal from '../../../Modal/MyModal';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import {
    del_project,
    exportExcelCSV,
    technicianImport,
} from '../../../Services/projectEnsino';
import Projects from './Projects';
import { CSVLink } from 'react-csv';
import ReportProject from './ReportProject';
import { useDispatch } from 'react-redux';
import { ContentUpload } from '../../../Upload/styles';
import Upload from '../../../Upload/upload';
import filesize from 'filesize';
import { uniqueId } from 'lodash';
import FileList from '../../../FileList/FileList';
import MyModalImport from '../../../Modal/MyModalImport';

function GridProjectEnsino(props) {
    const dispatch = useDispatch();
    const anexoRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [id, setId] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [projects, setProjects] = useState([]);
    const [modalFilter, setModalFilter] = useState(false);
    const [headers, setHeaders] = useState('');
    const [modalImport, setModalImport] = useState(false);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [alertAnexo, setAlertAnexo] = useState('');
    const [errorImport, setErrorImport] = useState('');
    const [successImport, setSuccessImport] = useState('');
    const [loadingAnexo, setLoadingAnexo] = useState(false);

    const titulo = 'projetos_ensino.titulo';
    const cod_centro_custo = 'projetos_ensino.cod_centro_custo';
    const gerente = 'gerente.name';
    const coordenador = 'coord.name';
    const status = 'status_projetos.descricao';
    const fileUpload = useRef();

    useEffect(() => {
        getProjects();
        return () => {
            getProjects();
        };
    }, []);

    //Função que busca os projetos na api
    const getProjects = () => {
        props.get();
    };

    //Função que abre o modal e define o id a ser excluído
    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
    };

    //Função que fecha o modal de excluir projeto
    const handleCloseExcluir = (event) => {
        setModal(false);
        setId('');
    };

    //Função que exclui um projeto
    // const handleExcluir = (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     setModal(false);

    //     del_project(id)
    //         .then((resp) => {
    //             setSuccess(resp.data.success);
    //             setError('');
    //             setLoading(false);
    //         })
    //         .then((resp) =>
    //             setTimeout(() => {
    //                 setSuccess('');
    //             }, 4000),
    //         )
    //         .catch((e) => {
    //             setLoading(false);
    //             setError(e.response.data.error);
    //             setSuccess('');
    //             setModal(false);
    //             setTimeout(() => {
    //                 setError('');
    //             }, 4000);
    //         })
    //         .then((resp) => props.get());
    //     // .then(resp => {setState({success:resp.data.success, error:''})})
    // };

    const exportCSV = (event) => {
        setHeaders([
            { label: 'status do técnico', key: 'descStatusTec' },
            { label: 'data de lançamento', key: 'createdAt' },
            { label: 'data da alocação', key: 'data_alocacao' },
            { label: 'data fim de alocação', key: 'data_fim_alocacao' },
            { label: 'tecnico', key: 'name' },
            { label: 'código sênior', key: 'fornecedor_senior' },
            { label: 'atuação', key: 'atuacao' },
            { label: 'formato', key: 'formato' },
            { label: 'título do projeto', key: 'titulo' },
            { label: 'codigo centro custo', key: 'cod_centro_custo' },
            { label: 'remuneração', key: 'tipos_remuneracoes' },
            { label: 'dedicação(dias/horas)', key: 'dedicacao' },
            { label: 'valor', key: 'valor' },
            { label: 'observação', key: 'description' },
        ]);

        exportExcelCSV(
            props.page,
            props.total_page,
            props.order_var,
            props.th,
            props.pesquisa,
            props.type ? props.type : '',
            props.area ? props.area : '',
            props.manager ? props.manager : '',
            props.coordinator ? props.coordinator : '',
            props.status ? props.status : ''
        )
            .then((resp) => {
                setProjects(resp.data);
            })
            .then((resp) => {
                fileUpload.current.link.click();
            });
    };

    const handleUpload = (files) => {
        // console.log(files);
        const validFormat = ['xlsx'];
        let nameSplit = files[0].name.split('.');
        let format = nameSplit[1];

        if (!validFormat.includes(format)) {
            setAlertAnexo('O formato do arquivo não é válido.');
            setTimeout(() => {
                setAlertAnexo('');
            }, 10000);

            return null;
        }

        const uploadedFile = files.map((file) => {
            let anexo = {
                file,
                id: uniqueId(),
                name: file.name,
                readableSize: filesize(file.size),
                progress: 0,
                uploaded: false,
                error: false,
                url: null,
            };
            // console.log(a);
            return anexo;
        });

        if (uploadedFile.length > 1) {
            setAlertAnexo('É permitido apenas um anexo por vez.');
            setTimeout(() => {
                setAlertAnexo('');
            }, 10000);

            return null;
        }

        // console.log('uploadedFile',uploadedFile[0].name);

        setUploadedFile(uploadedFile);
    };

    const closeModalImportTechnical = () => {
        setModalImport(false);
        setAlertAnexo('');
        setErrorImport('');
        setSuccessImport('');
        setLoadingAnexo('');
        handleDeleteImportTechnical();
    };
    const handleDeleteImportTechnical = (id) => {
        setUploadedFile([]);
    };

    const postImportTechnical = (e) => {
        if (uploadedFile.length <= 0) {
            return null;
        }

        setLoadingAnexo(true);
        setTimeout(() => {
            setLoadingAnexo(false);
        }, 3000);

        const data = new FormData();
        data.append('anexo', uploadedFile[0].file);

        technicianImport(data, {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            },
        })
            .then((resp) => {
                // console.log(resp);
                setLoadingAnexo(false);
                setSuccessImport(resp.data.success);
                setTimeout(() => {
                    setSuccessImport('');
                    setUploadedFile([]);
                }, 3000);
            })
            .catch((e) => {
                // console.log(e);
                setLoadingAnexo(false);
                setErrorImport(e.response.data.error);
                setTimeout(() => {
                    setErrorImport('');
                    setUploadedFile([]);
                }, 10000);
            });
    };

    console.log('Projeetos', props.projects);

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage>
                {console.log(props)}
                {loading === true ? (
                    <Content>
                        <ReactLoading
                            type="spinningBubbles"
                            color="blue"
                            height={200}
                            width={100}
                        />
                    </Content>
                ) : (
                    ''
                )}
                {success ? (
                    <div>
                        <Alert type="success">{success}</Alert>
                    </div>
                ) : (
                    ''
                )}
                {error ? (
                    <div>
                        <Alert type="danger">{error}</Alert>
                    </div>
                ) : (
                    ''
                )}
                {/* <MyModal
                    show={modal}
                    onHide={handleCloseExcluir}
                    title='Excluir Projeto'
                    content='Tem certeza que deseja excluir o projeto ?'
                    cancel={handleCloseExcluir}
                    del={handleExcluir}
                    to='/projects_ensino'
                    type='danger'
                    textButton='Excluir'
                /> */}
                <div className="row">
                    <div className="col-md-12">
                        <MyModal
                            show={modalFilter}
                            onHide={(e) => setModalFilter(false)}
                            title="Filtrar"
                            content={<ReportProject />}
                            cancel={function () {
                                return [
                                    props.clearPesquisa(),
                                    setTimeout(() => {
                                        setModalFilter(false);
                                    }, 1000),
                                ];
                            }}
                            del={function () {
                                dispatch({
                                    type: 'pageInitialEnsino',
                                    payload: 1,
                                });
                                return [
                                    props.get(),
                                    setTimeout(() => {
                                        setModalFilter(false);
                                    }, 1000),
                                ];
                            }}
                            to="#/"
                            type="success"
                            contentSecondary="Limpar Busca"
                            textButton="Buscar"
                        />
                        <MyModalImport
                            show={modalImport}
                            title="Importar Técnicos"
                            cancel={() => closeModalImportTechnical()}
                            onHide={() => closeModalImportTechnical()}
                            del={(e) => postImportTechnical(e)}
                            savingInProgress={loadingAnexo}
                            textButton="Salvar"
                            type="success"
                            content={
                                <>
                                    {alertAnexo ? (
                                        <div>
                                            <Alert
                                                type="danger"
                                                onClick={() =>
                                                    setAlertAnexo('')
                                                }
                                            >
                                                {alertAnexo}
                                            </Alert>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {successImport ? (
                                        <div>
                                            <Alert type="success" hidden={true}>
                                                {successImport}
                                            </Alert>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {errorImport ? (
                                        <div>
                                            <Alert type="danger" hidden={true}>
                                                {errorImport}
                                            </Alert>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {loadingAnexo === true ? (
                                        <Content>
                                            <ReactLoading
                                                type="spinningBubbles"
                                                color="blue"
                                                height={200}
                                                width={100}
                                            />
                                        </Content>
                                    ) : (
                                        <>
                                            <label ref={anexoRef}>
                                                Adicione a Planilha
                                            </label>
                                            <ContentUpload>
                                                <>
                                                    <Upload
                                                        onUpload={handleUpload}
                                                        disabled={
                                                            uploadedFile.length >
                                                            0
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {!!uploadedFile.length && (
                                                        <FileList
                                                            action={
                                                                props.match
                                                                    .params
                                                                    .action
                                                            }
                                                            toEdit={
                                                                props.match
                                                                    .params
                                                                    .action ===
                                                                'edit'
                                                                    ? `/register_sgagri/${props.match.params.id}/edit`
                                                                    : ''
                                                            }
                                                            toCadastro="new_register_sgagri"
                                                            files={uploadedFile}
                                                            onDelete={
                                                                handleDeleteImportTechnical
                                                            }
                                                        />
                                                    )}
                                                </>
                                            </ContentUpload>
                                        </>
                                    )}
                                </>
                            }
                        ></MyModalImport>
                    </div>
                </div>
                <div style={{ display: loading === true ? 'none' : 'block' }}>
                    <Table
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div
                                    className="dataTables_info"
                                    id="example1_info"
                                    role="status"
                                    aria-live="polite"
                                >
                                    Exibindo de {props.from} à {props.to} do
                                    total de {props.qtd_total}
                                </div>
                            </div>
                        }
                        cabecalho={
                            <Cabecalho
                                to="#/"
                                action={props.search}
                                clear={props.clearPesquisa}
                                valuePesquisa={props.pesquisa}
                                value={props.qtd_per_page}
                                onChange={props.change_qtd_per_page}
                                changePesquisa={props.changePesquisa}
                                buttonCsv={
                                    <>
                                        <button
                                            style={{
                                                background: '#dba502',
                                                color: '#FFF',
                                                borderRadius: '20.5px',
                                                width: '140px',
                                                marginRight: '5px',
                                            }}
                                            className="btn btn-sm"
                                            onClick={() => setModalImport(true)}
                                        >
                                            Importar Técnicos
                                        </button>

                                        <button
                                            style={{
                                                background: '#005F31',
                                                color: '#FFF',
                                                borderRadius: '20.5px',
                                                width: '140px',
                                            }}
                                            className="btn btn-sm"
                                            onClick={() => exportCSV()}
                                        >
                                            <i className="fas fa-file-csv"></i>{' '}
                                            Exportar CSV
                                        </button>
                                        <button
                                            style={{
                                                background: '#1A170E',
                                                color: '#FFF',
                                                borderRadius: '20.5px',
                                                width: '140px',
                                                marginLeft: '10px',
                                            }}
                                            className="btn btn-sm"
                                            onClick={() =>
                                                setModalFilter(!modalFilter)
                                            }
                                        >
                                            <i className="fas fa-filter"></i>{' '}
                                            Mais Filtros
                                        </button>

                                        <CSVLink
                                            filename={`Relatorio-${new Date()}.csv`}
                                            headers={headers}
                                            hidden={true}
                                            separator={';'}
                                            ref={fileUpload}
                                            data={projects || []}
                                        >
                                            <span className="fas fa-file-csv">
                                                {' '}
                                                Exportar CSV
                                            </span>
                                        </CSVLink>
                                    </>
                                }
                            />
                        }
                        pagination={
                            <Pagination>
                                {props.pagination(
                                    props.current_page,
                                    props.get,
                                    props.last_page,
                                    props.changePage
                                )}
                            </Pagination>
                        }
                        title="Projetos Ensino"
                        button={
                            props.perfis.indexOf(1) >= 0 ||
                            props.perfis.indexOf(6) >= 0 ||
                            props.perfis.indexOf(10) >= 0 ? (
                                <Link
                                    to="/new_project_ensino"
                                    className="btn btn-primary"
                                >
                                    Cadastro
                                </Link>
                            ) : (
                                ''
                            )
                        }
                    >
                        <Thead>
                            <th>
                                TÍTULO
                                <Link
                                    to="/projects_ensino"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(titulo)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th ===
                                            'projetos_ensino.titulo'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            <th>
                                CENTRO CUSTO
                                <Link
                                    to="/projects_ensino"
                                    style={{ float: 'right' }}
                                    onClick={() =>
                                        props.order(cod_centro_custo)
                                    }
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th ===
                                            'projetos_ensino.cod_centro_custo'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            <th>
                                GERENTE
                                <Link
                                    to="/projects_ensino"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(gerente)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th === 'gerente.name'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            <th>
                                COORDENADOR
                                <Link
                                    to="/projects_ensino"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(coordenador)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th === 'coord.name'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            <th>
                                STATUS
                                <Link
                                    to="/projects_ensino"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(status)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th ===
                                            'status_projetos.descricao'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            <th>AÇÕES</th>
                        </Thead>
                        <Tbody>
                            <Projects
                                projects={props.projects}
                                onDelete={onChangeModal}
                            />
                        </Tbody>
                        <Tfoot>
                            <th>TÍTULO</th>
                            <th>GERENTE</th>
                            <th>COORDENADOR</th>
                            <th>STATUS</th>
                            <th>AÇÕES</th>
                        </Tfoot>
                    </Table>
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}

const mapStateToProps = (state) => ({
    projects: state.projectEnsino.projectEnsino.data,
    perfis: state.auth.user.perfis,
    current_page: state.projectEnsino.projectEnsino.current_page,
    last_page: state.projectEnsino.projectEnsino.last_page,
    from: state.projectEnsino.projectEnsino.from,
    to: state.projectEnsino.projectEnsino.to,
    qtd_total: state.projectEnsino.projectEnsino.total,
    qtd_per_page: state.projectEnsino.qtd_per_page,
    total_page: state.projectEnsino.projectEnsino.total,
    pesquisa: state.projectEnsino.pesquisa || '',
    id_role: state.auth.user.id_role,
    asc: state.projectEnsino.asc,
    th: state.projectEnsino.th,
    page_var: state.projectEnsino.page,
    order_var: state.projectEnsino.order,
    manager: state.projectEnsino.manager.value,
    coordinator: state.projectEnsino.coordinator.value,
    status: state.projectEnsino.status.value,
    area: state.projectEnsino.area.value,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            pagination,
            get,
            order,
            change_qtd_per_page,
            changePesquisa,
            clearPesquisa,
            changePage,
            search,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GridProjectEnsino);
