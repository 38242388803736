/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import Footer from '../../../Footer/Footer';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    changePage,
    get,
    order,
    change_qtd_per_page,
    changePesquisa,
    clearPesquisa,
} from './Actions/actions';
import { pagination } from '../../../Pagination/paginations';
import {
    delSalesRecord,
    changeStatus,
    getClient,
    exportExcel,
} from '../../../Services/salesRecord';
import Pagination from '../../../Table/Pagination';
import Alert from '../../../Alerts/Alert';
import MyModal from '../../../Modal/MyModal';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import CabecalhoNew from '../../../Table/CabecalhoNew';
import Input from '../../../Forms/Input';
import TextArea from '../../../Forms/TextArea';
import Form from '../../../Forms/Form';
import AprovedIcon from '../../../Icons/emojione-v1_ballot-box-bold-check.png';
import ReprovedIcon from '../../../Icons/fluent_document-footer-remove-24-filled.png';
import IconAnalysys from '../../../Icons/cib_anaconda.png';
import CheckLists from './CheckLists';
import ReportSalesRecord from './ReportSalesRecord';
import makeAnimated from 'react-select/animated';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import ModalLarge from '../../../Modal/ModalLarge';
import TablePagamento from './TablePagamento';
import Pagamento from './Pagamento';
import { uniqueId } from 'lodash';
import { CSVLink } from 'react-csv';

function GridCheckList(props) {
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [codeCentroCusto, setCodeCentroCusto] = useState('');
    const [modal, setModal] = useState(false);
    const [description, setDescription] = useState('');
    const [modalReproved, setModalReproved] = useState(false);
    const [modalAproved, setModalAproved] = useState(false);
    const [modalFirstAproved, setModalFirstAproved] = useState(false);
    const [dataVencimento, setDataVencimento] = useState('');
    const [valorParcela, setValorParcela] = useState('');
    const [errorPagamento, setErrorPagamento] = useState('');
    const [salesRecord, setSalesRecord] = useState({});
    const [optionsClient, setOptionsClient] = useState([]);
    const [totalPagamento, setTotalPagamento] = useState(0);
    const [id, setId] = useState('');
    const [pagamento, setPagamento] = useState([]);
    const [success, setSuccess] = useState('');
    const [idClient, setIdClient] = useState([]);
    const [error, setError] = useState('');
    const [displayLegend, setDisplayLegend] = useState(true);
    const [modalFilter, setModalFilter] = useState(false);
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const from = useSelector((state) => state.sales_record.from);
    const to = useSelector((state) => state.sales_record.to);
    const dispatch = useDispatch();
    const [status_all, setStatusAll] = useState([]);
    const selectClientRef = useRef(null);
    const animatedComponents = makeAnimated();
    const [titleProject, setTitleProject] = useState('');
    const [headers, setHeaders] = useState('');
    const fileUpload = useRef();

    const getSalesRecord = () => {
        props.get();
    };

    useEffect(() => {
        getSalesRecord();
        return () => {
            getSalesRecord();
        };
    }, []);

    useEffect(() => {
        getAllClient();
    }, [modalAproved]);

    function getAllClient() {
        if (modalAproved === true) {
            getClient().then((resp) => {
                setOptionsClient(
                    resp.data.map((desc) => ({
                        value: desc.id,
                        label: desc.nome,
                    }))
                );
            });
        }
    }

    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
    };

    const handleCloseExcluir = (event) => {
        setModal(false);
        setId('');
    };

    const handleCancelOrClose = () => {
        setModalAproved(false);
        setPagamento([]);
        setTotalPagamento(0);
        setDataVencimento('');
        setValorParcela('');
        setErrorPagamento('');
        setCode('');
        setIdClient([]);
        setCodeCentroCusto('');
    };
    // console.log(totalPagamento)

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false);

        delSalesRecord(id)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError('');
                setLoading(false);
            })
            .then((resp) =>
                setTimeout(() => {
                    setSuccess('');
                }, 4000)
            )
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            })
            .then((resp) => props.get());
        // .then(resp => {setState({success:resp.data.success, error:''})})
    };

    //Função que formata o campo valor
    function formatReal(desc) {
        var tmp = `${desc.toFixed(2)}`.replace('.', ',');

        if (tmp.length > 6)
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, '.$1');
        return tmp;
    }

    const changeStatusSalesRecordAproved = (e) => {
        e.preventDefault();
        if (salesRecord.id_status == 1) {
            const object = {
                idStatus: 6,
                codCentroCusto: +codeCentroCusto,
            };

            changeStatus(salesRecord.id, object)
                .then((resp) => {
                    setSuccess(resp.data.success);
                    setError('');
                    setLoading(false);
                    setIdClient([]);
                    setCode('');
                    setPagamento([]);
                    setTotalPagamento(0);
                    setDataVencimento('');
                    setValorParcela('');
                    setErrorPagamento('');
                    setModalFirstAproved(false);
                    setCodeCentroCusto('');
                    setTimeout(() => {
                        setSuccess('');
                    }, 4000);
                })
                .catch((e) => {
                    setLoading(false);
                    setError(e.response.data.error);
                    setSuccess('');
                    setTimeout(() => {
                        setError('');
                    }, 4000);
                })
                .then((resp) => props.get());

            return;
        }

        if (totalPagamento !== salesRecord.valor_contrato) {
            return setErrorPagamento(
                'O valor total das parcelas é diferente do valor total do contrato'
            );
        }
        setModalAproved(false);
        setLoading(true);
        const ordenar = (a, b) => {
            return a.dataPagamento < b.dataPagamento
                ? -1
                : a.dataPagamento > b.dataPagamento
                ? 1
                : 0;
        };
        let pagamentoOrder = pagamento.sort(ordenar);
        let newClient = [];
        idClient.map((desc) => newClient.push(desc.value));

        const object = {
            idStatus: 2,
            client: newClient,
            code: code,
            pagamento: pagamentoOrder,
        };

        changeStatus(salesRecord.id, object)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError('');
                setLoading(false);
                setIdClient([]);
                setCode('');
                setPagamento([]);
                setTotalPagamento(0);
                setDataVencimento('');
                setValorParcela('');
                setErrorPagamento('');
                setTimeout(() => {
                    setSuccess('');
                }, 4000);
            })
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            })
            .then((resp) => props.get());
    };

    const modalReprovedOn = (salesRecordId, status) => {
        setModalReproved(true);
        setSalesRecord(salesRecordId);
    };

    const modalAprovedOn = (salesRecord) => {
        if (salesRecord.id_status == 1) {
            setModalFirstAproved(true);
            setTitleProject(salesRecord.title_project);
            setSalesRecord(salesRecord);
        } else {
            setModalAproved(true);
            setSalesRecord(salesRecord);
            setCodeCentroCusto(salesRecord.centro_custo);
            setDataInicio(formataData(salesRecord.data_primeira_visita));
            setDataFim(salesRecord.data_fim);
        }
    };

    function formataData(data) {
        let dataSplit = data.split('/');
        let dataFormatada = `${dataSplit[2]}-${dataSplit[1]}-${dataSplit[0]}`;
        return dataFormatada;
    }

    const changeStatusSalesRecordReproved = (e) => {
        e.preventDefault();
        setModalReproved(false);
        setLoading(true);
        const object = {
            idStatus: 3,
            description: description,
        };
        changeStatus(salesRecord, object)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError('');
                setLoading(false);
                setDescription('');
                setTimeout(() => {
                    setSuccess('');
                }, 4000);
            })
            .catch((e) => {
                setLoading(false);
                setDescription('');
                setError(e.response.data.error);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            })
            .then((resp) => props.get());
    };

    const check = (event) => {
        let checked = event.target.checked;
        if (checked === true) {
            setStatusAll(status_all.concat(parseInt(event.target.value)));
        } else {
            setStatusAll(
                status_all.filter(
                    (desc) => parseInt(desc) !== parseInt(event.target.value)
                )
            );
        }
    };

    //Função que pega os clientes escolhidos no projeto
    const handleChangeClient = (newValue: any, actionMeta: any) => {
        setIdClient(newValue);
    };

    const handlePagamento = (e) => {
        e.preventDefault();

        if (salesRecord.tipo_cobranca == 'Mensal') {
            let quantidadeDeParcelas = salesRecord.quantidade_parcela;
            let valorDasParcelas =
                salesRecord.valor_contrato / quantidadeDeParcelas;
            let total = salesRecord.valor_contrato;

            if (pagamento.length != 0) {
                return setErrorPagamento(
                    'Não é possível adicionar essas parcelas pois o tamanho será diferente da quantidade estabelecida'
                );
            }

            setTotalPagamento(total);

            let novaDataVencimento;

            // let dataTemporaria = new Date("Thu Jan 01 1970 01:02:01 GMT+0200");
            let dataTemporaria = new Date(dataVencimento);
            dataTemporaria.setTime(
                dataTemporaria.getTime() +
                    dataTemporaria.getTimezoneOffset() * 60 * 1000
            );

            let dia;
            let mes;
            let ano;
            let somaParcela = 0;

            let pagamentoTemporario = pagamento;

            for (
                let count = 0;
                count < quantidadeDeParcelas - 1;
                count = count + 1
            ) {
                dia = dataTemporaria.getDate();
                mes = dataTemporaria.getMonth();
                ano = dataTemporaria.getFullYear();

                novaDataVencimento = ano + '-' + (mes + 1) + '-' + dia;

                const data = {
                    id: uniqueId(),
                    dataPagamento: novaDataVencimento,
                    valorParcela: +valorDasParcelas.toFixed(2),
                };

                somaParcela = somaParcela + data.valorParcela;
                pagamentoTemporario = pagamentoTemporario.concat(data);

                dataTemporaria.setMonth(dataTemporaria.getMonth() + 1);
            }

            dia = dataTemporaria.getDate();
            mes = dataTemporaria.getMonth();
            ano = dataTemporaria.getFullYear();
            novaDataVencimento = ano + '-' + (mes + 1) + '-' + dia;

            const data = {
                id: uniqueId(),
                dataPagamento: novaDataVencimento,
                valorParcela: +(total - somaParcela).toFixed(2),
            };

            pagamentoTemporario = pagamentoTemporario.concat(data);

            setPagamento(pagamento.concat(pagamentoTemporario));
            setDataVencimento('');
            setValorParcela('');
            setErrorPagamento('');
        } else if (salesRecord.tipo_cobranca == 'Variável') {
            let total = totalPagamento + valorParcela;
            if (total > salesRecord.valor_contrato) {
                return setErrorPagamento(
                    'Não é possível adicionar esse valor de parcela pois ultrapassa o valor total do contrato'
                );
            }

            const data = {
                id: uniqueId(),
                dataPagamento: dataVencimento,
                valorParcela: valorParcela,
            };
            setTotalPagamento(totalPagamento + valorParcela);
            setPagamento(pagamento.concat(data));
            setDataVencimento('');
            setValorParcela('');
            setErrorPagamento('');
        } else {
            let total = totalPagamento;
            if (total === 0) {
                total = totalPagamento + valorParcela;
            } else {
                total = 0;
                return setErrorPagamento(
                    'Não é possível adicionar outra parcela, pois o tipo de cobrança é unica'
                );
            }
            if (total > salesRecord.valor_contrato) {
                return setErrorPagamento(
                    'Não é possível adicionar esse valor de parcela pois ultrapassa o valor total do contrato'
                );
            }

            const data = {
                id: uniqueId(),
                dataPagamento: dataVencimento,
                valorParcela: valorParcela,
            };
            setTotalPagamento(totalPagamento + valorParcela);
            setPagamento(pagamento.concat(data));
            setDataVencimento('');
            setValorParcela('');
            setErrorPagamento('');
        }
    };

    const handleDeletePagamento = (id) => {
        if (salesRecord.tipo_cobranca == 'Mensal') {
            setPagamento([]);
            setTotalPagamento('0');
        } else {
            let pagamentoAux = pagamento.filter((desc) => desc.id === id);
            setPagamento(pagamento.filter((desc) => desc.id !== id));
            setTotalPagamento(totalPagamento - pagamentoAux[0].valorParcela);
        }
    };

    const [csvSalesRecord, setCsvSalesRecord] = useState([]);
    const download = (event) => {
        setHeaders([
            { label: 'Id Reg. Venda', key: 'registro_venda_id' },
            { label: 'Centro de Custo', key: 'centro_custo' },
            { label: 'Área', key: 'descArea' },
            { label: 'Título do Projeto', key: 'title_project' },
            { label: 'Nome Usuário Resp. Cadastro', key: 'user_created_by' },
            { label: 'Status Reg. Venda', key: 'descStatus' },
            {
                label: 'Nome Usuário Responsável Pré Aprovação',
                key: 'pre_aproved',
            },
            { label: 'Nome Usuário Responsável Aprovação', key: 'aproved' },
            { label: 'Tipo do Serviço', key: 'descTipo' },
            { label: 'Gerente de Área', key: 'gerenteArea' },
            { label: 'Empresa', key: 'empresa' },
            { label: 'Filial', key: 'filial' },
            {
                label: 'Tamanho da Propriedade (Hectáres)',
                key: 'hectares_propriedade',
            },
            {
                label: 'Faturamento Anual da Propriedade',
                key: 'faturamento_anual',
            },
            { label: 'Atividade Principal do Cliente', key: 'main_activity' },
            { label: 'Nome do Contato', key: 'contato_nome' },
            { label: 'E-mail do Contato', key: 'contato_email' },
            { label: 'Telefone do Contato', key: 'contato_telefone' },
            { label: 'Estado', key: 'state' },
            { label: 'Cidade', key: 'city' },
            {
                label: 'Acerto de despesas com o cliente ?',
                key: 'reembolsavel',
            },
            {
                label: 'Descrição do Serviço / Informações do Cliente',
                key: 'descricao_servico',
            },
            { label: 'Cultura', key: 'cultura' },
            { label: 'Quantidade', key: 'quantidadeCultura' },
            { label: 'Data da Venda', key: 'data_venda' },
            { label: 'Agente da Venda', key: 'agente' },
            { label: 'Origem da Venda', key: 'origem_venda' },
            { label: 'Produto', key: 'produto' },
            { label: 'Valor Produto', key: 'valorProduto' },
            { label: 'Valor Total do Contrato', key: 'valor_contrato' },
            { label: 'Tipo de Cobrança Parcelas', key: 'tipo_cobranca' },
            {
                label: 'Valor das Parcelas Período de Vigência (Meses)',
                key: 'periodo_vigencia_meses',
            },
            {
                label: 'Data Prevista da Primeira Visita',
                key: 'data_primeira_visita',
            },
            { label: 'Período de Reajuste', key: 'readjustment_period' },
            {
                label: 'Indexador de Renovação Contratual',
                key: 'renewal_indexer',
            },
            { label: 'Impostos', key: 'imposto' },
            { label: 'Margem Administrativa', key: 'margemAdm' },
            { label: 'Margem Líquida', key: 'margemLqd' },
            { label: 'Margem Adicional', key: 'margemAd' },
            { label: 'Coordenador', key: 'coordenador_name' },
            { label: 'Técnico', key: 'technical' },
            { label: 'Tipo de Dedicação', key: 'nomeTipoDedicacaoTechnical' },
            { label: 'Dedicação', key: 'dedicacaoTechnical' },
            { label: 'Remuneração RMA', key: 'remuneracaoRMATechnical' },
            { label: 'Valor RMA', key: 'valorRMA' },
            { label: 'Id Projeto', key: 'id_projeto' },
        ]);

        exportExcel(
            1, //page
            props.qtd_per_page,
            props.order_var,
            props.th,
            props.pesquisa,
            props.id_coordinator,
            props.id_area,
            props.project_report,
            props.id_status,
            from,
            to
        )
            .then((resp) => {
                setCsvSalesRecord(resp.data);
            })
            .then((resp) => {
                fileUpload.current.link.click();
            });
    };

    return (
        <div className="wrapper" style={{ backgroundColor: '#E5E5E5, 100%' }}>
            <Header />
            <Sidebar />
            <div
                className="content-wrapper"
                style={{ backgroundColor: '#E5E5E5, 100%' }}
            >
                <section
                    className="content"
                    style={{ backgroundColor: '#E5E5E5, 100%' }}
                >
                    <MyModal
                        show={modal}
                        onHide={handleCloseExcluir}
                        title="Excluir Registro de Venda"
                        content="Tem certeza que deseja excluir o registro de venda ?"
                        cancel={handleCloseExcluir}
                        del={handleExcluir}
                        to="#/"
                        type="danger"
                        textButton="Excluir"
                    />
                    <div className="row">
                        <div className="col-md-12">
                            <MyModal
                                show={modalFilter}
                                onHide={(e) => setModalFilter(false)}
                                title="Filtrar"
                                content={<ReportSalesRecord />}
                                cancel={function () {
                                    return [
                                        props.clearPesquisa(),
                                        setTimeout(() => {
                                            setModalFilter(false);
                                        }, 1000),
                                    ];
                                }}
                                del={function () {
                                    dispatch({
                                        type: 'changeFirstPage',
                                        payload: 1,
                                    });
                                    return [
                                        props.get(),
                                        setTimeout(() => {
                                            setModalFilter(false);
                                        }, 1000),
                                    ];
                                }}
                                to="#/"
                                type="success"
                                contentSecondary="Limpar Busca"
                                textButton="Buscar"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <MyModal
                                show={modalReproved}
                                onHide={(e) => setModalReproved(false)}
                                title="Reprovar Lançamento"
                                content={
                                    <Form
                                        onSubmit={
                                            changeStatusSalesRecordReproved
                                        }
                                    >
                                        <TextArea
                                            label="Motivo"
                                            maxLength="254"
                                            placeholder="Descreva o motivo da reprovação"
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                            value={description}
                                            required={true}
                                        />
                                        <div className="text-right">
                                            <button
                                                style={{ marginRight: '10px' }}
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e) =>
                                                    setModalReproved(false)
                                                }
                                            >
                                                Cancelar
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-danger"
                                            >
                                                Reprovar
                                            </button>
                                        </div>
                                    </Form>
                                }
                                onSubmit={true}
                                cancel={(e) => setModalReproved(false)}
                                // del={changeStatusSalesRecordReproved}
                                to="#/"
                                type="danger"
                                textButton="Reprovar"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <ModalLarge
                                show={modalAproved}
                                onHide={(e) => handleCancelOrClose()}
                                title="Aprovar Registro de Venda"
                                cancel={(e) => setModalAproved(false)}
                            >
                                <Form onSubmit={changeStatusSalesRecordAproved}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <NumberFormat
                                                label="Código W3"
                                                name="code"
                                                icon="fas fa-key"
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                maxLength={9}
                                                thousandSeparator={''}
                                                decimalSeparator={','}
                                                placeholder=""
                                                customInput={Input}
                                                allowNegative={false}
                                                onChange={(e) =>
                                                    setCode(e.target.value)
                                                }
                                                value={code}
                                                required={true}
                                                // required={true}
                                                // isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= 999999999;}}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <NumberFormat
                                                label="Centro de Custo"
                                                name="codCentroCusto"
                                                icon="fas fa-key"
                                                fixedDecimalScale={true}
                                                maxLength={9}
                                                decimalScale={0}
                                                // thousandSeparator={""}
                                                // decimalSeparator={","}
                                                placeholder=""
                                                customInput={Input}
                                                allowNegative={false}
                                                onChange={(e) =>
                                                    setCodeCentroCusto(
                                                        e.target.value
                                                    )
                                                }
                                                value={codeCentroCusto}
                                                required={true}
                                                disabled={true}
                                                // required={true}
                                                // isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= 999999999;}}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>Clientes</label>
                                            <Select
                                                value={idClient}
                                                isMulti
                                                name="clientes"
                                                options={optionsClient}
                                                components={animatedComponents}
                                                closeMenuOnSelect={false}
                                                className="basic-multi-select"
                                                ref={selectClientRef}
                                                classNamePrefix="select"
                                                onChange={handleChangeClient}
                                                isDisabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                            />

                                            {idClient ? (
                                                idClient.length <= 0 ? (
                                                    <input
                                                        tabIndex={-1}
                                                        autoComplete="off"
                                                        style={{
                                                            opacity: 0,
                                                            width: '100%',
                                                            height: 0,
                                                            position:
                                                                'absolute',
                                                        }}
                                                        defaultValue={idClient}
                                                        onFocus={() =>
                                                            selectClientRef.current.focus()
                                                        }
                                                        required={true}
                                                    />
                                                ) : (
                                                    ''
                                                )
                                            ) : idClient == null ? (
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0,
                                                        width: '100%',
                                                        height: 0,
                                                        position: 'absolute',
                                                    }}
                                                    defaultValue={idClient}
                                                    onFocus={() =>
                                                        selectClientRef.current.focus()
                                                    }
                                                    required={true}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-4">
                                            <Input
                                                icon="fas fa-calendar"
                                                label="Data de Início"
                                                type="date"
                                                disabled={true}
                                                value={dataInicio}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                icon="fas fa-calendar"
                                                label="Data de Término"
                                                type="date"
                                                disabled={true}
                                                value={dataFim}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                icon="fas fa-calendar"
                                                label="Período de Vigência"
                                                type="text"
                                                disabled={true}
                                                value={
                                                    salesRecord.periodo_vigencia_meses
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <Input
                                                icon="fas fa-calendar"
                                                label="Valor Total do Contrato"
                                                type="text"
                                                disabled={true}
                                                value={`R$${formatReal(
                                                    parseFloat(
                                                        salesRecord.valor_contrato
                                                    )
                                                )}`}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                icon="fas fa-calendar"
                                                label="Tipo de Cobrança"
                                                type="text"
                                                disabled={true}
                                                value={
                                                    salesRecord.tipo_cobranca
                                                }
                                            />
                                        </div>
                                    </div>

                                    {salesRecord.tipo_cobranca ==
                                        'Variável' && (
                                        <div className="row mt-3">
                                            <div className="col-md-12">
                                                <TextArea
                                                    rows="6"
                                                    label="Descrição dos Valores de Parcelas"
                                                    value={
                                                        salesRecord.description_value
                                                    }
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="text-right mt-4">
                                        <div
                                            style={{
                                                width: '100%',
                                                marginBottom: '20px',
                                                position: 'absolute',
                                                bottom: '0%',
                                                left: '-2%',
                                            }}
                                        >
                                            <button
                                                style={{ marginRight: '10px' }}
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e) =>
                                                    handleCancelOrClose()
                                                }
                                            >
                                                Cancelar
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-success"
                                            >
                                                {salesRecord.id_status === 1
                                                    ? 'Pré Aprovar'
                                                    : 'Aprovar'}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                                <Form onSubmit={handlePagamento}>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <Input
                                                icon="fas fa-calendar"
                                                label="Data de Vencimento *"
                                                type="date"
                                                max="3000-01-01"
                                                value={dataVencimento}
                                                required={true}
                                                onChange={(e) =>
                                                    setDataVencimento(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-md-5">
                                            <NumberFormat
                                                icon="fas fa-dollar-sign"
                                                label="Valor da Parcela*"
                                                name="valor"
                                                allowNegative={false}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                placeholder=""
                                                customInput={Input}
                                                onChange={(e) =>
                                                    setValorParcela(
                                                        parseFloat(
                                                            e.target.value
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    ',',
                                                                    '.'
                                                                )
                                                        )
                                                    )
                                                }
                                                value={valorParcela}
                                                required={true}
                                                disabled={
                                                    props.match.params
                                                        .action === 'view' ||
                                                    salesRecord.tipo_cobranca ===
                                                        'Mensal'
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <div
                                                className="input-group mb-3"
                                                style={{ paddingTop: '30px' }}
                                            >
                                                <button
                                                    disabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : false
                                                    }
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    <span className="fas fa-plus"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <b>
                                            Total: R$
                                            {formatReal(
                                                parseFloat(totalPagamento)
                                            )}
                                        </b>
                                    </div>
                                    {errorPagamento ? (
                                        <Alert type="danger">
                                            {errorPagamento}
                                        </Alert>
                                    ) : (
                                        ''
                                    )}
                                    <TablePagamento>
                                        <Pagamento
                                            isDisabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            pagamento={pagamento}
                                            formatReal={formatReal}
                                            handleDeletePagamento={
                                                handleDeletePagamento
                                            }
                                        />
                                    </TablePagamento>
                                </Form>

                                {/* // onSubmit={true}
                                // cancel={(e) => setModalAproved(false)}
                                // // del={changeStatusSalesRecordReproved} 
                                // to="#/"
                                // type="success"
                                // textButton="Aprovar"                    */}
                            </ModalLarge>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <ModalLarge
                                show={modalFirstAproved}
                                onHide={(e) => {
                                    setModalFirstAproved(false);
                                    setCodeCentroCusto('');
                                }}
                                title="Pré Aprovar Registro de Venda"
                            >
                                {error && (
                                    <div>
                                        <Alert type="danger">{error}</Alert>
                                    </div>
                                )}
                                <Form onSubmit={changeStatusSalesRecordAproved}>
                                    {/* Tilulo Registro Venda */}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Input
                                                label="Titulo"
                                                autocomplete="off"
                                                value={titleProject}
                                                // icon="fas fa-user"
                                                name="titulo"
                                                type="text"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    {/* Centro de Custo */}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <NumberFormat
                                                label="Centro de Custo"
                                                name="codCentroCusto"
                                                icon="fas fa-key"
                                                fixedDecimalScale={true}
                                                maxLength={9}
                                                decimalScale={0}
                                                // thousandSeparator={""}
                                                // decimalSeparator={","}
                                                placeholder=""
                                                customInput={Input}
                                                allowNegative={false}
                                                onChange={(e) =>
                                                    setCodeCentroCusto(
                                                        e.target.value
                                                    )
                                                }
                                                value={codeCentroCusto}
                                                required={true}
                                                // required={true}
                                                // isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= 999999999;}}
                                            />
                                        </div>
                                    </div>

                                    <div className="text-right mt-4">
                                        <div
                                            style={{
                                                width: '100%',
                                                marginBottom: '20px',
                                                position: 'absolute',
                                                bottom: '0%',
                                                left: '-2%',
                                            }}
                                        >
                                            <button
                                                style={{ marginRight: '10px' }}
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e) => {
                                                    setModalFirstAproved(false);
                                                    setCodeCentroCusto('');
                                                }}
                                            >
                                                Cancelar
                                            </button>
                                            <button
                                                onClick={(e) =>
                                                    changeStatusSalesRecordAproved
                                                }
                                                type="submit"
                                                className="btn btn-success"
                                            >
                                                Pré Aprovar
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </ModalLarge>
                        </div>
                    </div>

                    {loading === true ? (
                        <Content>
                            <ReactLoading
                                type="spinningBubbles"
                                color="blue"
                                height={200}
                                width={100}
                            />
                        </Content>
                    ) : (
                        ''
                    )}
                    <div
                        style={{ display: loading === true ? 'none' : 'block' }}
                    >
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="box">
                                    <div
                                        className="box-body"
                                        style={{
                                            background: '#FFFFFF',
                                            boxShadow:
                                                '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                            padding: '15px',
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <h2>Registros de Vendas</h2>
                                            </div>
                                            <div className="text-right col-sm-6">
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        props.history.push(
                                                            `/new_check_list`
                                                        );
                                                        e.preventDefault();
                                                    }}
                                                    className="btn btn-primary"
                                                >
                                                    Cadastro
                                                </button>
                                            </div>
                                        </div>
                                        {/* {props.salesRecords.length >= 0 ||
                                        props.pesquisa ? ( */}
                                        <CabecalhoNew
                                            filter={
                                                <>
                                                    <div className="col-md-3 col-sm-12 mt-3">
                                                        <Input
                                                            label="De"
                                                            type="date"
                                                            icon="fas fa-calendar"
                                                            onChange={(e) =>
                                                                dispatch({
                                                                    type: 'changeFromReportSalesRecord',
                                                                    payload:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                            value={from}
                                                        />
                                                    </div>
                                                    <div className="col-md-3 col-sm-12 mt-3">
                                                        <Input
                                                            label="Até"
                                                            type="date"
                                                            max={to}
                                                            icon="fas fa-calendar"
                                                            onChange={(e) =>
                                                                dispatch({
                                                                    type: 'changeToReportSalesRecord',
                                                                    payload:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                            value={to}
                                                        />
                                                    </div>
                                                </>
                                            }
                                            buttonCsv={
                                                <>
                                                    <button
                                                        style={{
                                                            background:
                                                                '#005F31',
                                                            color: '#FFF',
                                                            borderRadius:
                                                                '20.5px',
                                                            width: '140px',
                                                        }}
                                                        className="btn btn-sm"
                                                        onClick={download}
                                                    >
                                                        <i className="fas fa-file-csv"></i>{' '}
                                                        Exportar CSV
                                                    </button>
                                                    <button
                                                        style={{
                                                            background:
                                                                '#1A170E',
                                                            color: '#FFF',
                                                            borderRadius:
                                                                '20.5px',
                                                            width: '140px',
                                                            marginLeft: '10px',
                                                        }}
                                                        className="btn btn-sm"
                                                        onClick={() =>
                                                            setModalFilter(
                                                                modalFilter ===
                                                                    false
                                                                    ? true
                                                                    : false
                                                            )
                                                        }
                                                    >
                                                        <i className="fas fa-filter"></i>{' '}
                                                        Mais Filtros
                                                    </button>
                                                    <CSVLink
                                                        filename={`Relatorio-${new Date()}.csv`}
                                                        headers={headers}
                                                        hidden={true}
                                                        separator={';'}
                                                        ref={fileUpload}
                                                        data={
                                                            csvSalesRecord || []
                                                        }
                                                    >
                                                        <span className="fas fa-file-csv">
                                                            {' '}
                                                            Exportar CSV
                                                        </span>
                                                    </CSVLink>
                                                </>
                                            }
                                            to="#/"
                                            action={props.get}
                                            clear={props.clearPesquisa}
                                            valuePesquisa={props.pesquisa}
                                            value={props.qtd_per_page}
                                            onChange={props.change_qtd_per_page}
                                            changePesquisa={
                                                props.changePesquisa
                                            }
                                        />
                                        {/* ) : (
                                            ""
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div
                                style={{
                                    display: `${
                                        displayLegend === true
                                            ? 'none'
                                            : 'block'
                                    }`,
                                }}
                                className="text-right"
                            >
                                <button
                                    onClick={(e) => setDisplayLegend(true)}
                                    className="btn"
                                    style={{
                                        background:
                                            'linear-gradient(99.87deg, #427B67 25.78%, #22AC7A 65.59%)',
                                        borderRadius: '20.5px',
                                    }}
                                >
                                    Legendas
                                </button>
                            </div>
                            <div
                                style={{
                                    backgroundColor: '#FFF',
                                    padding: '10px',
                                    borderRadius: '25px',
                                    display: `${
                                        displayLegend === false
                                            ? 'none'
                                            : 'block'
                                    }`,
                                }}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        float: 'right',
                                    }}
                                >
                                    <button
                                        onClick={(e) => setDisplayLegend(false)}
                                        className="btn"
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-2 mt-2">
                                        <h5 style={{ fontSize: '16px' }}>
                                            Legendas:
                                        </h5>
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span>
                                            <img
                                                src={IconAnalysys}
                                                width="20px"
                                                alt="Analise"
                                            />{' '}
                                            Em Análise
                                        </span>
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span>
                                            <img
                                                src={AprovedIcon}
                                                width="20px"
                                                alt="Aprovar"
                                            />{' '}
                                            Aprovar
                                        </span>
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <span>
                                            <img
                                                src={ReprovedIcon}
                                                width="20px"
                                                alt="Reprovar"
                                            />{' '}
                                            Reprovar
                                        </span>
                                    </div>
                                    {/* <div className="col-md-1 mt-2">
                                        <span><img src={IconBilled} width="20px" /> Faturar</span>

                                    </div> */}
                                </div>
                            </div>
                            {success ? (
                                <Alert type="success">{success}</Alert>
                            ) : (
                                ''
                            )}
                            {error ? <Alert type="danger">{error}</Alert> : ''}
                            <div className="row mt-3">
                                {parseInt(props.salesRecords.length) === 0 ? (
                                    <div
                                        style={{
                                            color: '#FFFF',
                                            textAlign: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <span style={{ color: 'black' }}>{`${
                                            !props.pesquisa
                                                ? 'Não existem Registros de Vendas cadastrados no sistema. Clique no botão Cadastro para inserir um novo Registro de Vendas no Sistema'
                                                : 'Nenhum registro encontrado na pesquisa'
                                        }`}</span>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <CheckLists
                                    modalAprovedOn={modalAprovedOn}
                                    modalReprovedOn={modalReprovedOn}
                                    history={props.history}
                                    check={check}
                                    sales_records={props.salesRecords}
                                    formatReal={formatReal}
                                    onDelete={onChangeModal}
                                />
                            </div>
                        </div>
                        {props.salesRecords.length > 0 && (
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="box">
                                        <div
                                            className="box-body"
                                            style={{
                                                background: '#FFFFFF',
                                                boxShadow:
                                                    '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                                padding: '15px',
                                            }}
                                        >
                                            <div className="row mt-4">
                                                <div className="col-md-1">
                                                    <select
                                                        value={
                                                            props.qtd_per_page
                                                        }
                                                        onChange={
                                                            props.change_qtd_per_page
                                                        }
                                                        name="example1_length"
                                                        aria-controls="example1"
                                                        className="custom-select custom-select-sm form-control form-control-sm"
                                                    >
                                                        <option value={8}>
                                                            8
                                                        </option>
                                                        <option value={24}>
                                                            24
                                                        </option>
                                                        <option value={48}>
                                                            48
                                                        </option>
                                                        <option value={96}>
                                                            96
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="col-md-5 mt-1 mb-2">
                                                    Exibindo de {props.from} à{' '}
                                                    {props.to} do total de{' '}
                                                    {props.qtd_total}
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div
                                                        style={{
                                                            float: 'right',
                                                        }}
                                                    >
                                                        <Pagination>
                                                            {props.pagination(
                                                                props.current_page,
                                                                props.get,
                                                                props.last_page,
                                                                props.changePage
                                                            )}
                                                        </Pagination>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

const mapStateToProps = (state) => ({
    salesRecords: state.sales_record.sales_records.data || [],
    current_page: state.sales_record.sales_records.current_page,
    perfis: state.auth.user.perfis,
    last_page: state.sales_record.sales_records.last_page,
    from: state.sales_record.sales_records.from,
    to: state.sales_record.sales_records.to,
    qtd_total: state.sales_record.sales_records.total,
    qtd_per_page: state.sales_record.qtd_per_page,
    pesquisa: state.sales_record.pesquisa || '',
    id_role: state.auth.user.id_role,
    asc: state.sales_record.asc,
    th: state.sales_record.th,
    id_coordinator: state.sales_record.coordinator_report.value || '',
    id_area: state.sales_record.area_report.value || '',
    id_status: state.sales_record.status_report.value || '',
    project_report: state.sales_record.project_report.value || '',
    from_date: state.sales_record.from,
    to_date: state.sales_record.to,
    order_var: state.sales_record.order,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            pagination,
            get,
            order,
            change_qtd_per_page,
            changePesquisa,
            clearPesquisa,
            changePage,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GridCheckList);
