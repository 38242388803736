import React, { useState, useEffect, useRef } from 'react';
import Form from '../../../Forms/Form';
import Input from '../../../Forms/Input';
import Alert from '../../../Alerts/Alert';
import TextArea from '../../../Forms/TextArea';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import {
    del_attachment,
    getFilial,
    getResource,
    post_project_ensino,
    put_project_ensino,
    get_view_project,
    getTechnicalProject,
    post_technical_project,
    changeStatusTechnical,
    checkDeleteTechnical,
    getAreaManager
} from '../../../Services/projectEnsino';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { ContentUpload } from '../../../Upload/styles';
import Upload from '../../../Upload/upload';
import FileList from '../../../FileList/FileList';
import MyModal from '../../../Modal/MyModal';
import Technical from './Technical';
import TableTechnical from './TableTechnical';
import RenderTechnical from './RenderTechnical';
import AlertNew from '../../../Alerts/AlertNew';
import makeAnimated from 'react-select/animated';
import RadioMaterial from '../../../Forms/RadioMaterial';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableProdutivity from '../CheckList/TableProdutivity';
import Produtivity from '../CheckList/Produtivity';
import TableProducts from '../CheckList/TableProducts';
import Products from '../CheckList/Products';

export default function Project(props) {
    const id_role = useSelector(state => state.auth.user.id) || '';
    const dispatch = useDispatch();
    let history = useHistory();
    const selectTypeRef = useRef(null);
    const selectStatusRef = useRef(null);
    const selectAreaRef = useRef(null);
    const selectManagerRef = useRef(null);
    const selectCoordinatorRef = useRef(null);
    const selectClientRef = useRef(null);
    const selectEmpresaRef = useRef(null);
    const selectFilialRef = useRef(null);
    const selectMainActivityRef = useRef(null);
    const selectMotiveRef = useRef(null);
    const culturaRef = useRef(null);
    const produtoRef = useRef(null);
    const role = useSelector(state => state.auth.user.perfis);
    const [title, setTitle] = useState('');
    const [id, setId] = useState('');
    const [reembolsavel, setReembolsavel] = useState('Não');
    const [inadiplente, setInadiplente] = useState('Não');
    const [dedication, setDedication] = useState('');
    const [code, setCode] = useState('');
    const [centroCusto, setCentroCusto] = useState('');
    const [idSalesRecord, setIdSalesRecord] = useState('');
    const [types, setTypes] = useState([]);
    const [id_type, setIdType] = useState('');
    const [status, setStatus] = useState([]);
    const [id_status, setIdStatus] = useState('');
    const [areas, setAreas] = useState([]);
    const [id_area, setIdArea] = useState('');
    const [hectares, setHectares] = useState('');
    const [billingProperty, setBillingProperty] = useState('');
    const [idMainActivity, setIdMainActivity] = useState('');
    const [valueCulture, setValueCulture] = useState('');
    const [idCulture, setIdCulture] = useState('');
    const [optionsCulture, setOptionsCulture] = useState([]);
    const [alertCultura, setAlertCultura] = useState('');
    const [produtivity, setProdutivity] = useState([]);
    const [product, setProduct] = useState([]);
    const [idProduct, setIdProduct] = useState('');
    const [optionsProduct, setOptionsProduct] = useState([]);
    const [alertProduto, setAlertProduto] = useState('');
    const [valueProduct, setValueProduct] = useState('');
    const [idMotive, setIdMotive] = useState('');
    const [optionsMotive, setOptionsMotive] = useState([]);
    const [managers, setManagers] = useState([]);
    const [id_manager, setIdManager] = useState('');
    const [coordinators, setCoordinators] = useState([]);
    const [id_coordinator, setIdCoordinator] = useState('');
    const [customers, setCustomers] = useState([]);
    const [id_client, setIdClient] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [value, setValue] = useState('');
    const [description, setDescription] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [imposto, setImposto] = useState('');
    const [margemAdm, setMargemAdm] = useState('');
    const [margemLqd, setMargemLqd] = useState('');
    const [margemAd, setMargemAd] = useState('');
    const [loading, setLoading] = useState(false);
    const [technical, setTechnical] = useState([]);
    const [id_technician, setIdTechnician] = useState([]);
    const [remuneration, setRemuneration] = useState([]);
    const [id_remuneration, setIdRemuneration] = useState([]);
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState('');
    const [alert, setAlert] = useState('');
    const [modal, setModal] = useState(false);
    const [value_technical, setValueTechnical] = useState('');
    const [technicalProject, setTechnicalProject] = useState([]);
    const [errorSubmitTechnical, setErrorSubmitTechnical] = useState('');
    const [successSubmitTechnical, setSuccessSubmitTechnical] = useState('');
    const [errorsTechnical, setErrorsTechnical] = useState([]);
    const [dataAlocacao, setDataAlocacao] = useState('');
    const [dataFimAlocacao, setDataFimAlocacao] = useState('');
    const animatedComponents = makeAnimated();
    const [idEmpresa, setIdEmpresa] = useState('');
    const [optionsEmpresa, setOptionsEmpresa] = useState([]);
    const [optionsAtuacao, setOptionsAtuacao] = useState([]);
    const [idAtuacao, setIdAtuacao] = useState([]);
    const [optionsFormato, setOptionsFormato] = useState([]);
    const [idFormato, setIdFormato] = useState([]);
    const [idFilial, setIdFilial] = useState('');
    const [optionsFilial, setOptionsFilial] = useState([]);
    const [listTechnical, setListTechnical] = useState([]);
    const [removedListTechnical, setRemovedListTechnical] = useState([]);
    const [newListTechnical, setNewListTechnical] = useState([]);
    const [optionsFormatoAtuacao, setOptionsFormatoAtuacao] = useState([]);
    const [idFormatoAtuacao, setIdFormatoAtuacao] = useState([]);

    useEffect(() => {
        getResources();
    }, []);

    useEffect(() => {
        viewPerId();
    }, []);

    useEffect(() => {
        permissionRole();
        return () => {
            permissionRole();
        };
    }, [props.match.params.action]);

    useEffect(() => {
        renderTechnical();
    }, [technicalProject]);

    // useEffect(() => {
    //     filial(idEmpresa.value);

    // }, [idEmpresa]);


    //Função que renderiza os técnicos adicionados ao projeto
    function renderTechnical() {
        return <RenderTechnical
            coordinator={id_coordinator}
            manager={id_manager}
            handleCheeseChange={changeStatusActive}
            roles={role}
            formatReal={formatReal}
            // technical={technicalProject}
            technical={listTechnical}
            isDisabled={props.match.params.action === 'view' ? true : false}
            mode = {props.match.params.action}
            handleRemove={deleteTechnicalProject}
        />;
    }

    function permissionRole() {
        if (role.indexOf(1) < 0 && role.indexOf(6) < 0 && role.indexOf(10) < 0) {
            return props.history.push('/projects');
        }
    }

    //Função que traz as filiais relacionados a empresa
    function filial(idEmpresa) {
        getFilial(idEmpresa)
            .then(resp => {
                setOptionsFilial(resp.data.map(desc => ({
                    value: desc.id,
                    label: desc.name,
                }))); 
                setIdFilial({
                    value: resp.data[0].id,
                    label: resp.data[0].name,
                })
            });
    }
    
    const handleChangeArea = (e) => {
        let areaID = e.value;
        setIdArea(e)
        
        getAreaManager(areaID)
            .then( resp => {
                setIdManager(resp.data[0])
                setManagers(
                    resp.data.map(desc => ({
                        value: desc.value,
                        label: desc.label,
                    })),
                );
            })
            .catch( err => console.log(err.data))
    }

    //Função que traz dados necessários para preencher os selects de cadastro
    function getResources() {
        getResource()
            .then(resp => {
                setTechnical(
                    resp.data.technical.map(desc => ({
                        value: desc.id,
                        label: desc.name,
                    })),
                );
                setRemuneration(resp.data.remuneration);
                setTypes(resp.data.types);
                setStatus(resp.data.status);
                setIdStatus({value:resp.data.status[3].value, label:resp.data.status[3].label})
                setAreas(resp.data.areasAtivo);
                // setManagers(
                //     resp.data.managers.map(desc => ({
                //         value: desc.id,
                //         label: desc.name,
                //     })),
                // );
                setCoordinators(
                    resp.data.coordinators.map(desc => ({
                        value: desc.id,
                        label: desc.name,
                    })),
                );
                setCustomers(
                    resp.data.customers.map(desc => ({
                        value: desc.id,
                        label: desc.nome,
                    })),
                );
                setOptionsEmpresa(resp.data.empresa);
                setIdEmpresa({value:resp.data.empresa[0].value, label:resp.data.empresa[0].label})   
                filial(resp.data.empresa[0].value)
                setOptionsAtuacao(resp.data.atuacao);
                setOptionsFormato(resp.data.formato);
                setOptionsFormatoAtuacao(resp.data.formatoAtuacao);
                setIdClient(resp.data.customers.map(desc => ({
                        value: desc.id,
                        label: desc.nome,
                    })),);
            });
    }

    const changeEmpresa = e => {
        // setIdEmpresa(e);
        // setIdFilial('');

    };

    //Função que salva o técnico no projeto
    const postTechnical = e => {
        e.preventDefault();
        let alocacao = null;
        let fim_alocacao = null;

        if (dataAlocacao) {
            let date = new Date(dataAlocacao),
                dia = date.getUTCDate(),
                diaF = (dia.length == 1) ? '0' + dia : dia,
                mes = (date.getUTCMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
                mesF = (mes.length == 1) ? '0' + mes : mes,
                anoF = date.getUTCFullYear();

            alocacao = `${diaF}/${mesF}/${anoF}`;
        }

        if (id_remuneration.value == 2 && dataFimAlocacao) {
            let date = new Date(dataFimAlocacao),
                dia = date.getUTCDate(),
                diaF = (dia.length == 1) ? '0' + dia : dia,
                mes = (date.getUTCMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
                mesF = (mes.length == 1) ? '0' + mes : mes,
                anoF = date.getUTCFullYear();

            fim_alocacao = `${diaF}/${mesF}/${anoF}`;
        }

        const data = {
            user_id: id_technician.value ? id_technician.value : null,
            name_technical: id_technician.label ? id_technician.label : null,
            id_remuneration: idAtuacao.value === 1 && optionsFormatoAtuacao ? idFormato.id_remuneracao : id_remuneration.value,
            remuneration: idAtuacao.value === 1 && optionsFormatoAtuacao ? idFormato.remuneracao : id_remuneration.label,
            value_technical: idAtuacao.value === 1 && optionsFormatoAtuacao ? (idFormato.valor_base * (dedication || 1)) : value_technical,
            id_project: props.match.params.id ? props.match.params.id : null,
            dedication: dedication ? dedication : null,
            dataAlocacao: alocacao ? alocacao : null,
            dataFimAlocacao: fim_alocacao ? fim_alocacao : null,
            id_atuacao: idAtuacao.value ? idAtuacao.value : null,
            atuacao: idAtuacao.label ? idAtuacao.label : null,
            id_formato: idFormato.value ? idFormato.value : null,
            formato: idFormato.label ? idFormato.label : null,
            ativo: 1,
            description: description ? description : null,
            new: true,
        };

        let result = cancelPostTechnical(data);
        if (result) {
            return false;
        }

        let array = [];
        let arrayNew = [];
        array.push(...listTechnical, data);
        arrayNew.push(...newListTechnical, data);
        setListTechnical(array);
        setNewListTechnical(arrayNew);

        renderTechnical();

        setIdTechnician('');
        setIdRemuneration('');
        setDedication('');
        setValueTechnical('');
        setDataAlocacao('');
        setDataFimAlocacao('');
        setIdAtuacao('');
        setIdFormato('');
        setDescription('');

    };

    const cancelPostTechnical = (data) => {
        //Valida se o tecnico foi adicinado duas vezes com a mesma remuneraçao
        // let result = listTechnical.find(value => {
        //     if (value.user_id == data.user_id && value.id_remuneration == data.id_remuneration && value.ativo == 1) {
        //         return true;
        //     }
        // });
        // if (result) {
        //     setErrorSubmitTechnical(`O técnico "${data.name_technical}" já está adicionado com a remuneração "${data.remuneration}"`);
        //     setTimeout(() => {
        //         setErrorSubmitTechnical('');
        //     }, 3000);
        //     return true;
        // }

        if (!data.user_id) {
            setErrorSubmitTechnical('O técnico não foi selecionado.');
            setTimeout(() => {
                setErrorSubmitTechnical('');
            }, 3000);
            return true;
        } else if (!data.dataAlocacao) {
            setErrorSubmitTechnical('A data de alocação não foi preenchida.');
            setTimeout(() => {
                setErrorSubmitTechnical('');
            }, 3000);
            return true;
        } else if (id_remuneration.value == 2 && !data.dataFimAlocacao) {
            setErrorSubmitTechnical('A data fim de alocação não foi preenchida.');
            setTimeout(() => {
                setErrorSubmitTechnical('');
            }, 3000);
            return true;
        } else if (id_remuneration.value == 2 && ( new Date(data.dataFimAlocacao) < new Date(data.dataAlocacao))) {
            setErrorSubmitTechnical('A data da alocação deve ser menor que a data fim da alocação.');
            setTimeout(() => {
                setErrorSubmitTechnical('');
            }, 3000);
            return true;
        } else if (!data.id_atuacao) {
            setErrorSubmitTechnical('A atuação não foi preenchida.');
            setTimeout(() => {
                setErrorSubmitTechnical('');
            }, 3000);
            return true;
        } else if (!data.id_formato) {
            setErrorSubmitTechnical('O formato não foi preenchido.');
            setTimeout(() => {
                setErrorSubmitTechnical('');
            }, 3000);
            return true;
        } else if (!data.dedication) {
            setErrorSubmitTechnical('A dedicação não foi preenchida.');
            setTimeout(() => {
                setErrorSubmitTechnical('');
            }, 3000);
            return true;
        } else if (idAtuacao.value === 1 && optionsFormatoAtuacao ? !idFormato.id_remuneracao : !data.id_remuneration) {
            setErrorSubmitTechnical('A remuneração não foi selecionada.');
            setTimeout(() => {
                setErrorSubmitTechnical('');
            }, 3000);
            return true;
        } else if (idAtuacao.value === 1 && optionsFormatoAtuacao ? !idFormato.valor_base : !data.value_technical) {
            setErrorSubmitTechnical('O valor não foi preenchido.');
            setTimeout(() => {
                setErrorSubmitTechnical('');
            }, 3000);
            return true;
        }

        return false;
    };
    //Função que pega os clientes escolhidos no projeto
    const handleChangeClient = (newValue: any, actionMeta: any) => {
        setIdClient(newValue);
    };

    //Função que traz os registros de determinado ID de projeto
    function viewPerId() {
        props.match.params.action && (
            get_view_project(props.match.params.id)
                .then(resp => {
                    setIdArea({
                        value: resp.data.project[0].id_area,
                        label: resp.data.project[0].descArea,
                    });
                    setIdStatus({
                        value: resp.data.project[0].id_status,
                        label: resp.data.project[0].descStatus,
                    });
                    setIdManager({
                        value: resp.data.project[0].id_gerente,
                        label: resp.data.project[0].gerente,
                    });
                    setManagers([{
                        value: resp.data.project[0].id_gerente,
                        label: resp.data.project[0].gerente,
                    }]);
                    setIdCoordinator({
                        value: resp.data.project[0].id_coordenador,
                        label: resp.data.project[0].coordenador,
                    });
                    setIdClient(resp.data.client.map(desc => ({
                        value: desc.id_cliente,
                        label: desc.cliente,
                    })));

                    setListTechnical(resp.data.technical);

                    setValue(resp.data.project[0].valor_projeto);
                    setStartDate(resp.data.project[0].data_inicio);
                    setEndDate(resp.data.project[0].data_fim);
                    setTitle(resp.data.project[0].titulo);
                    setCentroCusto(resp.data.project[0].centro_custo);
                    // setIdEmpresa({ value: resp.data.project[0].id_empresa, label: resp.data.project[0].empresa });
                    // filial(resp.data.project[0].value)
                    // setIdFilial({ value: resp.data.project[0].id_filial, label: resp.data.project[0].filial });
                })
        );
    }

    //Função que salva registro no banco de dados
    const handleForm = (e) => {
        e.preventDefault();
        setLoading(true);

        let newClient = [];
        id_client.map(desc => newClient.push(desc.value));

        const data = {
            title,
            centroCusto,
            status: id_status.value,
            area: id_area.value,
            manager: id_manager.value,
            coordinator: id_coordinator.value,
            clients: newClient,
            start_date: startDate,
            end_date: endDate,
            id_empresa: idEmpresa.value,
            id_filial: idFilial.value,
            listTechnical,
            removedListTechnical,
        };

        if (props.match.params.action === 'edit') {
            const id = props.match.params.id;
            put_project_ensino(id, data)
                .then(resp => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then(resp => setTimeout(() => {
                    window.location.reload();
                    setSuccess('');
                }, 2500))
                .catch(e => {
                    setLoading(false);
                    setSuccess(e.response.data.success ?? '');
                    setAlert(e.response.data.errorSubmit);
                    setErrorSubmitTechnical(e.response.data.errorSubmitTechnical);
                    if (e.response.data.technicals) {
                        setListTechnical(e.response.data.technicals);
                    }
                    setTimeout(() => {
                        // window.location.reload();
                        setSuccess('');
                        setErrorSubmitTechnical('');
                        setError('');
                        setAlert('');
                    }, 2500);
                });

        } else {
            post_project_ensino(data)
                .then(resp => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                    setId(resp.data.id_project);
                })
                .then(resp => setTimeout(() => {
                    window.location.reload();
                    setSuccess('');
                }, 2500))
                .catch(e => {
                    setLoading(false);
                    setSuccess('');
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        setError('');
                        setAlert('');
                    }, 2500);
                });

        }
    };

    //Função que fecha modal ao escolher que não deseja relacionar técnico ao projeto
    const handleClose = event => {
        setModal(false);
        // setTimeout(() => {
        //     window.location.reload();
        // }, 2000);
    };

    //Função que altera o status do técnico no projeto
    const changeStatusActive = (event) => {
        const id = event.target.id;
        let checked = event.target.checked === true ? 1 : 0;
        const data = {
            ativo: checked,
        };
        changeStatusTechnical(id, data)
            .then(resp => {
                setErrorSubmitTechnical('');
                setSuccessSubmitTechnical(resp.data.success);
                setListTechnical(resp.data.technicals.concat(newListTechnical));

                setTimeout(() => {
                    setSuccessSubmitTechnical('');
                }, 3000);
            })
            .catch(e => {
                setSuccessSubmitTechnical('');
                setErrorSubmitTechnical(e.response.data.errorSubmitTechnical);
                setTimeout(() => {
                    setErrorSubmitTechnical('');
                }, 3000);
            });
    };

    //Função que deleta o técnico do projeto
    const deleteTechnicalProject = (id) => {
        checkDeleteTechnical(id).then(resp => {
            setSuccessSubmitTechnical(resp.data.successSubmitTechnical);
            let newlistT = listTechnical.filter(technical => technical.id !== id);
            setListTechnical(newlistT);
            setRemovedListTechnical(removedListTechnical.concat(listTechnical.filter(technical => technical.id == id)));
            setTimeout(() => {
                setSuccessSubmitTechnical('');
            }, 2500);
        }).catch(e => {
            setErrorSubmitTechnical(e.response.data.errorSubmitTechnical);
            setTimeout(() => {
                setErrorSubmitTechnical('');
            }, 2500);
        });
    };

    //Função que formata o campo valor
    function formatReal(desc) {
        var tmp = `${desc.toFixed(2)}`.replace('.', ',');

        if (tmp.length > 6) 
        {
            tmp = tmp.replace(/(([0-9]{6}),([0-9]{2}$)*)/g, '.$1');
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, '.$1');
        }

        return tmp;
    }

    return (
        <>
            {/* <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage title={props.match.params.action === 'edit' ? "Edição de Projetos" : props.match.params.action === 'view' ? "Visualização de Projetos" : "Cadastro de Projetos"}> */}
            {loading === true ? (
                <Content>
                    <ReactLoading type='spinningBubbles' color='blue' height={200} width={100} />
                </Content>

            ) : ('')}
            {alert ? (
                <div>
                    <Alert type='danger' onCloseAlert={() => setAlert('')}>
                        {alert}
                    </Alert>
                </div>
            ) : ('')}

            {success ? (
                <div>
                    <Alert type='success' onCloseAlert={() => setSuccess('')}>
                        {success}
                    </Alert>
                </div>
            ) : ('')}

            <div style={{
                width: '100%',
                display: loading === true ? ('none') : ('block'),
                opacity: success || alert ? 0.1 : 1,
            }}>
                <Form onSubmit={handleForm}>
                    <div className='row'>
                        <div className='col-md-6'>
                            <Input
                                icon='fas fa-calendar-alt'
                                label='Título'
                                type='text'
                                value={title}
                                maxLength='255'
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder='Digite o título'
                                required={true}
                                disabled={props.match.params.action === 'view' ? true : false}
                            />
                            {error ? (<p style={{ color: 'red' }}>{error.title}</p>) : ('')}
                        </div>
                        <div className='col-md-6'>
                            <NumberFormat
                                label='Código Centro de Custo'
                                name='code'
                                icon='fas fa-key'
                                fixedDecimalScale={true}
                                decimalScale={0}
                                thousandSeparator={''}
                                decimalSeparator={','}
                                placeholder=''
                                customInput={Input}
                                allowNegative={false}
                                onChange={(e) => setCentroCusto(e.target.value)}
                                value={centroCusto}
                                disabled={props.match.params.action === 'view' ? true : false}
                            />
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className='col-md-6'>
                            <label>Empresa *</label>
                            <Select
                                required={true}
                                value={idEmpresa}
                                ref={selectEmpresaRef}
                                isDisabled={true}
                                options={optionsEmpresa}
                                onChange={changeEmpresa}
                            />
                            {!idEmpresa &&
                                <input
                                    tabIndex={-1}
                                    autoComplete='off'
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={idEmpresa}
                                    onFocus={() => selectEmpresaRef.current.focus()}
                                    required={true}
                                />
                            }
                        </div>
                        <div className='col-md-6'>
                            <label>Filial *</label>
                            <Select
                                required={true}
                                value={idFilial}
                                isDisabled={true}
                                ref={selectFilialRef}
                                options={optionsFilial}
                                onChange={(e) => setIdFilial(e)}
                            />
                            {!idFilial &&
                                <input
                                    tabIndex={-1}
                                    autoComplete='off'
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={idFilial}
                                    onFocus={() => selectFilialRef.current.focus()}
                                    required={true}
                                />
                            }
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <label>Status</label>
                            <Select
                                value={id_status}
                                ref={selectStatusRef}
                                label='Single select'
                                options={status}
                                onChange={(e) => setIdStatus(e)}
                                isDisabled={props.match.params.action === 'view' ? true : false}
                            />
                            {id_status.length <= 0 &&
                                <input
                                    tabIndex={-1}
                                    autoComplete='off'
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={id_status}
                                    onFocus={() => selectStatusRef.current.focus()}
                                    required={true}
                                />
                            }
                            {error ? (<p style={{ color: 'red' }}>{error.status}</p>) : ('')}
                        </div>
                        <div className='col-md-6'>
                            <label>Área</label>
                            <Select
                                value={id_area}
                                ref={selectAreaRef}
                                label='Single select'
                                options={areas}
                                onChange={(e) => handleChangeArea(e)}
                                isDisabled={props.match.params.action === 'view' ? true : false}

                            />
                            {id_area.length <= 0 &&
                                <input
                                    tabIndex={-1}
                                    autoComplete='off'
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={id_area}
                                    onFocus={() => selectAreaRef.current.focus()}
                                    required={true}
                                />
                            }
                            {error ? (<p style={{ color: 'red' }}>{error.area}</p>) : ('')}
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-md-4'>
                            <label>Gerente</label>
                            <Select
                                value={id_manager}
                                ref={selectManagerRef}
                                label='Single select'
                                options={managers}
                                onChange={(e) => setIdManager(e)}
                                isDisabled={props.match.params.action === 'view' || !id_area ? true : false}

                            />
                            {id_manager.length <= 0 &&
                                <input
                                    tabIndex={-1}
                                    autoComplete='off'
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={id_manager}
                                    onFocus={() => selectManagerRef.current.focus()}
                                    required={true}
                                />
                            }
                            {error ? (<p style={{ color: 'red' }}>{error.manager}</p>) : ('')}
                        </div>
                        <div className='col-md-4'>
                            <label>Coordenador</label>
                            <Select
                                ref={selectCoordinatorRef}
                                value={id_coordinator}
                                label='Single select'
                                options={coordinators}
                                onChange={(e) => setIdCoordinator(e)}
                                isDisabled={props.match.params.action === 'view' ? true : false}

                            />
                            {id_coordinator.length <= 0 &&
                                <input
                                    tabIndex={-1}
                                    autoComplete='off'
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={id_coordinator}
                                    onFocus={() => selectCoordinatorRef.current.focus()}
                                    required={true}
                                />
                            }
                            {error ? (<p style={{ color: 'red' }}>{error.coordinator}</p>) : ('')}
                        </div>
                        <div className='col-md-4'>
                            <label>Cliente</label>
                            <Select
                                value={id_client}
                                isMulti
                                name='clientes'
                                options={customers}
                                components={animatedComponents}
                                closeMenuOnSelect={false}
                                className='basic-multi-select'
                                ref={selectClientRef}
                                classNamePrefix='select'
                                onChange={handleChangeClient}
                                isDisabled={true}
                            />

                            {id_client ? id_client.length <= 0 ? (

                                    <input
                                        tabIndex={-1}
                                        autoComplete='off'
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={id_client}
                                        onFocus={() => selectClientRef.current.focus()}
                                        required={true}
                                    />
                                ) : ''
                                : id_client == null ? (
                                    <input
                                        tabIndex={-1}
                                        autoComplete='off'
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={id_client}
                                        onFocus={() => selectClientRef.current.focus()}
                                        required={true}
                                    />
                                ) : ''

                            }
                            {error ? (<p style={{ color: 'red' }}>{error.client}</p>) : ('')}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <Input
                                icon='fas fa-calendar-alt'
                                label='Data Início'
                                onChange={(e) => setStartDate(e.target.value)}
                                type='date'
                                value={startDate}
                                placeholder=''
                                required={true}
                                max={endDate}
                                disabled={props.match.params.action === 'view' ? true : false}
                            />
                            {error ? (<p style={{ color: 'red' }}>{error.end_date}</p>) : ('')}
                        </div>
                        <div className='col-md-4'>
                            <Input
                                icon='fas fa-calendar-alt'
                                label='Data Fim'
                                onChange={(e) => setEndDate(e.target.value)}
                                type='date'
                                value={endDate}
                                min={startDate}
                                placeholder=''
                                required={true}
                                disabled={props.match.params.action === 'view' ? true : false}
                            />
                            {error ? (<p style={{ color: 'red' }}>{error.start_date}</p>) : ('')}
                        </div>

                    </div>

                    <br></br>
                    <>
                        <div className='card-header'>
                            <h3 className='card-title'>Adição de Técnicos</h3>
                        </div>
                        {/* <div className='row mt-3 ml-3'> */}
                        <Technical
                            technical={technical}
                            atuacao={optionsAtuacao}
                            id_atuacao={idAtuacao}
                            changeAtuacao={(e) => (setIdAtuacao(e), setIdFormato(''), setIdRemuneration(''))}
                            formato={idAtuacao.value === 1 ? optionsFormatoAtuacao : optionsFormato}
                            id_formato={idFormato}
                            changeFormato={(e) => (setIdFormato(e), setDedication(''))}
                            descricao={description}
                            changeDescription={(e) => setDescription(e.target.value)}
                            dedication={dedication}
                            changeDedication={(e) => setDedication(parseFloat(e.target.value.replace('.', '').replace('.', '').replace(',', '.')))}
                            remunerations={remuneration}
                            changeTechnical={(e) => setIdTechnician(e)}
                            changeRemuneration={(e) => setIdRemuneration(e)}
                            changeValue={(e) => setValueTechnical(parseFloat(e.target.value.replace('.', '').replace('.', '').replace(',', '.')))}
                            id_technical={id_technician}
                            id_remuneration={idAtuacao.value === 1 && optionsFormatoAtuacao ? {value:idFormato.id_remuneracao, label:idFormato.remuneracao} : id_remuneration}
                            value_technical={idAtuacao.value === 1 && optionsFormatoAtuacao ? (idFormato.valor_base * (dedication || 1)) : value_technical}
                            isDisabled={props.match.params.action === 'view' ? true : false}
                            isDisabledRemuneracao={idAtuacao.value === 1 ? true : false}
                            isDisabledValor={idAtuacao.value === 1 ? true : false}
                            isVisible={id_remuneration.value == 2 ? true : false}
                            onClick={postTechnical}
                            errorTechnical={errorsTechnical ? (
                                <p style={{ color: 'red' }}>{errorsTechnical.id_technical}</p>) : ('')}
                            errorRemuneration={errorsTechnical ? (
                                <p style={{ color: 'red' }}>{errorsTechnical.id_remuneration}</p>) : ('')}
                            errorValueTechnical={errorsTechnical ? (
                                <p style={{ color: 'red' }}>{errorsTechnical.value_technical}</p>) : ('')}
                            location={window.location}
                            dataAlocacao={dataAlocacao}
                            dataFimAlocacao={dataFimAlocacao}
                            changeDataAlocacao={(e) => setDataAlocacao(e.target.value)}
                            changeDataFimAlocacao={(e) => setDataFimAlocacao(e.target.value)}
                            startDate={startDate}
                        />
                        {/* </div> */}
                        {errorSubmitTechnical ? (
                            <div>
                                <Alert type='danger' onCloseAlert={() => setErrorSubmitTechnical('')}>
                                    {errorSubmitTechnical}
                                </Alert>
                            </div>
                        ) : ('')}
                        {successSubmitTechnical ? (
                            <div>
                                <Alert type='success' onCloseAlert={() => setSuccessSubmitTechnical('')}>
                                    {successSubmitTechnical}
                                </Alert>
                            </div>
                        ) : ('')}
                        <TableTechnical >
                            {renderTechnical()}
                        </TableTechnical>
                    </>

                    <br></br>
                    <div className='text-right'>
                        <button style={{ marginRight: '10px' }} type='button' className='btn btn-secondary'
                                onClick={() => history.goBack()}>Voltar
                        </button>
                        {props.match.params.action === 'view' ? (

                            <Link
                                style={{
                                    cursor: role.indexOf(1) < 0 && role.indexOf(6) < 0 && role.indexOf(10) < 0 ? 'not-allowed' : '',
                                    color: role.indexOf(1) < 0 && role.indexOf(6) < 0 && role.indexOf(10) < 0 ? '#fff' : '',
                                    backgroundColor: role.indexOf(1) < 0 && role.indexOf(6) < 0 && role.indexOf(10) < 0 ? '#007bff' : '',
                                    borderColor: role.indexOf(1) < 0 && role.indexOf(6) < 0 && role.indexOf(10) < 0 ? '#007bff' : '',
                                    opacity: role.indexOf(1) < 0 && role.indexOf(6) < 0 && role.indexOf(10) < 0 ? .65 : '',
                                    boxShadow: role.indexOf(1) < 0 && role.indexOf(6) < 0 && role.indexOf(10) < 0 ? 'none' : '',

                                }}
                                onClick={e => {
                                    if (role.indexOf(1) < 0 && role.indexOf(6) < 0 && role.indexOf(10) < 0) {
                                        e.preventDefault();
                                    }
                                }}
                                to={`/project_ensino/${props.match.params.id}/edit`}
                                className='btn btn-primary'>Editar</Link>
                        ) : (
                            <button type='submit' className='btn btn-primary'>Salvar</button>
                        )}
                    </div>
                </Form>
            </div>
            {/* </StylePage> */}
            {/* <Footer /> */}
            {/* </div> */}
        </>
    );
}
