import React, { useEffect } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import StylePage from '../../../Style Page/StylePage'
import ReactLoading from 'react-loading'
import Alert from '../../../Alerts/Alert'
import Footer from '../../../Footer/Footer'
import TabsUser from './TabsUser'
import { Content } from '../../../LoadingStyle/StyleLoading'
import { useSelector } from 'react-redux'

export default function MyUser(props) {
    const loading = false
    const success = ''
    const alert = ''
    const id = useSelector(state => state.auth.user.id)
    
    useEffect(() => {
        verifyId();
        return () => {
            verifyId()
        }
    }, [props.match.params.id])

    function verifyId(){
        if(parseInt(id) !== parseInt(props.match.params.id)){
            props.history.push(`/profile/${id}`)
        }
    }
    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage title="" subtitle="">
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('')}
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ):('')}
                <div style={{display: loading === true ? ('none'):('block')}}>
                    <TabsUser />
                </div>   
            </StylePage>
            <Footer />
        </div>
    )
}
