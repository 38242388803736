import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import Input from '../../../Forms/Input'
import { useSelector, useDispatch } from 'react-redux'
import { getResource } from '../../../Services/extraCharge'

export default function ReportExtraCharge(props) {
    const coordinator_report = useSelector(state => state.extra_charge.coordinator_report)
    const project_report = useSelector(state => state.extra_charge.project_report) 
    const status_report = useSelector(state => state.extra_charge.status_report)
    const from = useSelector(state => state.extra_charge.from) 
    const to = useSelector(state => state.extra_charge.to)
    const dispatch = useDispatch()
    const [ project, setProject ] = useState([])
    const [ coordinator, setCoordinator ] = useState([])
    const [ status, setStatus ] = useState([])
    
    useEffect(() => {
        getResourceExtraCharge()
    }, [])

    function getResourceExtraCharge(){
        getResource()
        .then(resp => {
            setCoordinator(
                [
                    {value: '', label: '-- SELECIONE --'},
                    ...resp.data.coordinator.map(desc => ({
                        value: desc.id,
                        label: desc.name
                    }))
                ]
            )
            setProject(
                [
                    {value: '', label: '-- SELECIONE --'},
                    ...resp.data.project.map(desc => ({
                        value: desc.id,
                        label: desc.projeto
                    }))
                ]
            )

            setStatus(
                [
                    {value: '', label: '-- SELECIONE --'},
                    ...resp.data.status.map(desc => ({
                        value: desc.id,
                        label: desc.descricao
                    }))
                ]
            )
        })
    }
    
    return (
        <>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <label>Coordenador</label>
                        <Select 
                            value={coordinator_report}
                            label="Single select"
                            options={coordinator}
                            onChange={(e) => dispatch({type:'changeCoordinatorReportExtraCharge', payload:e})}             
                        /> 
                    </div>
                    <div className="col-md-12 mt-3">
                        <label>Projeto</label>
                        <Select 
                            value={project_report}
                            label="Single select"
                            options={project}
                            onChange={(e) => dispatch({type:'changeProjectReportExtraCharge', payload:e})}                      
                        /> 
                    </div>
                   
                </div>
                <div className="row mt-3 mb-4">
                    <div className="col-md-12 mt-3">
                        <label>Status</label>
                        <Select 
                            value={status_report}
                            label="Single select"
                            options={status}
                            onChange={(e) => dispatch({type:'changeStatusReportExtraCharge', payload:e})}   
                        /> 
                    </div>
                    <div className="col-md-12 mt-3">     
                        <Input 
                            label="De"
                            type="date"
                            icon="fas fa-calendar"
                            onChange={(e) => dispatch({ type: 'changeFromReportExtraCharge', payload:e.target.value })}
                            value={from}
                        />
                    </div>
                    <div className="col-md-12 mt-3"> 
                        <Input 
                            label="Até"
                            type="date"
                            icon="fas fa-calendar"
                            onChange={(e) => dispatch({ type: 'changeToReportExtraCharge', payload:e.target.value })}
                            value={to}
                        />
                    </div>   
                </div>
            </div>
        </>
    )
}
