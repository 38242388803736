/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import Footer from '../../../Footer/Footer';
import Table from '../../../Table/Table';
import Thead from '../../../Table/Thead';
import Tbody from '../../../Table/Tbody';
import Tfoot from '../../../Table/Tfoot';
import StylePage from '../../../Style Page/StylePage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    search,
    changePage,
    get,
    order,
    change_qtd_per_page,
    changePesquisa,
    clearPesquisa,
} from './Actions/actions';
import { pagination } from '../../../Pagination/paginations';
import { Link } from 'react-router-dom';
import Pagination from '../../../Table/Pagination';
import Cabecalho from '../../../Table/Cabecalho';
import Alert from '../../../Alerts/Alert';
import MyModal from '../../../Modal/MyModal';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import {
    del_project,
    get_projects,
    exportExcelDetalhado,
    exportExcelAgrupado,
    exportExcelPorTecnico,
    exportExcelPagamentos,
} from '../../../Services/project';
import Projects from './Projects';
import { CSVLink } from 'react-csv';
import ReportProject from './ReportProject';
import { useSelector, useDispatch } from 'react-redux';

function GridProject(props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [id, setId] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [projects, setProjects] = useState([]);
    const [modalFilter, setModalFilter] = useState(false);
    const [headers, setHeaders] = useState('');

    const titulo = 'projetos.titulo';
    const centro_custo = 'projetos.cod_centro_custo';
    const tipo = 'tipo_projetos.descricao';
    const area = 'area_projetos.descricao';
    const gerente = 'gerente.name';
    const coordenador = 'coord.name';
    const status = 'status_projetos.descricao';
    const fileUpload = useRef();

    useEffect(() => {
        getProjects();
        return () => {
            getProjects();
        };
    }, []);

    //Função que busca os projetos na api
    const getProjects = () => {
        props.get();
    };

    //Função que abre o modal e define o id a ser excluído
    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
    };

    //Função que fecha o modal de excluir projeto
    const handleCloseExcluir = (event) => {
        setModal(false);
        setId('');
    };

    //Função que exclui um projeto
    // const handleExcluir = (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     setModal(false);

    //     del_project(id)
    //         .then((resp) => {
    //             setSuccess(resp.data.success);
    //             setError('');
    //             setLoading(false);
    //         })
    //         .then((resp) =>
    //             setTimeout(() => {
    //                 setSuccess('');
    //             }, 4000),
    //         )
    //         .catch((e) => {
    //             setLoading(false);
    //             setError(e.response.data.error);
    //             setSuccess('');
    //             setModal(false);
    //             setTimeout(() => {
    //                 setError('');
    //             }, 4000);
    //         })
    //         .then((resp) => props.get());
    //     // .then(resp => {setState({success:resp.data.success, error:''})})
    // };

    const downloadDetalhado = (event) => {
        let page = 1;
        setHeaders([
            { label: 'id_projeto', key: 'value.id' },
            { label: 'id_registro venda', key: 'value.id_registro_venda' },
            { label: 'area', key: 'value.descArea' },
            { label: 'tipo_servico', key: 'value.descTipo' },
            { label: 'produtos', key: 'value.produto' },
            { label: 'codigo_w3', key: 'value.codigo_w3' },
            { label: 'projeto', key: 'value.titulo' },
            { label: 'cliente', key: 'value.nome_cliente' },
            {
                label: 'data_criacao_checklist',
                key: 'value.data_criacao_checklist',
            },
            { label: 'data_venda', key: 'value.data_venda' },
            {
                label: 'data_primeira_visita',
                key: 'value.data_primeira_visita',
            },
            { label: 'data_de_inicio', key: 'value.data_inicio' },
            { label: 'data_fim', key: 'value.data_fim' },
            { label: 'origem_venda', key: 'value.origem_venda' },
            { label: 'agente_venda', key: 'value.agente_venda' },
            { label: 'cidade', key: 'value.city_name' },
            { label: 'estado', key: 'value.UF' },
            { label: 'coordenador', key: 'value.coordenador' },
            { label: 'valor_total', key: 'value.valor_projeto' },
            { label: 'tipo_cobranca', key: 'value.tipo_cobranca' },
            { label: 'valor_parcela', key: 'value.valor_parcela' },
            { label: 'periodo_vigencia', key: 'value.periodo_vigencia_meses' },
            { label: 'atividade_princial', key: 'value.atividade_pricipal' },
            { label: 'tamanho_propriedade', key: 'value.hectares_propriedade' },
            { label: 'faturamento_propriedade', key: 'value.faturamento' },
            { label: 'nome_cultura', key: 'value.nome_cultura' },
            { label: 'valor_cultura', key: 'value.valor_cultura' },
            { label: 'status_projeto', key: 'value.descStatus' },
            { label: 'motivo_encerramento', key: 'value.descricao_motivo' },
            { label: 'centro_de_custo', key: 'value.cod_centro_custo' },
        ]);

        exportExcelDetalhado(
            page,
            props.total_page,
            props.order_var,
            props.th,
            props.pesquisa,
            props.type ? props.type : '',
            props.area ? props.area : '',
            props.manager ? props.manager : '',
            props.coordinator ? props.coordinator : '',
            props.status ? props.status : ''
        )
            .then((resp) => {
                let dados = resp.data.map((value) => {
                    return {
                        value: value.value,
                        data_base: value.data_base,
                    };
                });
                setProjects(dados);
            })
            .then((resp) => {
                // console.log(refunds)
                fileUpload.current.link.click();
            });
    };

    const downloadPorTecnico = (event) => {
        let page = 1;
        setHeaders([
            { label: 'id_projeto', key: 'value.id' },
            { label: 'area', key: 'value.descArea' },
            { label: 'projeto', key: 'value.titulo' },
            { label: 'tipo_servico', key: 'value.descTipo' },
            // {
            //     label: 'data_criacao_checklist',
            //     key: 'value.data_criacao_checklist',
            // },
            { label: 'cidade', key: 'value.city_name' },
            { label: 'estado', key: 'value.UF' },
            { label: 'coordenador', key: 'value.coordenador' },
            { label: 'tecnico', key: 'value.tecnico' },
            { label: 'data_alocacao', key: 'value.data_alocacao' },
            { label: 'dedicacao_dias', key: 'value.dedicacao_dias' },
            { label: 'remuneracao', key: 'value.remuneracao' },
            {
                label: 'periodicidade_pagamento',
                key: 'value.periodicidade_pagamento',
            },
            { label: 'periodo_vigencia', key: 'value.periodo_vigencia_meses' },
            // { label: 'data_encerramento', key: 'data_base[0].data_base' },
            // { label: 'data_alteração', key: 'value.data_alteracao' },
            { label: 'status_projeto', key: 'value.descStatus' },
            // { label: 'centro_de_custo', key: 'value.cod_centro_custo' },
        ]);

        exportExcelPorTecnico(
            page,
            props.total_page,
            props.order_var,
            props.th,
            props.pesquisa,
            props.type ? props.type : '',
            props.area ? props.area : '',
            props.manager ? props.manager : '',
            props.coordinator ? props.coordinator : '',
            props.status ? props.status : ''
        )
            .then((resp) => {
                let dados = resp.data.map((value) => {
                    return {
                        value: value.value,
                        data_base: value.data_base,
                    };
                });
                setProjects(dados);
            })
            .then((resp) => {
                // console.log(refunds)
                fileUpload.current.link.click();
            });
    };

    const downloadPagamentos = (event) => {
        let page = 1;
        setHeaders([
            { label: 'id_projeto', key: 'value.id' },
            { label: 'area', key: 'value.descArea' },
            { label: 'centro_de_custo', key: 'value.cod_centro_custo' },
            { label: 'projeto', key: 'value.titulo' },
            { label: 'tipo_parcela', key: 'value.TipoParcela' },
            { label: 'produtos', key: 'value.Produtos' },
            { label: 'coordenador', key: 'value.coordenador' },
            { label: 'valor_projeto', key: 'value.valor_projeto' },
            { label: 'data_inicio', key: 'value.data_inicio' },
            { label: 'data_fim', key: 'value.data_fim' },
            { label: 'status_projeto', key: 'value.descStatus' },
            { label: 'codigo_parcela', key: 'value.codigo_parcela' },
            { label: 'data_vencimento', key: 'value.data_vencimento' },
            { label: 'valor_parcela', key: 'value.valor_parcela' },
            { label: 'data_faturamento', key: 'value.data_faturamento' },
            { label: 'data_pagamento', key: 'value.data_pagamento' },
            { label: 'status_apuracao', key: 'value.status_apuracao' },
        ]);
        setLoading(true);
        exportExcelPagamentos(
            page,
            props.total_page,
            props.order_var,
            props.th,
            props.pesquisa,
            props.type ? props.type : '',
            props.area ? props.area : '',
            props.manager ? props.manager : '',
            props.coordinator ? props.coordinator : '',
            props.status ? props.status : ''
        )
            .then((resp) => {
                setLoading(false);
                let dados = resp.data.map((value) => {
                    return {
                        value: value.value,
                    };
                });
                setProjects(dados);
            })
            .then((resp) => {
                // console.log(refunds)
                fileUpload.current.link.click();
            });
    };

    const downloadAgrupado = (event) => {
        let page = 1;
        setHeaders([
            { label: 'id_projeto', key: 'value.id' },
            { label: 'id_registro', key: 'value.id_registro_venda' },
            { label: 'area', key: 'value.descArea' },
            { label: 'tipo_servico', key: 'value.descTipo' },
            { label: 'codigo_w3', key: 'value.codigo_w3' },
            { label: 'projeto', key: 'value.titulo' },
            {
                label: 'data_criacao_checklist',
                key: 'value.data_criacao_checklist',
            },
            { label: 'data_venda', key: 'value.data_venda' },
            { label: 'nome_cliente', key: 'value.nome_cliente' },
            {
                label: 'data_primeira_visita',
                key: 'value.data_primeira_visita',
            },
            { label: 'data_de_inicio', key: 'value.data_inicio' },
            { label: 'data_fim', key: 'value.data_fim' },
            { label: 'data_encerramento', key: 'data_base[0].data_base' },
            { label: 'origem_venda', key: 'value.origem_venda' },
            { label: 'agente_venda', key: 'value.agente_venda' },
            { label: 'cidade', key: 'value.city_name' },
            { label: 'estado', key: 'value.UF' },
            { label: 'coordenador', key: 'value.coordenador' },
            { label: 'valor_total', key: 'value.valor_projeto' },
            { label: 'tipo_cobranca', key: 'value.tipo_cobranca' },
            // { label: 'valor_parcela', key: 'value.valor_parcela' },
            { label: 'periodo_vigencia', key: 'value.periodo_vigencia_meses' },
            { label: 'atividade_princial', key: 'value.atividade_pricipal' },
            { label: 'tamanho_propriedade', key: 'value.hectares_propriedade' },
            { label: 'faturamento_propriedade', key: 'value.faturamento' },
            { label: 'status_projeto', key: 'value.descStatus' },
            { label: 'motivo_encerramento', key: 'value.descricao_motivo' },
            // { label: 'centro_de_custo', key: 'value.cod_centro_custo' },
        ]);
        exportExcelAgrupado(
            page,
            props.total_page,
            props.order_var,
            props.th,
            props.pesquisa,
            props.type ? props.type : '',
            props.area ? props.area : '',
            props.manager ? props.manager : '',
            props.coordinator ? props.coordinator : '',
            props.status ? props.status : ''
        )
            .then((resp) => {
                let dados = resp.data.map((value) => {
                    return {
                        value: value.value,
                        data_base: value.data_base,
                    };
                });
                setProjects(dados);
            })
            .then((resp) => {
                fileUpload.current.link.click();
            });
    };

    const [modalExport, setModalExport] = useState(false);

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage>
                {loading === true ? (
                    <Content>
                        <ReactLoading
                            type="spinningBubbles"
                            color="blue"
                            height={200}
                            width={100}
                        />
                    </Content>
                ) : (
                    ''
                )}
                {success ? <Alert type="success">{success}</Alert> : ''}
                {error ? <Alert type="danger">{error}</Alert> : ''}
                {/* <MyModal
                    show={modal}
                    onHide={handleCloseExcluir}
                    title='Excluir Projeto'
                    content='Tem certeza que deseja excluir o projeto ?'
                    cancel={handleCloseExcluir}
                    del={handleExcluir}
                    to='/projects'
                    type='danger'
                    textButton='Excluir'
                /> */}
                <div className="row">
                    <div className="col-md-12">
                        <MyModal
                            show={modalFilter}
                            onHide={(e) => setModalFilter(false)}
                            title="Filtrar"
                            content={<ReportProject />}
                            cancel={function () {
                                return [
                                    props.clearPesquisa(),
                                    setTimeout(() => {
                                        setModalFilter(false);
                                    }, 1000),
                                ];
                            }}
                            del={function () {
                                dispatch({ type: 'pageInitial', payload: 1 });
                                return [
                                    props.get(),
                                    setTimeout(() => {
                                        setModalFilter(false);
                                    }, 1000),
                                ];
                            }}
                            to="#/"
                            type="success"
                            contentSecondary="Limpar Busca"
                            textButton="Buscar"
                        />
                        {props.perfis.indexOf(1) >= 0 ||
                        props.perfis.indexOf(9) >= 0 ||
                        props.perfis.indexOf(3) >= 0 ||
                        props.perfis.indexOf(4) >= 0 ||
                        props.perfis.indexOf(8) >= 0 ||
                        props.perfis.indexOf(6) >= 0 ||
                        props.perfis.indexOf(12) >= 0 ? (
                            <MyModal
                                show={modalExport}
                                onHide={(e) => setModalExport(false)}
                                title="Exportar CSV"
                                content={
                                    <>
                                        {loading === true ? (
                                            <Content>
                                                <ReactLoading
                                                    type="spinningBubbles"
                                                    color="blue"
                                                    height={200}
                                                    width={100}
                                                />
                                            </Content>
                                        ) : (
                                            <div className="row">
                                                <div className="col-12 mb-3 text-center">
                                                    <button
                                                        onClick={
                                                            downloadAgrupado
                                                        }
                                                        type="button"
                                                        className="btn btn-success col-6 font-weight-bold"
                                                    >
                                                        Agrupado
                                                    </button>
                                                </div>

                                                <div className="col-12 mb-3 text-center">
                                                    <button
                                                        onClick={
                                                            downloadDetalhado
                                                        }
                                                        type="button"
                                                        className="btn btn-success col-6 font-weight-bold"
                                                    >
                                                        Detalhado
                                                    </button>
                                                </div>
                                                <div className="col-12 mb-3 text-center">
                                                    <button
                                                        onClick={
                                                            downloadPorTecnico
                                                        }
                                                        type="button"
                                                        className="btn btn-success col-6 font-weight-bold"
                                                    >
                                                        Por Técnico
                                                    </button>
                                                </div>
                                                <div className="col-12 mb-3 text-center">
                                                    <button
                                                        onClick={
                                                            downloadPagamentos
                                                        }
                                                        type="button"
                                                        className="btn btn-success col-6 font-weight-bold"
                                                    >
                                                        Pagamentos
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                }
                                onSubmit={true}
                                to="#/"
                                type="success"
                                contentSecondary="Limpar Busca"
                                textButton="Buscar"
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div style={{ display: loading === true ? 'none' : 'block' }}>
                    <Table
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div
                                    className="dataTables_info"
                                    id="example1_info"
                                    role="status"
                                    aria-live="polite"
                                >
                                    Exibindo de {props.from} à {props.to} do
                                    total de {props.qtd_total}
                                </div>
                            </div>
                        }
                        cabecalho={
                            <Cabecalho
                                to="#/"
                                action={props.search}
                                clear={props.clearPesquisa}
                                valuePesquisa={props.pesquisa}
                                value={props.qtd_per_page}
                                onChange={props.change_qtd_per_page}
                                changePesquisa={props.changePesquisa}
                                buttonCsv={
                                    <>
                                        <button
                                            style={{
                                                background: '#005F31',
                                                color: '#FFF',
                                                borderRadius: '20.5px',
                                                width: '140px',
                                            }}
                                            className="btn btn-sm"
                                            // onClick={download}
                                            onClick={() => setModalExport(true)}
                                        >
                                            <i className="fas fa-file-csv"></i>{' '}
                                            Exportar CSV
                                        </button>
                                        <button
                                            style={{
                                                background: '#1A170E',
                                                color: '#FFF',
                                                borderRadius: '20.5px',
                                                width: '140px',
                                                marginLeft: '10px',
                                            }}
                                            className="btn btn-sm"
                                            onClick={() =>
                                                setModalFilter(!modalFilter)
                                            }
                                        >
                                            <i className="fas fa-filter"></i>{' '}
                                            Mais Filtros
                                        </button>

                                        <CSVLink
                                            filename={`Relatorio-${new Date()}.csv`}
                                            headers={headers}
                                            hidden={true}
                                            separator={';'}
                                            ref={fileUpload}
                                            data={projects || []}
                                        >
                                            <span className="fas fa-file-csv">
                                                {' '}
                                                Exportar CSV
                                            </span>
                                        </CSVLink>
                                    </>
                                }
                            />
                        }
                        pagination={
                            <Pagination>
                                {props.pagination(
                                    props.current_page,
                                    props.get,
                                    props.last_page,
                                    props.changePage
                                )}
                            </Pagination>
                        }
                        title="Projetos"
                        button={
                            props.perfis.indexOf(1) >= 0 ||
                            props.perfis.indexOf(8) >= 0 ||
                            props.perfis.indexOf(9) >= 0 ||
                            props.perfis.indexOf(3) >= 0 ||
                            props.perfis.indexOf(6) >= 0 ? (
                                <Link
                                    to="/new_project"
                                    className="btn btn-primary"
                                >
                                    Cadastro
                                </Link>
                            ) : (
                                ''
                            )
                        }
                    >
                        <Thead>
                            <th>
                                TÍTULO
                                <Link
                                    to="/projects"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(titulo)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th === 'projetos.titulo'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            <th>
                                CENTRO DE CUSTO
                                <Link
                                    to="/projects"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(centro_custo)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th ===
                                            'projetos.cod_centro_custo'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            <th>
                                TIPO
                                <Link
                                    to="/projects"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(tipo)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th ===
                                            'tipo_projetos.descricao'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            <th>
                                ÁREA
                                <Link
                                    to="/projects"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(area)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th ===
                                            'area_projetos.descricao'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            <th>
                                GERENTE
                                <Link
                                    to="/projects"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(gerente)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th === 'gerente.name'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            <th>
                                COORDENADOR
                                <Link
                                    to="/projects"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(coordenador)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th === 'coord.name'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            {/* <th>CLIENTE<Link to="/projects" style={{float:'right'}} onClick={()=>props.order(cliente)}><i className={`right fas fa-sort-amount-${props.th === 'clientes.nome' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th> */}
                            <th>
                                STATUS
                                <Link
                                    to="/projects"
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(status)}
                                >
                                    <i
                                        className={`right fas fa-sort-amount-${
                                            props.th ===
                                            'status_projetos.descricao'
                                                ? props.asc === true
                                                    ? 'down'
                                                    : 'up'
                                                : 'down'
                                        }-alt`}
                                    ></i>
                                </Link>
                            </th>
                            <th>AÇÕES</th>
                        </Thead>
                        <Tbody>
                            <Projects
                                projects={props.projects}
                                onDelete={onChangeModal}
                            />
                        </Tbody>
                        <Tfoot>
                            <th>TÍTULO</th>
                            <th>CENTRO DE CUSTO</th>
                            <th>TIPO</th>
                            <th>ÁREA</th>
                            <th>GERENTE</th>
                            <th>COORDENADOR</th>
                            {/* <th>CLIENTE</th> */}
                            <th>STATUS</th>
                            <th>AÇÕES</th>
                        </Tfoot>
                    </Table>
                </div>
            </StylePage>

            <Footer />
        </div>
    );
}

const mapStateToProps = (state) => ({
    projects: state.project.projects.data,
    perfis: state.auth.user.perfis,
    current_page: state.project.projects.current_page,
    last_page: state.project.projects.last_page,
    from: state.project.projects.from,
    to: state.project.projects.to,
    qtd_total: state.project.projects.total,
    qtd_per_page: state.project.qtd_per_page,
    total_page: state.project.projects.total,
    pesquisa: state.project.pesquisa || '',
    id_role: state.auth.user.id_role,
    asc: state.project.asc,
    th: state.project.th,
    page_var: state.project.page,
    order_var: state.project.order,
    type: state.project.type.value,
    area: state.project.area.value,
    manager: state.project.manager.value,
    coordinator: state.project.coordinator.value,
    status: state.project.status.value,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            pagination,
            get,
            order,
            change_qtd_per_page,
            changePesquisa,
            clearPesquisa,
            changePage,
            search,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GridProject);
