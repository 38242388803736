import { get_users } from '../../../../Services/user'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[(dispatch,getState)=>{
        
        const order = getState().user.order
        const th = getState().user.th
        const qtd_per_page = getState().user.qtd_per_page
        const pesquisa = getState().user.pesquisa || '' 
        const page = getState().user.page      
      
        get_users(page,qtd_per_page,order,th,pesquisa)
        .then(resp=>dispatch({type:'Users', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().user.asc
      
        if(asc === true){
            dispatch({type:'GetUser_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetUser_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_user',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaUser',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaUser',
        payload:''
    },get(1)]
}

export const changePage = page => {
    return[{
        type: 'changePageUser',
        payload: page
    },get()]
}

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().user.pesquisa || ''
            if(pesquisa){
                return dispatch({type:'changePageUser', payload:1})
            }
        }, get()
    ]
}

