import './Dependencies/dependencies'
import './Dependencies/jquery-loader'
import React from 'react';
import { BrowserRouter ,Route, Switch } from "react-router-dom"
import Index from './Componentes/Pages/Index/Index'
import Login from './Componentes/Pages/Auth/Login'
import AuthRouter from './Componentes/AuthRouter'
import User from './Componentes/Pages/Cadastros/User/User'
import GridUser from './Componentes/Pages/Cadastros/User/GridUser'
import Client from './Componentes/Pages/Cadastros/Client/Client'
import GridClient from './Componentes/Pages/Cadastros/Client/GridClient'
import Filial from './Componentes/Pages/Cadastros/Filial/Filial'
import GridFilial from './Componentes/Pages/Cadastros/Filial/GridFilial'
import Visit from './Componentes/Pages/Cadastros/Visit/Visit'
import GridVisit from './Componentes/Pages/Cadastros/Visit/GridVisit'
import Reimbursement from './Componentes/Pages/Cadastros/Reimbursement/Reimbursement'
import GridReimbursement from './Componentes/Pages/Cadastros/Reimbursement/GridReimbursement'
import GridProject from './Componentes/Pages/Cadastros/Project/GridProject'
import GridReimbursementShared from './Componentes/Pages/Cadastros/ReimbursementShared/GridReimbursementShared'
import ReimbursementShared from './Componentes/Pages/Cadastros/ReimbursementShared/ReimbursementShared'
import Profile from './Componentes/Pages/Cadastros/User/MyUser'
import SendEmailRemember from './Componentes/Pages/RememberPassword/SendEmailRemember'
import RememberPassword from './Componentes/Pages/RememberPassword/RememberPassword'
import SuperRouter from './Componentes/SuperRouter';
import AdminFinancesRouter from './Componentes/AdminFinancesRouter';
import LoginRouter from './Componentes/LoginRouter';
import ViewRouter from './Componentes/ViewRouter';
import CheckList from './Componentes/Pages/Cadastros/CheckList/CheckList';
import GridCheckList from './Componentes/Pages/Cadastros/CheckList/GridCheckList';
import ExtraCharge from './Componentes/Pages/Cadastros/ExtraCharge/ExtraCharge';
import GridExtraCharge from './Componentes/Pages/Cadastros/ExtraCharge/GridExtraCharge';
import SalesRouter from './Componentes/SalesRouter';
import SgagriRouter from './Componentes/SgagriRouter';
import ProjectAmendment from './Componentes/Pages/Cadastros/ProjectAmendment/ProjectAmendment';
import GridProjectAmendment from './Componentes/Pages/Cadastros/ProjectAmendment/GridProjectAmendment';
import TabsProject from './Componentes/Pages/Cadastros/Project/TabsProject';
import BillsToPay from './Componentes/Pages/Cadastros/BillsToPay/BillsToPay';
import GridUpdateKM from './Componentes/Pages/Cadastros/UpdateKM/GridUpdateKM';
import GridEmpresa from './Componentes/Pages/Cadastros/Empresa/GridEmpresa';
import Empresa from './Componentes/Pages/Cadastros/Empresa/Empresa';
import GridProjectEnsino from './Componentes/Pages/Cadastros/ProjectEnsino/GridProjectEnsino'
import TabsProjectEnsino from './Componentes/Pages/Cadastros/ProjectEnsino/TabsProject';

import ServiceType from './Componentes/Pages/Cadastros/ServiceType/ServiceType'
import GridServiceType from './Componentes/Pages/Cadastros/ServiceType/GridServiceType'
import ReimbursementType from './Componentes/Pages/Cadastros/ReimbursementType/ReimbursementType'
import GridReimbursementType from './Componentes/Pages/Cadastros/ReimbursementType/GridReimbursementType'
import GridArea from './Componentes/Pages/Cadastros/Area/GridArea'
import Area from './Componentes/Pages/Cadastros/Area/Area'
import Culture from './Componentes/Pages/Cadastros/Culture/Culture'
import GridCulture from './Componentes/Pages/Cadastros/Culture/GridCulture'
import GridMotive from './Componentes/Pages/Cadastros/Reason/GridMotive';
import Motive from './Componentes/Pages/Cadastros/Reason/Motive';
import GridRegisterSgagri from './Componentes/Pages/Cadastros/RegisterSgagri/GridRegisterSgagri';
import RegisterSgagri from './Componentes/Pages/Cadastros/RegisterSgagri/RegisterSgagri';

const Routes = () =>(
    <>      
        <BrowserRouter>
            <Switch>
                <LoginRouter exact path='/' component={Login}/>      
                <LoginRouter path="/login" component={Login} /> 
                <Route path="/send_email_remember" component={SendEmailRemember} /> 
                <Route path="/remember_password" component={RememberPassword} />
                <AuthRouter path="/index" component={Index} />

                <SuperRouter path="/new_user" component={User} />
                <SuperRouter exact path="/user/:id/:action" component={User} />
                <SuperRouter path="/users" component={GridUser} /> 

                <SuperRouter path="/new_client" component={Client} />
                <ViewRouter path="/client/:id/:action" component={Client} />
                <ViewRouter path="/customers" component={GridClient} />

                <AdminFinancesRouter path="/new_filial" component={Filial} />
                <AdminFinancesRouter path="/filial/:id/:action" component={Filial} />
                <AdminFinancesRouter path="/filiais" component={GridFilial} />

                <AdminFinancesRouter path="/new_cultura" component={Culture} />
                <AdminFinancesRouter path="/cultura/:id/:action" component={Culture} />
                <AdminFinancesRouter path="/culturas" component={GridCulture} />

                <AuthRouter path="/new_visit" component={Visit} />
                <AuthRouter path="/visit/:id/:action" component={Visit} />
                <AuthRouter path="/visits" component={GridVisit} />  

                <AuthRouter path="/new_reimbursement" exact component={Reimbursement} />
                <AuthRouter path="/reimbursement/:id/:action" exact component={Reimbursement} />
                <AuthRouter path="/refunds" exact component={GridReimbursement} />

                
                <AuthRouter path="/new_reimbursement_shared" exact component={ReimbursementShared} />
                <AuthRouter path="/reimbursement_shared/:id/:action" exact component={ReimbursementShared} />
                <AuthRouter path="/refunds_shared" exact component={GridReimbursementShared} />  

                <AuthRouter path="/new_project" component={TabsProject} />
                <AuthRouter path="/project/:id/:action" component={TabsProject} />
                <AuthRouter path="/projects" exact component={GridProject} />  
                
                <AuthRouter path="/new_project_ensino" component={TabsProjectEnsino} />
                <AuthRouter path="/project_ensino/:id/:action" component={TabsProjectEnsino} />
                <AuthRouter path="/projects_ensino" exact component={GridProjectEnsino} />  

                <SalesRouter path="/new_check_list" component={CheckList} />
                <SalesRouter path="/check_list/:id/:action" exact component={CheckList} />
                <SalesRouter path="/check_lists" exact component={GridCheckList} />

                <SgagriRouter path="/registers_sgagri" exact component={GridRegisterSgagri} />
                <SgagriRouter path="/register_sgagri/:id/:action" exact component={RegisterSgagri} />
                <SgagriRouter path="/new_register_sgagri" exact component={RegisterSgagri} />

                <SalesRouter path="/new_extra_charge" exact component={ExtraCharge} />  
                <SalesRouter path="/extra_charges" component={GridExtraCharge} /> 
                <SalesRouter path="/extra_charge/:id/:action" exact component={ExtraCharge} /> 

                <SalesRouter path="/new_project_amendment" exact component={ProjectAmendment} />  
                <SalesRouter path="/contract_changes" component={GridProjectAmendment} /> 
                <SalesRouter path="/project_amendment/:id/:action" exact component={ProjectAmendment} /> 

                <SalesRouter path="/bills_to_pay" exact component={BillsToPay} />  

                <AuthRouter path="/extract" exact component={BillsToPay} />  
                {/* <SalesRouter path="/bills_to_pay_record" component={GridBillsToPay} /> 
                <SalesRouter path="/bills_to_pay/:id/:action" exact component={BillsToPayRecords} />  */}

                <AuthRouter path="/profile/:id" exact component={Profile} /> 

                <AdminFinancesRouter path="/update_km" exact component={GridUpdateKM}/> 

                <AdminFinancesRouter path="/empresas" exact component={GridEmpresa}/> 
                <AdminFinancesRouter path="/new_empresa" component={Empresa} />
                <AdminFinancesRouter exact path="/empresa/:id/:action" component={Empresa} /> 

                <AdminFinancesRouter path="/service_type" exact component={GridServiceType}/> 
                <AdminFinancesRouter path="/new_service_type" component={ServiceType} />
                <AdminFinancesRouter exact path="/service_type/:id/:action" component={ServiceType} />

                <AdminFinancesRouter path="/reimbursement_type" exact component={GridReimbursementType}/> 
                <AdminFinancesRouter path="/new_reimbursement_type" component={ReimbursementType} />
                <AdminFinancesRouter exact path="/reimbursement_type/:id/:action" component={ReimbursementType} /> 

                <AdminFinancesRouter path="/area" exact component={GridArea}/> 
                <AdminFinancesRouter path="/new_area" component={Area} />
                <AdminFinancesRouter exact path="/area/:id/:action" component={Area} />
                
                <AdminFinancesRouter path="/motives" exact component={GridMotive}/> 
                <AdminFinancesRouter path="/new_motive" component={Motive} />
                <AdminFinancesRouter exact path="/motive/:id/:action" component={Motive} />

            </Switch>      
        </BrowserRouter>  
    </>
);
export default Routes;