const ExtraChargeReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'ExtraCharge':
            return{...state, extra_charges:actions.payload}

        case 'GetExtraCharge_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetExtraCharge_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_ExtraCharge':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaExtraCharge':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaExtraCharge':
            return{...state, 
                pesquisa:actions.payload,
                project_report: actions.project_report,
                from: actions.from ,
                to: actions.to,
                status_report: "",
                coordinator_report: ""
            }

        case 'changeCoordinatorReportExtraCharge':
            return{...state, coordinator_report:actions.payload}
        
        case 'changeStatusReportExtraCharge':
            return{...state, status_report:actions.payload}

        case 'changeProjectReportExtraCharge':
            return{...state, project_report:actions.payload}

        case 'changeFromReportExtraCharge':
            return{...state, from:actions.payload}

        case 'changeToReportExtraCharge':
            return{...state, to:actions.payload}

        case 'changePageExtraCharge':
            return{...state, page:actions.payload}
        

        default:
            return state;
    }
}
export default ExtraChargeReducer;