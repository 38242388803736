import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Content } from './styles';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import UserIndex from '../../Icons/user_index.png';
import ClientIndex from '../../Icons/client_index.png';
import ProjectIndex from '../../Icons/project_index.png';
import VisitIndex from '../../Icons/visit_index.png';
import ReimbursementIndex from '../../Icons/reimbursement_index.png';
import ReimbursementSharedIndex from '../../Icons/reimbursement_shared_index.png';

function Index(props) {
    const mediaMatch = window.matchMedia('(min-width: 768px)');
    const [matches, setMatches] = useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });
    const style = {
        padding: '50px',
        content: isBased => ({
            marginTop: isBased ? '40px' : '',
            marginLeft: isBased ? '5%' : '',

        }),
        card: isBased => ({

            maxWidth: isBased ? '400px' : '300px',
            marginLeft: isBased ? '5%' : '10%',

        }),
    };
    // const explode = `${props.name}`.split(' ')
    // const name = explode[0]
    return (
        <>
            <Sidebar />
            <Header />
            <div className='content-wrapper' style={{ height: '100%' }}>
                <Content>
                    <section className='content' style={{ padding: '50px', minHeight: '90vh' }}>
                        <div className='row' style={style.content(matches)}>
                            {
                                props.perfis.indexOf(1) >= 0 || props.perfis.indexOf(2) >= 0 || props.perfis.indexOf(3) >= 0 || props.perfis.indexOf(6) >= 0 || props.perfis.indexOf(10) >= 0 ?
                                    (
                                        <div className='col-md-4 mt-4' style={style.card(matches)}>
                                            <div style={
                                                {
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    height: '334.81px',
                                                    backgroundColor: '#FFFFFF',
                                                    boxShadow: '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
                                                }
                                            }
                                            >
                                                <button
                                                    className='btn'
                                                    onClick={() => props.history.push('/users')}
                                                    style={
                                                        {
                                                            padding: '15px',
                                                            width: '90%',
                                                            height: '334.81px',
                                                            border: '0px solid transparent',
                                                        }
                                                    }
                                                >
                                                    <img
                                                        style={{
                                                            width: '180px',
                                                            height: '180px',
                                                            margin: '15px',
                                                            padding: '15px',
                                                            alignItems: 'center',
                                                        }}
                                                        src={UserIndex}
                                                        alt='User'
                                                    /><br></br><span style={
                                                    {
                                                        fontFamily: 'Roboto',
                                                        fontStyle: 'normal',
                                                        fontWeight: '500',
                                                        fontSize: '24px',
                                                        lineHeight: '24px',
                                                        /* identical to box height, or 100% */

                                                        letterSpacing: '0.4px',
                                                        textTransform: 'uppercase',

                                                        color: '#2A4038',
                                                    }
                                                }>USUÁRIOS</span></button>
                                            </div>

                                        </div>
                                    ) : ('')
                            }
                            {
                                props.perfis.indexOf(1) >= 0 || props.perfis.indexOf(2) >= 0 || props.perfis.indexOf(11) >= 0 || props.perfis.indexOf(3) >= 0 || props.perfis.indexOf(4) >= 0 || props.perfis.indexOf(5) >= 0 || props.perfis.indexOf(6) >= 0 || props.perfis.indexOf(7) >= 0 || props.perfis.indexOf(9) >= 0 || props.perfis.indexOf(8) >= 0 ?
                                    (
                                        <div className='col-md-4 mt-4' style={style.card(matches)}>
                                            <div style={
                                                {
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    height: '334.81px',
                                                    backgroundColor: '#FFFFFF',
                                                    boxShadow: '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
                                                }
                                            }
                                            >
                                                <button
                                                    className='btn'
                                                    onClick={() => props.history.push('/customers')}
                                                    style={
                                                        {
                                                            padding: '15px',
                                                            width: '90%',
                                                            height: '334.81px',
                                                            border: '0px solid transparent',
                                                        }
                                                    }
                                                >
                                                    <img
                                                        style={{
                                                            width: '180px',
                                                            height: '180px',
                                                            margin: '15px',
                                                            padding: '15px',
                                                        }}
                                                        src={ClientIndex}
                                                        alt='Client'
                                                    /><br></br><span style={{
                                                    fontFamily: 'Roboto',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    fontSize: '24px',
                                                    lineHeight: '24px',
                                                    /* identical to box height, or 100% */

                                                    letterSpacing: '0.4px',
                                                    textTransform: 'uppercase',

                                                    color: '#2A4038',
                                                }}>CLIENTES</span></button>
                                            </div>

                                        </div>
                                    ) : ('')
                            }
                            {!(props.perfis.length === 1 && props.perfis.indexOf(10) >= 0 || props.perfis.indexOf(11) >= 0) && (
                                <>
                                    <div className='col-md-4 mt-4' style={style.card(matches)}>
                                        <div style={
                                            {
                                                alignItems: 'center',
                                                textAlign: 'center',
                                                height: '334.81px',
                                                backgroundColor: '#FFFFFF',
                                                boxShadow: '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
                                            }
                                        }
                                        >
                                            <button
                                                className='btn'
                                                onClick={() => props.history.push('/projects')}
                                                style={
                                                    {
                                                        padding: '15px',
                                                        width: '90%',
                                                        height: '334.81px',
                                                        border: '0px solid transparent',
                                                    }
                                                }
                                            >
                                                <img
                                                    style={{
                                                        width: '180px',
                                                        height: '180px',
                                                        margin: '15px',
                                                        padding: '15px',
                                                    }}
                                                    src={ProjectIndex}
                                                    alt='project'
                                                /><br></br><span style={{
                                                fontFamily: 'Roboto',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                fontSize: '24px',
                                                lineHeight: '24px',
                                                /* identical to box height, or 100% */

                                                letterSpacing: '0.4px',
                                                textTransform: 'uppercase',

                                                color: '#2A4038',
                                            }}>PROJETOS</span></button>
                                        </div>

                                    </div>
                                    <div className='col-md-4 mt-4' style={style.card(matches)}>
                                        <div style={
                                            {
                                                alignItems: 'center',
                                                textAlign: 'center',
                                                height: '334.81px',
                                                backgroundColor: '#FFFFFF',
                                                boxShadow: '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
                                            }
                                        }
                                        >
                                            <button
                                                className='btn'
                                                onClick={() => props.history.push('/visits')}
                                                style={
                                                    {
                                                        padding: '15px',
                                                        width: '90%',
                                                        height: '334.81px',
                                                        border: '0px solid transparent',
                                                    }
                                                }
                                            >
                                                <img
                                                    style={{
                                                        width: '180px',
                                                        height: '180px',
                                                        margin: '15px',
                                                        padding: '15px',
                                                    }}
                                                    src={VisitIndex}
                                                    alt='Visit'
                                                /><br></br><span style={{
                                                fontFamily: 'Roboto',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                fontSize: '24px',
                                                lineHeight: '24px',
                                                /* identical to box height, or 100% */

                                                letterSpacing: '0.4px',
                                                textTransform: 'uppercase',

                                                color: '#2A4038',
                                            }}>VISITAS</span></button>
                                        </div>

                                    </div>
                                </>
                            )}
                            <div className='col-md-4 mt-4' style={style.card(matches)}>
                                <div style={
                                    {
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        height: '334.81px',
                                        backgroundColor: '#FFFFFF',
                                        boxShadow: '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
                                    }
                                }
                                >
                                    <button
                                        className='btn'
                                        onClick={() => props.history.push('/refunds')}
                                        style={
                                            {
                                                padding: '15px',
                                                width: '90%',
                                                height: '334.81px',
                                                border: '0px solid transparent',
                                            }
                                        }
                                    >
                                        <img
                                            style={{ width: '180px', height: '180px', margin: '15px', padding: '15px' }}
                                            src={ReimbursementIndex}
                                            alt='Reimbursement'
                                        /><br></br><span style={{
                                        fontFamily: 'Roboto',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '24px',
                                        lineHeight: '24px',
                                        /* identical to box height, or 100% */

                                        letterSpacing: '0.4px',
                                        textTransform: 'uppercase',

                                        color: '#2A4038',
                                    }}>REEMBOLSOS</span></button>
                                </div>

                            </div>
                            <div className='col-md-4 mt-4' style={style.card(matches)}>
                                <div style={
                                    {
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        height: '334.81px',
                                        backgroundColor: '#FFFFFF',
                                        boxShadow: '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
                                    }
                                }
                                >
                                    <button
                                        className='btn'
                                        onClick={() => props.history.push('/refunds_shared')}
                                        style={
                                            {

                                                padding: '15px',
                                                width: '90%',
                                                height: '334.81px',
                                                border: '0px solid transparent',
                                            }
                                        }
                                    >
                                        <img
                                            style={{ width: '180px', height: '180px', margin: '15px', padding: '15px' }}
                                            src={ReimbursementSharedIndex}
                                            alt='Reimbursement Shared'
                                        /><br></br><span style={{
                                        fontFamily: 'Roboto',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '24px',
                                        lineHeight: '24px',
                                        letterSpacing: '0.4px',
                                        textTransform: 'uppercase',
                                        color: '#2A4038',
                                    }}>REEMBOLSOS COMPARTILHADOS</span></button>
                                </div>
                            </div>
                        </div>
                    </section>
                </Content>
            </div>
            <Footer />
        </>
    );

}

const mapStateToProps = state => ({
    name: state.auth.user.name,
    perfis: state.auth.user.perfis,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Index);
