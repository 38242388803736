import { app } from "./Api";

const getResource = async () => {
    return app.get("bills_to_pay/get_resource");
};

const postBillsToPay = async (data) => {
    return app.post(`bills_to_pay`, data);
};


const postContabil = async (data) => {
    return app.post(`bills_to_pay/contabil`, data);
};

const postEmailExtratoMensal = async (data) => {
    return app.post(`bills_to_pay/send_email`, data);
};

const putSalesRecord = async (id, data) => {
    return app.post(`sales_record/${id}`, data);
};

const getBillsToPay = async (
    competencia,
    status,
    technical,
    pesquisa,
    typeBill,
    projectBill,
    typeProjects
) => {
    return app.get(
        `bills_to_pay?competencia=${competencia || ""}&status=${
            status || ""
        }&technical=${technical || ""}&pesquisa=${pesquisa || ""}&typeBill=${
            typeBill || ""
        }&projectBill=${projectBill || ""}&typeProjects=${typeProjects || ''}`
    );
};

const exportExcelCSV = async (
    competencia,
    status,
    technical,
    pesquisa,
    typeBill,
    projectBill,
    typeProjects
) => {
    return app.get(
        `bills_to_pay/export_excel_csv?competencia=${competencia || ""}&status=${
            status || ""
        }&technical=${technical || ""}&pesquisa=${pesquisa || ""}&typeBill=${
            typeBill || ""
        }&projectBill=${projectBill || ""}&typeProjects=${typeProjects || ''}`
    );
};

const getViewSalesRecord = async (id) => {
    return app.get(`sales_record/${id}`);
};

const delSalesRecord = async (id) => {
    return app.delete(`sales_record/${id}`);
};

const changeStatus = async (id, data) => {
    return app.put(`change_sales_record/${id}`, data);
};

const getClient = async () => {
    return app.get(`sales_record/get_client`);
};

const getCity = async (idState) => {
    return app.get(`sales_record/get_city/${idState}`);
};

const delAttachment = async (id) => {
    return app.delete(`sales_record/del_attachment/${id}`);
};

const changeBillsToPay = async (data) => {
    return app.put(`change_bills_to_pay/`, data);
};

export {
    getResource,
    changeBillsToPay,
    delAttachment,
    postBillsToPay,
    putSalesRecord,
    getBillsToPay,
    getViewSalesRecord,
    delSalesRecord,
    changeStatus,
    getClient,
    getCity,
    postContabil,
    exportExcelCSV,
    postEmailExtratoMensal
};
