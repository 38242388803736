import React from 'react'

export default function Tfoot(props) {
    return (
        <tfoot>
            <tr>
                {props.children}
            </tr>
        </tfoot>
    )
}
