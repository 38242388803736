import { app } from './Api'

const get_view_client = async (id) => {
    return app.get(`client/${id}`)
}

const get_customers = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`client?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const exportExcel = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`client/export_excel?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const post_client = async (data) => {
    return app.post(`client`, data)
}

const changeStatus =  async (id,data) => {
    return app.put(`client/change_status/${id}`,data)
}

const put_client = async (id,data) => {
    return app.put(`client/${id}`,data)
}

const del_client = async (id) => {
    return app.delete(`client/${id}`)
}

const getResource = async () => {
    return app.get(`client_get_resource`)
}

const getCity =  async (idState) => {
    return app.get(`client/get_city/${idState}`)
}

const getStateViaCep =  async (uf) => {
    return app.get(`client/get_state_viacep/${uf}`)
}

const getCityViaCep =  async (city) => {
    return app.get(`client/get_city_viacep/${city}`)
}


export { get_view_client, get_customers, changeStatus, post_client, put_client, del_client, getResource, exportExcel, getCity, getStateViaCep, getCityViaCep}