import { get_visits } from '../../../../Services/visit'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[(dispatch,getState)=>{
        
        const order = getState().visit.order
        const th = getState().visit.th
        const qtd_per_page = getState().visit.qtd_per_page
        const pesquisa = getState().visit.pesquisa || ''
        const id_technical = getState().visit.technical_report.value || ''
        const id_coordinator = getState().visit.coordinator_report.value || ''
        const id_project = getState().visit.project_report.value || ''   
        const id_remuneration = getState().visit.remuneration_report.value || ''  
        const id_status = getState().visit.status_report.value || ''
        const from = getState().visit.from
        const to = getState().visit.to    
        const page = getState().visit.page     
      
        get_visits(page,qtd_per_page,order,th,pesquisa,id_technical,id_coordinator,id_project,id_remuneration,id_status,from,to)
        .then(resp=>dispatch({type:'Visits', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().visit.asc
      
        if(asc === true){
            dispatch({type:'GetVisit_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetVisit_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_visit',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaVisit',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[
        (dispatch, getState) =>{
            const fromFix = getState().visit.fromFix
            const toFix = getState().visit.toFix
            dispatch({
                type:'clearPesquisaVisit',
                payload:'',
                technical_report: '',
                coordinator_report: '',
                project_report: '' ,  
                remuneration_report: '' , 
                status_report: '',
                from: fromFix ,
                to: toFix       
            })
        },get(1)]
}

export const changePage = page => {
    return[{
        type: 'changePageVisit',
        payload: page
    },get()]
}

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().visit.pesquisa || ''
            const id_technical = getState().visit.technical_report.value || ''
            const id_coordinator = getState().visit.coordinator_report.value || ''
            const id_project = getState().visit.project_report.value || ''   
            const id_remuneration = getState().visit.remuneration_report.value || ''  
            const id_status = getState().visit.status_report.value || ''
            const from = getState().visit.from
            const to = getState().visit.to 
            if(pesquisa || id_technical || id_coordinator || id_project || id_remuneration || id_status || from || to){
                return dispatch({type:'changePageVisit', payload:1})
            }
        }, get()
    ]
}