import { getSalesRecord } from '../../../../Services/salesRecord';

//Função que traz os dados do banco de dados
export const get = () => {
    return [
        (dispatch, getState) => {
            const order = getState().sales_record.order;
            const th = getState().sales_record.th;
            const qtd_per_page = getState().sales_record.qtd_per_page;
            const pesquisa = getState().sales_record.pesquisa || '';
            const id_coordinator =
                getState().sales_record.coordinator_report.value || '';
            const id_status = getState().sales_record.status_report.value || '';
            const area_report = getState().sales_record.area_report.value || '';
            const project_report =
                getState().sales_record.project_report.value || '';
            const from = getState().sales_record.from;
            const to = getState().sales_record.to;
            const page = getState().sales_record.page;

            getSalesRecord(
                page,
                qtd_per_page,
                order,
                th,
                pesquisa,
                id_coordinator,
                area_report,
                project_report,
                id_status,
                from,
                to
            ).then((resp) => {
                dispatch({ type: 'SalesRecord', payload: resp.data });
            });
        },
    ];
};

//Função que ordena as colunas
export const order = (coluna) => {
    return [
        (dispatch, getState) => {
            const asc = getState().sales_record.asc;

            if (asc === true) {
                dispatch({
                    type: 'GetSalesRecord_Desc',
                    desc: true,
                    asc: false,
                    order: 'desc',
                    th: coluna,
                });
            }
            if (asc === false) {
                dispatch({
                    type: 'GetSalesRecord_Asc',
                    asc: true,
                    desc: false,
                    order: 'asc',
                    th: coluna,
                });
            }
        },
        get(1),
    ];
};

export const change_qtd_per_page = (event) => {
    return [
        {
            type: 'qtd_per_page_SalesRecord',
            payload: event.target.value,
        },
        get(1),
    ];
};

export const changePesquisa = (event) => {
    return [
        {
            type: 'pesquisaSalesRecord',
            payload: event.target.value,
        },
    ];
};

export const clearPesquisa = (event) => {
    return [
        (dispatch, getState) => {
            const fromFix = getState().sales_record.fromFix;
            const toFix = getState().sales_record.toFix;
            dispatch({
                type: 'clearPesquisaSalesRecord',
                payload: '',
                coordinator_report: '',
                area_report: '',
                status_report: '',
                from: fromFix,
                to: toFix,
                project_report: '',
            });
        },
        get(1),
    ];
};

export const changePage = (page) => {
    return [
        {
            type: 'changePageSalesRecord',
            payload: page,
        },
        get(),
    ];
};

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().sales_record.pesquisa || '';
            const id_coordinator =
                getState().sales_record.coordinator_report.value || '';
            const id_status = getState().sales_record.status_report.value || '';
            const from = getState().sales_record.from;
            const to = getState().sales_record.to;
            if (pesquisa || id_coordinator || id_status || from || to) {
                return dispatch({ type: 'changePageSalesRecord', payload: 1 });
            }
        },
        get(),
    ];
};
