import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'react-toggle/style.css';
import { useSelector } from 'react-redux';

export default function Projects({ projects, onDelete }) {
    const project = projects || [];
    const perfis = useSelector((state) => state.auth.user.perfis);
    const history = useHistory();

    //Função que verifica se o usuário tem permissão para acessar a edição do registro
    function permissionRole() {
        if (
            perfis.indexOf(1) < 0 &&
            perfis.indexOf(6) < 0 &&
            perfis.indexOf(10) < 0
        ) {
            return history.push('/projects');
        }
    }

    useEffect(() => {
        permissionRole();
        return () => {
            permissionRole();
        };
    }, [permissionRole]);

    console.log('gerente', project);
    return project.map((desc) => (
        <tr key={desc.id}>
            <td>{desc.titulo}</td>
            <td>{desc.cod_centro_custo}</td>
            <td>{desc.gerente}</td>
            <td>{desc.coordenador}</td>
            <td>{desc.descStatus}</td>
            <td>
                <ul className="nav">
                    <li className="nav-item dropdown">
                        <a
                            className="nav-link"
                            data-toggle="dropdown"
                            href="#/"
                        >
                            <i className="fas fa-ellipsis-h" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <Link
                                to={`/project_ensino/${desc.id}/view`}
                                className="dropdown-item"
                            >
                                <i className="fas fa-eye"></i> Detalhes
                            </Link>
                            {perfis.indexOf(1) >= 0 ||
                            perfis.indexOf(6) >= 0 ||
                            perfis.indexOf(10) >= 0 ? (
                                <>
                                    <Link
                                        to={`/project_ensino/${desc.id}/edit`}
                                        className="dropdown-item"
                                    >
                                        <i className="fas fa-edit"></i> Editar
                                    </Link>
                                    {/* <Link to='#/' className='dropdown-item' onClick={() => onDelete(desc.id)}><i
                                            className='fas fa-trash-alt'></i> Excluir</Link> */}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    </li>
                </ul>
            </td>
        </tr>
    ));
}
