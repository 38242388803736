import styled from 'styled-components'

export const PushMenu = styled.div`
    @media(min-width: 768px){ 
        display: none; 
       
    }
    @media(max-width: 768px){ 
       
       
        color: rgb(0, 74, 52);
       
    }
`