import React, { useState } from 'react'
import Form from '../../../Forms/Form'
import Input from '../../../Forms/Input'
import { alter_password } from '../../../Services/user'
import Alert from '../../../Alerts/Alert'



export default function MyPassword(props) {
    const [ password, setPassword ] = useState('')
    const [ newPassword, setNewPassword ] = useState('')
    const [ confirmNewPassword, setConfirmNewPassword ] = useState('')
    const [ success, setSuccess ] = useState('')
    const [ error, setError ] = useState('')
    const [ errorNewPassword, setErrorNewPassword ] = useState('')
    const [ errorConfirm, setErrorConfirm ] = useState('')

    const handleForm = (e) => {
        e.preventDefault();
        if(newPassword !== confirmNewPassword){
            return setErrorConfirm("As senhas não conferem")
        }
        const id = window.location.pathname.split('/')[2]
        const data = {
            password: password,
            new_password: newPassword,
            confirm_password: confirmNewPassword
        }
        alter_password(id,data)
        .then(resp => {
            setError('')
            setSuccess(resp.data.success)
            setTimeout(()=> {
                setSuccess('')
                setPassword('')
                setNewPassword('')
                setConfirmNewPassword('')
                localStorage.removeItem('token')
                window.location.reload()
            },3000)
        })
        .catch(e => {
            setSuccess('')
            setErrorNewPassword(e.response.data.errorNewPassword || '')
            setError(e.response.data.error)
            setTimeout(()=> {
                setError('')
            },3000)
        })
    }
    return (
        <>

            <div>
                {success && <Alert type="success">{success}</Alert>}
                {error && <Alert type="danger">{error}</Alert>}
            </div>
            <Form onSubmit={handleForm}>
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <Input 
                            label="Senha Atual"
                            type="password"
                            icon="fas fa-key"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required={true}
                        />
                        {errorNewPassword && <p style={{color: 'red'}}>{errorNewPassword}</p>}
                    </div>
                    <div className="col-md-6 offset-md-3">
                        <Input 
                            label="Nova Senha"
                            type="password"
                            icon="fas fa-key"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required={true}
                        />
                        {errorConfirm && <p style={{color: 'red'}}>{errorConfirm}</p>}

                    </div>
                    <div className="col-md-6 offset-md-3">
                        <Input 
                            label="Confirmar Nova Senha"
                            type="password"
                            icon="fas fa-key"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            required={true}

                        />
                        {errorConfirm && <p style={{color: 'red'}}>{errorConfirm}</p>}
                    </div>
                    <div className="col-md-6 offset-md-3 mt-3">
                        <button style={{borderRadius: '20px'}} type="submit" className="btn btn-primary btn-block">Salvar</button>
                    </div> 
                </div>
            </Form>
        </>
    )
}
