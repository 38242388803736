const FilialReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Filiais':
            return{...state, filiais:actions.payload}

        case 'GetFilial_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetFilial_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_filial':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaFilial':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaFilial':
            return{...state, pesquisa:actions.payload}

        case 'changePageFilial':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default FilialReducer;