import { get_filiais } from '../../../../Services/filial'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[(dispatch,getState)=>{
        
        const order = getState().filial.order
        const th = getState().filial.th
        const qtd_per_page = getState().filial.qtd_per_page
        const pesquisa = getState().filial.pesquisa || ''       
        const page = getState().filial.page 

        get_filiais(page,qtd_per_page,order,th,pesquisa)
        .then(resp=>dispatch({type:'Filiais', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().filial.asc
      
        if(asc === true){
            dispatch({type:'GetFilial_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetFilial_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

//Função que define a quantidade de registros à serem exibidos por página
export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_filial',
        payload:event.target.value
    },get(1)]
}

//Função que pega a pega a pesquisa digitadda pelo o usuário e salva no estado do redux
export const changePesquisa = event => {
    return[{
        type:'pesquisaFilial',
        payload:event.target.value
    }]
}

//Função que limpa a pesquisa salva na árvore pelo usuário
export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaFilial',
        payload:''
    },get(1)]
}

//Função que altera a página dos registros à serem exibidas
export const changePage = page => {
    return[{
        type: 'changePageFilial',
        payload: page
    },get()]
}

//Função que pega a página que o usuário esta no momento e realiza um get
export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().filial.pesquisa || ''
            if(pesquisa){
                return dispatch({type:'changePageFilial', payload:1})
            }
        }, get()
    ]
}