const ReimbursementReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Refunds':
            return{...state, refunds:actions.payload}

        case 'GetReimbursement_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetReimbursement_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_reimbursement':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaReimbursement':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaReimbursement':
            return{...state, 
                pesquisa:actions.payload,
                technical_report: actions.technical_report,
                project_report: actions.project_report ,  
                type_report: actions.type_report , 
                status_report: actions.status_report,
                from: actions.from ,
                coordinator_report: actions.coordinator_report,
                to: actions.to,
                id_reimbursement_shared: actions.id_reimbursement_shared
            }

        case 'changeTechnicalReport':
            return{...state, technical_report:actions.payload}

        case 'changeCoordinatorReport':
            return{...state, coordinator_report:actions.payload}

        case 'changeProjectReport':
            return{...state, project_report:actions.payload}

        case 'changeTypeReport':
            return{...state, type_report:actions.payload}
        
        case 'changeStatusReport':
            return{...state, status_report:actions.payload}

        case 'changeFromReport':
            return{...state, from:actions.payload}

        case 'changeToReport':
            return{...state, to:actions.payload}

        case 'changePageReimbursement':
            return{...state, page:actions.payload}

        case 'changeIdReimbursementShared':
            return{...state, id_reimbursement_shared:actions.payload}
        

        default:
            return state;
    }
}
export default ReimbursementReducer;