import React from "react";
import { Link } from "react-router-dom";
import "react-toggle/style.css";
import { useSelector } from "react-redux";

export default function BillsToPayRecords({
    billsToPay,
    onDelete,
    formatReal,
    changeAllBox,
}) {
    const project = billsToPay || [];
    const perfis = useSelector((state) => state.auth.user.perfis);
    return project.map((desc) => (
        <tr key={desc.id}>
            {perfis.indexOf(1) >= 0 ||
            perfis.indexOf(2) >= 0 ||
            perfis.indexOf(3) >= 0 ||
            perfis.indexOf(6) >= 0 ? (
                <td>
                    <input
                        type="checkbox"
                        id={`${desc.id}`}
                        name="checked"
                        onClick={() => changeAllBox(desc.id)}
                    />
                </td>
            ) : (
                ""
            )}
            {
                perfis.indexOf(7) >= 0 ?                             
                <>
                    <td style={{ width: "7%" }}>{desc.filial_name}</td>
                </>
                : ''
            }
            <td>{desc.usuario}</td>
            {
                perfis.indexOf(7) >= 0 ?                             
                <>
                    <td>{desc.projeto}</td>
                    <td style={{ width: "7%" }}>{desc.cod_senior}</td>
                    <td style={{ width: "7%" }}>{desc.cod_centro_custo}</td>
                </>
                : 
                <>
                    <td style={{ width: "7%" }}>{desc.cod_centro_custo}</td>
                    <td>{desc.projeto}</td>
                </>
            }
            <td style={{ width: "7%" }} >{desc.tipo}</td>
            {
                perfis.indexOf(7) >= 0 ?                             
                <>
                    <td style={{ width: "7%" }}>{desc.variavel_correcao.toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</td>
                    <td style={{ width: "7%" }}>{desc.valor_contabil.toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</td>
                </>
                : ''
            }
            <td style={{ width: "7%" }}>
                {desc.valor.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })}
            </td>
            {
                perfis.indexOf(1) >= 0 ||
                perfis.indexOf(2) >= 0 ||
                perfis.indexOf(3) >= 0 ||
                perfis.indexOf(6) >= 0 ?                             
                <>
                    <td style={{ width: "7%" }}>{desc.variavel_correcao.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })}</td>
                    <td style={{ width: "7%" }}>{desc.valor_contabil.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })}</td>
                </>
                : ''
            }
            {/* <td>{desc.cliente}</td> */}
            <td style={{ width: "5%" }}>{desc.status}</td>
            <td>
            {desc.id_tipo_projeto != null ?
                <ul className="nav">
                    <li className="nav-item dropdown">
                        <a
                            className="nav-link"
                            data-toggle="dropdown"
                            href="#/"
                        >
                            <i className="fas fa-ellipsis-h" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            {/* {perfis.indexOf(1) >= 0 || perfis.indexOf(2) >= 0 || perfis.indexOf(3) >= 0 || perfis.indexOf(6) >= 0 ? (
                                <>
                                    <Link to={`/project/${desc.id}/edit`} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>
                                    <Link to="#/" className='dropdown-item' onClick={()=>onDelete(desc.id)} ><i className='fas fa-trash-alt'></i> Excluir</Link>                                
                                </>
                            ) : ''} */}
                            {desc.id_visita ? (
                                <>
                                    <Link
                                        to={`/visit/${desc.id_visita}/view`}
                                        className="dropdown-item"
                                    >
                                        <i className="fas fa-edit"></i>Registro
                                        base
                                    </Link>
                                </>
                            ) : desc.id_tipo_projeto == 2 ?(
                                <>
                                    <Link
                                        to={`/project_ensino/${desc.id_projeto}/view`}
                                        className="dropdown-item"
                                    >
                                        <i className="fas fa-edit"></i>Registro
                                        base
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Link
                                        to={`/project/${desc.id_projeto}/view`}
                                        className="dropdown-item"
                                    >
                                        <i className="fas fa-edit"></i>Registro
                                        base
                                    </Link>
                                </>
                            ) }
                        </div>
                    </li>
                </ul>
                : null
            }
            </td>
        </tr>
    ));
}

// Selecionar campos
// Botão de atualizar filtrando por perfil
// Tabela com scroll, botar overflow-y:auto no tbody
// Remover select quando não existirem registros
