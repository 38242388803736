import React from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Cabecalho(props) {
    const enterPressed = (event) => {
        var code = event.keyCode || event.which;
        if (code === 13) {
            //13 is the enter keycode
            props.action();
        }
    };
    const perfis = useSelector((state) => state.auth.user.perfis);
    return (
        <>
            {props.children}
            <div className="col-sm-12 col-md-6">
                <div className="row mb-2" >
                    <div className="col-md-3">
                        <Select
                            value={props.mes}
                            label="Single select"
                            options={props.meses}
                            onChange={props.onChangeMes}
                            isDisabled={false}
                        />
                    </div>
                    <div className="col-md-3">
                        <Select
                            value={props.ano}
                            label="Single select"
                            options={props.anos}
                            onChange={props.onChangeAno}
                            isDisabled={false}
                        />
                    </div>
                    <span style={{ fontSize: "14px", marginTop: "8px" }} >{'('+props.compIni + ' - ' + props.compFin+')'}</span>
                </div>
                <div className="row">
                    <div>
                        
                        {perfis.indexOf(1) >= 0 ||
                        perfis.indexOf(2) >= 0 ||
                        perfis.indexOf(6) >= 0
                            ? props.buttonGerarPagamento
                            : ""}
                    
                        {perfis.indexOf(1) >= 0 ||
                        perfis.indexOf(2) >= 0 ||
                        perfis.indexOf(6) >= 0
                            ? props.buttonGerarContabil
                            : ""}

                        {perfis.indexOf(1) >= 0 ||
                        perfis.indexOf(2) >= 0 ||
                        perfis.indexOf(6) >= 0
                            ? props.buttonEnviarEmail
                            : ""}

                        {perfis.indexOf(1) >= 0 ||
                        perfis.indexOf(2) >= 0 ||
                        perfis.indexOf(6) >= 0
                            ? props.buttonChangeStatusSelected
                            : ""}
                    </div>

                </div>
                
            </div>

            <div className="col-md-6 d-flex justify-content-end">
                <div id="example2_filter" className="dataTables_filter">

                    <div className="col-mb-6 mb-2">
                        {props.buttonCsv}
                    </div>
                    
                    <div className="col-mb-6 d-flex justify-content-end">

                        <label className="" style={{textAlign: 'end'}}>
                            <input
                                onKeyPress={enterPressed}
                                value={props.valuePesquisa}
                                type=""
                                onChange={props.changePesquisa}
                                className="form-control form-control-sm"
                                aria-controls="example1"
                            />
                            <span
                                style={{ marginLeft: "-9%", marginRight: "3%" }}
                                className="fas fa-search"
                            ></span>
                            <Link
                                to={props.to}
                                onClick={props.action}
                                style={{ marginLeft: "25px" }}
                                className="btn btn-outline-info btn-sm"
                            >
                                Pesquisar
                            </Link>
                            <Link
                                to={props.to}
                                onClick={props.clear}
                                style={{
                                    marginLeft: "10px",
                                }}
                                className="btn btn-outline-danger btn-sm"
                            >
                                Limpar Busca
                            </Link>
                        </label>

                    </div>
                </div>
            </div>
            <div className="row" style={{ display: "inline-block" }}>
                <div className="col-md-12">{props.buttonStatus}</div>
            </div>
        </>
    );
}
