import React from 'react';
import { Link } from 'react-router-dom';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import { useSelector } from 'react-redux';

export default function Users({ users, onDelete, changeStatus }) {
    const user = users || [];
    const perfis = useSelector((state) => state.auth.user.perfis) || [];
    return user.map((desc) => (
        <tr key={desc.id}>
            {/* <td>
                <Toggle
                    
                    id={`${desc.id}`}
                    checked={desc.ativo === 1 ?(true):(false)}
                    onChange={changeStatus} 
                /> */}
            {/* <label htmlFor='cheese-status'>Adjacent label tag</label> */}

            {/* </td> */}
            <td>{desc.id}</td>
            <td>{desc.name}</td>
            <td>{desc.email}</td>
            <td>{desc.descricao}</td>
            {perfis.indexOf(1) >= 0 ||
            perfis.indexOf(4) >= 0 /**|| perfis.indexOf(6) >=0 */ ? (
                <td>
                    <ul className="nav">
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link"
                                data-toggle="dropdown"
                                href="#/"
                            >
                                <i className="fas fa-ellipsis-h" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <Link
                                    to={`/user/${desc.id}/view`}
                                    className="dropdown-item"
                                >
                                    <i className="fas fa-eye"></i> Detalhes
                                </Link>
                                <Link
                                    to={`/user/${desc.id}/edit`}
                                    className="dropdown-item"
                                >
                                    <i className="fas fa-edit"></i> Editar
                                </Link>
                                {/* <Link to="#/" className='dropdown-item' onClick={()=>onDelete(desc.id)} ><i className='fas fa-trash-alt'></i> Excluir</Link>                                 */}
                            </div>
                        </li>
                    </ul>
                </td>
            ) : (
                ''
            )}
        </tr>
    ));
}
