import React from 'react';
import NavItem from './NavItem';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FaBuilding } from 'react-icons/fa';
import Home from '../Icons/home.png';
import User from '../Icons/user.png';
import Client from '../Icons/client.png';
import Project from '../Icons/project.png';
import ProjetoEnsino from '../Icons/circle-e.png';
import Visit from '../Icons/visit.png';
import Reimbursement from '../Icons/reimbursement.png';
import ReimbursementShared from '../Icons/reimbursement_shared.png';
import CheckList from '../Icons/checklist.png';
import ExtraCharge from '../Icons/extracharge.png';
import ChangeContract from '../Icons/changeContract.png';
import Settings from '../Icons/Group7.png';
import building from '../Icons/building.png';
import BillsToPay from '../Pages/Cadastros/BillsToPay/BillsToPay';
import IconBillsToPay from '../Icons/fluent_money-20-filled.png';

export default function Menu(props) {
    const perfis = useSelector((state) => state.auth.user.perfis);
    let history = useHistory();
    let subItens = [
        { descricao: 'Empresas', route: '/empresas' },
        { descricao: 'Filial', route: '/filiais' },
        { descricao: 'Tipo de Serviço', route: '/service_type' },
        { descricao: 'Tipo de Reembolso', route: '/reimbursement_type' },
        { descricao: 'Área', route: '/area' },
        { descricao: 'Usuários', route: '/users' },
        { descricao: 'Clientes', route: '/customers' },
        { descricao: 'Cultura', route: '/culturas' },
        { descricao: 'Motivos de Encerramento', route: '/motives' },
    ];

    let subItensEnsino = [
        { descricao: 'Usuários', route: '/users' },
        { descricao: 'Clientes', route: '/customers' },
    ];

    let subItensTecnicoVendedor = [
        { descricao: 'Clientes', route: '/customers' },
    ];

    let subItensConfigurations = [
        { descricao: 'Atualizar KM', route: 'update_km' },
    ];
    let route = history.location.pathname.split('/')[1];
    console.log(route);
    // const id_user = useSelector( state => state.auth.user.id )
    return (
        <>
            <NavItem
                to="/index"
                item="Home"
                icon={Home}
                active={route === 'index'}
            />

            {perfis.indexOf(1) >= 0 ||
            perfis.indexOf(2) >= 0 ||
            perfis.indexOf(6) >= 0 ? (
                <>
                    <NavItem
                        to="/index#"
                        item="Cadastros"
                        icon={building}
                        dropdown={true}
                        subMenus={subItens}
                        active={
                            route === 'cadastros' ||
                            route === 'service_type' ||
                            route === 'new_service_type' ||
                            route === 'reimbursement_type' ||
                            route === 'new_reimbursement_type' ||
                            route === 'empresas' ||
                            route === 'empresa' ||
                            route === 'new_empresa' ||
                            route === 'filiais' ||
                            route === 'new_filial' ||
                            route === 'filial' ||
                            route === 'area' ||
                            route === 'new_area' ||
                            route === 'users' ||
                            route === 'new_user' ||
                            route === 'user' ||
                            route === 'culturas' ||
                            route === 'new_cultura' ||
                            route === 'cultura' ||
                            route === 'customers' ||
                            route === 'new_client' ||
                            route === 'client' ||
                            route === 'motives' ||
                            route === 'motive' ||
                            route === 'new_motive'
                        }
                    />
                </>
            ) : perfis.indexOf(7) >= 0 ||
              perfis.indexOf(9) >= 0 ||
              perfis.indexOf(11) >= 0 ? (
                <>
                    <NavItem
                        to="/index#"
                        item="Cadastros"
                        icon={building}
                        dropdown={true}
                        subMenus={subItensTecnicoVendedor}
                        active={
                            route === 'cadastros' ||
                            route === 'customers' ||
                            route === 'new_client' ||
                            route === 'client'
                        }
                    />
                </>
            ) : (
                ''
            )}
            {perfis.indexOf(10) >= 0 && perfis.length === 1 ? (
                <>
                    <NavItem
                        to="/index#"
                        item="Cadastros"
                        icon={building}
                        dropdown={true}
                        subMenus={subItensEnsino}
                        active={
                            route === 'cadastros' ||
                            route === 'users' ||
                            route === 'new_user' ||
                            route === 'user' ||
                            route === 'customers' ||
                            route === 'new_client' ||
                            route === 'client'
                        }
                    />
                </>
            ) : (
                ''
            )}
            {/*
                perfis.indexOf(1) >= 0 || perfis.indexOf(2) >= 0 || perfis.indexOf(3) >= 0 || perfis.indexOf(6) >= 0 ? (
                    <>
                        <NavItem
                            to="/users"
                            item="Usuários"
                            icon={User}
                            active={
                                    route === "users" ||
                                    route === "new_user" ||
                                    route === "user" ? true : false
                            }
                        />
                    </>

                ) : ''
            */}

            {/*
                perfis.indexOf(1) >= 0 || perfis.indexOf(2) >= 0 || perfis.indexOf(3) >= 0 || perfis.indexOf(4) >= 0 || perfis.indexOf(5) >= 0 || perfis.indexOf(6) >= 0 || perfis.indexOf(8) >= 0 ? (
                    <NavItem
                        to="/customers"
                        item="Clientes"
                        icon={Client}
                        active={
                                route === "customers" ||
                                route === "new_client" ||
                                route === "client" ? true : false
                        }
                    />
                ) : ''
                    */}
            {(perfis.indexOf(1) >= 0 ||
                perfis.indexOf(9) >= 0 ||
                perfis.indexOf(3) >= 0 ||
                perfis.indexOf(4) >= 0 ||
                perfis.indexOf(6) >= 0 ||
                perfis.indexOf(7) >= 0 ||
                perfis.indexOf(8) >= 0 ||
                perfis.indexOf(12) >= 0) && (
                <NavItem
                    to="/projects"
                    item="Projetos Consultoria"
                    icon={Project}
                    active={
                        route === 'projects' ||
                        route === 'new_project' ||
                        route === 'project'
                    }
                />
            )}
            {(perfis.indexOf(1) >= 0 ||
                perfis.indexOf(6) >= 0 ||
                perfis.indexOf(10) >= 0) && (
                <NavItem
                    to="/projects_ensino"
                    item="Projetos Ensino"
                    icon={ProjetoEnsino}
                    active={
                        route === 'projects_ensino' ||
                        route === 'new_project_ensino' ||
                        route === 'project_ensino'
                    }
                />
            )}

            {(perfis.indexOf(1) >= 0 ||
                perfis.indexOf(2) >= 0 ||
                perfis.indexOf(3) >= 0 ||
                perfis.indexOf(4) >= 0 ||
                perfis.indexOf(5) >= 0 ||
                perfis.indexOf(6) >= 0 ||
                perfis.indexOf(7) >= 0 ||
                perfis.indexOf(8) >= 0 ||
                perfis.indexOf(12) >= 0) && (
                <NavItem
                    item="Visitas"
                    to="/visits"
                    icon={Visit}
                    active={
                        route === 'visits' ||
                        route === 'new_visit' ||
                        route === 'visit'
                    }
                />
            )}
            {(perfis.indexOf(1) >= 0 ||
                perfis.indexOf(2) >= 0 ||
                perfis.indexOf(3) >= 0 ||
                perfis.indexOf(4) >= 0 ||
                perfis.indexOf(5) >= 0 ||
                perfis.indexOf(6) >= 0 ||
                perfis.indexOf(7) >= 0 ||
                perfis.indexOf(8) >= 0 ||
                perfis.indexOf(10) >= 0 ||
                perfis.indexOf(11) >= 0 ||
                perfis.indexOf(12) >= 0) && (
                <>
                    <NavItem
                        item="Reembolsos"
                        to="/refunds"
                        icon={Reimbursement}
                        active={
                            route === 'refunds' ||
                            route === 'new_reimbursement' ||
                            route === 'reimbursement'
                        }
                    />
                    <NavItem
                        item="Reembolso Compartilhado"
                        to="/refunds_shared"
                        icon={ReimbursementShared}
                        active={
                            route === 'refunds_shared' ||
                            route === 'reimbursement_shared' ||
                            route === 'new_reimbursement_shared'
                        }
                    />
                </>
            )}
            {(perfis.indexOf(1) >= 0 ||
                perfis.indexOf(6) >= 0 ||
                perfis.indexOf(11) >= 0) && (
                <>
                    <NavItem
                        item="Registro SGAgri"
                        to="/registers_sgagri"
                        icon={CheckList}
                        active={
                            route === 'new_register_sgagri' ||
                            route === 'register_sgagri' ||
                            route === 'registers_sgagri'
                        }
                    />
                </>
            )}
            {perfis.indexOf(1) >= 0 ||
            perfis.indexOf(2) >= 0 ||
            perfis.indexOf(3) >= 0 ||
            perfis.indexOf(6) >= 0 ||
            perfis.indexOf(8) >= 0 ||
            perfis.indexOf(9) >= 0 ||
            perfis.indexOf(12) >= 0 ? (
                <>
                    <NavItem
                        item="Registro de Venda"
                        to="/check_lists"
                        icon={CheckList}
                        active={
                            route === 'new_check_list' ||
                            route === 'check_list' ||
                            route === 'check_lists'
                        }
                    />
                    <NavItem
                        item="Registro de Serviço Extra"
                        to="/extra_charges"
                        icon={ExtraCharge}
                        active={
                            route === 'new_extra_charge' ||
                            route === 'extra_charges' ||
                            route === 'extra_charge'
                        }
                    />
                    <NavItem
                        item="Registro de Alteração de Projeto"
                        to="/contract_changes"
                        icon={ChangeContract}
                        active={
                            route === 'new_project_amendment' ||
                            route === 'contract_changes' ||
                            route === 'project_amendment'
                        }
                    />
                </>
            ) : (
                ''
            )}
            {perfis.indexOf(1) >= 0 /*||
            perfis.indexOf(2) >= 0 ||
            perfis.indexOf(3) >= 0 || perfis.indexOf(6) >= 0*/ ? (
                <NavItem
                    item="Contas a Pagar"
                    to="/bills_to_pay"
                    icon={IconBillsToPay}
                    active={route === 'bills_to_pay'}
                />
            ) : (
                <>
                    {perfis.indexOf(1) >= 0 && (
                        // perfis.indexOf(9) === -1 &&
                        // perfis.indexOf(8) === -1 &&
                        // perfis.indexOf(7) === -1 &&
                        // perfis.indexOf(4) === -1 &&
                        // perfis.indexOf(6) === -1 &&
                        // perfis.indexOf(10) === -1 &&
                        // perfis.indexOf(11) === -1 &&
                        // perfis.indexOf(12) === -1
                        <NavItem
                            item="Extrato"
                            to="/extract"
                            icon={IconBillsToPay}
                            active={route === 'extract'}
                        />
                    )}
                </>
            )}
            {/* <NavItem item="Registro de Venda" to="/check_lists" icon="fas fa-clipboard-list" />
            <NavItem item="Registro de Cobrança Extra" to="/extra_charges" icon="fas fa-clipboard-list" />   */}
            {/* perfis.indexOf(1) >= 0 || perfis.indexOf(2) >= 0 || perfis.indexOf(6) >= 0 ? (
                <NavItem 
                    item="Atualizar KM" 
                    to="/update_km" 
                    // icon={BillsToPay} 
                    active={
                        route === "update_km" ? true : false
                    } 
                    />
                ) : ''
                */}
            {perfis.indexOf(1) >= 0 ||
            perfis.indexOf(2) >= 0 ||
            perfis.indexOf(6) >= 0 ? (
                <>
                    <NavItem
                        to="/index#"
                        item="Configurações"
                        icon={Settings}
                        dropdown={true}
                        subMenus={subItensConfigurations}
                        active={route === 'update_km'}
                    />
                </>
            ) : (
                ''
            )}
        </>
    );
}
