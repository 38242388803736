import React from 'react'

export default function Footer() {
    return (
        <>
            <footer className="main-footer" style={{backgroundColor:'#2A4038', color: '#D1D1D1'}}>
                
                <div className="float-right d-none d-sm-block">
                    <b>Version</b> 2.0
                </div>
                <strong>Copyright © 2020-2020 Rehagro TI</strong> Todos os direitos reservados
                
            </footer>
            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* Control sidebar content goes here */}
            </aside>
            {/* /.control-sidebar */}
        </>
    )
}
