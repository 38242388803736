import { get_refunds } from '../../../../Services/reimbursement_shared'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[
        
        (dispatch,getState)=>{
        const order = getState().reimbursement_shared.order
        const th = getState().reimbursement_shared.th
        const qtd_per_page = getState().reimbursement_shared.qtd_per_page
        const pesquisa = getState().reimbursement_shared.pesquisa || ''
        const id_technical = getState().reimbursement_shared.technical_report.value || ''
        const id_project = getState().reimbursement_shared.project_report.value || ''   
        const id_type = getState().reimbursement_shared.type_report.value || ''  
        const id_status = getState().reimbursement_shared.status_report.value || ''
        const from = getState().reimbursement_shared.from
        const to = getState().reimbursement_shared.to 
        const page = getState().reimbursement_shared.page     
      
        get_refunds(page,qtd_per_page,order,th,pesquisa,id_technical,id_project,id_type,id_status,from,to)
        .then(resp=>dispatch({type:'RefundsShared', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().reimbursement_shared.asc
      
        if(asc === true){
            dispatch({type:'GetReimbursementShared_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetReimbursementShared_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_reimbursement_shared',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaReimbursementShared',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[
        (dispatch, getState) =>{
            const fromFix = getState().reimbursement_shared.fromFix
            const toFix = getState().reimbursement_shared.toFix
            dispatch({
                type:'clearPesquisaReimbursementShared',
                payload:'',
                technical_report: '',
                project_report: '' ,  
                type_report: '' , 
                status_report: '',
                from: fromFix ,
                to: toFix       
            })
        },get(1)]
}

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().reimbursement_shared.pesquisa || ''
            const id_technical = getState().reimbursement_shared.technical_report.value || ''
            const id_project = getState().reimbursement_shared.project_report.value || ''   
            const id_type = getState().reimbursement_shared.type_report.value || ''  
            const id_status = getState().reimbursement_shared.status_report.value || ''
            const from = getState().reimbursement_shared.from
            const to = getState().reimbursement_shared.to 
            if(pesquisa || id_technical || id_project || id_type || id_status || from || to){
                return dispatch({type:'changePageReimbursementShared', payload:1})
            }
        }, get()
    ]
}

export const changePage = page => {
    return[{
        type: 'changePageReimbursementShared',
        payload: page
    },get()]
}