const ReimbursementSharedReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'RefundsShared':
            return{...state, refunds_shared:actions.payload}

        case 'GetReimbursementShared_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetReimbursementShared_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_reimbursement_shared':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaReimbursement':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaReimbursementShared':
            return{...state, 
                pesquisa:actions.payload,
                technical_report: actions.technical_report,
                project_report: actions.project_report ,  
                type_report: actions.type_report , 
                status_report: actions.status_report,
                from: actions.from ,
                to: actions.to
            }

        case 'changeTechnicalReportShared':
            return{...state, technical_report:actions.payload}

        case 'changeProjectReportShared':
            return{...state, project_report:actions.payload}

        case 'changeTypeReportShared':
            return{...state, type_report:actions.payload}
        
        case 'changeStatusReportShared':
            return{...state, status_report:actions.payload}

        case 'changeFromReportShared':
            return{...state, from:actions.payload}

        case 'changeToReportShared':
            return{...state, to:actions.payload}

        case 'changePageReimbursementShared':
            return{...state, page:actions.payload}
        

        default:
            return state;
    }
}
export default ReimbursementSharedReducer;