import { app } from './Api'

const getResource = async() => {
    return app.get('register_sgagri/get_resource')
}

const postRegisterSgagri = async (data) => {
    return app.post(`register_sgagri`, data)
}

const putRegisterSgagri = async (id,data) => {
    return app.post(`register_sgagri/${id}`,data)
}

const getRegisterSgagri = async (page,qtd_per_page,order,th,pesquisa,project_report,id_status,from,to) => {
    return app.get(`register_sgagri?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&id_registro_sgagri=${project_report}&id_status=${id_status}&from=${from}&to=${to}`)
}

const getViewRegisterSgagri = async (id) => {
    return app.get(`register_sgagri/${id}`)
}

const delRegisterSgagri = async (id) => {
    return app.delete(`register_sgagri/${id}`)
}

const changeStatus =  async (id,data) => {
    return app.put(`change_register_sgagri/${id}`,data)
}

const getClient =  async () => {
    return app.get(`register_sgagri/get_client`)
}

const getCity =  async (idState) => {
    return app.get(`register_sgagri/get_city/${idState}`)
}

const getFilial =  async (idEmpresa) => {
    return app.get(`register_sgagri/get_filial/${idEmpresa}`)
}

const getAreaManager = async (idArea) => {
    return app.get(`register_sgagri/manager_by_id_area/${idArea}`);
};

const delAttachment = async (id) => {
    return app.delete(`register_sgagri/del_attachment/${id}`)
}

export { getAreaManager, getResource, getFilial, delAttachment, postRegisterSgagri, putRegisterSgagri, getRegisterSgagri, getViewRegisterSgagri, delRegisterSgagri, changeStatus, getClient, getCity }