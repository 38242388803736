import { app } from './Api';

const get_view_project = async (id) => {
    return app.get(`project_ensino/${id}`);
};

const get_projects = async (
    page,
    qtd_per_page,
    order,
    th,
    pesquisa,
    area,
    manager,
    cod_centro_custo,
    coordinator,
    status,
) => {
    return app.get(
        `project_ensino?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&area=${area}&manager=${manager}&cod_centro_custo=${cod_centro_custo}&coordinator=${coordinator}&status=${status}`,
    );
};

const post_project_ensino = async (data) => {
    return app.post(`project_ensino`, data);
};

const put_project_ensino = async (id, data) => {
    return app.put(`project_ensino/${id}`, data);
};

const del_project = async (id) => {
    return app.delete(`project_ensino/${id}`);
};

const changeStatusTechnical = async (id, data) => {
    return app.put(`project_ensino/change_status_technical/${id}`, data);
};

const getResource = async () => {
    return app.get(`project_ensino/create`);
};

const del_attachment = async (id) => {
    return app.delete(`project/del_attachment/${id}`);
};

const getTechnicalProject = async (id_project) => {
    return app.get(`project/technical/${id_project}`);
};

const post_technical_project = async (data) => {
    return app.post(`project_ensino/post_technical`, data);
};

const checkDeleteTechnical = async (id) => {
    return app.post(`project_ensino/check_delete_technical/${id}`);
};

const getPagamentos = async (idProjeto) => {
    return app.get(`project/pagamentos/${idProjeto}`);
};

const alterPagamento = async (data) => {
    return app.put(`change_pagamentos`, data);
};

const getFilial = async (idEmpresa) => {
    return app.get(`project_ensino/get_filial/${idEmpresa}`);
};

const getAreaManager = async (idArea) => {
    return app.get(`project_ensino/manager_by_id_area/${idArea}`);
};

const changeStatusPag = async (data) => {
    return app.put(`change_status_pagamentos`, data);
};

const exportExcelDetalhado = async (
    page,
    qtd_per_page,
    order,
    th,
    pesquisa,
    type,
    area,
    manager,
    coordinator,
    status,
) => {
    return app.get(
        `project/export_excel?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&type=${type}&area=${area}&manager=${manager}&coordinator=${coordinator}&status=${status}`,
    );
};

const exportExcelPorTecnico = async (
    page,
    qtd_per_page,
    order,
    th,
    pesquisa,
    type,
    area,
    manager,
    coordinator,
    status,
) => {
    return app.get(
        `project/export_excel_tecnico?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&type=${type}&area=${area}&manager=${manager}&coordinator=${coordinator}&status=${status}`,
    );
};

const exportExcelCSV = async (
    page,
    qtd_per_page,
    order,
    th,
    pesquisa,
    type,
    area,
    manager,
    coordinator,
    status,
) => {
    return app.get(
        `project_ensino/export_excel_csv?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&type=${type}&area=${area}&manager=${manager}&coordinator=${coordinator}&status=${status}`,
    );
};

const technicianImport = async (data) => {
    return app.post(`project_ensino/technician_import`, data);
};

export {
    exportExcelCSV,
    exportExcelDetalhado,
    exportExcelPorTecnico,
    changeStatusPag,
    alterPagamento,
    getPagamentos,
    del_attachment,
    get_view_project,
    get_projects,
    changeStatusTechnical,
    post_project_ensino,
    put_project_ensino,
    getResource,
    del_project,
    getTechnicalProject,
    post_technical_project,
    checkDeleteTechnical,
    getFilial,
    getAreaManager,
    technicianImport
};
