import { app } from './Api'

const post_motive = async (data) => {
    return app.post(`motive`, data)
}

const get_view_motive = async (id) => {
    return app.get(`motive/${id}`)
}

const put_motive = async (id,data) => {
    return app.put(`motive/${id}`,data)
}

const get_motives = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`motive?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}


export { post_motive, get_view_motive, get_motives, put_motive }