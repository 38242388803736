import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store/index';
import { Provider } from 'react-redux';
import jwt from 'jsonwebtoken';
import { URL } from './baseURL/baseURL';
import { information } from './Componentes/Services/auth';
import ReactLoading from 'react-loading';
import { Content } from './Componentes/LoadingStyle/StyleLoading';
import './Dependencies/dependencies';
import './Dependencies/jquery-loader';

const jwt_secret =
  'bXb9j2qTmalssygigAcOri0KribLguhj3klya3M6aegnoNzRYoKtepRtYymMPCNC';

let token = localStorage.getItem('token');

if (token) {
    jwt.verify(token, jwt_secret, (err, decoded) => {
        if (err) {
            console.log(err);
            if (err.name !== 'NotBeforeError') {
                localStorage.removeItem('token');
                token = null;
            }
        } else {
            if (decoded.iss !== URL + '/login') {
                localStorage.removeItem('token');
                token = null;
            }
        }
    });
}

const render = () => {
    ReactDOM.render(
        <>
            <Provider store={store}>
                <App />
            </Provider>
        </>,

        document.getElementById('root')
    );
};

const renderLoad = () => {
    ReactDOM.render(
        <Content>
            <ReactLoading
                type="spinningBubbles"
                color="green"
                height={300}
                width={300}
            />
        </Content>,
        document.getElementById('root')
    );
};
if (token) {
    renderLoad();
    information()
        .then((res) => {
            store.dispatch({ type: 'SET_LOGIN', payload: res.data });
        })
        .then(() => {
            render();
        });
} else {
    render();
}
