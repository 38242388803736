import React from 'react'
import "react-toggle/style.css"

export default function Produtivity({ produtivity, formatReal, handleDeleteProdutivity, isDisabled }) {
    const produtivitys = produtivity || []
    
    return produtivitys.map((desc) =>(
        <tr key={desc.id}>
            <td className="text-center">{desc.nameCulture}</td>
            <td className="text-center">{formatReal(parseFloat(`${desc.value}`))}</td>
            <td className="text-center">
                <button disabled={isDisabled} type="button" className="btn btn-danger" onClick={() => handleDeleteProdutivity(desc.id)}><span className="fas fa-minus"></span></button>
            </td>
        </tr>
        
    ))  
}
