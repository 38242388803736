import React from 'react'
import jwt from 'jsonwebtoken'
import { useDispatch } from  'react-redux'
import jwt_decode from "jwt-decode";

// const logout = () => dispatch({type: 'SET_LOGOUT'})

function verifyTokenValid(token){
    
    let decodedToken = jwt_decode(token);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();

    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
        return false
    }
    else
    {
        return true
    }
}
export { verifyTokenValid }