import { app } from './Api'

const get_view_service_types = async (id) => {
    return app.get(`service_type/${id}`)
}

const get_service_types = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`service_type?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const edit_service_types = async (id,data) => {
    return app.put(`service_type/${id}`,data)
}

const create_service_types = async (data) => {
    return app.post(`service_type`,data)
}

const changeStatus =  async (id,data) => {
    return app.put(`service_type/change_status/${id}`,data)
}

export { get_view_service_types, get_service_types, edit_service_types, create_service_types, changeStatus }