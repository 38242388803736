import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Input from '../../../Forms/Input';
import { useSelector, useDispatch } from 'react-redux';
import {
    getTechnicalReport,
    getProjectsReport,
    getRemunerationReport,
    getStatusReport,
    getCoordinatorReport,
} from '../../../Services/visit';

export default function ReportVisit(props) {
    const technical_report = useSelector(
        (state) => state.visit.technical_report
    );
    const project_report = useSelector((state) => state.visit.project_report);
    const coordinator_report = useSelector(
        (state) => state.visit.coordinator_report
    );
    const remuneration_report = useSelector(
        (state) => state.visit.remuneration_report
    );
    const status_report = useSelector((state) => state.visit.status_report);
    const from = useSelector((state) => state.visit.from);
    const to = useSelector((state) => state.visit.to);
    const dispatch = useDispatch();
    const [technical, setTechnical] = useState([]);
    const [coordinator, setCoordinator] = useState([]);
    const [projects, setProjects] = useState([]);
    const [remuneration, setRemuneration] = useState([]);
    const [status, setStatus] = useState([]);

    useEffect(() => {
        Technical(project_report.value);
        return () => {
            Technical();
        };
    }, [project_report.value]);

    useEffect(() => {
        Projects(technical_report.value, coordinator_report.value);
        return () => {
            Projects();
        };
    }, [technical_report.value, coordinator_report.value]);

    useEffect(() => {
        Coordinator();
        return () => {
            Coordinator();
        };
    }, []);

    useEffect(() => {
        StatusVisit();
        return () => {
            StatusVisit();
        };
    }, []);

    useEffect(() => {
        TypeRemuneration(technical_report.value, project_report.value);
        return () => {
            TypeRemuneration();
        };
    }, [technical_report.value, project_report.value]);

    useEffect(() => {
        TypeRemuneration();
        return () => {
            TypeRemuneration();
        };
    }, []);

    function Technical(id_project) {
        getTechnicalReport(
            parseInt(id_project) > 0 ? parseInt(id_project) : ''
        ).then((resp) => {
            setTechnical(
                [
                    { value: '', label: '-- SELECIONE --' },
                    ...resp.data.map((desc) => ({
                        value: desc.id,
                        label: desc.name,
                    })),
                ]
                // resp.data.map(desc => ({
                //     value: desc.id,
                //     label: desc.name,
                // })).concat({
                //     value:'',
                //     label:'-- VAZIO --'
                // })
            );
        });
    }

    function Coordinator() {
        getCoordinatorReport().then((resp) => {
            setCoordinator(
                [
                    { value: '', label: '-- SELECIONE --' },
                    ...resp.data.map((desc) => ({
                        value: desc.id_coordenador,
                        label: desc.coordenador,
                    })),
                ]
                // resp.data.map(desc => ({
                //     value: desc.id,
                //     label: desc.name,
                // })).concat({
                //     value:'',
                //     label:'-- VAZIO --'
                // })
            );
        });
    }

    function Projects(id_technical, id_coordinator) {
        getProjectsReport(
            parseInt(id_technical) > 0 ? parseInt(id_technical) : '',
            parseInt(id_coordinator) > 0 ? parseInt(id_coordinator) : ''
        ).then((resp) => {
            setProjects(
                [
                    { value: '', label: '-- SELECIONE --' },
                    ...resp.data.map((desc) => ({
                        value: desc.id,
                        label: desc.titulo,
                    })),
                ]
                // resp.data.map(desc => ({
                //     value: desc.id,
                //     label: desc.titulo
                // })).concat({
                //     value:'',
                //     label:'-- VAZIO --'
                // })
            );
        });
    }

    function TypeRemuneration(id_technical, id_project) {
        getRemunerationReport(id_technical, id_project).then((resp) => {
            setRemuneration(
                resp.data.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
        });
    }

    function StatusVisit() {
        getStatusReport().then((resp) => {
            setStatus(
                [
                    { value: '', label: '-- SELECIONE --' },
                    ...resp.data.map((desc) => ({
                        value: desc.id,
                        label: desc.descricao,
                    })),
                ]
                // resp.data.map(desc => ({
                //     value: desc.id,
                //     label: desc.descricao,
                // })).concat({
                //     value:'',
                //     label:'-- VAZIO --'
                // })
            );
        });
    }

    const changeTechnical = (e) => {
        return [
            dispatch({ type: 'changeTechnicalReportVisit', payload: e }),
            Projects(e.value, coordinator_report.value),
            TypeRemuneration(e.value, project_report.value),
        ];
    };

    const changeCoordinator = (e) => {
        return [
            dispatch({ type: 'changeCoordinatorReportVisit', payload: e }),
            Projects(technical_report.value, e.value),
        ];
    };

    const changeProject = (e) => {
        return [
            dispatch({ type: 'changeProjectReportVisit', payload: e }),
            Technical(e.value),
            TypeRemuneration(technical_report.value, e.value),
        ];
    };

    return (
        <>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 mt-4">
                        <label>Técnico</label>
                        <Select
                            value={technical_report}
                            label="Single select"
                            options={technical}
                            onChange={changeTechnical}
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <label>Coordenador</label>
                        <Select
                            value={coordinator_report}
                            label="Single select"
                            options={coordinator}
                            onChange={changeCoordinator}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <label>Projeto</label>
                        <Select
                            value={project_report}
                            label="Single select"
                            options={projects}
                            onChange={changeProject}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <label>Remuneração</label>
                        <Select
                            value={remuneration_report}
                            label="Single select"
                            options={remuneration}
                            onChange={(e) =>
                                dispatch({
                                    type: 'changeRemunerationReport',
                                    payload: e,
                                })
                            }
                        />
                    </div>
                </div>
                <div className="row mt-3 mb-4">
                    <div className="col-md-12 mt-4">
                        <label>Status</label>
                        <Select
                            value={status_report}
                            label="Single select"
                            options={status}
                            onChange={(e) =>
                                dispatch({
                                    type: 'changeStatusReportVisit',
                                    payload: e,
                                })
                            }
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <Input
                            label="De"
                            type="date"
                            icon="fas fa-calendar"
                            onChange={(e) =>
                                dispatch({
                                    type: 'changeFromReportVisit',
                                    payload: e.target.value,
                                })
                            }
                            value={from}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <Input
                            label="Até"
                            type="date"
                            icon="fas fa-calendar"
                            onChange={(e) =>
                                dispatch({
                                    type: 'changeToReportVisit',
                                    payload: e.target.value,
                                })
                            }
                            value={to}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
