import React, { useEffect, useState } from 'react';
import Form from '../../../Forms/Form';
import StylePage from '../../../Style Page/StylePage';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import Footer from '../../../Footer/Footer';
import Alert from '../../../Alerts/Alert';
import { Content } from '../../../LoadingStyle/StyleLoading';
import ReactLoading from 'react-loading';
import Input from '../../../Forms/Input'
import { useHistory } from 'react-router-dom';
import { getValuesKm, change_km } from '../../../Services/updateKm'
import NumberFormat from 'react-number-format';

function ProjectAmendment(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [alert, setAlert] = useState('');
    const [kmCar, setKmCar] = useState('');
    const [kmMoto, setKmMoto] = useState('');
    const [lastUpdateKm, setLastUpdateKm] = useState('');
    const [lastUpdateKmBlock, setLastUpdateKmBlock] = useState('');
    const [dataReajuste, setDataReajuste] = useState('');

    useEffect(() => {
        getValuesKm()
            .then(resp => {
                resp.data.valorKMCarro ? setKmCar(resp.data.valorKMCarro.km_carro) : setKmCar('0');
                resp.data.valorKMMoto ? setKmMoto(resp.data.valorKMMoto.km_moto)  : setKmMoto('0');
                resp.data.ultimo_reajuste ? setLastUpdateKm(resp.data.ultimo_reajuste.data_vigencia) : setLastUpdateKm('');
                resp.data.ultimo_reajuste ? setLastUpdateKmBlock(resp.data.ultimo_reajusteBlock.data_vigenciaBlock) : setLastUpdateKmBlock('');
            })
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(typeof(+kmMoto))
        let data = {
            valorKMCar: +kmCar,
            valorKMMoto: +kmMoto,
            lastUpdateKm,
            dataReajuste
        }

        // console.log(data)

        change_km(data)
            .then(resp => {
                console.log(resp.data.success);
                setLoading(false)
                setSuccess(resp.data.success)
            })
            .then(resp => {
                setTimeout(() => {
                    setSuccess('')
                    history.go(0)
                }, 3000)
            })
            .catch(error => {
                console.log(error.response.data.errorSubmit);
                setAlert(error.response.data.errorSubmit);

                setTimeout(() => {
                    setAlert('')
                }, 3000);

            })

    }

    const setKmCarEvent = (e) =>
    {
        setKmCar(parseFloat(
                e.target.value
                    .replace('.', '')
                    .replace('.', '')
                    .replace(',', '.')
            ));
    }

    const setKmMotoEvent = (e) =>
    {
        setKmMoto(parseFloat(
                e.target.value
                    .replace('.', '')
                    .replace('.', '')
                    .replace(',', '.')
            ));
    }

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage
                title={'Atualização de KM'}
            >
                {loading === true ? (
                    <Content>
                        <ReactLoading
                            type="spinningBubbles"
                            color="blue"
                            height={200}
                            width={100}
                        />
                    </Content>
                ) : (
                    ''
                )}
                {success ?
                    <div onClick={() => {
                        setAlert('')
                        setSuccess('')
                        setTimeout(() => {
                            history.go(0)
                        }, 500);
                    }
                    }>
                        <Alert type="success">{success}</Alert>
                    </div>
                    : ''
                }

                {alert ?
                    <div onClick={() => {
                        setAlert('')
                        setSuccess('')
                        setTimeout(() => {
                            history.go(0)
                        }, 500);
                    }
                    }>
                        <Alert type="danger">{alert}</Alert>
                    </div>
                    : ''
                }
                <div
                    style={{
                        width: '100%',
                        display: loading === true ? 'none' : 'block',
                        opacity: success || alert ? 0.1 : 1,
                    }}
                >
                    <Form onSubmit={handleSubmit}>

                        <div className="row mb-3">
                            <div className="col-md-6">
                                {/* <Input
                                    label='Valor KM Carro'
                                    icon='fas fa-dollar-sign'
                                    value={kmCar}
                                    required={true}
                                    id='kmCar'
                                    placeholder='KM Carro'
                                    type='number'
                                    onChange={e => setKmCar(e.target.value)}
                                /> */}
                                <NumberFormat
                                    label='Valor KM Carro'
                                    icon='fas fa-dollar-sign'
                                    name="valor"
                                    id='kmCar'
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    placeholder="KM Carro"
                                    customInput={Input}
                                    onChange={setKmCarEvent}
                                    value={kmCar}
                                    disabled={false}
                                    required={true}
                                    isAllowed={(values) => {
                                        const { floatValue, formattedValue } =
                                            values;
                                        return (
                                            formattedValue === '' ||
                                            floatValue <= 999999
                                        );
                                    }}
                                />
                            </div>

                            <div className="col-md-6">
                                {/* <Input
                                    label='Valor KM Moto'
                                    icon='fas fa-dollar-sign'
                                    value={kmMoto}
                                    required={true}
                                    id='kmMoto'
                                    placeholder='KM Moto'
                                    type='number'
                                    onChange={e => setKmMoto(e.target.value)}
                                /> */}
                                <NumberFormat
                                    label='Valor KM Moto'
                                    icon='fas fa-dollar-sign'
                                    name="valor"
                                    id='kmMoto'
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    placeholder='KM Moto'
                                    customInput={Input}
                                    onChange={setKmMotoEvent}
                                    value={kmMoto}
                                    disabled={false}
                                    required={true}
                                    isAllowed={(values) => {
                                        const { floatValue, formattedValue } =
                                            values;
                                        return (
                                            formattedValue === '' ||
                                            floatValue <= 999999
                                        );
                                    }}
                                />
                            </div>

                        </div>

                        <div className='row mb-3'>
                            <div className="col-md-4">
                                <Input
                                    icon="fas fa-calendar-alt"
                                    label="Data do último reajuste"
                                    // onChange={e => setLastUpdateKm(e.target.value)}
                                    type="date"
                                    value={lastUpdateKm}
                                    placeholder=""
                                    disabled={true}
                                />
                            </div>

                            <div className="col-md-4">
                                <Input
                                    icon="fas fa-calendar-alt"
                                    label="Data do Reajuste"
                                    onChange={e => setDataReajuste(e.target.value)}
                                    type="date"
                                    value={dataReajuste}
                                    placeholder=""
                                    required={true}
                                    min={lastUpdateKmBlock}
                                />
                            </div>

                        </div>

                        <div className="text-right">
                            <button style={{ marginRight: '10px' }} type="button" className="btn btn-secondary" onClick={() => history.goBack()}>Voltar</button>

                            <button type="submit" className="btn btn-primary">Salvar</button>

                        </div>

                    </Form>
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}

export default ProjectAmendment;
