import { app } from './Api'

const getValuesKm = async () => {
    return app.get(`update_km`)
}

const change_km = async (data) => {
    return app.put(`change_km`, data)
}

export { getValuesKm, change_km }