import React, { useState, useEffect } from "react";
import Select from "react-select";
import Input from "../../../Forms/Input";
import { useSelector, useDispatch } from "react-redux";
import { getResource } from "../../../Services/project";

export default function ReportVisit(props) {
    const [area, setArea] = useState(
        useSelector((state) => state.project.area)
    );
    const [areas, setAreas] = useState([]);
    const [coordinator, setCoordinator] = useState(
        useSelector((state) => state.project.coordinator)
    );
    const [coordinators, setCoordinators] = useState([]);
    const [status, setStatus] = useState(
        useSelector((state) => state.project.status)
    );
    const [statusOptions, setStatusOptions] = useState([]);
    const [manager, setManager] = useState(
        useSelector((state) => state.project.manager)
    );
    const [managers, setManagers] = useState([]);
    const [type, setType] = useState(
        useSelector((state) => state.project.type)
    );
    const [types, setTypes] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        resource();
    }, []);

    const resource = () => {
        getResource().then((resp) => {
            setAreas(
                resp.data.areas.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setCoordinators(
                resp.data.usersFilter.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setStatusOptions(
                resp.data.status.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setManagers(
                resp.data.usersFilter.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setTypes(
                resp.data.types.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
        });
    };

    const handleType = (e) => {
        setType(e);
        dispatch({ type: "type", payload: e });
    };

    const handleArea = (e) => {
        setArea(e);
        dispatch({ type: "area", payload: e });
    };

    const handleManager = (e) => {
        setManager(e);
        dispatch({ type: "manager", payload: e });
    };

    const handleCoordenador = (e) => {
        setCoordinator(e);
        dispatch({ type: "coordenador", payload: e });
    };

    const handleStatus = (e) => {
        setStatus(e);
        dispatch({ type: "status", payload: e });
    };
    return (
        <>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 mt-4">
                        <label>Tipo</label>
                        <Select
                            value={type}
                            label="Single select"
                            options={types}
                            onChange={handleType}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <label>Área</label>
                        <Select
                            value={area}
                            label="Single select"
                            options={areas}
                            onChange={handleArea}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <label>Gerente</label>
                        <Select
                            value={manager}
                            label="Single select"
                            options={managers}
                            onChange={handleManager}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <label>Coordenador</label>
                        <Select
                            value={coordinator}
                            label="Single select"
                            options={coordinators}
                            onChange={handleCoordenador}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <label>Status</label>
                        <Select
                            value={status}
                            label="Single select"
                            options={statusOptions}
                            onChange={handleStatus}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
