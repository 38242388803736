import React from 'react'

export default function TextArea(props) {
    return (
        <div className="form-group">
            <label>{props.label}</label>
            <textarea 
                disabled={props.disabled} 
                className="form-control fas" 
                value={props.value} 
                rows={props.rows} 
                onChange={props.onChange} 
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                required={props.required}
            >
                {props.children}
            </textarea>
        </div>
    )
}
