const EmpresaReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Empresas':
            return{...state, empresas:actions.payload}

        case 'GetEmpresa_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetEmpresa_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_empresa':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaEmpresa':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaEmpresa':
            return{...state, pesquisa:actions.payload}

        case 'changePageEmpresa':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default EmpresaReducer;