import React from 'react'
import Select from 'react-select'
import NumberFormat from 'react-number-format'
import Input from '../../../Forms/Input'
import TextArea from '../../../Forms/TextArea'

export default function Technical(props) {
    return (
       
        <>
            <div className='row mt-3 ml-3'>
                <div className="col-md-4">
                    <label>Técnico</label>
                    <Select 
                        value={props.id_technical}
                        label="Single select"
                        options={props.technical}
                        onChange={props.changeTechnical}
                        isDisabled={props.isDisabled}    
                    />
                    <div className="mt-3">
                        {props.errorTechnical}
                    </div>
                </div>
                <div className="col-md-4">
                    <Input 
                        icon="fas fa-calendar-alt" 
                        label="Data da alocação" 
                        onChange={props.changeDataAlocacao} 
                        type="date" 
                        value={props.dataAlocacao}
                        min={props.startDate}  
                        placeholder='' 
                        // required={true}
                        disabled={props.isDisabled}
                    />
                    <div className="mt-3">
                        {props.errordataAlocacao}
                    </div>
                </div>
                <div className="col-md-4">
                    <label>Atuação</label>
                    <Select 
                        value={props.id_atuacao}
                        label=""
                        options={props.atuacao}
                        onChange={props.changeAtuacao}
                        isDisabled={props.isDisabled}    
                    />
                    <div className="mt-3">
                        {props.errorTechnical}
                    </div>
                </div>
            </div>
            
            <div className='row mt-2 ml-3'>
                <div className="col-md-4">
                    <label>Formato</label>
                    <Select 
                        value={props.id_formato}
                        label=""
                        options={props.formato}
                        onChange={props.changeFormato}
                        isDisabled={props.isDisabled}    
                    />
                    <div className="mt-3">
                        {props.errorTechnical}
                    </div>
                </div>
                <div className="col-md-4">
                    <NumberFormat   
                        icon="fas fa-user-tie"
                        label="Dedicação (Dias/Horas/Parcelas)"
                        name="dedicacao" 
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={"."} 
                        decimalSeparator={","}
                        placeholder=""
                        customInput={Input}
                        allowNegative={false}
                        onChange={props.changeDedication} 
                        value={props.dedication}
                        // required={true}
                        disabled={props.isDisabled}                       
                    />
                    {/* {props.errorValueTechnical} */}
                </div>

                <div className="col-md-4">
                <label>Remuneração</label>
                <Select 
                    value={props.id_remuneration}
                    label="Single select"
                    options={props.remunerations}
                    onChange={props.changeRemuneration}
                    isDisabled={props.isDisabled || props.isDisabledRemuneracao}    
                /> 
                <div className="mt-3">
                    {props.errorRemuneration}

                </div>
                </div>

            </div>

            <div className='row mt-2 ml-3'>
                
                <div className='col-md-5'>
                    <TextArea
                        rows='3'
                        label='Descrição'
                        value={props.descricao}
                        maxLength='254'
                        onChange={props.changeDescription}
                        disabled={props.isDisabled}
                    />
                    {props.error ? (<p style={{ color: 'red' }}>{props.error.description}</p>) : ('')}
                </div>

                <div className="col-md-3">
                    <NumberFormat   
                        icon="fas fa-dollar-sign"
                        label="Valor"
                        name="valor" 
                        fixedDecimalScale={true}
                        decimalScale={2}
                        thousandSeparator={"."} 
                        decimalSeparator={","}
                        placeholder=""
                        customInput={Input}
                        onChange={props.changeValue} 
                        value={props.value_technical}
                        disabled={props.isDisabled || props.isDisabledValor}                          
                    />
                    {props.errorValueTechnical}
                </div>
                {props.isVisible &&
                    <div className="col-md-3">
                        <Input 
                            icon="fas fa-calendar-alt" 
                            label="Data fim da alocação" 
                            onChange={props.changeDataFimAlocacao} 
                            type="date" 
                            value={props.dataFimAlocacao}
                            min={props.startDate}  
                            placeholder='' 
                            // required={true}
                            disabled={props.isDisabled}
                        />
                        <div className="mt-3">
                            {props.errordataAlocacao}
                        </div>
                    </div>
                }

                <div className="col-md-1">
                    <div className="input-group mb-3" style={{paddingTop:'30px'}}>
                        <button  disabled={props.isDisabled} type="button" className="btn btn-primary" onClick={props.onClick}><span className="fas fa-plus"></span></button>
                    </div>
                </div>    
                    
            </div>
            
                           
        </>    
    )
}


