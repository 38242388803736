import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import Footer from '../../../Footer/Footer'
import { connect, useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { pagination } from '../../../Pagination/paginations'
import Pagination from '../../../Table/Pagination'
import Alert from '../../../Alerts/Alert'
import MyModal from '../../../Modal/MyModal'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import Refunds from './Refunds'
import ReportReimbursement from './ReportReimbursement'
import { CSVLink } from "react-csv"
import { goButton } from './js/goButton'
import CabecalhoNew from '../../../Table/CabecalhoNew'
import Input from '../../../Forms/Input'
import TextArea from '../../../Forms/TextArea'
import Form from '../../../Forms/Form'
import AprovedIcon from '../../../Icons/emojione-v1_ballot-box-bold-check.png'
import ReprovedIcon from '../../../Icons/fluent_document-footer-remove-24-filled.png'
import IconAnalysys from '../../../Icons/cib_anaconda.png'
import IconBilled from '../../../Icons/ic_round-payments.png'
import { 
    search, 
    changePage,
    get, 
    order, 
    change_qtd_per_page, 
    changePesquisa,
    clearPesquisa 
} from './Actions/actions'
import { 
    changeStatus, 
    del_reimbursement, 
    get_refunds, 
    changeStatusAll 
} from '../../../Services/reimbursement'

function GridReimbursement(props) {
    const [ loading, setLoading ]                 = useState(false);
    const [ description, setDescription ]         = useState('');
    const [ modal, setModal ]                     = useState(false);
    const [ modalReproved, setModalReproved ]     = useState(false);
    const [ modalFilter, setModalFilter ]         = useState(false);
    const [ idReimbursement, setIdReimbursement ] = useState('')
    const [ displayLegend, setDisplayLegend ]     = useState(true)
    const [ id, setId ]                           = useState('');
    const [ refunds, setRefunds ]                 = useState([]);
    const [ success, setSuccess ]                 = useState('');
    const [ error, setError ]                     = useState('');
    const [ status_all, setStatusAll ]            = useState([])
    const fileUpload                              = useRef()
    const from                                    = useSelector(state => state.reimbursement.from) 
    const to                                      = useSelector(state => state.reimbursement.to)
    const dispatch                                = useDispatch()
    const headers = [
        {label:'Código do Reembolso',key:'id'}, 
        {label:'ID Projeto',key:'id_projeto'},
        {label:'Código Centro de Custo',key:'cod_centro_custo'},
        {label:'Código do Reembolso W3',key:'codigo_despesa_w3'}, 
        {label:'Tipo do Lançamento',key:'tipo_reembolso'}, 
        {label:'Nome do Técnico',key:'name'}, 
        {label:'Título do Projeto',key:'titulo'},
        {label:'Coordenador',key:'coordenador_name'},
        {label:'Tipo de Reembolso',key:'descTipo'}, 
        {label:'Status',key:'descStatus'},
        {label:'Motivo de Reprovação',key:'motivo_reprovacao'},
        {label:'Descrição',key:'descReembolso'}, 
        {label:'Data de Lançamento',key:'data_lancamento'},
        {label:'Data da Despesa',key:'data_despesa'},
        {label:'KM',key:'km'},
        {label:'Valor',key:'valor'},
        {label:'Percentual',key:'percentual'},
        {label:'Reembolso Compartilhado', key:'id_reembolso_compartilhado'},
    ]

    useEffect(() => {
        getRefunds();
        return () => {
            getRefunds()
        }
    },[])

    //Função que pega todos os registro de reembolso
    const getRefunds = () => {
        props.get();
    }
    
    //Evento que controle estado do modal
    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
        
    }

    //Evento que fecha modal
    const handleCloseExcluir = event => {
        setModal(false);
        setId('');
    }

    //Evento que exclui um registro
    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false)
        
        del_reimbursement(id)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
        
        })
        .then(resp => (
            setTimeout(()=> {
                setSuccess('')
            },4000)

        ))
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (props.get()))
    }

    //Evento do modal de reprovação
    const modalReprovedOn = (id_reimbursement) => {
        setModalReproved(true)
        setIdReimbursement(id_reimbursement)
    }

    //Evento que reprova um lançamento
    const changeStatusReimbursementReproved = (e) => {
        e.preventDefault();
        setModalReproved(false)
        setLoading(true)
        const object = {
            id_status: 3,
            description: description
        }
        changeStatus(idReimbursement,object)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setDescription('')
            setLoading(false)
            setTimeout(()=> {
                setSuccess('')
            },4000)
        
        })
        .catch(e => {
            setLoading(false)
            setDescription('')
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (props.get()))            

    }

    //Evento que altera o status de um lançamento
    const changeStatusReimbursement = (id_reimbursement,id_status) => {
        setLoading(true)
        const object = {
            id_status: parseInt(id_status)
        }
        changeStatus(id_reimbursement,object)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
            setTimeout(()=> {
                setSuccess('')
            },4000)
        
        })
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (props.get()))            
    }

    //Evento que altera o status de lançamentos selecionados
    const changeStatusReimbursementAll = (status) => {
        setLoading(true)
        const object = {
            idsReimbursement: status_all,
            status: status
        }
        changeStatusAll(object,status)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
            setStatusAll([])
            setTimeout(()=> {
                setSuccess('')
            },4000)
        
        })
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (props.get()))            

    }

    //Evento que pega os lancamentos marcados
    const check = event =>{
		let checked = event.target.checked
		if(checked === true){
			setStatusAll(status_all.concat(parseInt(event.target.value)))
		}else{	
            setStatusAll(status_all.filter(desc => parseInt(desc) !== parseInt(event.target.value)))
		}		
	}

    //Evento que gera relatorio csv
    const download = event => {
        setLoading(true);
        get_refunds(props.page,props.qtd_total,props.order_var,props.th,props.pesquisa,props.id_technical,props.id_coordinator,props.id_project,props.id_type,props.id_status,props.from_date,props.to_date)
        .then(resp => {
            setRefunds(resp.data.data.map(desc => ({
                id: desc.id,
                codigo_despesa_w3: desc.codigo_despesa_w3,
                tipo_reembolso: desc.id_reembolso_compartilhado ? 'Reembolso Compartilhado' : 'Reembolso Simples',
                name: (desc.name || desc.tecnico_cobranca_extra),
                coordenador_name: (desc.coordenador_name || desc.coordenador_name_cobranca),
                titulo: (desc.titulo || desc.titulo_projeto_cobranca),
                descTipo: desc.descTipo,
                descStatus: desc.descStatus,
                // valor: `R$${formatReal(parseFloat(desc.valor))}`,
                valor: desc.valor,
                data_despesa: desc.data_despesa,
                data_lancamento: desc.data_lancamento,
                descReembolso: desc.descReembolso,
                km: desc.km,
                motivo_reprovacao: desc.motivo_reprovacao,
                percentual: `${desc.percentual}%`,
                id_reembolso_compartilhado: desc.id_reembolso_compartilhado,
                id_projeto: (desc.id_projeto || desc.id_projeto_cobranca || desc.id_projeto_ensino ),
                cod_centro_custo: (desc.projetos_cod_centro_custo || desc.projetoEnsino_cod_centro_custo || desc.pce_cod_centro_custo),
            })))
            setLoading(false);
        })
        .then(resp => {
            fileUpload.current.link.click()
        })
        .catch( err => {
            setLoading(false)
            setError(err.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        })
    }

    //Função que formata o campo valor
    function formatReal(desc){
        var tmp = `${desc.toFixed(2)}`.replace('.',',');
        if( tmp.length > 6 )
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, ".$1")
        return tmp;
    }

    return (
        <div className="wrapper" style={{backgroundColor: '#E5E5E5, 100%'}}>
            <Header />
            <Sidebar />
                <div className="content-wrapper" style={{backgroundColor: '#E5E5E5, 100%'}}>
                    <section className="content" style={{backgroundColor: '#E5E5E5, 100%'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <MyModal
                                    show={modal}
                                    onHide={handleCloseExcluir}
                                    title="Excluir Reembolso"
                                    content="Tem certeza que deseja excluir o reembolso ?"
                                    cancel={handleCloseExcluir}
                                    del={handleExcluir}     
                                    to="#/"
                                    type="danger"
                                    textButton="Excluir"                   
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <MyModal
                                    show={modalFilter}
                                    onHide={(e) => setModalFilter(false)}
                                    title="Filtrar"
                                    content={
                                        <ReportReimbursement />
                                    }
                                    cancel={function(){
                                        return [
                                            props.clearPesquisa(),
                                            setTimeout(()=>{
                                                setModalFilter(false)
                                            },1000)
                                        ]
                                    }}
                                    del={function(){
                                        return [
                                            props.search(),
                                            setTimeout(()=>{
                                                setModalFilter(false)
                                            },1000)
                                        ]
                                    }} 
                                    to="#/"
                                    type="success"
                                    contentSecondary="Limpar Busca"
                                    textButton="Buscar"                   
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <MyModal
                                    show={modalReproved}
                                    onHide={(e) => setModalReproved(false)}
                                    title="Reprovar Lançamento"
                                    content={
                                        <Form onSubmit={changeStatusReimbursementReproved}>
                                            <TextArea 
                                                label="Motivo" 
                                                maxLength="254"
                                                placeholder="Descreva o motivo da reprovação" 
                                                onChange={(e) => setDescription(e.target.value)} 
                                                value={description} 
                                                required={true} />
                                            <div className="text-right">
                                                <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={(e) => setModalReproved(false)}>Cancelar</button>
                                                <button type="submit" className="btn btn-danger">Reprovar</button>
                                            </div>
                                        </Form>
                                    }
                                    onSubmit={true}
                                    cancel={(e) => setModalReproved(false)}
                                    to="#/"
                                    type="danger"
                                    textButton="Reprovar"                   
                                />
                            </div>
                        </div>
                        {
                            loading === true ? (
                                <Content>
                                    <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                                </Content>
                            
                            ):('')
                        }
                        <div style={{display: loading === true ? ('none'):('block')}}>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <div className="box">
                                        <div className="box-body" style={{background: '#FFFFFF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '15px'}}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h2>Registros de Reembolsos</h2>
                                                </div>
                                                <div className="text-right col-sm-6">
                                                    <button 
                                                        className="btn btn-primary"
                                                        type="button" 
                                                        onClick={(e) => {props.history.push(`/new_reimbursement`);e.preventDefault()}} 
                                                    >Cadastro</button>
                                                </div>
                                            </div>
                                            
                                            {
                                                props.refunds.length >= 0 || props.pesquisa ?
                                                    <CabecalhoNew
                                                        filter={
                                                            <>

                                                                <div className="col-md-3 col-sm-12 mt-3">     
                                                                    <Input 
                                                                        label="De"
                                                                        type="date"
                                                                        icon="fas fa-calendar"
                                                                        onChange={(e) => dispatch({ type: 'changeFromReport', payload:e.target.value })}
                                                                        value={from}
                                                                    />
                                                                </div>
                                                                <div className="col-md-3 col-sm-12 mt-3"> 
                                                                    <Input 
                                                                        label="Até"
                                                                        type="date"
                                                                        icon="fas fa-calendar"
                                                                        onChange={(e) => dispatch({ type: 'changeToReport', payload:e.target.value })}
                                                                        value={to}
                                                                    />
                                                                </div> 
                                                            </>
                                                        } 
                                                        buttonStatus={
                                                            goButton(props.refunds, status_all, changeStatusReimbursementAll,props.perfis)
                                                        }
                                                        buttonCsv={
                                                            <>
                                                                <button style={{background: '#005F31', color: '#FFF', borderRadius: '20.5px', width: '140px'}} className="btn btn-sm" onClick={download}>
                                                                    <i className="fas fa-file-csv"></i> Exportar CSV
                                                                </button>
                                                                <button style={{background: '#1A170E', color: '#FFF', borderRadius: '20.5px', width: '140px', marginLeft: '10px'}} className="btn btn-sm" onClick={() => setModalFilter(modalFilter === false ? true : false)}>
                                                                    <i className="fas fa-filter"></i> Mais Filtros
                                                                </button>
                                                                <CSVLink
                                                                    filename={`Relatorio-${new Date()}.csv`}
                                                                    headers={headers}
                                                                    hidden={true}
                                                                    separator={";"}
                                                                    ref={fileUpload}
                                                                    data={refunds || []}
                                                                >
                                                                    <span className="fas fa-file-csv"> Exportar CSV</span>
                                                                </CSVLink>
                                                            </>
                                                        }
                                                        to="#/" 
                                                        action={props.search} 
                                                        clear={props.clearPesquisa} 
                                                        valuePesquisa={props.pesquisa} 
                                                        value={props.qtd_per_page} 
                                                        onChange={props.change_qtd_per_page} 
                                                        changePesquisa={props.changePesquisa} 
                                                    />
                                                :''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div style={{display: `${displayLegend === true ? 'none' : 'block'}`}} className="text-right">
                                    <button onClick={(e) => setDisplayLegend(true)} className="btn btn-secondary" style={{background: 'linear-gradient(99.87deg, #427B67 25.78%, #22AC7A 65.59%)',borderRadius: '20.5px'}}>Legendas</button>
                                </div>
                                <div style={{backgroundColor: '#FFF', padding: '10px', borderRadius:'25px', display: `${displayLegend === false ? 'none' : 'block'}`}}>
                                    <div style={{position: 'relative', float: 'right'}}>
                                        <button onClick={(e) => setDisplayLegend(false)} className="btn"><i className="fas fa-times"></i></button>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-1 mt-1">
                                            <h5 style={{fontSize: '16px'}}>Legendas:</h5>
                                        </div>
                                        <div className="col-md-3 mt-1">
                                            <span><img src={IconAnalysys} width="20px" alt="Analise" /> Em Análise</span>
                                        </div>
                                        <div className="col-md-3 mt-1">
                                            <span><img src={AprovedIcon} width="20px" alt="Aprovar" /> Aprovar</span>
                                        </div>
                                        <div className="col-md-3 mt-1">
                                            <span><img src={ReprovedIcon} width="20px" alt="Reprovar" /> Reprovar</span>
                                        </div>
                                        <div className="col-md-1 mt-1">
                                            <span><img src={IconBilled} width="20px" alt="Faturar" /> Faturar</span>
                                        </div>
                                    </div>
                                </div>
                                {
                                    success ? (
                                        <Alert type="success">{success}</Alert>
                                    ):("")
                                }
                                {
                                    error ? (
                                        <Alert type="danger">{error}</Alert>
                                    ):("")
                                }
                                
                                
                                <div className="row mt-3">
                                    {
                                        parseInt(props.refunds.length) === 0 ? 
                                            <div 
                                                style={{
                                                    color: '#FFFF', 
                                                    textAlign: 'center', 
                                                    width: '100%'
                                                }}
                                            >
                                                <span style={{color: 'black'}}>{`${!props.pesquisa ? 'Não existem reembolsos cadastrados no sistema. Clique no botão Cadastro para inserir um reembolso no Sistema' : 'Nenhum registro encontrado na pesquisa'}`}</span>
                                            </div>
                                        :''

                                    }
                                    <Refunds modalReprovedOn={modalReprovedOn} history={props.history} check={check} changeStatusReimbursement={changeStatusReimbursement} formatReal={formatReal} refunds={props.refunds} onDelete={onChangeModal} />
                                </div>
                            </div>
                            {
                                props.refunds.length > 0 && (
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <div className="box">
                                                <div className="box-body" style={{background: '#FFFFFF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '15px'}}>
                                                    <div className="row mt-4">
                                                        <div className="col-md-1">
                                                        
                                                                
                                                            <select  value={props.qtd_per_page} onChange={props.change_qtd_per_page} name="example1_length" aria-controls="example1" className="custom-select custom-select-sm form-control form-control-sm">
                                                                <option value={8}>8</option>
                                                                <option value={24}>24</option>
                                                                <option value={48}>48</option>
                                                                <option value={96}>96</option>
                                                            </select> 
                                                            
                                                        
                                                        </div>
                                                        <div className="col-md-5 mt-1 mb-2">
                                                            Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                                                        </div>
                                                        <div className="col-md-6 col-sm-12">
                                                            <div style={{float: 'right'}}>

                                                                <Pagination>{props.pagination(props.current_page,props.get,props.last_page,props.changePage)}</Pagination>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </section>
                </div>
            <Footer />
        </div>
    )
}

const mapStateToProps = state => ({
    refunds:state.reimbursement.refunds.data || [],
    current_page:state.reimbursement.refunds.current_page,
    last_page:state.reimbursement.refunds.last_page,
    from:state.reimbursement.refunds.from,
    to:state.reimbursement.refunds.to,
    qtd_total:state.reimbursement.refunds.total,
    qtd_per_page:state.reimbursement.qtd_per_page,
    id_role:state.auth.user.id_role,
    asc:state.reimbursement.asc,
    th:state.reimbursement.th,
    pesquisa: state.reimbursement.pesquisa || '',
    id_technical: state.reimbursement.technical_report.value || '',
    id_coordinator: state.reimbursement.coordinator_report.value || '',
    id_project: state.reimbursement.project_report.value || '' ,  
    id_type: state.reimbursement.type_report.value || '',  
    id_status: state.reimbursement.status_report.value || '',
    from_date: state.reimbursement.from,
    to_date: state.reimbursement.to,
    order_var: state.reimbursement.order,
    perfis: state.auth.user.perfis || []


});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
        pagination, 
        get, 
        order, 
        change_qtd_per_page, 
        changePesquisa, 
        clearPesquisa,
        changePage,
        search 
    }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(GridReimbursement)
