import { app } from './Api'

const get_view_reimbursement = async (id) => {
    return app.get(`reimbursement_shared/${id}`)
}

const get_refunds = async (page,qtd_per_page,order,th,pesquisa,id_technical,id_project,id_type,id_status,from,to) => {
    return app.get(`reimbursement_shared?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&id_technical=${id_technical}&id_project=${id_project}&id_type=${id_type}&id_status=${id_status}&from=${from}&to=${to}`)
}

const post_reimbursement = async (data) => {
    return app.post(`reimbursement_shared`, data)
}

const del_reimbursement = async (id) => {
    return app.delete(`reimbursement_shared/${id}`)
}

const changeStatus =  async (id,data) => {
    return app.put(`reimbursement_shared/change_status/${id}`,data)
}

const changeStatusAll =  async (data) => {
    return app.put(`change_status_shared_all`,data)
}

const put_reimbursement = async (id,data) => {
    return app.post(`reimbursement_shared/${id}`,data)
}

const getTechnical = async () => {
    return app.get(`reimbursement_shared/technical/get`)
}

const getProjects = async (id_technician) => {
    return app.get(`reimbursement_shared/projects/get?id_technical=${id_technician}`)
}

const getProjectsReport = async (id_technician) => {
    return app.get(`reimbursement_shared/projects_report/get?id_technical=${id_technician}`)
}

const getTechnicalReport = async (id_project) => {
    return app.get(`reimbursement_shared/technical_report/get?id_project=${id_project}`)
}

const getType = async () => {
    return app.get(`reimbursement_shared/type/get`)
}

const getStatusReport = async () => {
    return app.get(`reimbursement_shared/status_reimbursement/get`)
}

const del_attachment = async (id) => {
    return app.delete(`reimbursement_shared/del_attachment/${id}`)
}


export { changeStatusAll, getStatusReport, getTechnicalReport, getProjectsReport, del_attachment, get_view_reimbursement, get_refunds, changeStatus, post_reimbursement, put_reimbursement, getTechnical, getProjects, getType, del_reimbursement }