import React, { useState, useEffect } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import Footer from '../../../Footer/Footer';
import Table from '../../../Table/Table';
import Thead from '../../../Table/Thead';
import Tbody from '../../../Table/Tbody';
import Tfoot from '../../../Table/Tfoot';
import StylePage from '../../../Style Page/StylePage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pagination } from '../../../Pagination/paginations';
import { Link } from 'react-router-dom';
import Pagination from '../../../Table/Pagination';
import Cabecalho from '../../../Table/Cabecalho';
import Alert from '../../../Alerts/Alert';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import Reasons from './Motives';
import {
    search,
    changePage,
    get,
    order,
    change_qtd_per_page,
    changePesquisa,
    clearPesquisa,
} from './Actions/actions';

function GridMotive(props) {

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const descricao = 'descricao';

    useEffect(() => {
        getMotives();
        return () => {
            getMotives();
        };

    }, []);

    //Função que traz todos cadastrados no banco de dados
    const getMotives = () => {
        props.get();
    };

    return (
        <div className='wrapper'>
            <Header />
            <Sidebar />
            <StylePage>

                {
                    loading === true ? (
                        <Content>
                            <ReactLoading type='spinningBubbles' color='blue' height={200} width={100} />
                        </Content>

                    ) : ('')
                }
                {
                    success ? (
                        <Alert type='success'>{success}</Alert>
                    ) : ('')
                }
                {
                    error ? (
                        <Alert type='danger'>{error}</Alert>
                    ) : ('')
                }

                <div style={{ display: loading === true ? ('none') : ('block') }}>

                    <Table
                        view={
                            <div className='col-sm-12 col-md-5'>
                                <div className='dataTables_info' id='example1_info' role='status' aria-live='polite'>
                                    Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                                </div>
                            </div>
                        }

                        cabecalho={

                            <Cabecalho
                                to='#/'
                                action={props.search}
                                clear={props.clearPesquisa}
                                valuePesquisa={props.pesquisa}
                                value={props.qtd_per_page}
                                onChange={props.change_qtd_per_page}
                                changePesquisa={props.changePesquisa}
                            />

                        }
                        pagination={
                            <Pagination>
                                {
                                    props.pagination(props.current_page, props.get, props.last_page, props.changePage)
                                }
                            </Pagination>
                        }
                        title='Motivos de Encerramento'
                        button={
                            props.perfis.indexOf(1) >= 0 || props.perfis.indexOf(2) >= 0 || props.perfis.indexOf(3) >= 0 || props.perfis.indexOf(6) >= 0 || props.perfis.indexOf(10) >= 0 ? (
                                <Link to='/new_motive' className='btn btn-primary'>Cadastro</Link>
                            ) : ''

                        }>
                        <Thead>
                            <th>
                                MOTIVO DE ENCERRAMENTO
                                <Link
                                    to='/motives'
                                    style={{ float: 'right' }}
                                    onClick={() => props.order(descricao)}
                                >
                                    <i className={`right fas fa-sort-amount-${props.th === 'descricao' ? (props.asc === true ? ('down') : ('up')) : ('down')}-alt`}></i>
                                </Link>
                            </th>
                            <th>AÇÕES</th>
                        </Thead>
                        <Tbody>
                            <Reasons
                                motives={props.motives}
                            />
                        </Tbody>
                        <Tfoot>
                            <th>MOTIVO DE ENCERRAMENTO</th>
                            <th>AÇÕES</th>
                        </Tfoot>
                    </Table>
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}

const mapStateToProps = state => ({
    motives: state.motive.motives.data,
    current_page: state.motive.motives.current_page,
    last_page: state.motive.motives.last_page,
    from: state.motive.motives.from,
    to: state.motive.motives.to,
    qtd_total: state.motive.motives.total,
    qtd_per_page: state.motive.qtd_per_page,
    pesquisa: state.motive.pesquisa,
    asc: state.motive.asc,
    th: state.motive.th,
    perfis: state.auth.user.perfis,
    id_role: state.auth.user.id_role,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({
        pagination,
        get,
        order,
        change_qtd_per_page,
        changePesquisa,
        clearPesquisa,
        changePage,
        search,
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GridMotive);
