import React from 'react'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'
import Input from '../../../Forms/Input'

export default function Pagamentos({pagamentos, editLine, actionClose, handleChange, actionSave, changeStatusPagamento}) {
    const pagamento = pagamentos || []

    return pagamento.map(desc => (
        <tr key={desc.codigo_parcela}>
            <td className="text-center">
                <Input
                    id={desc.codigo_parcela}
                    icon="fas fa-key" 
                    name={desc.codigo_parcela}
                    value={desc.codigo_parcela}
                    disabled={true}
                    onChange={handleChange}
                />
            </td>
            <td className="text-center">
                <Input
                    id="data_vencimento"
                    type="date"
                    name={`${desc.codigo_parcela}-edit`}
                    icon="fas fa-calendar" 
                    defaultValue={desc.data_vencimento}
                    disabled={true}
                    onChange={handleChange}
                />
            </td>
            <td className="text-center">
                <NumberFormat   
                    id="valor_parcela"
                    icon="fas fa-dollar-sign"
                    name={`${desc.codigo_parcela}-edit`}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator={"."} 
                    decimalSeparator={","}
                    placeholder=""
                    customInput={Input}
                    onChange={handleChange}
                    disabled={true}
                    // onChange={(e) => setValorParcela(parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')))} 
                    defaultValue={desc.valor_parcela}
                    // required={true}
                    // disabled={props.match.params.action === 'view' ? true : false}                         
                />
            </td>
            <td className="text-center">
                <Input
                    id="data_faturamento"
                    type="date"
                    name={`${desc.codigo_parcela}-edit`}
                    icon="fas fa-calendar" 
                    defaultValue={desc.data_faturamento}
                    disabled={true}
                    onChange={handleChange}
                    
                />
            </td>
            <td className="text-center">
                <Input
                    id="data_pagamento"
                    type="date"
                    icon="fas fa-calendar" 
                    name={`${desc.codigo_parcela}-edit`}
                    defaultValue={desc.data_pagamento}
                    disabled={true}
                    onChange={handleChange}
                />
            </td>
            <td className="text-center">
                <Link style={{marginRight: '10px', color:parseInt(desc.status_apuracao) === 1 ? '#FFA500' : '#DCDCDC'}} onClick={() => changeStatusPagamento(desc.codigo_parcela,1)} to="#/">
                    <i style={{marginTop: '35px'}} className='fas fa-hourglass-half' />
                </Link>
                <Link style={{marginRight: '10px', color:parseInt(desc.status_apuracao) === 2 ? '#0000FF' : '#DCDCDC'}} onClick={() => changeStatusPagamento(desc.codigo_parcela,2)} to="#/">
                    <i className='fas fa-thumbs-up' />
                </Link>
                <Link style={{marginRight: '10px', color:parseInt(desc.status_apuracao) === 3 ? '#FF0000' : '#DCDCDC'}} onClick={() => changeStatusPagamento(desc.codigo_parcela,3)} to="#/">
                    <i className='fas fa-thumbs-down' />
                </Link>
                <Link style={{color:parseInt(desc.status_apuracao) === 4 ? '#008000' : '#DCDCDC'}} onClick={() => changeStatusPagamento(desc.codigo_parcela,4)} to="#/">
                    <i className='fas fa-check' />
                </Link>
            </td>
            <td className="text-center">
                <Link name={`btn-action`} id={desc.codigo_parcela} style={{marginRight: '10px'}} onClick={() => editLine(desc.codigo_parcela)} to="#/">
                    <i style={{marginTop: '35px'}} className='fas fa-pencil-alt' />
                </Link>
                <Link name={`btn-action-close`} id={desc.codigo_parcela} style={{display: 'none', marginRight: '10px', color: '#FF0000'}} onClick={() => actionClose(desc.codigo_parcela)} to="#/">
                    <i style={{marginTop: '35px'}} className='fas fa-times' />
                </Link>
                <Link name={`btn-action-save`} id={desc.codigo_parcela} style={{display: 'none', color: '#008000'}} onClick={actionSave} to="#/">
                    <i style={{marginTop: '35px'}} className='fas fa-check' />
                </Link>
            </td>
        </tr>
    ))
}
