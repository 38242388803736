const ProjectEnsinoReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'ProjectsEnsino':
            return{...state, projectEnsino:actions.payload}

        case 'GetProjectEnsino_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetProjectEnsino_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_project_ensino':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaProjectEnsino':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaProjectEnsino':
            return{...state, 
                pesquisa:actions.payload,
                type:actions.payload,
                area:actions.payload,
                manager:actions.payload,
                coordinator:actions.payload,
                status:actions.payload,
                cod_centro_custo:actions.payload,
            }
            
        case 'pageInitialEnsino':
            return{...state, page:actions.payload}
        
        case 'changePageProjectEnsino':
            return{...state, page:actions.payload}

        case 'areaProjectEnsino':
            return{...state, area:actions.payload}
        
        case 'managerProjectEnsino':
            return{...state, manager:actions.payload}

        case 'centroCustoProjectEnsino':
            return{...state, cod_centro_custo:actions.payload}
        
        case 'coordenadorProjectEnsino':
            return{...state, coordinator:actions.payload}
        
        case 'statusProjectEnsino':
            return{...state, status:actions.payload}

        

        default:
            return state;
    }
}
export default ProjectEnsinoReducer;