import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard';
import { ButtonAction } from '../../../Button/style';
import AprovedIcon from '../../../Icons/emojione-v1_ballot-box-bold-check.png';
import ReprovedIcon from '../../../Icons/fluent_document-footer-remove-24-filled.png';
import IconAnalysys from '../../../Icons/cib_anaconda.png';
import IconSincronizar from '../../../Icons/sincronizar.png';
import './style.css';

export default function Refunds(props) {

    const registers = useSelector(state => state.reimbursement.refunds.data) || [];
    const id_role = useSelector(state => state.auth.user.id) || '';
    const perfis = useSelector(state => state.auth.user.perfis) || [];
    const status = registers.map(desc => (
        desc.id_status
    ));

    //Função que verifica se o status fitlrado no lançamento é igual para renderizar o check de seleção
    function todosIguaisOuDiferentes(states, id, gerente, coordenador) {
        var filtrado = states.filter(function(elem, pos, arr) {
            return parseInt(arr.indexOf(elem)) === parseInt(pos);
        });

        if (filtrado.length === 1) {
            if (perfis.indexOf(7) >= 0) {
                if (parseInt(id_role) === parseInt(gerente) || parseInt(id_role) === parseInt(coordenador)) {
                    return (
                        <label className='chk'>
                            <input className='input-check' style={{ width: '30px', height: '30px' }} name='checked'
                                   type='checkbox' id='checked' onClick={props.check} value={id} />
                            <span>
                                
                            </span>
                        </label>

                    );
                } else {
                    return (
                        <label className='chk'>
                            <input className='input-check' style={{ width: '30px', height: '30px' }} disabled='true'
                                   type='checkbox' />
                            {/* <input className="input-check" style={{width: '30px', height: '30px'}} name="checked" type="checkbox" id="checked" onClick={props.check} value={id}/> */}
                            <span></span>
                        </label>
                    );

                }
            } else {
                return (
                    <label className='chk'>
                        <input className='input-check' style={{ width: '30px', height: '30px' }} name='checked'
                               type='checkbox' id='checked' onClick={props.check} value={id} />
                        <span></span>
                    </label>
                );

            }
        }
    }

    const reimbursement = props.refunds || [];
    return reimbursement.map(desc => (
        <div key={desc.id} className='col-md-3 mt-4 mt-4'>
            <RecipeReviewCard
                id_status={desc.id_status}
                perfis={perfis}
                title={desc.tecnico_cobranca_extra || desc.name}
                actionRoute={props.action}
                selectStatus={

                    perfis.indexOf(1) >= 0 || perfis.indexOf(2) >= 0 || perfis.indexOf(3) >= 0 || perfis.indexOf(4) >= 0 || perfis.indexOf(6) >= 0 || perfis.indexOf(8) >= 0 || perfis.indexOf(10) >= 0 ? (

                        todosIguaisOuDiferentes(status, desc.id, desc.gerente, desc.coordenador)
                    ) : ''

                }
                rel={
                    <>
                        {
                            desc.id_reembolso_compartilhado ? (
                                <>
                                    <h3 style={{ fontSize: '15px', fontWeight: 700 }}>Comprovantes</h3>
                                    <hr></hr>
                                    {
                                        desc.anexos_compartilhados.map(res => (
                                            <p>
                                                <span style={{ fontWeight: 700, fontSize: '11px' }}>
                                                    <a href={res.link} target='_blank'
                                                       rel='noopener noreferrer'>{res.nome}</a>
                                                </span>
                                            </p>
                                        ))
                                    }
                                </>

                            ) : (

                                <>
                                    <h3 style={{ fontSize: '15px', fontWeight: 700 }}>Comprovantes</h3>
                                    <hr></hr>
                                    {
                                        desc.anexos.map(res => (
                                            <p>
                                                <span style={{ fontWeight: 700, fontSize: '11px' }}>
                                                    <a href={res.link} target='_blank'
                                                       rel='noopener noreferrer'>{res.nome}</a>
                                                </span>
                                            </p>
                                        ))
                                    }
                                </>
                            )
                        }
                        <hr></hr>
                        {desc.id_status === 3 && (
                            <>
                                <h3 style={{ fontSize: '15px', fontWeight: 700 }}>Motivo Reprovação</h3>
                                <hr></hr>
                                <p>{desc.motivo_reprovacao}</p>
                            </>
                        )}
                    </>
                }
                actions={
                    !desc.id_reembolso_compartilhado &&
                    <>
                        <ul className='nav'>
                            <li className='nav-item dropdown'>
                                <a className='nav-link' data-toggle='dropdown' href='#/'>
                                    <i style={{ color: '#FFF', width: '30px', height: '30px' }}
                                       className='fas fa-bars' />
                                </a>
                                <div className='dropdown-menu dropdown-menu-right'>
                                    <Link
                                        to={`/reimbursement/${desc.id}/view`}
                                        className='dropdown-item'
                                    >
                                        <i className='fas fa-eye' /> Detalhes
                                    </Link>
                                    {
                                        desc.id_status === 1 && parseInt(desc.created_by) === parseInt(id_role) && (
                                            <>
                                                <Link
                                                    to={`/reimbursement/${desc.id}/edit`}
                                                    className='dropdown-item'
                                                >
                                                    <i className='fas fa-edit' /> Editar
                                                </Link>
                                                <Link
                                                    to='#/'
                                                    className='dropdown-item'
                                                    onClick={() => props.onDelete(desc.id)}
                                                >
                                                    <i className='fas fa-trash-alt' /> Excluir
                                                </Link>
                                            </>
                                        )
                                    }
                                </div>
                            </li>
                        </ul>
                    </>
                }
                actionsStatus={
                    props.action !== 'view' ?
                        perfis.indexOf(1) >= 0 || perfis.indexOf(12) >= 0 || perfis.indexOf(3) >= 0 || perfis.indexOf(4) >= 0 || perfis.indexOf(6) >= 0 || perfis.indexOf(8) >= 0 || perfis.indexOf(10) >= 0 ?
                            (
                                perfis.indexOf(7) >= 0 ? (
                                    parseInt(id_role) === parseInt(desc.gerente) || parseInt(id_role) === parseInt(desc.coordenador) ? (
                                        desc.id_status === 1 ? (
                                            <>
                                                <ButtonAction backgroundButton='#1F9950'>
                                                    <button
                                                        type='button' tooltip='Aprovar'
                                                        className='btn'
                                                        onClick={() => props.changeStatusReimbursement(desc.id, 2)}
                                                    >
                                                        <img style={{ width: '25px', height: '25px' }} src={AprovedIcon}
                                                             alt='Aprovado'></img>
                                                    </button>
                                                </ButtonAction>
                                                <ButtonAction backgroundButton='#F6525C'>
                                                    <button
                                                        type='button' tooltip='Reprovar'
                                                        className='btn ml-3'
                                                        onClick={() => props.modalReprovedOn(desc.id, 3)}
                                                    >
                                                        <img style={{ width: '25px', height: '25px' }}
                                                             src={ReprovedIcon} alt='Reprovado'></img>
                                                    </button>
                                                </ButtonAction>
                                            </>

                                        ) : desc.id_status === 2 ? (

                                            <>
                                                <ButtonAction backgroundButton='#F0E70C'>

                                                    <button
                                                        type='button'
                                                        className='btn' tooltip='Em análise'
                                                        onClick={() => props.changeStatusReimbursement(desc.id, 1)}
                                                    >
                                                        <img style={{ width: '25px', height: '25px' }}
                                                             src={IconAnalysys} alt='Analise'></img>
                                                    </button>
                                                </ButtonAction>
                                                <ButtonAction backgroundButton='#F6525C'>

                                                    <button
                                                        type='button' tooltip='Reprovar'
                                                        className='btn ml-3'
                                                        onClick={() => props.modalReprovedOn(desc.id, 3)}
                                                    >
                                                        <img style={{ width: '25px', height: '25px' }}
                                                             src={ReprovedIcon} alt='Reprovado'></img>
                                                    </button>
                                                </ButtonAction>
                                            </>

                                        ) : desc.id_status === 3 ? (

                                            <>
                                                <ButtonAction backgroundButton='#F0E70C'>

                                                    <button
                                                        type='button'
                                                        className='btn'
                                                        onClick={() => props.changeStatusReimbursement(desc.id, 1)}
                                                    >
                                                        <img style={{ width: '25px', height: '25px' }}
                                                             src={IconAnalysys} alt='Analise'></img>
                                                    </button>
                                                </ButtonAction>
                                                <ButtonAction backgroundButton='#1F9950'>

                                                    <button
                                                        type='button'
                                                        className='btn ml-3'
                                                        onClick={() => props.changeStatusReimbursement(desc.id, 2)}
                                                    >
                                                        <img style={{ width: '25px', height: '25px' }} src={AprovedIcon}
                                                             alt='Aprovado'></img>
                                                    </button>
                                                </ButtonAction>
                                            </>

                                        ) : ('')
                                    ) : ('')
                                ) : desc.id_status === 1 ? (

                                    <>
                                        <ButtonAction backgroundButton='#1F9950'>

                                            <button
                                                type='button' tooltip='Aprovar'
                                                className='btn'
                                                onClick={() => props.changeStatusReimbursement(desc.id, 2)}
                                            >
                                                <img style={{ width: '25px', height: '25px' }} src={AprovedIcon}
                                                     alt='Aprovado'></img>
                                            </button>
                                        </ButtonAction>
                                        <ButtonAction backgroundButton='#F6525C'>

                                            <button
                                                type='button' tooltip='Reprovar'
                                                className='btn ml-3'
                                                onClick={() => props.modalReprovedOn(desc.id, 3)}
                                            >
                                                <img style={{ width: '25px', height: '25px' }} src={ReprovedIcon}
                                                     alt='Reprovado'></img>
                                            </button>
                                        </ButtonAction>
                                    </>

                                ) : desc.id_status === 2 ? (

                                    <>
                                        <ButtonAction backgroundButton='#F0E70C'>

                                            <button
                                                type='button' tooltip='Em análise'
                                                className='btn'
                                                onClick={() => props.changeStatusReimbursement(desc.id, 1)}
                                            >
                                                <img style={{ width: '25px', height: '25px' }} src={IconAnalysys}
                                                     alt='Analise'></img>
                                            </button>
                                        </ButtonAction>
                                        <ButtonAction backgroundButton='#F6525C'>

                                            <button
                                                type='button' tooltip='Reprovar'
                                                className='btn ml-3'
                                                onClick={() => props.modalReprovedOn(desc.id, 3)}
                                            >
                                                <img style={{ width: '25px', height: '25px' }} src={ReprovedIcon}
                                                     alt='Reprovado'></img>
                                            </button>
                                        </ButtonAction>

                                        {perfis.indexOf(1) >= 0 || perfis.indexOf(2) >= 0 || perfis.indexOf(3) >= 0 || perfis.indexOf(6) >= 0 || perfis.indexOf(10) >= 0 ? (
                                            <ButtonAction>
                                                <button
                                                    type='button' tooltip='Integrar ao W3'
                                                    className='btn ml-3'
                                                    onClick={() => props.changeStatusReimbursement(desc.id, 5)}
                                                >
                                                    <img style={{ width: '25px', height: '25px' }} src={IconSincronizar}
                                                         alt='Sincronizar'></img>
                                                </button>
                                            </ButtonAction>
                                        ) : ''}
                                    </>
                                ) : desc.id_status === 3 ? (
                                    <>
                                        <ButtonAction backgroundButton='#F0E70C'>
                                            <button
                                                type='button'
                                                className='btn'
                                                onClick={() => props.changeStatusReimbursement(desc.id, 1)}
                                            >
                                                <img style={{ width: '25px', height: '25px' }} src={IconAnalysys}
                                                     alt='Analise'></img>
                                            </button>
                                        </ButtonAction>
                                        <ButtonAction backgroundButton='#1F9950'>
                                            <button
                                                type='button'
                                                className='btn ml-3'
                                                onClick={() => props.changeStatusReimbursement(desc.id, 2)}
                                            >
                                                <img style={{ width: '25px', height: '25px' }} src={AprovedIcon}
                                                     alt='Aprovado'></img>
                                            </button>
                                        </ButtonAction>
                                    </>
                                ) : ('')
                            ) : ''
                        : ''}
            >
                <p style={{ height: '40px' }}>
                <span style={{ height: '50px', fontWeight: 700 }}>
                        {desc.id_cobranca_extra ?
                            <>
                                <span>Serviço Extra:</span>
                                <a href='#/' onClick={(e) => {
                                    props.history.push(`/extra_charge/${desc.id_cobranca_extra}/view`);
                                    e.preventDefault();
                                }}>{desc.cobranca_extra}</a>
                            </>
                            :
                            <>
                                <span>Projeto:</span>
                                <Link to={desc.id_projeto ? `/project/${desc.id_projeto}/view` : `/project_ensino/${desc.id_projeto_ensino}/view`}>{desc.titulo}</Link>
                            </>
                        }
                    {/* Projeto: <a href="#/" onClick={(e) => {props.history.push(`/project/${desc.id_cobranca_extra ? desc.id_projeto_cobranca : desc.id_projeto}/view`);e.preventDefault()}}>{desc.titulo || desc.titulo_projeto_cobranca}</a> */}
                    </span>
                    {/* <span style={{height: '50px', fontWeight: 700}}> 
                        Projeto: <a href="#/" onClick={(e) => {props.history.push(`/project/${desc.id_projeto}/view`);e.preventDefault()}}>{desc.titulo}</a>
                    </span> */}

                </p>
                <p>
                    {
                        desc.id_reembolso_compartilhado ?
                            <>
                                <span style={{ fontWeight: 700 }}>
                                    Tipo de Reembolso: <a href='#/' onClick={(e) => {
                                    props.history.push(`/reimbursement_shared/${desc.id_reembolso_compartilhado}/view`);
                                    e.preventDefault();
                                }}>Reembolso Compartilhado</a>
                                </span>
                            </>
                            : <><span style={{ height: '40px', fontWeight: 700 }}>Tipo de Despesa :</span> <span>Reembolso Simples</span></>
                    }

                </p>
                {perfis.indexOf(1) >= 0 || perfis.indexOf(2) >= 0 || perfis.indexOf(3) >= 0 || perfis.indexOf(6) >= 0 || perfis.indexOf(10) >= 0 ? (
                    <p><span style={{ height: '40px', fontWeight: 700 }}>Código W3 :</span>
                        <span>{desc.codigo_despesa_w3}</span></p>

                ) : ''

                }
                <p><span style={{ height: '40px', fontWeight: 700 }}>Coordenador :</span>
                    <span>{desc.coordenador_name || desc.coordenador_name_cobranca}</span></p>
                <p><span style={{ height: '40px', fontWeight: 700 }}>Tipo :</span> <span>{desc.descTipo}</span></p>
                {desc.id_tipo === 3 || desc.id_tipo === 11 || desc.id_tipo === 12 ?
                    <p><span style={{ height: '40px', fontWeight: 700 }}>Km :</span> <span>{`${desc.km} km`}</span>
                    </p> :
                    <p><span style={{ height: '40px', fontWeight: 700 }}>Km :</span> <span>Não se aplica</span></p>}
                <p><span style={{ height: '40px', fontWeight: 700 }}>Valor :</span>
                    {/* <span>{`R$${props.formatReal(parseFloat(desc.valor))}`}</span></p> */}
                    <span>{`R$${desc.valor}`}</span></p>
                <p><span style={{ height: '40px', fontWeight: 700 }}>Percentual :</span>
                    <span>{`${props.formatReal(parseFloat(desc.percentual))} %`}</span></p>
                <p><span style={{ height: '40px', fontWeight: 700 }}>Data de Lançamento :</span>
                    <span>{desc.data_lancamento}</span></p>
                <p><span style={{ height: '40px', fontWeight: 700 }}>Data da Despesa :</span>
                    <span>{desc.data_despesa}</span></p>
            </RecipeReviewCard>
        </div>

    ));

}
