import { app } from './Api'

const get_view_visit = async (id) => {
    return app.get(`visit/${id}`)
}

const get_visits = async (page,qtd_per_page,order,th,pesquisa,id_technical,id_coordinator,id_project,id_remuneration,id_status,from,to) => {
    return app.get(`visit?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&id_technical=${id_technical}&id_coordinator=${id_coordinator}&id_project=${id_project}&id_remuneration=${id_remuneration}&id_status=${id_status}&from=${from}&to=${to}`)
}

const post_visit = async (data) => {
    return app.post(`visit`, data)
}

const del_visit = async (id) => {
    return app.delete(`visit/${id}`)
}

const changeStatus =  async (id,data) => {
    return app.put(`visit/change_status/${id}`,data)
}

const changeStatusAll =  async (data) => {
    return app.put(`change_status_visit_all`,data)
}

const put_visit = async (id,data) => {
    return app.put(`visit/${id}`,data)
}

const getTechnical = async () => {
    return app.get(`visit/technical/get`)
}

const getProjects = async (id_technician,module) => {
    return app.get(`visit/projects/get?id_technical=${id_technician}&typeModule=${module}`)
}

const getExtraCharges = async (idTechnician,idProject) => {
    return app.get(`visit/extra_charges/get?id_project=${idProject || ''}&id_technical=${idTechnician}`)
}

const getRemuneration = async (id_technician,id_project) => {
    return app.get(`visit/remuneration/get?id_technical=${id_technician || ''}&id_project=${id_project || ''}`)
}

const getRemunerationExtraCharge = async (id_technician,id_extra_charge) => {
    return app.get(`visit/remuneration_extra_charge/get?id_technical=${id_technician || ''}&id_project=${id_extra_charge || ''}`)
}

const getProjectsReport = async (id_technician,id_coordinator) => {
    return app.get(`visit/projects_report/get?id_technical=${id_technician}&id_coordinator=${id_coordinator}`)
}

const getTechnicalReport = async (id_project) => {
    return app.get(`visit/technical_report/get?id_project=${id_project}`)
}

const getCoordinatorReport = async () => {
    return app.get(`coordinator_report_visit`)
}

const getStatusReport = async () => {
    return app.get(`visit/status_visit/get`)
}

const getRemunerationReport = async (id_technician,id_project) => {
    return app.get(`visit/remuneration_report/get?id_technical=${id_technician || ''}&id_project=${id_project || ''}`)
}

const exportExcel = async (page,qtd_per_page,order,th,pesquisa,id_technical,id_coordinator,id_project,id_remuneration,id_status,from,to) => {
    return app.get(`visit/export_excel?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&id_technical=${id_technical}&id_coordinator=${id_coordinator}&id_project=${id_project}&id_remuneration=${id_remuneration}&id_status=${id_status}&from=${from}&to=${to}`)
}

export { 
    getExtraCharges, 
    changeStatusAll, 
    getCoordinatorReport, 
    getRemunerationReport, 
    getStatusReport, 
    getProjectsReport, 
    getTechnicalReport, 
    get_view_visit, 
    get_visits, 
    changeStatus, 
    post_visit, 
    put_visit, 
    getTechnical, 
    getProjects, 
    getRemuneration, 
    del_visit,
    getRemunerationExtraCharge,
    exportExcel 
}