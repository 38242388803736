import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Input from '../../../Forms/Input';
import { useSelector, useDispatch } from 'react-redux';
import { getResource } from '../../../Services/salesRecord';

export default function ReportSalesRecord(props) {
    const coordinator_report = useSelector(
        (state) => state.sales_record.coordinator_report
    );
    const area_report = useSelector((state) => state.sales_record.area_report);
    const status_report = useSelector(
        (state) => state.sales_record.status_report
    );
    const project_report = useSelector(
        (state) => state.sales_record.project_report
    );
    const from = useSelector((state) => state.sales_record.from);
    const to = useSelector((state) => state.sales_record.to);
    const dispatch = useDispatch();
    const [area, setArea] = useState([]);
    const [coordinator, setCoordinator] = useState([]);
    const [status, setStatus] = useState([]);
    const [project, setProject] = useState([]);

    useEffect(() => {
        getResourceSalesRecord();
    }, []);

    function getResourceSalesRecord() {
        getResource().then((resp) => {
            setCoordinator([
                { value: '', label: '-- SELECIONE --' },
                ...resp.data.coordinator.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                })),
            ]);
            setArea([
                { value: '', label: '-- SELECIONE --' },
                ...resp.data.area.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                })),
            ]);

            setStatus([
                { value: '', label: '-- SELECIONE --' },
                ...resp.data.status.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                })),
            ]);
            setProject([
                { value: '', label: '-- SELECIONE --' },
                ...resp.data.project.map((desc) => ({
                    value: desc.id,
                    label: desc.title_project,
                })),
            ]);
        });
    }

    return (
        <>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <label>Coordenador</label>
                        <Select
                            value={coordinator_report}
                            label="Single select"
                            options={coordinator}
                            onChange={(e) => {
                                dispatch({
                                    type: 'changeCoordinatorReportSalesRecord',
                                    payload: e,
                                });
                            }}
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <label>Área</label>
                        <Select
                            value={area_report}
                            label="Single select"
                            options={area}
                            onChange={(e) =>
                                dispatch({
                                    type: 'changeAreaReportSalesRecord',
                                    payload: e,
                                })
                            }
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <label>Título do Projeto</label>
                        <Select
                            value={project_report}
                            label="Single select"
                            options={project}
                            onChange={(e) => {
                                dispatch({
                                    type: 'changeProjectReportSalesRecord',
                                    payload: e,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="row mt-3 mb-4">
                    <div className="col-md-12 mt-3">
                        <label>Status</label>
                        <Select
                            value={status_report}
                            label="Single select"
                            options={status}
                            onChange={(e) =>
                                dispatch({
                                    type: 'changeStatusReportSalesRecord',
                                    payload: e,
                                })
                            }
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <Input
                            label="De"
                            type="date"
                            icon="fas fa-calendar"
                            onChange={(e) =>
                                dispatch({
                                    type: 'changeFromReportSalesRecord',
                                    payload: e.target.value,
                                })
                            }
                            value={from}
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <Input
                            label="Até"
                            type="date"
                            icon="fas fa-calendar"
                            onChange={(e) =>
                                dispatch({
                                    type: 'changeToReportSalesRecord',
                                    payload: e.target.value,
                                })
                            }
                            value={to}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
