const ClientReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Customers':
            return{...state, customers:actions.payload}

        case 'GetClient_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetClient_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_client':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaClient':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaClient':
            return{...state, pesquisa:actions.payload}

        case 'changePageClient':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default ClientReducer;