import React, { useState, useEffect } from 'react'
import Form from '../../../Forms/Form'
import Input from '../../../Forms/Input'
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import { useSelector } from 'react-redux'
import { alter_information } from '../../../Services/user'
import Alert from '../../../Alerts/Alert'
import RadioMaterial from '../../../Forms/RadioMaterial'
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';


export default function UserInformation(props) {
    const [ image, setImage ] = useState([])
    const [ name, setName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ success, setSuccess ] = useState('')
    const [ notificacaoEmail, setNotificacaoEmail ] = useState("Não")
    const [ errorSubmit, setErrorSubmit ] = useState('')
    const [ error, setError ] = useState([])
    const photo_perfil = useSelector(state => state.auth.user.foto_perfil) || []
    const myName = useSelector(state => state.auth.user.name) || []
    const myEmail = useSelector(state => state.auth.user.email) || []
    const role = useSelector(state => state.auth.user.perfis)
    const notificacao = useSelector(state => state.auth.user.notificacao)
    

    useEffect(() => {
        setPhoto();
        return () => {

            setPhoto();
        }
    }, [])

    useEffect(() => {
        setMyName();
        return () => {
            setMyName();
        }
    }, [])

    useEffect(() => {
        setMyNotification();
        return () => {
            setMyNotification();
        }
    }, [])


    useEffect(() => {
        setMyEmail();
        return () => {
            setMyEmail();
        }
        
    }, [])


    function setPhoto(){
        setImage(photo_perfil)
    }

    function setMyNotification(){
        setNotificacaoEmail(parseInt(notificacao) === 1 ? "Sim" : 'Não')
    }

    function setMyName(){
        setName(myName)
    }

    function setMyEmail(){
        setEmail(myEmail)
    }

    const handleDrop = dropped => {
        setImage(dropped[0])
    }

    const handleForm = e => {
        e.preventDefault();
        console.log(image)
        const id = window.location.pathname.split('/')[2]
        const data = new FormData();
        let isImage = false
        image.name && image !== photo_perfil && data.append(`image`, image)
        image.name && image !== photo_perfil && (isImage = true)
        
        data.append('name', name)
        data.append('email', email)
        data.append('isImage', isImage)
        data.append('notificacao_email', notificacaoEmail === "Sim" ? 1 : 0)
        data.append("_method", "put");

        alter_information(id,data,{
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(resp => {
            setError('')
            setSuccess(resp.data.success)
            setTimeout(()=> {
                window.location.reload()
            },3000)
        })
        .catch(e => {
            setSuccess('')
            setError(e.response.data.error || [])
            setErrorSubmit(e.response.data.errorSubmit || '')
            setTimeout(()=> {
                setError('')
            },3000)
        })
    }
    return (
        <>
            
            <div>
                {success && <Alert type="success">{success}</Alert>}
                {errorSubmit && <Alert type="danger">{errorSubmit}</Alert>}
            </div>
            <Form onSubmit={handleForm}>
                <div className="row justify-content-center">
                    
                        <Dropzone
                            onDrop={handleDrop}
                            
                            style={{ width: '150px', height: '150px',  BorderStyle:'none', border: 0}}
                            
                        >
                            {({ getRootProps, getInputProps }) => (
                                
                                <div {...getRootProps()}>
                                
                            
                                        <AvatarEditor   
                                            onPositionChange={''}         
                                            disablecanvasrotation
                                            width={150} 
                                            height={150} 
                                            image={image}
                                            borderRadius={100}
                                            border={1}
                                            
                                            style={{
                                                borderRadius: 100,
                                                BorderStyle:'none', 
                                                verticalAlign: 'middle',
                                                cursor: 'pointer',
                                                marginBlockStart: '1em',
                                                marginBlockEnd: '1em',
                                                marginInlineStart: '40px',
                                                marginInlineEnd: '40px',
                                            }}
                                            // scale={1.2}
                                            // className="rounded-circle"
                                        />
                                        <input {...getInputProps()} />
                                    
                                </div>
                            )}
                        </Dropzone>
                    
                </div>
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <Input 
                            label="Nome"
                            type="text"
                            icon="fas fa-user"
                            value={name}
                            onChange={(e)=>setName(e.target.value)}
                        />
                        {error.name && <p style={{color: 'red'}}>{error.name}</p>}

                    </div>
                    <div className="col-md-6 offset-md-3">
                        <Input 
                            label="Email"
                            type="text"
                            icon="fas fa-envelope"
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                            disabled={role.indexOf(1) < 0 && role.indexOf(2) < 0 && role.indexOf(3) < 0  && role.indexOf(6) < 0 ? true : false}
                        />
                        {error.email && <p style={{color: 'red'}}>{error.email}</p>}
                    </div>
                    <div className="col-md-6 offset-md-3 mt-3">
                        <RadioMaterial
                            row 
                            label="Deseja receber notificação de status via email ?"
                            aria-label="notificacaoEmail" 
                            name="notificacaoEmail" 
                            value={notificacaoEmail} 
                            styleLabel={{
                                color: '#BEBEBE',
                                fontSize: '12px',
                                marginBottom: '15px'
                            }} 
                            onChange={(e)=>setNotificacaoEmail(e.target.value)}
                        >
                            <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                            <FormControlLabel value="Não" control={<Radio />} label="Não" />
                        </RadioMaterial>
                    </div>
                    <div className="col-md-6 offset-md-3 mt-3">
                        <button style={{borderRadius: '20px'}} type="submit" className="btn btn-primary btn-block">Salvar</button>
                    </div>  
                </div>
            </Form>
        </>
    )
}
