import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import StylePage from '../../../Style Page/StylePage'
import Form from '../../../Forms/Form'
import Input from '../../../Forms/Input'
import Alert from '../../../Alerts/Alert'
import Footer from '../../../Footer/Footer'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import Select from 'react-select'
import { Link, useHistory } from "react-router-dom"
import { register } from '../../../Services/auth'
import { create_empresa, get_view_empresa, edit_empresa } from '../../../Services/empresa'
import NumberFormat from 'react-number-format'


export default function Empresa(props) {

    let history = useHistory();
    const [ nome, setNome ] = useState('')
    const [ codSenior, setCodSenior ] = useState('')
    const [ error, setError ] = useState([])
    const [ success, setSuccess ] = useState('')
    const [ alert, setAlert ] = useState('')
    const [ loading, setLoading ] = useState(false)


    useEffect(() => {
        viewPerId();
    }, [])


    function viewPerId() {
        props.match.params.action && (
            get_view_empresa(props.match.params.id)
            .then(resp => {
                setNome(resp.data.empresas[0].nome)
                setCodSenior(resp.data.empresas[0].cod_senior)
            })
        )
    }

   
    const changeNome = event => {
        const nome = event.target.value
        setNome(nome)
    }

    const changeCodSenior = event => {
        const cod_senior = event.target.value
        setCodSenior(cod_senior)
    }

    const handleForm=(e)=>{
        e.preventDefault();
        setLoading(true)
        
        const data = {
            nome: nome,
            codSenior: codSenior
        }

        if(props.match.params.action === 'edit'){
            const id = props.match.params.id
            edit_empresa(id,data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.message ? "Os dados fornecidos são inválidos" : '')  
            })           

        }else{
            create_empresa(data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.message ? "Os dados fornecidos são inválidos" : '')  
            })              

        }
    }

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage title={props.match.params.action === 'edit' ? "Edição de Empresa" : props.match.params.action === 'view' ? "Visualização de Empresa" : "Cadastro de Empresa"}>
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('')}
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ):('')}
                <div style={{display: loading === true ? ('none'):('block')}}>
                    <Form onSubmit={handleForm} autoComplete="off">
                        <div className="row">
                            <div className={`col-md-6 ${props.match.params.action === 'edit' || props.match.params.action === 'view' ? 'offset-md-3' : 'offset-md-3'}`}>
                                <NumberFormat  
                                    label="Código Sênior" 
                                    name="code"
                                    icon="fas fa-key" 
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    thousandSeparator={""} 
                                    decimalSeparator={","}
                                    placeholder=""
                                    customInput={Input}
                                    allowNegative={false}
                                    onChange={(e)=>setCodSenior(e.target.value)} 
                                    value={codSenior}
                                    disabled={props.match.params.action === 'view' ? (true):(false)}
                                    required={true} 
                                    maxLength="4"
                                    isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= 9999;}}                         
                                />
                                {error ? (<p style={{color:'red'}}>{error}</p>):('')}
                            </div>
                        </div>
                        <div className="row">
                            <div className={ `${props.match.params.action === 'edit' || props.match.params.action === 'view' ? 'col-md-6 offset-md-3' : 'col-md-6 offset-md-3'}`}>
                                <Input 
                                    label="Nome" 
                                    autocomplete="off"
                                    value={nome} 
                                    onChange={changeNome} 
                                    icon="fas fa-user" 
                                    name="name" 
                                    type="text" 
                                    placeholder="Digite o nome da empresa" 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    maxLength="45"
                                />
                                {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div>   
                        </div>
                        
                        <br></br>
                        <div className="text-right" style={{marginTop: '9%'}}>
                            <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                <Link to={`/empresa/${props.match.params.id}/edit`} className="btn btn-primary">Editar</Link>
                            ) : (
                                <button type="submit" className="btn btn-primary">Salvar</button>                           
                            )}
                        </div>        
                    </Form>
                </div>   
            </StylePage>
            <Footer />
        </div>
    )
}
