import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {Link} from 'react-router-dom'

export default function MyModalImport(props) {
    return(
        <Modal show={props.show} onHide={props.onHide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body> 
                {props.content}
            </Modal.Body>
                {props.savingInProgress !== true &&
                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.cancel}>
                            {props.contentSecondary || 'Cancelar'} 
                        </Button>
                        
                        <Link to={props.to} className={`btn btn-${props.type}`} onClick={props.del}>
                            {props.textButton}
                        </Link>
                    </Modal.Footer>
                }
        </Modal>
    )
}

