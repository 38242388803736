import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import StylePage from '../../../Style Page/StylePage';
import Form from '../../../Forms/Form';
import Input from '../../../Forms/Input';
import Alert from '../../../Alerts/Alert';
import Footer from '../../../Footer/Footer';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import NumberFormat from 'react-number-format';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { post_client, get_view_client, put_client, getResource, getCity, getStateViaCep, getCityViaCep } from '../../../Services/client';
import '../../../../Dependencies/dependencies';
import '../../../../Dependencies/jquery-loader';
import RadioMaterial from '../../../Forms/RadioMaterial';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'react-select';


export default function Client(props) {

    let history = useHistory();
    const selectFarmActivity = useRef(null);
    const selectCountryRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [cpf, setCPF] = useState('');
    const [number, setNumber] = useState('');
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState('');
    const [alert, setAlert] = useState('');
    const [code, setCode] = useState('');
    const [codSenior, setCodSenior] = useState('');
    const [explorationActivity, setExplorationActivity] = useState('CPF');
    const [propertyRegistration, setPropertyRegistration] = useState('CPF');
    const [generation, setGeneration] = useState(1);
    const [optionsFarmActivity, setOptionsFarmActivity] = useState([]);
    const [farmActivity, setFarmActivity] = useState('');
    const [idCountry, setIdCountry] = useState({ value: 23, label: 'Brasil' });
    const [country, setCountry] = useState([]);
    const perfis = useSelector(state => state.auth.user.perfis);

    const [ inscricao_estadual, setInscricaoEstadual] = useState("");   
    const [ cep, setCep] = useState("");   
    const [ logradouro, setLogradouro] = useState("");      
    const [ numero, setNumero] = useState("");      
    const [ complemento, setComplemento] = useState("");      
    const [ bairro, setBairro] = useState("");      
    const [ id_city, setIdCity] = useState("");   
    const [ id_state, setIdState] = useState("");  
    const selectStateRef                = useRef(null)
	const selectCityRef                 = useRef(null)
    const [ optionsState, setOptionsState ]  = useState([])
    const [ optionsCity, setOptionsCity ]  = useState([])



    function buscarCep() {
        if(cep.length != 8) {
            setAlert("CEP com formato inválido!");
            setTimeout(()=> {
                setAlert('');
            },4000)
        } else {
            fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then((res) => res.json())
            .then((data) => {
                if(data.erro)
                {
                   setAlert("Não foi possível encontrar o CEP")
                   setTimeout(()=> {
                        setAlert('');
                    },4000)
                }
                else
                {
                    // setAlert(data.uf)
                    //  this.state.uf = data.uf;
                    //  this.state.cidade = data.localidade;
                    //  this.state.uf = data.uf;
                    getStateViaCep(data.uf)
                    .then(resp => {
                        setIdState({value:resp.data.id, label:resp.data.uf})
                    })
                    .then(() => {
                        getCityViaCep(data.localidade)
                        .then(resp => {
                            setIdCity({value:resp.data.id, label:resp.data.name})
                        })
                    });
                    
                    setBairro(data.bairro);
                    setLogradouro(data.logradouro);
                }

            })
        }
    }
    
    


    useEffect(() => {
        viewPerId();
    }, []);

    useEffect(() => {
        permissionRole();
        return () => {
            permissionRole();
        };
    }, [props.match.params.action]);

    useEffect(() => {
        getActivitys();
        return () => {
            getActivitys();
        };
    }, []);

    useEffect(() => {
		city(id_state.value)
	}, [id_state])

    function viewPerId() {
        props.match.params.action && (
            get_view_client(props.match.params.id)
                .then(resp => {
                    setName(resp.data[0].nome);
                    setEmail(resp.data[0].email);
                    setCPF(resp.data[0].cpf_cnpj);
                    setNumber(resp.data[0].telefone);
                    setCodSenior(resp.data[0].senior_codcli);
                    setCode(resp.data[0].codigo_w3);
                    setExplorationActivity(resp.data[0].exploracao_atividade);
                    setPropertyRegistration(resp.data[0].registro_imovel);
                    setGeneration(resp.data[0].geracao);
                    setIdCountry({ value: resp.data[0].id_pais, label: resp.data[0].pais });
                    setFarmActivity({ value: resp.data[0].id_atividade_fazenda, label: resp.data[0].atividade_fazenda });
                    setInscricaoEstadual(resp.data[0].inscr1icao_estadual);
                    setCep(resp.data[0].cep);
                    setLogradouro(resp.data[0].logradouro);
                    setNumero(resp.data[0].numero);
                    setComplemento(resp.data[0].complemento);
                    setBairro(resp.data[0].bairro);
                    setIdState({value:resp.data[0].id_state, label:resp.data[0].uf});
                    setIdCity({value:resp.data[0].id_city, label:resp.data[0].cityName});
                    // getResource()
                    // .then( res => {
                    //     let array = res.data.filter(desc => parseInt(desc.id) === parseInt(resp.data.id_atividade_fazenda))
                    //     setFarmActivity(res.data.filter(desc => parseInt(desc.id) === parseInt(resp.data.id_atividade_fazenda)).map(des => ({value: des.id, label:des.descricao})))
                    //     setFarmActivity(
                    //         {
                    //             value: array.length > 0 ? array[0].id : '',
                    //             label: array.length > 0 ? array[0].descricao : ''
                    //         }
                    //     )
                    // })

                })
        );
    }

    //Função que verifica se o usuário tem permissão para acessar a edição do registro
    function permissionRole() {
        if (props.match.params.action === 'edit') {
            if (perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) < 0) {
                return props.history.push('/customers');
            }
        }
    }

    function getActivitys() {
        getResource()
            .then(resp => {
                setOptionsFarmActivity(resp.data.farm.map(desc => ({
                    value: desc.id,
                    label: desc.descricao,
                })));
                setCountry(resp.data.country.map(desc => ({
                    value: desc.id,
                    label: desc.description,
                })));
                setOptionsState(resp.data.state.map(desc => ({
                    value: desc.id,
                    label: desc.name
                })))
            });
    }

    //Função que pega o evento de onChange do campo email e salva no state da aplicação
    const changeEmail = event => {
        const email = event.target.value;
        setEmail(email);
    };

    const changeState = e => {
		setIdState(e)
		setIdCity('')

	}

    function city(idState){
		getCity(idState)
		.then(resp => {
			setOptionsCity(resp.data.map(desc => ({
				value: desc.id,
				label: desc.name
			})))
		})
	}

    const changeInscricaoEstadual = event => {
        const inscricao_estadual = event.target.value;

        const re = /^[0-9\b]+$/;

        // regex pra aceitar só números caso valor não seja vazio
        if (inscricao_estadual === '' || re.test(inscricao_estadual)) {
            setInscricaoEstadual(inscricao_estadual);
        }
        
    };

    const changeCep = event => {
        const cep = event.target.value.replace('-', '');

        const re = /^[0-9\b]+$/;

        // regex pra aceitar só números caso valor não seja vazio
        if (cep === '' || re.test(cep)) {
            setCep(cep);
        }
        
    };

    //Função que pega o evento de onChange do campo nome e salva no state da aplicação
    const changeName = event => {
        const name = event.target.value;
        setName(name);
    };
    
    const changeLogradouro = event => {
        const logradouro = event.target.value;
        setLogradouro(logradouro);
    };

    const changeComplemento = event => {
        const complemento = event.target.value;
        setComplemento(complemento);
    };

    const changeBairro = event => {
        const bairro = event.target.value;
        setBairro(bairro);
    };

    //Função que pega o evento de onChange do campo cpf e salva no state da aplicação
    const changeCPF = event => {
        const cpf = event.target.value.replace('.', '').replace('.', '').replace('-', '').replace('/', '');
        setCPF(cpf);
    };

    //Função que pega o evento de onChange do campo telefone e salva no state da aplicação
    const changeNumber = event => {
        const number = event.target.value.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
        setNumber(number);
    };
    //Função que valida realiza put ou post para a API
    const handleForm = (e) => {
        e.preventDefault();
        setLoading(true);

        //verificando se o campo cpf_cnpj contém a quantidade correta de caracteres
        if (parseInt(idCountry.value) === 23 && cpf.length !== 11 && cpf.length !== 14) {
            return [
                setLoading(false),
                setError({ cpf_cnpj: 'O campo cpf_cnpj deve conter um mínimo de 11 ou 14 dígitos' }),
            ];
        }

        if (cep.length !== 8) {
            return [
                setLoading(false),
                setError({ cep: 'O campo cep deve conter 8 digitos' }),
            ];
        }

        // if (parseInt(idCountry.value) === 23 && cpf.length !== 11 && cpf.length !== 14) {
        //     return [
        //         setLoading(false),
        //         setError({ cpf_cnpj: 'O campo cpf_cnpj deve conter um mínimo de 11 ou 14 dígitos' }),
        //     ];
        // }

        //Objeto a ser enviado para a API
        const data = {
            name: name,
            email: email,
            cpf_cnpj: cpf,
            number: number,
            code: code,
            codSenior: codSenior,
            idFarmActivity: parseInt(farmActivity.value),
            explorationActivity: explorationActivity,
            propertyRegistration: propertyRegistration,
            generation: generation,
            idCountry: parseInt(idCountry.value),

            inscricao_estadual: inscricao_estadual,
            cep: cep,
            logradouro: logradouro,
            numero: numero,
            complemento: complemento,
            bairro: bairro,
            id_city:parseInt(id_city.value),
        };

        //Verificando qual a action para definir se será Put ou Post
        if (props.match.params.action === 'edit') {
            const id = props.match.params.id;
            put_client(id, data)
                .then(resp => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then(resp => setTimeout(() => {
                    window.location.reload();
                }, 2500))
                .catch(e => {
                    setLoading(false);
                    setSuccess('');
                    setError(e.response.data.error);
                    setAlert(e.response.data.errorSubmit ?? e.response.data.error);
                    setTimeout(()=> {
                        setError('')
                        setAlert('');
                    },4000)
                });


        } else {
            post_client(data)
                .then(resp => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then(resp => setTimeout(() => {
                    window.location.reload();
                }, 2500))
                .catch(e => {
                    setLoading(false);
                    setSuccess('');
                    setError(e.response.data.error);
                    setAlert(e.response.data.errorSubmit);
                });

        }
    };

    return (
        <div className='wrapper'>
            <Sidebar />
            <Header />
            <StylePage
                title={
                    props.match.params.action === 'edit' ? 'Edição de Cliente' :
                        props.match.params.action === 'view' ? 'Visualização de Cliente' :
                            'Cadastro de Cliente'
                }
            >
                {loading === true ? (
                    <Content>
                        <ReactLoading type='spinningBubbles' color='blue' height={200} width={100} />
                    </Content>

                ) : ('')}
                {alert ? (
                    <Alert type='danger'>
                        {alert}
                    </Alert>
                ) : ('')}
                {success ? (
                    <Alert type='success'>
                        {success}
                    </Alert>
                ) : ('')}
                <div style={{ display: loading === true ? ('none') : ('block') }}>
                    <Form onSubmit={handleForm}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <label>País</label>
                                <Select
                                    value={idCountry}
                                    ref={selectCountryRef}
                                    label='Single select'
                                    options={country}
                                    onChange={(e) => setIdCountry(e)}
                                    isDisabled={props.match.params.action === 'view' ? true : false}

                                />
                                {!idCountry &&
                                    <input
                                        tabIndex={-1}
                                        autoComplete='off'
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={idCountry}
                                        onFocus={() => selectCountryRef.current.focus()}
                                        required={true}
                                    />
                                }
                                {error ? (<p style={{ color: 'red' }}>{error.idCountry}</p>) : ('')}
                            </div>
                            <div className='col-md-6'>
                                <NumberFormat
                                    label='Código W3'
                                    name='code'
                                    icon='fas fa-key'
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    decimalSeparator={','}
                                    placeholder=''
                                    customInput={Input}
                                    allowNegative={false}
                                    onChange={(e) => setCode(e.target.value)}
                                    value={code}
                                    disabled={props.match.params.action === 'view' ? (true) : (false)}
                                    // required={true} 
                                    isAllowed={(values) => {
                                        const { floatValue, formattedValue } = values;
                                        return formattedValue === '' || floatValue <= 999999999;
                                    }}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Input
                                    label='Razão Social'
                                    value={name}
                                    onChange={changeName}
                                    icon='fas fa-envelope'
                                    name='name'
                                    type='text'
                                    placeholder='Digite a razão social do cliente'
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.name}</p>) : ('')}
                            </div>
                            <div className={`col-md-6`}>
                                <NumberFormat
                                    label='Código Sênior'
                                    name='code'
                                    icon='fas fa-key'
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    decimalSeparator={','}
                                    placeholder=''
                                    customInput={Input}
                                    allowNegative={false}
                                    onChange={(e) => setCodSenior(e.target.value)}
                                    value={codSenior}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    // required={true}
                                    maxLength='6'
                                    isAllowed={(values) => {
                                        const { floatValue, formattedValue } = values;
                                        return formattedValue === '' || floatValue <= 999999;
                                    }}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.codSenior}</p>) : ('')}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Input
                                    label='Email'
                                    value={email}
                                    onChange={changeEmail}
                                    icon='fas fa-envelope'
                                    name='email'
                                    type='email'
                                    placeholder='Digite o email do cliente'
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.email}</p>) : ('')}
                            </div>
                            <div className='col-md-6'>
                                <NumberFormat
                                    disabled={props.match.params.action === 'view' ? (true) : (false)}
                                    allowEmptyFormatting={false}
                                    label='Telefone'
                                    mask=''
                                    customInput={Input}
                                    onChange={changeNumber}
                                    format={number ? number[2] === '3' || number[2] === '4' || number[2] === '2' ? ('(##) ####-####') : ('(##) #####-####') : ('(##) #####-####')}
                                    icon='fa-phone'
                                    value={number}
                                    name='telefone'
                                    type='text'
                                    placeholder='Digite o telefone do cliente'
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.number}</p>) : ('')}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Input
                                    label='Inscrição Estadual'
                                    value={inscricao_estadual}
                                    onChange={changeInscricaoEstadual}
                                    icon='fa-address-card'
                                    name='inscricao_estadual'
                                    type='text'
                                    required={false}
                                    maxLength='14'
                                    placeholder='Digite a inscrição estadual do cliente'
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.incricao_estadual}</p>) : ('')}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <NumberFormat
                                    disabled={props.match.params.action === 'view' ? (true) : (false)}
                                    allowEmptyFormatting={false}
                                    icon='fa-address-card'
                                    mask=''
                                    placeholder='Digite o CPF do cliente'
                                    label='CPF / CNPJ'
                                    customInput={Input}
                                    value={cpf}
                                    onChange={changeCPF}
                                    required={idCountry.value === 23 ? true : false}
                                    format={cpf && cpf.length <= 11 ? '###.###.###-###' : '##.###.###/####-##'}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.cpf_cnpj}</p>) : ('')}
                            </div>
                            <div className='col-md-6'>
                                <label>Principal Atividade</label>
                                <Select
                                    value={farmActivity}
                                    ref={selectFarmActivity}
                                    label='Single select'
                                    options={optionsFarmActivity}
                                    onChange={(e) => setFarmActivity(e)}
                                    isDisabled={props.match.params.action === 'view' ? true : false}

                                />
                                {!farmActivity &&
                                    <input
                                        tabIndex={-1}
                                        autoComplete='off'
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={farmActivity}
                                        onFocus={() => selectFarmActivity.current.focus()}
                                        required={true}
                                    />
                                }
                                {error ? (<p style={{ color: 'red' }}>{error.idFarmActivity}</p>) : ('')}
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-2'>
                                <NumberFormat
                                    disabled={props.match.params.action === 'view' ? (true) : (false)}
                                    allowEmptyFormatting={false}
                                    icon='fa-address-card'
                                    mask=''
                                    placeholder='Digite o CEP do cliente'
                                    label='CEP'
                                    customInput={Input}
                                    value={cep}
                                    onChange={changeCep}
                                    required={true}
                                    format={'#####-###'}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.cep}</p>) : ('')}
                            </div>
                            <div className='col-md-1'>
                                <button style={{ marginTop: '29px' }} type='button' className='btn btn-secondary'
                                        onClick={() => buscarCep()}>Buscar
                                </button>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Input
                                    label='Logradouro'
                                    value={logradouro}
                                    onChange={changeLogradouro}
                                    icon='fa-address-card'
                                    name='logradouro'
                                    type='text'
                                    required={true}
                                    maxLength='100'
                                    placeholder='Digite o logradouro do cliente'
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.logradouro}</p>) : ('')}
                            </div>
                            <div className='col-md-2'>
                                <NumberFormat
                                    label='N°'
                                    name='numero'
                                    icon='fa-address-card'
                                    fixedDecimalScale={false}
                                    decimalScale={0}
                                    // thousandSeparator={''}
                                    // decimalSeparator={','}
                                    placeholder=''
                                    customInput={Input}
                                    allowNegative={false}
                                    onChange={(e) => setNumero(e.target.value)}
                                    value={numero}
                                    disabled={props.match.params.action === 'view' ? (true) : (false)}
                                    required={true} 
                                    isAllowed={(values) => {
                                        const { floatValue, formattedValue } = values;
                                        return formattedValue === '' || floatValue <= 999999;
                                    }}
                                />
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Input
                                    label='Complemento'
                                    value={complemento}
                                    onChange={changeComplemento}
                                    icon='fa-address-card'
                                    name='complemento'
                                    type='text'
                                    required={false}
                                    maxLength='15'
                                    placeholder='Digite o complemento do cliente'
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.complemento}</p>) : ('')}
                            </div>
                            <div className='col-md-6'>
                                <Input
                                    label='Bairro'
                                    value={bairro}
                                    onChange={changeBairro}
                                    icon='fa-address-card'
                                    name='bairro'
                                    type='text'
                                    required={true}
                                    maxLength='100'
                                    placeholder='Digite o bairro do cliente'
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.bairro}</p>) : ('')}
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Estado *</label>
                                <Select 
                                    required={true}
                                    value={id_state}
                                    ref={selectStateRef}
                                    isDisabled={props.match.params.action === 'view' ? true : false}
                                    options={optionsState}
                                    onChange={changeState}
                                /> 
                                {!id_state && 
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={id_state}
                                        onFocus={() => selectStateRef.current.focus()}
                                        required={true}
                                    />
                                } 
                            </div>
                            <div className="col-md-6">
                                <label>Cidade *</label>
                                <Select 
                                    required={true}
                                    value={id_city}
                                    isDisabled={props.match.params.action === 'view' ? true : false}
                                    ref={selectCityRef}
                                    options={optionsCity}
                                    onChange={ (e) => setIdCity(e) }
                                /> 
                                {!id_city && 
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={id_city}
                                        onFocus={() => selectCityRef.current.focus()}
                                        required={true}
                                    />
                                } 
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-4'>
                                <RadioMaterial
                                    row
                                    disabled={props.match.params.action === 'view' ? (true) : (false)}
                                    label='Exploração da Atividade'
                                    aria-label='explorationActivity'
                                    name='explorationActivity'
                                    value={explorationActivity}
                                    styleLabel={{
                                        color: '#BEBEBE',
                                        fontSize: '12px',
                                        marginBottom: '15px',
                                    }}
                                    onChange={(e) => setExplorationActivity(e.target.value)}
                                >
                                    <FormControlLabel value='CPF' control={<Radio />} label='CPF' />
                                    <FormControlLabel value='CNPJ' control={<Radio />} label='CNPJ' />
                                </RadioMaterial>
                                {error ? (<p style={{ color: 'red' }}>{error.explorationActivity}</p>) : ('')}
                            </div>
                            <div className='col-md-4'>
                                <RadioMaterial
                                    row
                                    disabled={props.match.params.action === 'view' ? (true) : (false)}
                                    label='Imóveis Rurais Registrados Em :'
                                    aria-label='propertyRegistration'
                                    name='propertyRegistration'
                                    value={propertyRegistration}
                                    styleLabel={{
                                        color: '#BEBEBE',
                                        fontSize: '12px',
                                        marginBottom: '15px',
                                    }}
                                    onChange={(e) => setPropertyRegistration(e.target.value)}
                                >
                                    <FormControlLabel value='CPF' control={<Radio />} label='CPF' />
                                    <FormControlLabel value='CNPJ' control={<Radio />} label='CNPJ' />
                                </RadioMaterial>
                                {error ? (<p style={{ color: 'red' }}>{error.propertyRegistration}</p>) : ('')}
                            </div>
                            <div className='col-md-4'>
                                <RadioMaterial
                                    row
                                    disabled={props.match.params.action === 'view' ? (true) : (false)}
                                    label='Quantidade de Gerações Atuantes'
                                    aria-label='generation'
                                    name='generation'
                                    value={generation}
                                    styleLabel={{
                                        color: '#BEBEBE',
                                        fontSize: '12px',
                                        marginBottom: '15px',
                                    }}
                                    onChange={(e) => setGeneration(parseInt(e.target.value))}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label='1' />
                                    <FormControlLabel value={2} control={<Radio />} label='2' />
                                    <FormControlLabel value={3} control={<Radio />} label='3' />
                                    <FormControlLabel value={4} control={<Radio />} label='4' />
                                </RadioMaterial>
                                {error ? (<p style={{ color: 'red' }}>{error.generation}</p>) : ('')}
                            </div>
                        </div>
                        <br></br>
                        <div className='text-right'>
                            <button style={{ marginRight: '10px' }} type='button' className='btn btn-secondary'
                                    onClick={() => history.goBack()}>Voltar
                            </button>
                            {props.match.params.action === 'view' ? (
                                <Link
                                    style={{
                                        cursor: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) < 0 ? 'not-allowed' : '',
                                        color: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) < 0 ? '#fff' : '',
                                        backgroundColor: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) < 0 ? '#007bff' : '',
                                        borderColor: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) < 0 ? '#007bff' : '',
                                        opacity: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) < 0 ? .65 : '',
                                        boxShadow: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) < 0 ? 'none' : '',

                                    }}
                                    onClick={perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) < 0 ? e => e.preventDefault() : ''}
                                    to={`/client/${props.match.params.id}/edit`}
                                    className='btn btn-primary'>Editar</Link>
                            ) : (
                                <button disabled={props.match.params.action === 'view' ? true : false} type='submit'
                                        className='btn btn-primary'>Salvar</button>
                            )}
                        </div>
                    </Form>
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}
