const MotiveReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Motives':
            return{...state, motives:actions.payload}

        case 'GetMotive_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetMotive_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_motive':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaMotive':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaMotive':
            return{...state, pesquisa:actions.payload}

        case 'changePageMotive':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default MotiveReducer;