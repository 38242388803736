import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Box from '@material-ui/core/Box';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import Project from './Project';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import StylePage from '../../../Style Page/StylePage';
import Footer from '../../../Footer/Footer';
import TablePagamento from './TablePagamento';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>
                        <Typography component={'span'}>{children}</Typography>
                    </div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function TabsProject(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    console.log(props);
    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage>
                <div className={classes.root}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="scrollable force tabs example"
                        >
                            <Tab
                                label="Informações Projeto"
                                icon={<PersonPinIcon />}
                                {...a11yProps(0)}
                            />
                            <Tab
                                label="Pagamentos"
                                icon={<VpnKeyIcon />}
                                {...a11yProps(1)}
                            />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <Project {...props} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TablePagamento {...props} />
                    </TabPanel>
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}
