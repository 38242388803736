import { getBillsToPay } from "../../../../Services/billsToPay";

//Função que traz os dados do banco de dados
export const get = () => {
    return [
        (dispatch, getState) => {
            const competencia = getState().bills_to_pay.competencia;
            const status = getState().bills_to_pay.status_report.value;
            const technical = getState().bills_to_pay.technical.value;
            const typeBill = getState().bills_to_pay.typeBill.value;
            const projectBill = getState().bills_to_pay.projectBill.value;
            const typeProjects = getState().bills_to_pay.typeProjects.value;
            const pesquisa = getState().bills_to_pay.pesquisa;
            
            getBillsToPay(
                competencia,
                status,
                technical,
                pesquisa,
                typeBill,
                projectBill,
                typeProjects
            )
                .then((resp) =>{
                    dispatch({ type: "BillsToPay", payload: [] })
                    dispatch({ type: "BillsToPay", payload: resp.data.consultoria.concat(resp.data.ensino).concat(resp.data.complementar) })
                }
                )
                .then(() => {
                    let valorTotal = 0.0;
                    let valorTotalContabil = 0.0;
                    let valorTotalCorrecao = 0.0;
                    const billsNow = getState().bills_to_pay.bills_to_pay;
                    if (billsNow) {
                        for (let count = 0; count < billsNow.length; count++) {
                            valorTotal = valorTotal + billsNow[count].valor;
                            valorTotalContabil = valorTotalContabil + billsNow[count].valor_contabil;
                            valorTotalCorrecao = valorTotalCorrecao + billsNow[count].variavel_correcao;
                        }
                        // console.log(valorTotal);
                    }
                    dispatch({
                        type: "ValorTotalUpdate",
                        payload: valorTotal,
                    });
                    dispatch({
                        type: "ValorTotalContabilUpdate",
                        payload: valorTotalContabil,
                    });
                    dispatch({
                        type: "ValorTotalCorrecaoUpdate",
                        payload: valorTotalCorrecao,
                    });
                });
        },
    ];
};

//Função que ordena as colunas
export const order = (coluna) => {
    return [
        (dispatch, getState) => {
            const asc = getState().bills_to_pay.asc;

            if (asc === true) {
                dispatch({
                    type: "GetBillsToPay_Desc",
                    desc: true,
                    asc: false,
                    order: "desc",
                    th: coluna,
                });
            }
            if (asc === false) {
                dispatch({
                    type: "GetBillsToPay_Asc",
                    asc: true,
                    desc: false,
                    order: "asc",
                    th: coluna,
                });
            }
        },
        get(1),
    ];
};

export const change_qtd_per_page = (event) => {
    return [
        {
            type: "qtd_per_page_BillsToPay",
            payload: event.target.value,
        },
        get(1),
    ];
};

export const changePesquisa = (event) => {
    return [
        {
            type: "pesquisaBillsToPay",
            payload: event.target.value,
        },
    ];
};

export const clearPesquisa = (event) => {
    return [
        (dispatch, getState) => {
            const fromFix = getState().bills_to_pay.fromFix;
            const toFix = getState().bills_to_pay.toFix;
            dispatch({
                type: "clearPesquisaBillsToPay",
                payload: "",
                coordinator_report: "",
                area_report: "",
                status_report: "",
                technical: "",
                typeBill: "",
                projectBill: "",
                typeProjects: "",
                from: fromFix,
                to: toFix,
            });
        },
        get(1),
    ];
};

export const changePage = (page) => {
    return [
        {
            type: "changePageBillsToPay",
            payload: page,
        },
        get(),
    ];
};

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().bills_to_pay.pesquisa || "";
            const id_coordinator =
                getState().bills_to_pay.coordinator_report.value || "";
            const id_status = getState().bills_to_pay.status_report.value || "";
            const from = getState().bills_to_pay.from;
            const to = getState().bills_to_pay.to;
            if (pesquisa || id_coordinator || id_status || from || to) {
                return dispatch({ type: "changePageBillsToPay", payload: 1 });
            }
        },
        get(),
    ];
};
