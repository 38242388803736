import React from 'react'
import Table from '../../../Table/Table'
import Thead from '../../../Table/Thead'
import Tbody from '../../../Table/Tbody'

export default function TableProdutivity(props) {
    return (
        <Table 
            className="table" 
            title={props.title}
        >
            <Thead>
                <th className="text-center">CULTURA</th>
                <th className="text-center">QUANTIDADE</th>
                <th className="text-center">AÇÃO</th>
            </Thead>
            <Tbody> 
                {props.children}
            </Tbody>
            
        </Table>
    )
}