import { app } from './Api'

const get_view_area = async (id) => {
    return app.get(`area/${id}`)
}

const get_resource = async () => {
    return app.get(`get_resource_area`)
}

const get_areas = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`area?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const edit_area = async (id,data) => {
    return app.put(`area/${id}`,data)
}

const create_area = async (data) => {
    return app.post(`area`,data)
}

const changeStatus =  async (id,data) => {
    return app.put(`area/change_status/${id}`,data)
}



export { get_view_area, get_areas, edit_area, create_area, get_resource, changeStatus }