import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import StylePage from '../../../Style Page/StylePage';
import Form from '../../../Forms/Form';
import Input from '../../../Forms/Input';
import Alert from '../../../Alerts/Alert';
import Footer from '../../../Footer/Footer';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import NumberFormat from 'react-number-format';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { post_filial, get_view_filial, put_filial, getResource } from '../../../Services/filial';
import '../../../../Dependencies/dependencies';
import '../../../../Dependencies/jquery-loader';
import Select from 'react-select';


export default function Filial(props) {

    let history = useHistory();
    const selectCountryRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [cnpj, setCNPJ] = useState('');
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState('');
    const [alert, setAlert] = useState('');
    const [codeSenior, setCodeSenior] = useState('');
    const [idEmpresas, setIdEmpresas] = useState({});
    const [empresas, setEmpresas] = useState([]);
    const perfis = useSelector(state => state.auth.user.perfis);


    useEffect(() => {
        viewPerId();
    }, []);

    useEffect(() => {
        permissionRole();
        return () => {
            permissionRole();
        };
    }, [props.match.params.action]);

    useEffect(() => {
        getActivitys();
        return () => {
            getActivitys();
        };
    }, []);

    function viewPerId() {
        props.match.params.action && (
            get_view_filial(props.match.params.id)
                .then(resp => {
                    setName(resp.data[0].name);
                    setCNPJ(resp.data[0].cnpj);
                    setCodeSenior(resp.data[0].code_filial_senior);
                    setIdEmpresas({ value: resp.data[0].id_empresas, label: resp.data[0].empresas });
                })
        );
    }

    //Função que verifica se o usuário tem permissão para acessar a edição do registro
    function permissionRole() {
        console.log(perfis.indexOf(10));
        if (props.match.params.action === 'edit') {
            if (perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) < 0) {
                return props.history.push('/filiais');
            }
        }
    }

    //Função que retorna as empresas cadastradas
    function getActivitys() {
        getResource()
            .then(resp => {
                setEmpresas(resp.data.empresas.map(desc => ({
                    value: desc.id,
                    label: desc.nome,
                })));
            });
    }

    //Função que pega o evento de onChange do campo code e salva no state da aplicação
    const changeCodeSenior = event => {
        const codeSenior = event.target.value;
        setCodeSenior(codeSenior);
    };

    //Função que pega o evento de onChange do campo nome da filial e salva no state da aplicação
    const changeName = event => {
        const name = event.target.value;
        setName(name);
    };

    //Função que pega o evento de onChange do campo CNPJ e salva no state da aplicação
    const changeCNPJ = event => {
        const cnpj = event.target.value.replace('.', '').replace('.', '').replace('-', '').replace('/', '');
        setCNPJ(cnpj);
    };

    //Função que valida realiza put ou post para a API
    const handleForm = (e) => {
        e.preventDefault();
        setLoading(true);


        //Objeto a ser enviado para a API
        const data = {
            name: name,
            cnpj: cnpj,
            code_filial_senior: codeSenior,
            id_empresas: parseInt(idEmpresas.value),
        };

        //Verificando qual a action para definir se será Put ou Post
        if (props.match.params.action === 'edit') {
            const id = props.match.params.id;
            put_filial(id, data)
                .then(resp => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then(resp => setTimeout(() => {
                    window.location.reload();
                }, 2500))
                .catch(e => {
                    setLoading(false);
                    setSuccess('');
                    setError(e.response.data.error);
                    setAlert(e.response.data.errorSubmit);
                });


        } else {
            post_filial(data)
                .then(resp => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then(resp => setTimeout(() => {
                    window.location.reload();
                }, 2500))
                .catch(e => {
                    setLoading(false);
                    setSuccess('');
                    setError(e.response.data.error);
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        setAlert('');
                    }, 2500);
                });

        }
    };

    return (
        <div className='wrapper'>
            <Sidebar />
            <Header />
            <StylePage
                title={
                    props.match.params.action === 'edit' ? 'Edição de Filial' :
                        props.match.params.action === 'view' ? 'Visualização de Filial' :
                            'Cadastro de Filial'
                }
            >
                {loading === true ? (
                    <Content>
                        <ReactLoading type='spinningBubbles' color='blue' height={200} width={100} />
                    </Content>

                ) : ('')}
                {alert ? (
                    <div>
                        <Alert type='danger' hidden='false'>
                            {alert}
                        </Alert>
                    </div>
                ) : ('')}
                {success ? (
                    <div>
                        <Alert type='success'>
                            {success}
                        </Alert>
                    </div>
                ) : ('')}
                <div style={{ display: loading === true ? ('none') : ('block') }}>
                    <Form onSubmit={handleForm}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Input
                                    label='Nome da Filial'
                                    value={name}
                                    onChange={changeName}
                                    icon='fa-address-card'
                                    name='name'
                                    type='text'
                                    placeholder='Digite o nome da filial'
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.name}</p>) : ('')}
                            </div>
                            <div className='col-md-6'>
                                <NumberFormat
                                    disabled={props.match.params.action === 'view' ? (true) : (false)}
                                    allowEmptyFormatting={false}
                                    icon='fas fa-building'
                                    mask=''
                                    placeholder='Digite o CNPJ da filial'
                                    label='CNPJ'
                                    customInput={Input}
                                    value={cnpj}
                                    onChange={changeCNPJ}
                                    required={true}
                                    format={'##.###.###/####-##'}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.cnpj}</p>) : ('')}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <NumberFormat
                                    label='Código Sênior'
                                    name='code_filial_senior'
                                    icon='fas fa-key'
                                    customInput={Input}
                                    allowNegative={false}
                                    onChange={changeCodeSenior}
                                    value={codeSenior}
                                    disabled={props.match.params.action === 'view' ? (true) : (false)}
                                    required={true}
                                    format={'####'}
                                />
                                {error ? (<p style={{ color: 'red' }}>{error.code_filial_senior}</p>) : ('')}
                            </div>
                            <div className='col-md-6'>
                                <label>Empresa</label>
                                <Select
                                    value={idEmpresas}
                                    ref={selectCountryRef}
                                    label='Single select'
                                    options={empresas}
                                    onChange={(e) => setIdEmpresas(e)}
                                    isDisabled={props.match.params.action === 'view' ? true : false}
                                    required={true}

                                />
                                {!idEmpresas &&
                                    <input
                                        tabIndex={-1}
                                        autoComplete='off'
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={idEmpresas}
                                        onFocus={() => selectCountryRef.current.focus()}
                                        required={true}
                                    />
                                }
                                {error ? (<p style={{ color: 'red' }}>{error.id_empresas}</p>) : ('')}
                            </div>

                        </div>
                        <br></br>
                        <div className='text-right'>
                            <button style={{ marginRight: '10px' }} type='button' className='btn btn-secondary'
                                    onClick={() => history.goBack()}>Voltar
                            </button>
                            {props.match.params.action === 'view' ? (
                                <Link
                                    style={{
                                        cursor: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) ? 'not-allowed' : '',
                                        color: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) ? '#fff' : '',
                                        backgroundColor: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) ? '#007bff' : '',
                                        borderColor: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) ? '#007bff' : '',
                                        opacity: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) ? .65 : '',
                                        boxShadow: perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) < 0 && perfis.indexOf(10) ? 'none' : '',

                                    }}
                                    onClick={perfis.indexOf(1) < 0 && perfis.indexOf(2) < 0 && perfis.indexOf(3) < 0 && perfis.indexOf(6) && perfis.indexOf(10) < 0 ? e => e.preventDefault() : ''}
                                    to={`/filial/${props.match.params.id}/edit`}
                                    className='btn btn-primary'>Editar</Link>
                            ) : (
                                <button disabled={props.match.params.action === 'view' ? true : false} type='submit'
                                        className='btn btn-primary'>Salvar</button>
                            )}
                        </div>
                    </Form>
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}
