/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import StylePage from '../../../Style Page/StylePage';
import Form from '../../../Forms/Form';
import Input from '../../../Forms/Input';
import Alert from '../../../Alerts/Alert';
import Footer from '../../../Footer/Footer';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import { register } from '../../../Services/auth';
import { getPerfis, get_view_user, edit_user } from '../../../Services/user';
import makeAnimated from 'react-select/animated';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

export default function User(props) {
    let history = useHistory();
    const selectRoleRef = useRef(null);
    const selectSituacaoRef = useRef(null);
    const selectStatusRef = useRef(null);
    const selectModeloRemuneracaoRef = useRef(null);
    const selectFilialRef = useRef(null);
    const selectEmpresaRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState([]);
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState('');
    const [alert, setAlert] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
    const animatedComponents = makeAnimated();
    const [code, setCode] = useState('');
    const [codeProvider, setCodeProvider] = useState('');
    const [codeSenior, setCodeSenior] = useState('');
    const [codeCentroCusto, setCodeCentroCusto] = useState('');
    // const [ id_situacao, setIdSituacao ] = useState({value: 1, label: 'Ativo'})
    // const [ situacoes, setSituacoes ] = useState([])
    const [id_status_user, setIdStatusUser] = useState([]);
    const [status, setStatus] = useState('');
    const [id_modelos_remuneracoes, setIdModelosRemuneracoes] = useState({
        value: 2,
        label: 'Variável',
    });
    const [modelo_remuneracao, setModeloRemuneracao] = useState([]);
    const [id_filial, setIdFilial] = useState('');
    const [filial, setFilial] = useState([]);
    const [id_empresa, setIdEmpresa] = useState({
        value: 1,
        label: 'REHAGRO RECURSOS HUMANOS NO AGRO NEGOCIO LTDA',
    });
    const [empresa, setEmpresa] = useState([]);
    const [valor_remuneracao, setValorRemuneracao] = useState(0);
    const [data_admissao, setDataAdmissao] = useState('');
    const [data_saida, setDataSaida] = useState('');
    const perfis = useSelector((state) => state.auth.user.perfis) || [];
    const [viewPerIdConst, setViewPerIdConst] = useState(false);

    useEffect(() => {
        obterPerfis();
    }, []);

    useEffect(() => {
        permissionRole();
        return () => {
            permissionRole();
        };
    }, [props.match.params.action]);

    useEffect(() => {
        viewPerId();
    }, [viewPerIdConst]);

    function optionsStatus(valorContrato) {
        const qtdparcel = [
            { label: `Ativo`, value: 1 },
            { label: `Inativo`, value: 0 },
        ];
        return qtdparcel;
    }

    //Traz os recursos: (ROLE, SITUACAO e MODELO DE REMUNERÇÃO)
    function obterPerfis() {
        getPerfis().then((resp) => {
            setRoles(
                resp.data.roles.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            // setSituacoes(
            //     resp.data.situations.map(desc => ({
            //         value: desc.id,
            //         label: desc.name
            //     }))
            // )
            setModeloRemuneracao(
                resp.data.models_remunerations.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setFilial(
                resp.data.filial.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setEmpresa(
                resp.data.empresa.map((desc) => ({
                    value: desc.id,
                    label: desc.nome,
                }))
            );
        });
    }

    //Função que verifica se o usuário tem permissão para acessar a edição do registro
    function permissionRole() {
        if (perfis.indexOf(6) >= 0) {
            return props.history.push('/users');
        }
        if (
            props.match.params.action === 'edit' ||
            props.match.params.action === 'view'
        ) {
            if (
                perfis.indexOf(1) < 0 &&
                perfis.indexOf(2) < 0 &&
                perfis.indexOf(3) < 0
                /**&&
                perfis.indexOf(6) < 0 */
            ) {
                return props.history.push('/users');
            } else {
                setViewPerIdConst(true);
            }
        }
    }

    function viewPerId() {
        props.match.params.action &&
            get_view_user(props.match.params.id).then((resp) => {
                setName(resp.data.user[0].name);
                setEmail(resp.data.user[0].email);
                setCode(resp.data.user[0].codigo_w3);
                setCodeProvider(resp.data.user[0].cod_colaborador_hcm);
                setCodeSenior(resp.data.user[0].fornecedor_senior);
                setCodeCentroCusto(resp.data.user[0].cod_centro_custo);
                setImage(
                    resp.data.user[0].foto_perfil ||
                        'http://localhost:8000/storage/fotos/default.webp'
                );
                setRole(
                    resp.data.role.map((desc) => ({
                        value: desc.perfil_id,
                        label: desc.descricao,
                    }))
                );
                // setIdSituacao({
                //     value: resp.data.user[0].id_situacao,
                //     label: resp.data.user[0].descSituacao
                // })
                setIdModelosRemuneracoes({
                    value: resp.data.user[0].id_modelos_remuneracoes,
                    label: resp.data.user[0].descRemuneracoes,
                });
                setValorRemuneracao(resp.data.user[0].valor_remuneracao);
                setDataAdmissao(resp.data.user[0].data_admissao);
                setDataSaida(resp.data.user[0].data_saida);
                setIdStatusUser({
                    value: resp.data.user[0].ativo,
                    label: optionsStatus().filter(
                        (desc) => desc.value === resp.data.user[0].ativo
                    )[0].label,
                });
                resp.data.user[0].id_filial == null
                    ? setIdFilial('')
                    : setIdFilial({
                          value: resp.data.user[0].id_filial,
                          label: resp.data.user[0].descFilial,
                      });
                setIdEmpresa({
                    value: id_empresa.value,
                    label: id_empresa.label,
                });
            });
    }

    const handleChangeRole = (newValue: any, actionMeta: any) => {
        setRole(newValue);
    };

    const changeEmail = (event) => {
        const email = event.target.value;
        setEmail(email);
    };

    const changeName = (event) => {
        const name = event.target.value;
        setName(name);
    };

    const changePassword = (event) => {
        const password = event.target.value;
        setPassword(password);
    };

    const changeConfirmPassword = (event) => {
        const confirm_password = event.target.value;
        setConfirmPassword(confirm_password);
    };

    const handleForm = (e) => {
        e.preventDefault();
        setLoading(true);

        let newRole = [];
        role.map((desc) => newRole.push(desc.value));

        const data = {
            name: name,
            email: email,
            password: password,
            roles: newRole,
            confirm_password: confirm_password,
            code: code,
            codeProvider: codeProvider,
            codeSenior,
            cod_centro_custo: codeCentroCusto,
            // id_situacao: parseInt(id_situacao.value),
            id_modelos_remuneracoes: parseInt(id_modelos_remuneracoes.value),
            valor_remuneracao: valor_remuneracao,
            data_admissao: data_admissao,
            data_saida: data_saida,
            id_status_user: parseInt(id_status_user.value),
            id_filial: parseInt(id_filial.value),
            id_empresa: parseInt(id_empresa.value),
        };

        // if(password !== confirm_password)
        // {
        //     return[
        //         setLoading(false),
        //         setErrorConfirmPassword('Erro ao cadastrar o usuário. As senhas não conferem!')
        //     ]

        // }

        if (props.match.params.action === 'edit') {
            const id = props.match.params.id;
            edit_user(id, data)
                .then((resp) => {
                    setLoading(false);
                    setAlert('');
                    setErrorConfirmPassword('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then((resp) =>
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500)
                )
                .catch((e) => {
                    setLoading(false);
                    setErrorConfirmPassword('');
                    setSuccess('');
                    setError(e.response.data.error);
                    setAlert(e.response.data.message);
                    setTimeout(() => {
                        setAlert('');
                    }, 2500);
                });
        } else {
            if (password !== confirm_password) {
                return [
                    setLoading(false),
                    setErrorConfirmPassword(
                        'Erro ao cadastrar o usuário. As senhas não conferem!'
                    ),
                ];
            }
            register(data)
                .then((resp) => {
                    setLoading(false);
                    setAlert('');
                    setErrorConfirmPassword('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then((resp) =>
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500)
                )
                .catch((e) => {
                    setLoading(false);
                    setErrorConfirmPassword('');
                    setSuccess('');
                    setError(e.response.data.error);
                    setAlert(e.response.data.message);
                    setTimeout(() => {
                        setAlert('');
                    }, 2500);
                });
        }
    };

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage
                title={
                    props.match.params.action === 'edit'
                        ? 'Edição de Usuário'
                        : props.match.params.action === 'view'
                        ? 'Visualização de Usuário'
                        : 'Cadastro de Usuário'
                }
            >
                {loading === true ? (
                    <Content>
                        <ReactLoading
                            type="spinningBubbles"
                            color="blue"
                            height={200}
                            width={100}
                        />
                    </Content>
                ) : (
                    ''
                )}
                {alert ? <Alert type="danger">{alert}</Alert> : ''}
                {success ? <Alert type="success">{success}</Alert> : ''}
                <div style={{ display: loading === true ? 'none' : 'block' }}>
                    {props.match.params.action === 'edit' ||
                    props.match.params.action === 'view' ? (
                        <div className="row justify-content-center">
                            <Dropzone
                                noClick
                                style={{
                                    width: '150px',
                                    height: '150px',
                                    BorderStyle: 'none',
                                    border: 0,
                                }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <AvatarEditor
                                            onPositionChange={''}
                                            disablecanvasrotation
                                            width={150}
                                            height={150}
                                            image={image}
                                            borderRadius={100}
                                            border={1}
                                            style={{
                                                borderRadius: 100,
                                                BorderStyle: 'none',
                                                verticalAlign: 'middle',
                                                cursor: 'pointer',
                                                marginBlockStart: '1em',
                                                marginBlockEnd: '1em',
                                                marginInlineStart: '40px',
                                                marginInlineEnd: '40px',
                                            }}
                                            // scale={1.2}
                                            // className="rounded-circle"
                                        />
                                        <input {...getInputProps()} />
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    ) : (
                        ''
                    )}
                    <Form onSubmit={handleForm} autoComplete="off">
                        <div className="row">
                            <div
                                className={`col-md-6 ${
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'offset-md-3'
                                        : ''
                                }`}
                            >
                                <NumberFormat
                                    label="Código W3"
                                    name="code"
                                    icon="fas fa-key"
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    thousandSeparator={''}
                                    decimalSeparator={','}
                                    placeholder=""
                                    customInput={Input}
                                    allowNegative={false}
                                    onChange={(e) => setCode(e.target.value)}
                                    value={code}
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    // required={true}
                                    isAllowed={(values) => {
                                        const { floatValue, formattedValue } =
                                            values;
                                        return (
                                            formattedValue === '' ||
                                            floatValue <= 999999999
                                        );
                                    }}
                                />
                            </div>
                            {/* <div className={`col-md-6 ${props.match.params.action === 'edit' || props.match.params.action === 'view' ? 'offset-md-3' : ''}`}>
                                <NumberFormat  
                                    label="Código do fornecedor no W3" 
                                    name="codeProvider"
                                    icon="fas fa-key" 
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    thousandSeparator={""} 
                                    decimalSeparator={","}
                                    placeholder=""
                                    customInput={Input}
                                    allowNegative={false}
                                    onChange={(e)=>setCodeProvider(e.target.value)} 
                                    value={codeProvider}
                                    disabled={props.match.params.action === 'view' ? (true):(false)}
                                    // required={true} 
                                    isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= 999999999;}}                         
                                />
                            </div> */}
                            <div
                                className={`col-md-6 ${
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'offset-md-3'
                                        : ''
                                }`}
                            >
                                <NumberFormat
                                    label="Código Fornecedor Sênior"
                                    name="codeSenior"
                                    icon="fas fa-key"
                                    placeholder=""
                                    maxLength={9}
                                    customInput={Input}
                                    allowNegative={false}
                                    onChange={(e) =>
                                        setCodeSenior(e.target.value)
                                    }
                                    value={codeSenior}
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    // required={true}
                                />
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.codeSenior}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>

                            <div
                                className={`col-md-6 ${
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'offset-md-3'
                                        : ''
                                }`}
                            >
                                <Input
                                    label="Código Colaborador HCM"
                                    name="codeProvider"
                                    autocomplete="off"
                                    value={codeProvider}
                                    maxLength="15"
                                    onChange={(e) =>
                                        setCodeProvider(e.target.value)
                                    }
                                    icon="fas fa-user"
                                    type="text"
                                    placeholder="Digite o código"
                                    required={false}
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                            </div>

                            <div
                                className={`col-md-6 ${
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'offset-md-3'
                                        : ''
                                }`}
                            >
                                <NumberFormat
                                    label="Código Centro de Custo"
                                    name="codeCentroCusto"
                                    icon="fas fa-key"
                                    placeholder=""
                                    required={true}
                                    maxLength={9}
                                    customInput={Input}
                                    allowNegative={false}
                                    onChange={(e) =>
                                        setCodeCentroCusto(e.target.value)
                                    }
                                    value={codeCentroCusto}
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    // required={true}
                                />
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.cod_centro_custo}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={`${
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'col-md-6 offset-md-3'
                                        : 'col-md-12'
                                }`}
                            >
                                <Input
                                    label="Nome Completo do Colaborador"
                                    autocomplete="off"
                                    value={name}
                                    maxLength="50"
                                    onChange={changeName}
                                    icon="fas fa-user"
                                    name="name"
                                    type="text"
                                    placeholder="Digite o nome do usuário"
                                    required={true}
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                                {error ? (
                                    <p style={{ color: 'red' }}>{error.name}</p>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={`col-md-6 ${
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'offset-md-3'
                                        : ''
                                }`}
                            >
                                <Input
                                    label="Email Corporativo"
                                    value={email}
                                    onChange={changeEmail}
                                    icon="fas fa-envelope"
                                    name="email"
                                    type="text"
                                    autocomplete="off"
                                    placeholder="Digite o email do usuário"
                                    required={true}
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.email}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>

                            <div
                                className={`col-md-6 ${
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'offset-md-3'
                                        : ''
                                }`}
                            >
                                <label>Perfil</label>
                                <Select
                                    value={role}
                                    isMulti
                                    name="perfis"
                                    options={roles}
                                    components={animatedComponents}
                                    closeMenuOnSelect={false}
                                    className="basic-multi-select"
                                    ref={selectRoleRef}
                                    classNamePrefix="select"
                                    onChange={handleChangeRole}
                                    isDisabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                                {role ? (
                                    role.length <= 0 ? (
                                        <input
                                            tabIndex={-1}
                                            autoComplete="off"
                                            style={{
                                                opacity: 0,
                                                width: '100%',
                                                height: 0,
                                                position: 'absolute',
                                            }}
                                            defaultValue={role}
                                            onFocus={() =>
                                                selectRoleRef.current.focus()
                                            }
                                            required={true}
                                        />
                                    ) : (
                                        ''
                                    )
                                ) : role == null ? (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={role}
                                        onFocus={() =>
                                            selectRoleRef.current.focus()
                                        }
                                        required={true}
                                    />
                                ) : (
                                    ''
                                )}

                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.roles}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div
                                className={`col-md-6 ${
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'offset-md-3'
                                        : ''
                                }`}
                            >
                                <label>Empresa</label>
                                <Select
                                    value={id_empresa}
                                    ref={selectEmpresaRef}
                                    label="Single select"
                                    options={empresa}
                                    onChange={(e) => setIdEmpresa(e)}
                                    isDisabled={true}
                                />
                                {id_empresa.length <= 0 && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={id_empresa}
                                        onFocus={() =>
                                            selectEmpresaRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.id_empresa}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div
                                className={`col-md-6 ${
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'offset-md-3'
                                        : ''
                                }`}
                            >
                                <label>Filial</label>
                                <Select
                                    value={id_filial}
                                    ref={selectFilialRef}
                                    label="Single select"
                                    options={filial}
                                    onChange={(e) => setIdFilial(e)}
                                    isDisabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                                {id_filial.length <= 0 && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={id_filial}
                                        onFocus={() =>
                                            selectFilialRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.id_filial}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                            {/* <div className={`col-md-6 ${props.match.params.action === 'edit' || props.match.params.action === 'view' ? 'offset-md-3' : ''}`}>
                                <label>Situação</label>
                                <Select 
                                    value={id_situacao}
                                    ref={selectSituacaoRef}
                                    label="Single select"
                                    options={situacoes}
                                    onChange={ (e) => setIdSituacao(e) }
                                    isDisabled={props.match.params.action === 'view' ? true : false}             
                                /> 
                                {id_situacao.length <= 0 && 
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={id_situacao}
                                        onFocus={() => selectSituacaoRef.current.focus()}
                                        required={true}
                                    />
                                }
                                {error ? (<p style={{color:'red'}}>{error.id_situacao}</p>):('')}  
                            </div> */}
                            <div
                                className={`col-md-6 ${
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'offset-md-3'
                                        : ''
                                }`}
                            >
                                <label>Modelo de Remuneração</label>
                                <Select
                                    value={id_modelos_remuneracoes}
                                    ref={selectModeloRemuneracaoRef}
                                    label="Single select"
                                    options={modelo_remuneracao}
                                    onChange={(e) =>
                                        setIdModelosRemuneracoes(e)
                                    }
                                    isDisabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                                {id_modelos_remuneracoes.length <= 0 && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={id_modelos_remuneracoes}
                                        onFocus={() =>
                                            selectModeloRemuneracaoRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.id_modelos_remuneracoes}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div
                                className={`col-md-6 ${
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'offset-md-3'
                                        : ''
                                }`}
                            >
                                <NumberFormat
                                    label="Valor Remuneração"
                                    name="valor_remuneracao"
                                    icon="fas fa-dollar-sign"
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    placeholder=""
                                    customInput={Input}
                                    required={
                                        parseInt(
                                            id_modelos_remuneracoes.value
                                        ) == 2
                                            ? false
                                            : true
                                    }
                                    onChange={(e) =>
                                        setValorRemuneracao(
                                            parseFloat(
                                                e.target.value
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace(',', '.')
                                            )
                                        )
                                    }
                                    value={valor_remuneracao}
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    isAllowed={(values) => {
                                        const { floatValue, formattedValue } =
                                            values;
                                        return (
                                            formattedValue === '' ||
                                            floatValue <= 999999
                                        );
                                    }}
                                />
                            </div>
                            <div
                                className={`col-md-6 ${
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'offset-md-3'
                                        : ''
                                }`}
                            >
                                <Input
                                    icon="fas fa-calendar"
                                    label="Data Admissão"
                                    name="validity"
                                    required={true}
                                    type="date"
                                    placeholder=""
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    onChange={(e) =>
                                        setDataAdmissao(e.target.value)
                                    }
                                    value={data_admissao}
                                />
                            </div>
                            <div
                                className={`col-md-6 ${
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'offset-md-3'
                                        : ''
                                }`}
                            >
                                <Input
                                    icon="fas fa-calendar"
                                    label="Data Saída"
                                    name="validity"
                                    required={
                                        id_status_user.value === 0
                                            ? true
                                            : false
                                    }
                                    type="date"
                                    placeholder=""
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    onChange={(e) =>
                                        setDataSaida(e.target.value)
                                    }
                                    value={data_saida}
                                />
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.data_saida}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div
                                className={`col-md-6 ${
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'offset-md-3'
                                        : ''
                                }`}
                            >
                                <label>Status do Usuário</label>
                                <Select
                                    value={id_status_user}
                                    ref={selectSituacaoRef}
                                    label="Single select"
                                    options={optionsStatus()}
                                    onChange={(e) => setIdStatusUser(e)}
                                    isDisabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                                {id_status_user.length <= 0 && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={id_status_user}
                                        // onFocus={() => selectStatusRef.current.focus()}
                                        required={true}
                                    />
                                )}
                                {error ? (
                                    <p style={{ color: 'red' }}>
                                        {error.id_status_user}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        <div
                            className="row"
                            style={{
                                display:
                                    props.match.params.action === 'edit' ||
                                    props.match.params.action === 'view'
                                        ? 'none'
                                        : '',
                            }}
                        >
                            <div className="col-md-6">
                                <Input
                                    label="Senha"
                                    autocomplete="off"
                                    value={password}
                                    onChange={changePassword}
                                    icon="fas fa-lock"
                                    name="password"
                                    type="password"
                                    placeholder="Digite a senha do usuário"
                                    required={
                                        props.match.params.action === 'edit'
                                            ? false
                                            : true
                                    }
                                />
                                {errorConfirmPassword ? (
                                    <p style={{ color: 'red' }}>
                                        {errorConfirmPassword}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="col-md-6">
                                <Input
                                    label="Confirme Senha"
                                    autocomplete="off"
                                    value={confirm_password}
                                    onChange={changeConfirmPassword}
                                    icon="fas fa-lock"
                                    name="confirm_password"
                                    type="password"
                                    placeholder="Confirme a senha do usuário"
                                    required={
                                        props.match.params.action === 'edit'
                                            ? false
                                            : true
                                    }
                                />
                                {errorConfirmPassword ? (
                                    <p style={{ color: 'red' }}>
                                        {errorConfirmPassword}
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        <br></br>
                        <div className="text-right" style={{ marginTop: '9%' }}>
                            <button
                                style={{ marginRight: '10px' }}
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => history.goBack()}
                            >
                                Voltar
                            </button>
                            {props.match.params.action === 'view' ? (
                                <Link
                                    to={`/user/${props.match.params.id}/edit`}
                                    className="btn btn-primary"
                                >
                                    Editar
                                </Link>
                            ) : (
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Salvar
                                </button>
                            )}
                        </div>
                    </Form>
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}
