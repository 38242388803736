import React from 'react'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import { useSelector } from 'react-redux'

export default function RenderTechnical({ technical, handleCheeseChange, roles, formatReal, handleRemove, isDisabled, coordinator, manager }) {
    const technicals = technical || []
    const perfis  = roles || []
    const user_id = useSelector(state => state.auth.user.id)
    return technicals.map(desc =>(
        <tr key={desc.id}>
            <td className="text-center">
                <Toggle             
                    id={`${desc.id}`}
                    checked={desc.ativo === 1 ? true : false}
                    onChange={handleCheeseChange} 
                    disabled={isDisabled}
                />

            </td>
            <td className="text-center">{desc.data_alocacao}</td>
            <td className="text-center">{desc.name}</td>
            <td className="text-center">{desc.tipo_dedicacao}</td>
            {`${perfis}`.indexOf(1)>=0 ||`${perfis}`.indexOf(2)>=0 ||`${perfis}`.indexOf(3)>=0 ||`${perfis}`.indexOf(5)>=0 ||`${perfis}`.indexOf(6)>=0 ||parseInt(coordinator.value) === parseInt(user_id) || parseInt(manager.value) === parseInt(user_id) ?( <td className="text-center">{`${desc.dedicacao} dias`}</td>): parseInt(desc.user_id) === parseInt(user_id) ? <td className="text-center">{`${desc.dedicacao} dias`}</td> : <td className="text-center"></td> }
            {/* {`${perfis}`.indexOf(1)>=0 ||`${perfis}`.indexOf(2)>=0 ||`${perfis}`.indexOf(3)>=0 ||`${perfis}`.indexOf(4)>=0 ||`${perfis}`.indexOf(5)>=0 ||`${perfis}`.indexOf(6)>=0 ||`${perfis}`.indexOf(8)>=0 ?<td className="text-center">{desc.descricao}</td> : <span></span>} */}
            <td className="text-center">{desc.descricao}</td>
            {`${perfis}`.indexOf(1)>=0 ||`${perfis}`.indexOf(2)>=0 ||`${perfis}`.indexOf(3)>=0 ||`${perfis}`.indexOf(5)>=0 ||`${perfis}`.indexOf(6)>=0 || parseInt(coordinator.value) === parseInt(user_id) || parseInt(manager.value) === parseInt(user_id) ?( <td className="text-center">R${formatReal(parseFloat(`${desc.valor}`))}</td>): parseInt(desc.user_id) === parseInt(user_id) ? <td className="text-center">R${formatReal(parseFloat(`${desc.valor}`))}</td> : <td className="text-center"></td> }
            <td className="text-center">
                <button disabled={isDisabled} type="button" className="btn btn-danger" onClick={() => handleRemove(desc.id)}><span className="fas fa-minus"></span></button>
            </td>
        </tr>
        
    ))  
}

