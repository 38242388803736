import React from 'react'
import { Link } from 'react-router-dom';

export function goButton(refundsShared,status,changeStatusReimbursementSharedAll){
    let get_refunds= refundsShared || []
    var filtrado = get_refunds.map(desc => (
        desc.id_status
    )).filter(function(elem, pos, arr) {
        return parseInt(arr.indexOf(elem)) === parseInt(pos);
    });

    if(filtrado.length === 1 && status.length > 0){
        switch(filtrado[0]){
            case 1:
                return (
                    <>
                        <Link 
                            to={`#/`} 
                            className='btn btn-outline-success btn-sm'
                            style={{margin: '5px'}}
                            onClick={()=>changeStatusReimbursementSharedAll(2)}
                        >
                            <i className='fas fa-check-square' /> Aprovar Selecionados
                            
                        </Link>
                        {/* <Link 
                            to={`#/`} 
                            className='btn btn-outline-danger btn-sm'
                            style={{margin: '5px'}}
                            onClick={()=>changeStatusReimbursementSharedAll(3)}
                        >
                            <i className='fas fa-edit' /> Reprovar
                        </Link>                   */}
                    </> 

                )
            case 2:
                return(
                    <>
                        <Link 
                            to={`#/`} 
                            className='btn btn-outline-warning btn-sm'
                            style={{margin: '5px'}}
                            onClick={()=>changeStatusReimbursementSharedAll(1)}
                        >
                            <i className='fas fa-spinner' /> Em Análise Selecionados
                        </Link>
                        {/* <Link 
                            to={`#/`} 
                            className='btn btn-outline-danger btn-sm'
                            style={{margin: '5px'}}
                            onClick={()=>changeStatusReimbursementSharedAll(3)}
                        >
                            <i className='fas fa-eye' /> Reprovar
                        </Link> */}
                        {/* <Link 
                            to={`#/`} 
                            className='btn btn-outline-info btn-sm'
                            style={{margin: '5px'}}
                            onClick={()=>changeStatusReimbursementSharedAll(4)} 
                        >
                            <i className='fas fa-money-bill-wave' /> Faturar Selecionados
                        </Link>                   */}
                    </>
                )
            case 3:
                return (
                    <>
                        <Link 
                            to={`#/`} 
                            className='btn btn-outline-warning btn-sm'
                            style={{margin: '5px'}}
                            onClick={()=>changeStatusReimbursementSharedAll(1)}
                        >
                            <i className='fas fa-spinner' /> Em Análise Selecionados
                        </Link>
                        <Link 
                            to={`#/`} 
                            className='btn btn-outline-success btn-sm'
                            style={{margin: '5px'}}
                            onClick={()=>changeStatusReimbursementSharedAll(2)}
                        >
                            <i className='fas fa-check-square' /> Aprovar Selecionados
                        </Link>                 
                    </>
                )
            default:
                return ''
        }
    }
}