/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import Footer from '../../../Footer/Footer';
import Table from '../../../Table/Table';
import Thead from '../../../Table/Thead';
import Tbody from '../../../Table/Tbody';
import Tfoot from '../../../Table/Tfoot';
import StylePage from '../../../Style Page/StylePage';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    search,
    changePage,
    get,
    order,
    change_qtd_per_page,
    changePesquisa,
    clearPesquisa,
} from './Actions/actions';
import { pagination } from '../../../Pagination/paginations';
import { Link } from 'react-router-dom';
import Pagination from '../../../Table/Pagination';
import CabecalhoBills from '../../../Table/CabecalhoBills';
import Alert from '../../../Alerts/Alert';
import MyModal from '../../../Modal/MyModal';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import { del_project } from '../../../Services/project';
import {
    postBillsToPay,
    getBillsToPay,
    changeBillsToPay,
    postContabil,
    exportExcelCSV,
    postEmailExtratoMensal,
} from '../../../Services/billsToPay';
import BillsToPayRecords from './BillsToPayRecords';
import { defineYears } from './defineYears';
import { CSVLink } from 'react-csv';
import ReportBills from './ReportBills';
import { useDispatch } from 'react-redux';

function BillsToPay(props) {
    function formatNumeric(desc) {
        var tmp = `${desc.toFixed(2)}`.replace('.', ',');

        if (tmp.length > 6)
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, '.$1');
        return tmp;
    }

    const dispatch = useDispatch();
    const [hideEmail, setHideEmail] = useState('none');
    const [modalFilter, setModalFilter] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [id, setId] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [ano, setAno] = useState(['']);
    const [mes, setMes] = useState(['']);
    const [competInicial, setCompetInicial] = useState('');
    const [competFinal, setCompetFinal] = useState('');
    const [competencia, setCompetencia] = useState(
        useSelector((state) => state.bills_to_pay.competencia)
    );
    const [status_report, setStatusReport] = useState(
        useSelector((state) => state.bills_to_pay.status_report)
    );
    const [technical, setTechnical] = useState(
        useSelector((state) => state.bills_to_pay.technical)
    );
    const [billsToPay, setBillsToPay] = useState([]);
    const [selectedBills, setSelectedBills] = useState([]);
    const fileUpload = useRef();
    const headers = [
        { label: 'Filial', key: 'filial_name' },
        { label: 'Nome Profissional', key: 'usuario' },
        { label: 'Nome Projeto', key: 'projeto' },
        { label: 'Código Sênior', key: 'cod_senior' },
        { label: 'Código Centro de Custo', key: 'cod_centro_custo' },
        { label: 'Tipo', key: 'tipo' },
        { label: 'Variável de Correção', key: 'variavel_correcao' },
        { label: 'Valor Contabil', key: 'valor_contabil' },
        { label: 'Valor', key: 'valor' },
        { label: 'Status', key: 'status' },
    ];

    const headersFinanceiro = [
        { label: 'Filial', key: 'filial_name' },
        { label: 'Nome Profissional', key: 'usuario' },
        { label: 'Código Sênior', key: 'cod_senior' },
        { label: 'Código Centro de Custo', key: 'cod_centro_custo' },
        { label: 'Nome Projeto', key: 'projeto' },
        { label: 'Tipo', key: 'tipo' },
        { label: 'Mês competência', key: 'competencia' },
        { label: 'Valor', key: 'valor' },
        { label: 'Variável de Correção', key: 'variavel_correcao' },
        { label: 'Valor Contabil', key: 'valor_contabil' },
        { label: 'Status', key: 'status' },
    ];

    const meses = [
        { label: 'Janeiro', value: '01' },
        { label: 'Fevereiro', value: '02' },
        { label: 'Março', value: '03' },
        { label: 'Abril', value: '04' },
        { label: 'Maio', value: '05' },
        { label: 'Junho', value: '06' },
        { label: 'Julho', value: '07' },
        { label: 'Agosto', value: '08' },
        { label: 'Setembro', value: '09' },
        { label: 'Outubro', value: '10' },
        { label: 'Novembro', value: '11' },
        { label: 'Dezembro', value: '12' },
    ];
    const anos = defineYears();

    const perfis = useSelector((state) => state.auth.user.perfis);
    const [dadosExcel, setDadosExcel] = useState([]);
    const competenciaExcel = useSelector(
        (state) => state.bills_to_pay.competencia
    );
    const status = useSelector(
        (state) => state.bills_to_pay.status_report.value
    );
    const technicalExcel = useSelector(
        (state) => state.bills_to_pay.technical.value
    );
    const typeBill = useSelector((state) => state.bills_to_pay.typeBill.value);
    const projectBill = useSelector(
        (state) => state.bills_to_pay.projectBill.value
    );
    const typeProjects = useSelector(
        (state) => state.bills_to_pay.typeProjects.value
    );
    const pesquisa = useSelector((state) => state.bills_to_pay.pesquisa);

    useEffect(() => {
        getAllBills();
        return () => {
            getAllBills();
        };
    }, []);

    useEffect(() => {
        setCurrentMonth();
        return () => {
            setCurrentMonth();
        };
    }, []);

    useEffect(() => {
        setCurrentYear();
        return () => {
            setCurrentYear();
        };
    }, []);

    useEffect(() => {
        setCurrentComp();
        return () => {
            setCurrentComp();
        };
    }, []);

    useEffect(() => {
        permissionRole();
        return () => {
            permissionRole();
        };
    }, []);

    //Função que verifica se o usuário tem permissão para acessar a edição do registro
    function permissionRole() {
        if (!(perfis.indexOf(1) >= 0)) {
            return props.history.push('/index');
        }
    }

    //Função que busca os projetos na api
    const getAllBills = (comp, stat) => {
        // let newComp = comp || competencia;
        // let newStatus = stat || status_report;
        props.get();

        // getBillsToPay(newComp, newStatus).then((resp) => {
        //     setBillsToPay(resp.data);
        // });
    };

    const clickAll = (event) => {
        let isChecked = event.target.checked;
        let checked = document.querySelectorAll('input[name="checked"]');

        if (isChecked) {
            for (let i = 0; i < checked.length; i++) {
                if (checked[i].checked === false) {
                    checked[i].checked = true;
                }
            }
            changeAllBoxToChecked();
        } else if (!isChecked) {
            for (let i = 0; i < checked.length; i++) {
                if (checked[i].checked === true) {
                    checked[i].checked = false;
                }
            }
            changeAllBoxToNotChecked();
        }
    };

    //Função que abre o modal e define o id a ser excluído
    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
    };

    const setCurrentMonth = () => {
        let mesAtual = props.competencia.split('-')[1];
        setMes(meses.filter((desc) => desc.value === mesAtual)[0]);
    };

    function retornaMes(mes) {
        if (mes < 10) {
            return `0${mes}`;
        } else {
            return mes;
        }
    }

    function formatComp(competencia, inicialFinal) {
        let data = new Date(competencia);

        if (inicialFinal == 'inicial') {
            let dataComp = data.setMonth(data.getMonth() - 2);
            let dataCompFormatada = new Date(dataComp);
            let dataObj = new Date(dataCompFormatada);
            let diaDataObj = dataObj.toLocaleDateString().substring(0, 2);
            if (diaDataObj == '24') {
                let diaF = '25';
                let month = (dataObj.getUTCMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
                let mesF = month.length == 1 ? '0' + month : month;
                let anoF = dataObj.getUTCFullYear();

                let dataInicialComp = `${diaF}/${mesF}/${anoF}`;

                setCompetInicial(dataInicialComp);
            } else {
                setCompetInicial(dataCompFormatada);
            }
        } else {
            let dataComp = data.setMonth(data.getMonth() - 1);
            let dataCompFormatada = new Date(dataComp);
            let dataObj = new Date(dataCompFormatada);
            let diaDataObj = dataObj.toLocaleDateString().substring(0, 2);
            if (diaDataObj == '23') {
                let diaF = '24';
                let month = (dataObj.getUTCMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
                let mesF = month.length == 1 ? '0' + month : month;
                let anoF = dataObj.getUTCFullYear();

                let dataFinalComp = `${diaF}/${mesF}/${anoF}`;
                setCompetFinal(dataFinalComp);
            } else {
                setCompetFinal(dataCompFormatada);
            }
        }
    }

    function changeComp(competencia) {
        let compInicial = competencia + '-25';
        formatComp(compInicial, 'inicial');

        let compFinal = competencia + '-24';
        formatComp(compFinal, 'final');
    }

    const setCurrentYear = () => {
        let data = new Date();
        let anoAtual = data.getFullYear();
        setAno(
            anos.filter(
                (desc) => parseInt(desc.value) === parseInt(anoAtual)
            )[0]
        );
    };

    const setCurrentComp = () => {
        let competenciaChange = props.competencia;
        changeComp(competenciaChange);
    };

    const onChangeMes = (e) => {
        setMes(e);
        setHideEmail('none');
        let competenciaChange = `${ano.value}-${e.value}`;

        changeComp(competenciaChange);
        dispatch({
            type: 'changeCompetencia',
            payload: competenciaChange,
        });
        getAllBills(competenciaChange);
    };

    const onChangeAno = (e) => {
        setAno(e);
        setHideEmail('none');
        let competenciaChange = `${e.value}-${mes.value}`;
        changeComp(competenciaChange);
        dispatch({
            type: 'changeCompetencia',
            payload: competenciaChange,
        });
        getAllBills(competenciaChange);
    };

    function formatReal(desc) {
        var tmp = `${desc.toFixed(2)}`.replace('.', ',');

        if (tmp.length > 6)
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, '.$1');
        return tmp;
    }

    const gerarPagamento = () => {
        setLoading(true);
        const data = {
            competencia: props.competencia,
        };
        postBillsToPay(data)
            .then((resp) => {
                setLoading(false);
                getAllBills();
                setSuccess(resp.data.success);
                setError('');
            })
            .then((resp) =>
                setTimeout(() => {
                    setSuccess('');
                }, 4000)
            )
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    const gerarContabil = () => {
        setLoading(true);
        const data = {
            competencia: props.competencia,
        };
        postContabil(data)
            .then((resp) => {
                setLoading(false);
                getAllBills();
                setSuccess(resp.data.success);
                setHideEmail('inline-block');
                setError('');
            })
            .then((resp) =>
                setTimeout(() => {
                    setSuccess('');
                }, 4000)
            )
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    const enviarEmailExtratoMensal = () => {
        setLoading(true);
        const data = {
            competencia: props.competencia,
        };
        postEmailExtratoMensal(data)
            .then((resp) => {
                setLoading(false);
                getAllBills();
                setSuccess(resp.data.success);
                setHideEmail('none');
                setError('');
            })
            .then((resp) =>
                setTimeout(() => {
                    setSuccess('');
                }, 3000)
            )
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    const download = (event) => {
        exportExcelCSV(
            competenciaExcel ?? '',
            status ?? '',
            technicalExcel ?? '',
            pesquisa ?? '',
            typeBill ?? '',
            projectBill ?? '',
            typeProjects ?? ''
        )
            .then((resp) => {
                setDadosExcel(
                    resp.data.consultoria
                        .concat(resp.data.ensino)
                        .concat(resp.data.complementar)
                );
            })
            .then((resp) => {
                fileUpload.current.link.click();
            });
    };

    //Função que fecha o modal de excluir projeto
    const handleCloseExcluir = (event) => {
        setModal(false);
        setId('');
    };

    const changeAllBox = (id) => {
        var checkBox = document.getElementById(id);

        if (checkBox.checked === true) {
            setSelectedBills(selectedBills.concat(id));
        } else {
            setSelectedBills(selectedBills.filter((desc) => desc !== id));
        }
    };

    const changeAllBoxToChecked = () => {
        setSelectedBills(['']);
        let data = [];

        for (let i = 0; i < props.billsToPay.length; i++) {
            data = data.concat(props.billsToPay[i].id);
        }
        setSelectedBills(data);
    };

    const changeAllBoxToNotChecked = () => {
        setSelectedBills([]);
    };

    const commitChangeAllBox = () => {
        setLoading(true);
        var pagamentoRealizado = 2;

        const data = {
            ids: selectedBills,
            id_status: pagamentoRealizado,
        };

        changeBillsToPay(data)
            .then((resp) => {
                setLoading(false);
                getAllBills();
                setSuccess(resp.data.success);
                setError('');
            })
            .then((resp) =>
                setTimeout(() => {
                    setSuccess('');
                }, 4000)
            )
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    //Função que exclui um projeto
    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false);

        del_project(id)
            .then((resp) => {
                setSuccess(resp.data.success);
                setError('');
                setLoading(false);
            })
            .then((resp) =>
                setTimeout(() => {
                    setSuccess('');
                }, 4000)
            )
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
                setSuccess('');
                setModal(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            })
            .then((resp) => props.get());
        // .then(resp => {setState({success:resp.data.success, error:''})})
    };

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage>
                {loading === true ? (
                    <Content>
                        <ReactLoading
                            type="spinningBubbles"
                            color="blue"
                            height={200}
                            width={100}
                        />
                    </Content>
                ) : (
                    ''
                )}
                {success ? <Alert type="success">{success}</Alert> : ''}
                {error ? <Alert type="danger">{error}</Alert> : ''}
                <MyModal
                    show={modal}
                    onHide={handleCloseExcluir}
                    title="Excluir Projeto"
                    content="Tem certeza que deseja excluir o projeto ?"
                    cancel={handleCloseExcluir}
                    del={handleExcluir}
                    to="/projects"
                    type="danger"
                    textButton="Excluir"
                />
                <div className="row">
                    <div className="col-md-12">
                        <MyModal
                            show={modalFilter}
                            onHide={(e) => setModalFilter(false)}
                            title="Filtrar"
                            content={<ReportBills />}
                            cancel={function () {
                                return [
                                    props.clearPesquisa(),
                                    setTimeout(() => {
                                        setModalFilter(false);
                                    }, 1000),
                                ];
                            }}
                            del={function () {
                                return [
                                    props.get(),
                                    setTimeout(() => {
                                        setModalFilter(false);
                                    }, 1000),
                                ];
                            }}
                            to="#/"
                            type="success"
                            contentSecondary="Limpar Busca"
                            textButton="Buscar"
                        />
                    </div>
                </div>
                <div style={{ display: loading === true ? 'none' : 'block' }}>
                    <Table
                        tableStyle={{ minHeight: '600px' }}
                        // view={
                        //     <div className='col-sm-12 col-md-5'>
                        //         <div
                        //             className='dataTables_info'
                        //             id='example1_info'
                        //             role='status'
                        //             aria-live='polite'
                        //         >
                        //             Exibindo de {props.from} à {props.to} do
                        //             total de {props.qtd_total}
                        //         </div>
                        //     </div>
                        // }
                        cabecalho={
                            <CabecalhoBills
                                to="#/"
                                action={props.search}
                                clear={props.clearPesquisa}
                                valuePesquisa={props.pesquisa}
                                value={props.qtd_per_page}
                                onChange={props.change_qtd_per_page}
                                changePesquisa={props.changePesquisa}
                                buttonCsv={
                                    <>
                                        {/* <button style={{background: '#005F31', color: '#FFF', borderRadius: '20.5px', width: '140px'}} className="btn btn-sm" onClick={download}>
                                            <i className="fas fa-file-csv"></i> Exportar CSV
                                        </button> */}
                                        <button
                                            style={{
                                                background: '#005F31',
                                                color: '#FFF',
                                                borderRadius: '20.5px',
                                                width: '140px',
                                            }}
                                            className="btn btn-sm"
                                            onClick={download}
                                        >
                                            <i className="fas fa-file-csv"></i>{' '}
                                            Exportar CSV
                                        </button>
                                        <button
                                            style={{
                                                background: '#1A170E',
                                                color: '#FFF',
                                                borderRadius: '20.5px',
                                                width: '140px',
                                                marginLeft: '10px',
                                            }}
                                            className="btn btn-sm"
                                            onClick={() =>
                                                setModalFilter(!modalFilter)
                                            }
                                        >
                                            <i className="fas fa-filter"></i>{' '}
                                            Mais Filtros
                                        </button>
                                        <CSVLink
                                            filename={`Relatorio-Contas-${new Date()}.csv`}
                                            headers={
                                                perfis.indexOf(1) >= 0 ||
                                                perfis.indexOf(2) >= 0 ||
                                                perfis.indexOf(3) >= 0 ||
                                                perfis.indexOf(6) >= 0
                                                    ? headersFinanceiro
                                                    : headers
                                            }
                                            hidden={true}
                                            separator={';'}
                                            ref={fileUpload}
                                            data={dadosExcel.concat([
                                                {
                                                    data_avaliacao: 'TOTAL:',
                                                    valor: `${props.valorTotal.toFixed(
                                                        2
                                                    )}`.replace('.', ','),
                                                },
                                            ])}
                                        >
                                            <span className="fas fa-file-csv">
                                                {' '}
                                                Exportar CSV
                                            </span>
                                        </CSVLink>
                                    </>
                                }
                                buttonGerarPagamento={
                                    <Link
                                        to={'#/'}
                                        onClick={gerarPagamento}
                                        style={{
                                            marginLeft: '7px',
                                            marginRight: '25px',
                                        }}
                                        className="btn btn-outline-info btn-sm"
                                    >
                                        Gerar Pagamentos
                                    </Link>
                                }
                                buttonGerarContabil={
                                    <Link
                                        to={'#/'}
                                        onClick={gerarContabil}
                                        style={{
                                            marginLeft: '0px',
                                            marginRight: '25px',
                                        }}
                                        className="btn btn-outline-info btn-sm"
                                    >
                                        Gerar Valor Contábil
                                    </Link>
                                }
                                buttonEnviarEmail={
                                    <Link
                                        to={'#/'}
                                        onClick={enviarEmailExtratoMensal}
                                        style={{
                                            marginLeft: '0px',
                                            marginRight: '25px',
                                            display: hideEmail,
                                        }}
                                        className="btn btn-outline-info btn-sm"
                                    >
                                        Enviar via E-mail Extrato Mensal
                                    </Link>
                                }
                                buttonChangeStatusSelected={
                                    <Link
                                        to={'#/'}
                                        onClick={commitChangeAllBox}
                                        style={{
                                            marginRight: '25px',
                                            display:
                                                selectedBills.length > 0
                                                    ? 'inline-block'
                                                    : 'none',
                                        }}
                                        className="btn btn-outline-info btn-sm"
                                    >
                                        Alterar Status
                                    </Link>
                                }
                                meses={meses}
                                mes={mes}
                                anos={anos}
                                ano={ano}
                                onChangeMes={onChangeMes}
                                onChangeAno={onChangeAno}
                                compIni={competInicial}
                                compFin={competFinal}
                            />
                        }
                        // pagination={
                        //     <Pagination>
                        //         {props.pagination(
                        //             props.current_page,
                        //             props.get,
                        //             props.last_page,
                        //             props.changePage,
                        //         )}
                        //     </Pagination>
                        // }
                        title={
                            perfis.indexOf(1) >= 0 ||
                            perfis.indexOf(2) >= 0 ||
                            perfis.indexOf(3) >= 0 ||
                            perfis.indexOf(6) >= 0
                                ? 'Contas a pagar'
                                : 'Extrato'
                        }
                    >
                        <Thead>
                            {perfis.indexOf(1) >= 0 ||
                            perfis.indexOf(2) >= 0 ||
                            perfis.indexOf(3) >= 0 ||
                            perfis.indexOf(6) >= 0 ? (
                                <th>
                                    <input
                                        type="checkbox"
                                        id={`myCheckAll`}
                                        onClick={(e) => clickAll(e)}
                                    />
                                </th>
                            ) : (
                                ''
                            )}
                            {perfis.indexOf(7) >= 0 ? (
                                <>
                                    <th>Filial</th>
                                </>
                            ) : (
                                ''
                            )}
                            <th>Nome Profissional</th>
                            {perfis.indexOf(7) >= 0 ? (
                                <>
                                    <th>Nome Projeto</th>
                                    <th>Cód. Fornecedor Sênior</th>
                                    <th>Cód. Centro de Custo</th>
                                </>
                            ) : (
                                <>
                                    <th>Cód. Centro de Custo</th>
                                    <th>Nome Projeto</th>
                                </>
                            )}
                            <th>Tipo</th>
                            {perfis.indexOf(7) >= 0 ? (
                                <>
                                    <th>Variável de Correção</th>
                                    <th>Valor Contábil</th>
                                </>
                            ) : (
                                ''
                            )}
                            <th>Valor</th>
                            {perfis.indexOf(1) >= 0 ||
                            perfis.indexOf(2) >= 0 ||
                            perfis.indexOf(3) >= 0 ||
                            perfis.indexOf(6) >= 0 ? (
                                <>
                                    <th>Variável de Correção</th>
                                    <th>Valor Contabil</th>
                                </>
                            ) : (
                                ''
                            )}
                            {/* <th>CLIENTE<Link to="/projects" style={{float:'right'}} onClick={()=>props.order(cliente)}><i className={`right fas fa-sort-amount-${props.th === 'clientes.nome' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th> */}
                            <th>STATUS</th>
                            <th>AÇÕES</th>
                        </Thead>
                        <Tbody>
                            <BillsToPayRecords
                                billsToPay={props.billsToPay}
                                formatReal={formatReal}
                                onDelete={onChangeModal}
                                changeAllBox={changeAllBox}
                            />
                        </Tbody>
                        <Tfoot>
                            {perfis.indexOf(1) >= 0 ||
                            perfis.indexOf(2) >= 0 ||
                            perfis.indexOf(3) >= 0 ||
                            perfis.indexOf(6) >= 0 ? (
                                <th></th>
                            ) : (
                                ''
                            )}
                            {/* <th></th> */}
                            {/* <th></th> */}
                            {perfis.indexOf(7) >= 0 ? (
                                <>
                                    <th></th>
                                </>
                            ) : (
                                ''
                            )}
                            <th></th>
                            <th></th>
                            <th></th>
                            {perfis.indexOf(1) >= 0 ||
                            perfis.indexOf(2) >= 0 ||
                            perfis.indexOf(3) >= 0 ||
                            perfis.indexOf(6) >= 0 ? (
                                <>
                                    <th className="text-right">Total: </th>
                                </>
                            ) : (
                                <th></th>
                            )}

                            {perfis.indexOf(1) >= 0 ||
                            perfis.indexOf(2) >= 0 ||
                            perfis.indexOf(3) >= 0 ||
                            perfis.indexOf(6) >= 0 ? (
                                <>
                                    <th>
                                        R$ {formatNumeric(props.valorTotal)}
                                    </th>
                                    <th>
                                        R${' '}
                                        {formatNumeric(
                                            props.valorTotalCorrecao
                                        )}
                                    </th>
                                </>
                            ) : (
                                <th className="text-right">Total: </th>
                            )}

                            {perfis.indexOf(7) >= 0 ? (
                                <>
                                    <th>
                                        R${' '}
                                        {formatNumeric(
                                            props.valorTotalCorrecao
                                        )}
                                    </th>
                                    <th>
                                        R${' '}
                                        {formatNumeric(
                                            props.valorTotalContabil
                                        )}
                                    </th>
                                    <th>
                                        R$ {formatNumeric(props.valorTotal)}
                                    </th>
                                </>
                            ) : (
                                <th>
                                    R$ {formatNumeric(props.valorTotalContabil)}
                                </th>
                            )}
                            <th></th>
                            <th></th>
                        </Tfoot>
                    </Table>
                </div>
            </StylePage>

            <Footer />
        </div>
    );
}

const mapStateToProps = (state) => ({
    billsToPay: state.bills_to_pay.bills_to_pay,
    perfis: state.auth.user.perfis,
    current_page: state.bills_to_pay.bills_to_pay.current_page,
    last_page: state.bills_to_pay.bills_to_pay.last_page,
    from: state.bills_to_pay.bills_to_pay.from,
    to: state.bills_to_pay.bills_to_pay.to,
    qtd_total: state.bills_to_pay.bills_to_pay.total,
    qtd_per_page: state.bills_to_pay.qtd_per_page,
    pesquisa: state.bills_to_pay.pesquisa,
    id_role: state.auth.user.id_role,
    asc: state.bills_to_pay.asc,
    th: state.bills_to_pay.th,
    competencia: state.bills_to_pay.competencia,
    valorTotal: state.bills_to_pay.valorTotal,
    valorTotalContabil: state.bills_to_pay.valorTotalContabil,
    valorTotalCorrecao: state.bills_to_pay.valorTotalCorrecao,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            pagination,
            get,
            order,
            change_qtd_per_page,
            changePesquisa,
            clearPesquisa,
            changePage,
            search,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(BillsToPay);
