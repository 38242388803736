const AreaReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Areas':
            return{...state, areas:actions.payload}

        case 'GetAreas_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetAreas_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_area':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaArea':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaArea':
            return{...state, pesquisa:actions.payload}

        case 'changePageArea':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default AreaReducer;