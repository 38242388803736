import {Route, Redirect} from 'react-router-dom'
import React from 'react'
import {connect} from 'react-redux'
import { verifyTokenValid } from './Js/verifyTokenValid';

const SalesRouter = ({component:Component, ...rest }) =>{
   
    
    return (  
        <Route
            {...rest}
            
            render={props => {
                let token = localStorage.getItem('token');
                
                if(token){
                    if(!verifyTokenValid(token))
                    {
                        localStorage.removeItem('token')
                        return <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                        />
                    }

                    if(rest.perfis.indexOf(1) >= 0 || rest.perfis.indexOf(2) >= 0 || rest.perfis.indexOf(3) >= 0 || rest.perfis.indexOf(6) >= 0 || rest.perfis.indexOf(8) >= 0 || rest.perfis.indexOf(9) >= 0 || rest.perfis.indexOf(12) >= 0){
                        return <Component {...props} />
                    }else{
                        return <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />  
                    }      

                }else{              
                    return <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: props.location }
                                }}
                             />  
                }     
            }}
        />
    );
}
const mapStateToProps = state => {
    return{
        perfis:state.auth.user.perfis,   
    }
}
export default connect(mapStateToProps)(SalesRouter);