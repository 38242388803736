import React from 'react'
import Modal from 'react-bootstrap/Modal'
export default function ModalLarge(props) {
    return (
        <>
            <Modal
                size="lg"
                show={props.show}
                onHide={props.onHide}
                aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            </Modal>
        </>
    );
}
  