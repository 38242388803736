import React from 'react'

export default function CabecalhoNew(props) {

    // const enterPressed = (event) => {
    //     var code = event.keyCode || event.which;
    //     if(code === 13) { //13 is the enter keycode
    //         props.action()
    //     } 
    // }
    return (
        <>
            <div className="row mt-3">
                    {props.filter}
                <div className="col-md-2 col-sm-12" style={{marginTop: '47px'}}>
                    <button style={{background: '#2D9CDB', color: '#FFF', width: '100%', borderRadius: '20.5px'}} to={props.to} onClick={props.action} className="btn btn-sm"><i className="fas fa-search"></i> Pesquisar</button>
                    {/* <Link to={props.to} onClick={props.clear} style={{marginLeft:'10px', marginRight:'10px'}} className="btn btn-outline-danger btn-sm">Limpar Busca</Link> */}
                </div>
                <div className="col-md-4" style={{marginTop: '47px'}}>
                    {props.buttonCsv} 
                </div>
            </div>
            <div className="text-center mt-4">        
                {props.buttonStatus} 
            </div>
        </>
    )
}
