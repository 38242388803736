import React from "react";
import Table from "../../../Table/Table";
import Thead from "../../../Table/Thead";
import Tbody from "../../../Table/Tbody";

export default function TablePagamento(props) {
    return (
        <Table className="table" title={props.title}>
            <Thead>
                <th className="text-center">DATA PARCELA</th>
                <th className="text-center">VALOR</th>
                <th className="text-center">AÇÃO</th>
            </Thead>
            <Tbody>{props.children}</Tbody>
            {/* <tr>
                <th>Total: {props.total}</th>
            </tr> */}
        </Table>
    );
}
