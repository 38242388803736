const BillsToPayReducer = (state = {}, actions) => {
    switch (actions.type) {
        case "BillsToPay":
            return { ...state, bills_to_pay: actions.payload };
        case "BillsStatusChange":
            return { ...state, status_report: actions.payload };
        case "BillsTechnicalChange":
            return { ...state, technical: actions.payload };
        case "BillsTypeChange":
            return { ...state, typeBill: actions.payload };
        case "BillsTypesProjectsChange":
            return { ...state, typeProjects: actions.payload };
        case "BillsProjectChange":
            return { ...state, projectBill: actions.payload };
        case "ValorTotalUpdate":
            return { ...state, valorTotal: actions.payload };
        case "ValorTotalContabilUpdate":
            return { ...state, valorTotalContabil: actions.payload };
        case "ValorTotalCorrecaoUpdate":
            return { ...state, valorTotalCorrecao: actions.payload };
        case "GetBillsToPay_Desc":
            return {
                ...state,
                desc: actions.desc,
                asc: actions.asc,
                order: actions.order,
                th: actions.th,
            };

        case "GetBillsToPay_Asc":
            return {
                ...state,
                desc: actions.desc,
                asc: actions.asc,
                order: actions.order,
                th: actions.th,
            };

        case "qtd_per_page_BillsToPay":
            return { ...state, qtd_per_page: actions.payload };

        case "pesquisaBillsToPay":
            return { ...state, pesquisa: actions.payload };

        case "clearPesquisaBillsToPay":
            return {
                ...state,
                pesquisa: actions.payload,
                coordinator_report: actions.coordinator_report,
                status_report: actions.status_report,
                technical: actions.technical,
                typeBill: actions.typeBill,
                typeProjects: actions.typeProjects,
                projectBill: actions.projectBill,
                area_report: actions.area_report,
                from: actions.from,
                to: actions.to,
            };

        case "changeCoordinatorReportBillsToPay":
            return { ...state, coordinator_report: actions.payload };

        case "changeStatusReportBillsToPay":
            return { ...state, status_report: actions.payload };

        case "changeAreaReportBillsToPay":
            return { ...state, area_report: actions.payload };

        case "changeFromReportBillsToPay":
            return { ...state, from: actions.payload };

        case "changeToReportBillsToPay":
            return { ...state, to: actions.payload };

        case "changePageBillsToPay":
            return { ...state, page: actions.payload };

        case "changeCompetencia":
            return { ...state, competencia: actions.payload };
        default:
            return state;
    }
};
export default BillsToPayReducer;
